<section *ngIf="FinalPageComponent.isLoading || FinalPageComponent.finalPageEnum == FinalPageEnum.None" style=" text-align: center;PADDING: 50px;">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</section>

<section [hidden]="FinalPageComponent.isLoading" class="set-row-width">
  <div class="font-30 text-center">
    {{ FinalPageComponent.finalPageEnum == FinalPageEnum.Congratulation ? 'Congratulations!' : 'Thank you'}}
  </div>
  
  <div class="text-center my-4">
    <img src="{{getThankYouImage()}}" alt="Congrats!" width="100" height="100" >
  </div>

  <!-- <div class="set-row-width" *ngIf="finalPageEnum == FinalPageEnum.ErrorReceived">
    <p class="font-18" style="line-height: 30px">
      Thank you for completing the application! For technical reasons we are not able to process your application online, we encourage you to come to our nearest office to fill in the application
    </p>
  </div> -->

  <div class="set-row-width" >
    <div *ngIf="FinalPageComponent.finalPageEnum == FinalPageEnum.Wait2Days || FinalPageComponent.finalPageEnum == FinalPageEnum.ErrorReceived">
      <p class="font-18" style="line-height: 30px" >
        Hooray! We received your application and will contact you within 24 business hours to gather more information.</p>
      <p>
        Questions? Call us at 800.421.7111. We look forward to becoming your best financial partner and friend.  
      </p>
    </div>


    <div *ngIf="FinalPageComponent.finalPageEnum == FinalPageEnum.Congratulation">
      <p class="font-18" style="line-height: 30px" >
        <!-- Your application has been approved. Please contact Member Services at 800.421.7111 if you would like to add an
        additional joint owner or beneficiary. -->

        Congratulations {{FinalPageComponent.dataFinal.firstName}}, your application has been approved! Below are next steps to  <strong>finalize your membership:</strong>

      </p>
      <ul>
        <li>
          <p>Your member number is <strong>{{FinalPageComponent.dataFinal.memberNumber}}</strong>. You’ll need it to <a target="_blank" href="{{staticLinks['Final.Congratulation.RegisterBank']}}">register for online banking</a>. Once you register you’ll be able to log into our mobile app. </p>
        </li>
        <li>
          <p>If you decided to “Sign Later” keep an eye out for a DocuSign email. Please be sure to sign your documents. </p>
        </li>

        <li *ngIf="AppComponent.direct_deposit_shown_failed">
          <p>If you would like to enroll in Direct Deposit or your were not able to use our Direct Deposit Switch Feature, please click on the below link
            <br>
            <a target="_blank" href="{{staticLinks['Final.Congratulation.DD']}}">https://www.skyone.org/sign-up-for-direct-deposit/</a>.
           </p>
        </li>

      </ul>

      <p>Enjoying your experience so far? Want to earn up to $500 just for referring your friends to us? <a href="{{staticLinks['Final.Congratulation.Refer']}}">Learn more</a></p>
      <p>Check out our current offers <a href="{{staticLinks['Skyone']}}">here</a>.</p>
    </div>
  </div>
</section>
