export enum CardType {
    AMERICAN_EXPRESS = 'American Express',
    DINERS = 'Diners',
    DINERS_CARTE_BLANCHE = 'Diners Carte Blanche',
    DISCOVER_CLUB = 'Discover Club',
    CHINA_UNIONPAY = 'China UnionPay',
    JCB = 'JCB',
    LASER = 'Laser',
    MAESTRO = 'Maestro',
    MASTERCARD = 'Mastercard',
    VISA_ELECTRON = 'Visa Electron',
    VISA = 'VISA',
  }