import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { CommonService } from "./common.service";

@Injectable(
    {
        providedIn: "root"
    }
)
export class LoanApiLogService {
    constructor( private httpClient: HttpClient,private commonService: CommonService)    {

    }


    saveProductCode(appId, selectedProducts): Observable<any> {
        return this
        .httpClient
        .post(this.commonService.baseUrl + `/api/loanApiLog/save-product-code-log/${appId}`
        , selectedProducts, this.commonService.getAuthHeaderNew(true));
    }

     parseXml(xmlStr) {
        var result;
        var parser = require('xml2js');
        parser.Parser().parseString(xmlStr, (e, r) => {result = r});
        return result;
    }

    async testXmlToJson(){
        
      var obj =  this.parseXml('<OUTPUT version="1.0"><ERROR type="INTERNAL_ERROR">Unexpected execution.  Please inform a system administrator.  </ERROR></OUTPUT>');
      console.log(obj)

      var obj2 =  this.parseXml('<OUTPUT version="1.0"><RESPONSE loan_number="70525" loan_id="c0ce7a68847f4b019a131367b8fede30" /></OUTPUT>');
      console.log(obj2)

      

      // console.log('case ' + appId);
      // if(obj && obj.OUTPUT.RESPONSE[0].$.loan_id && obj.OUTPUT.RESPONSE[0].$.loan_number && obj.OUTPUT.RESPONSE[0].$.loan_id == appId){
      //   console.log('true');
      // }else{
      //   console.log('false');
      // }




    }

    // async precheckBookSymitar(appId: string){
    //     try{
    //         await this.apiService.getLoan(appId).toPromise();
    //         //check Add Approved Acccounts response
    //         var checkApiLog = await this.apiService.getLoanApiLogByApiLink(appId, "Add Approved Acccounts").toPromise();
    //         console.log(checkApiLog)
    //         const obj = this.parseXml(checkApiLog.rawResponse);
    //         console.log(obj)
    //         //If RawResponse IS NOT NULL and Loan_number is not null, and Loan_Id is NOT null and Loan_Id== appId
    //         if(obj && obj.OUTPUT.RESPONSE[0].$.loan_id && obj.OUTPUT.RESPONSE[0].$.loan_number && obj.OUTPUT.RESPONSE[0].$.loan_id == appId){
    //           return true;
    //         }
    //         return false;
    //       }
    //     catch{
    //       return false;
    //     }
    //   }
}