import { FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTIONFOM_ANSWER, FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTION, BASE_EMPLOYMENTEmployment_status, BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION, BASE_MONTHLY_DEBT, BASE_LOAN_INFO, BASE_EMPLOYMENT, BASE_CONTACT_INFOPreferred_contact_method, base_applicant_type } from './common.model';

    /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [System.SerializableAttribute()]
        [System.Diagnostics.DebuggerStepThroughAttribute()]
        [System.ComponentModel.DesignerCategoryAttribute("code")]
        [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_LOANAPPLICANTS {
            public APPLICANT: PERSONAL_APPLICANT[];
            public selected_product_borrower_id: string;
        }
    
    
    
        export enum PERSONAL_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status {
            LIVEWITHPARENTS,
    
            OWN,
    
            RENT,
    
            BUYING,
    
            OTHER,
    
            GOVQUARTERS,
    
            BARRACKS,
    
            LEASE,
    
            SUBLEASE,
    
            PERSONALRESIDENCE,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_CURRENT_ADDRESS_CHOICE {
                public LOOSE_ADDRESS: BASE_ADDRESS_LOOSE;
        }
    
    /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [System.SerializableAttribute()]
        [System.Diagnostics.DebuggerStepThroughAttribute()]
        [System.ComponentModel.DesignerCategoryAttribute("code")]
        [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class PERSONAL_BASE_APPLICANTCURRENT_ADDRESS extends BASE_CURRENT_ADDRESS_CHOICE {
        public occupancy_status: PERSONAL_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status;
        public occupancy_statusSpecified: boolean;
        public occupancy_duration: string;
        public other_occupancy_description: string;
    }
    
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ADDRESS_LOOSE {
            public street_address_1: string;
            public street_address_2: string;
            public country: string;
            public address_verification_date: Date;
            public address_verification_dateSpecified: boolean;
            public city: string;
            public state: string;
            public zip: string;
            public county: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BENEFICIAL_OWNERS {
            public BENEFICIAL_OWNER: BENEFICIAL_OWNERSBENEFICIAL_OWNER[];
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BENEFICIAL_OWNERSBENEFICIAL_OWNER {
            public ID_CARD: BASE_ID_CARD;
            public first_name: string;
            public middle_name: string;
            public last_name: string;
            public suffix: string;
            public dob: Date;
            public dobSpecified: boolean;
            public is_tin_ssn: BASE_APPLICANTDeclined_answer_race_gender;
            public is_tin_ssnSpecified: boolean;
            public ssn: string;
            public address: string;
            public city: string;
            public state: string;
            public zip: string;
            public is_beneficial_owner: BASE_APPLICANTDeclined_answer_race_gender;
            public is_beneficial_ownerSpecified: boolean;
            public percent_business_owned: number;
            public percent_business_ownedSpecified: boolean;
            public is_control: BASE_APPLICANTDeclined_answer_race_gender;
            public is_controlSpecified: boolean;
            public is_authorized_credit_report: BASE_APPLICANTDeclined_answer_race_gender;
            public is_authorized_credit_reportSpecified: boolean;
            public citizenship: BENEFICIAL_OWNERSBENEFICIAL_OWNERCitizenship;
            public citizenshipSpecified: boolean;
            public control_title: BENEFICIAL_OWNERSBENEFICIAL_OWNERControl_title;
            public control_titleSpecified: boolean;
            public is_ofac_istwatch: BASE_APPLICANTDeclined_answer_race_gender;
            public is_ofac_istwatchSpecified: boolean;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ID_CARD {
            public card_type: string;
            public card_number: string;
            public state: string;
            public country: string;
            public exp_date: Date;
            public exp_dateSpecified: boolean;
            public date_issued: Date;
            public date_issuedSpecified: boolean;
        }
        export enum BASE_APPLICANTDeclined_answer_race_gender {
            Y,
    
            N,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BENEFICIAL_OWNERSBENEFICIAL_OWNERCitizenship {
            USCITIZEN,
    
            PERMRESIDENT,
    
            NONPERMRESIDENT,
    
            NONRESIDENTALIEN,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BENEFICIAL_OWNERSBENEFICIAL_OWNERControl_title {
            AGENT,
    
            CORP_REP,
    
            MANAGER,
    
            OWNER,
    
            PARTNER,
    
            PRESIDENT,
    
            SECRETARY,
    
            TREASURER,
    
            VICE_PRESIDENT,
    
            MEMBER,
    
            DIRECTOR,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_WEBMS_HISTORY {
            public MESSAGE: BASE_WEBMS_HISTORYMESSAGE[];
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_WEBMS_HISTORYMESSAGE {
            public time: Date;
            public timeSpecified: boolean;
            public name: string;
            public type: string;
            public text: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_EVENT_HISTORY {
            public EVENT: BASE_EVENT_HISTORYEVENT[];
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_EVENT_HISTORYEVENT {
            public timestamp: Date;
            public timestampSpecified: boolean;
            public username: string;
            public $event: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_MODIFICATION_HISTORY {
            public AUDIT: BASE_MODIFICATION_HISTORYAUDIT[];
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_MODIFICATION_HISTORYAUDIT {
            public modification_date: Date;
            public modification_dateSpecified: boolean;
            public modifier_name: string;
            public modifier_type: string;
            public Text: string[];
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_LOAN_CONTACTS {
            public CONTACT_INFO: BASE_LOAN_CONTACTSCONTACT_INFO[];
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_LOAN_CONTACTSCONTACT_INFO {
            public contact_type: BASE_LOAN_CONTACTSCONTACT_INFOContact_type;
            public nss_borrower_index: string;
            public first_name: string;
            public middle_name: string;
            public last_name: string;
            public suffix: string;
            public address: string;
            public city: string;
            public state: string;
            public zip: string;
            public phone: string;
            public work_phone: string;
            public fax: string;
            public cell: string;
            public pager: string;
            public email: string;
            public company_name: string;
            public business_entity_type: base_business_entity_type;
            public business_entity_typeSpecified: boolean;
            public role_type: string;
            public department_name: string;
            public company_address: string;
            public company_city: string;
            public company_state: string;
            public company_zip: string;
            public company_phone: string;
            public company_fax: string;
            public county: string;
            public notes: string;
            public ssn: string;
            public dob: Date;
            public dobSpecified: boolean;
            public is_ssn_taxid: BASE_APPLICANTDeclined_answer_race_gender;
            public is_ssn_taxidSpecified: boolean;
            public paac: string;
        }
        export enum BASE_LOAN_CONTACTSCONTACT_INFOContact_type {
            ASSETOWN,
    
            INSAGENT,
    
            TITLEDPER,
    
            OTHER,
    
            SELLER,
    
            NSS,
    
            APPLICANT,
    
            BUSINESS,
    
            TRUST
        }
        export enum base_business_entity_type {
            JOINT_VEN,
    
            ASSN,
    
            UNINCORPORATED_ASSN,
    
            PARTNER,
    
            GEN_PARTNER,
    
            CORP,
    
            SCORP,
    
            CCORP,
    
            NONPROFIT,
    
            LLC,
    
            LLP,
    
            SP,
    
            LIMPARTNER,
    
            CCORPLLC,
    
            SCORPLLC,
    
            PARTNERLLC,
    
            SPLLC,
    
            ORG_OR_CLUB,
    
            NONPROFITCORP,
    
            NONPROFITORG,
    
            PROFESSIONALCORP,
    
            PUBLICFUNDS,
    
            NONMEMBER,
    
            IOLTA,
    
            UAHROF,
    
            GOVERNMENT,
    
            TRUST,
    
            OTHER,
    
            IOLA,
    
            NGO,
    
            INDIVIDUAL,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class INTEGRATION_DATA {
    
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_HMDA_XML {
            public ITEM: BASE_HMDA_XMLITEM[];
            public version: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_HMDA_XMLITEM {
            public key: string;
            public value: string;
            public Value: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_HMDA_INFO {
            public property_address: string;
            public property_city: string;
            public property_state: string;
            public property_type: BASE_HMDA_INFOProperty_type;
            public property_typeSpecified: boolean;
            public property_zip: string;
            public is_hoepa: BASE_APPLICANTDeclined_answer_race_gender;
            public is_hoepaSpecified: boolean;
            public is_hoepa_manual: BASE_APPLICANTDeclined_answer_race_gender;
            public is_hoepa_manualSpecified: boolean;
            public lien_position: number;
            public lien_positionSpecified: boolean;
            public hmda_loan_purpose: string;
            public property_occupancy_status: BASE_HMDA_INFOProperty_occupancy_status;
            public property_occupancy_statusSpecified: boolean;
            public msa_number: string;
            public property_tax_id: string;
            public census_tract_number: string;
            public property_county: string;
            public property_county_code: string;
            public state_code: string;
            public interview_method: BASE_HMDA_INFOInterview_method;
            public interview_methodSpecified: boolean;
            public purchaser: BASE_HMDA_INFOPurchaser;
            public purchaserSpecified: boolean;
            public property_address_verification_date: Date;
            public property_address_verification_dateSpecified: boolean;
        }
        export enum BASE_HMDA_INFOProperty_type {
            SFR,
    
            //[System.Xml.Serialization.XmlEnumAttribute("2 UNIT")]
            Item2UNIT,
    
            //[System.Xml.Serialization.XmlEnumAttribute("3 UNIT")]
            Item3UNIT,
    
            //[System.Xml.Serialization.XmlEnumAttribute("4 UNIT")]
            Item4UNIT,
    
            //[System.Xml.Serialization.XmlEnumAttribute("5+ UNIT")]
            Item5UNIT,
    
            TOWNHOUSE,
    
            //[System.Xml.Serialization.XmlEnumAttribute("HIGH RISE CONDO")]
            HIGHRISECONDO,
    
            //[System.Xml.Serialization.XmlEnumAttribute("LOW RISE CONDO")]
            LOWRISECONDO,
    
            PUD,
    
            //[System.Xml.Serialization.XmlEnumAttribute("MOBILE HOME")]
            MOBILEHOME,
    
            //[System.Xml.Serialization.XmlEnumAttribute("MANUFACTURED HOME")]
            MANUFACTUREDHOME,
    
            //[System.Xml.Serialization.XmlEnumAttribute("MIXED USE")]
            MIXEDUSE,
    
            CONDOTEL,
    
            COOP,
    
            COMMERCIAL,
    
            FARM,
    
            //[System.Xml.Serialization.XmlEnumAttribute("HOME BUSINESS")]
            HOMEBUSINESS,
    
            LAND,
    
            OTHER,
    
            MNL,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_HMDA_INFOProperty_occupancy_status {
            INVESTMENT,
    
            //[System.Xml.Serialization.XmlEnumAttribute("PRIMARY RESIDENCE")]
            PRIMARYRESIDENCE,
    
            //[System.Xml.Serialization.XmlEnumAttribute("SECOND HOME")]
            SECONDHOME,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_HMDA_INFOInterview_method {
            TELEPHONE,
    
            FACETOFACE,
    
            INTERNET,
    
            MAIL,
    
            FAX,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_HMDA_INFOPurchaser {
            NOT_ORIGINATED,
    
            FANNIE_MAE,
    
            GINNIE_MAE,
    
            FREDDIE_MAC,
    
            FARMER_MAC,
    
            PRIVATE,
    
            COMMERCIAL,
    
            LIFE_INSURANCE,
    
            AFFILIATE,
    
            OTHER
        }
        /*[System.Xml.Serialization.XmlIncludeAttribute(typeof(PERSONAL_SYSTEM))]
            [System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_SYSTEM {
            public PROCESSOR: BASE_PARTY;
            public LOAN_OFFICER: BASE_PARTY;
            public BOOKING_OFFICER: BASE_PARTY;
            public APPROVAL_OFFICER: BASE_PARTY;
            public COUNTER_OFFER_OFFICER: BASE_PARTY;
            public FUNDER: BASE_PARTY;
            public DECLINING_OFFICER: BASE_PARTY;
            public DECIDING_OFFICER: BASE_PARTY;
            public ORIGINATOR: BASE_SYSTEMORIGINATOR;
            public BRANCH: BASE_BRANCH;
            public LENDER: BASE_SYSTEMLENDER;
            public ORGANIZATION: BASE_PARTY;
            public INSURANCE_SELLER: BASE_PARTY;
            public CLINIC: BASE_PARTY;
            public CLINIC_WORKER: BASE_PARTY;
            public INSURANCE_LAST_PULLED_OFFICER: BASE_PARTY;
            public source: string;
            public origination_ip: string;
            public external_source: string;
            public loan_number: string;
            public reference_number_type: string;
            constructor() {
                this.source = "GATEWAY";
                this.origination_ip = "";
            }
        }
        /*[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_BRANCH))]
            [System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_PARTY {
            public reference_id: string;
            public name: string;
            public code: string;
            public phone: string;
            public fax: string;
            public email: string;
            public title: string;
            public teller_number: string;
            public terminal_id: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_BRANCH extends BASE_PARTY {
            public ADDRESS: BASE_ADDRESS_LOOSE;
            public reference_id2: string;
            public deluxe_org_reporting_unit: string;
            public ews_org_reporting_unit: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_SYSTEMORIGINATOR extends BASE_PARTY {
            public type: BASE_SYSTEMORIGINATORType;
        }
        export enum BASE_SYSTEMORIGINATORType {
            C,
    
            L,
    
            P,
    
            O,
    
            S,
    
            Z
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_SYSTEMLENDER extends BASE_PARTY {
            public ADDRESS: BASE_ADDRESS_LOOSE;
            public cuna_client_id: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_SYSTEM extends BASE_SYSTEM {
            public CLOSING_BRANCH: BASE_BRANCH;
            public type: PERSONAL_SYSTEMType;
        }
        export enum PERSONAL_SYSTEMType {
            LPQ,
    
            DAPP,
    
            OTHER
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_STRUCTURED_COMMENTS {
            public STRUCTURED_INTERNAL_COMMENTS: BASE_STRUCTURED_COMMENT[];
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_STRUCTURED_COMMENT {
            public MODIFIER: BASE_PARTY;
            public modifier_type: BASE_STRUCTURED_COMMENTModifier_type;
            public modifier_typeSpecified: boolean;
            public last_modified_date: Date;
            public last_modified_dateSpecified: boolean;
            public creation_date: Date;
            public creation_dateSpecified: boolean;
            public message: string;
        }
        export enum BASE_STRUCTURED_COMMENTModifier_type {
            L,
    
            Z,
    
            U
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_COMMENTS {
            public DECISION_COMMENTS: Object;
            public EXTERNAL_COMMENTS: Object;
            public INTERNAL_COMMENTS: Object;
            public STIPULATIONS: Object;
            public FRAUD_WARNINGS: Object;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_CLOSE_END_INFO {
            public ATO: BASE_CLOSE_END_INFOATO[];
            public amount_owed_to_lender: number;
            public amount_owed_to_lenderSpecified: boolean;
            public amount_refinanced: number;
            public amount_refinancedSpecified: boolean;
            public cash_prepaid_finance_charge: number;
            public cash_prepaid_finance_chargeSpecified: boolean;
            public mapr_cash_fee: number;
            public mapr_cash_feeSpecified: boolean;
            public filing_fee_amount: number;
            public filing_fee_amountSpecified: boolean;
            public filing_fee_type: BASE_CLOSE_END_INFOFiling_fee_type;
            public filing_fee_typeSpecified: boolean;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_CLOSE_END_INFOATO {
            public name: string;
            public memo: string;
            public amount: number;
            public amountSpecified: boolean;
            public is_PPFC: BASE_APPLICANTDeclined_answer_race_gender;
            public is_PPFCSpecified: boolean;
            public is_mapr: BASE_APPLICANTDeclined_answer_race_gender;
            public is_maprSpecified: boolean;
            public funding_method: BASE_CLOSE_END_INFOATOFunding_method;
            public funding_methodSpecified: boolean;
        }
        export enum BASE_CLOSE_END_INFOATOFunding_method {
            IL,
    
            SD,
    
            SL,
    
            EL
        }
        export enum BASE_CLOSE_END_INFOFiling_fee_type {
            FINANCED,
    
            CASH,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.Xml.Serialization.XmlIncludeAttribute(typeof(PERSONAL_INSURANCE))]
            [System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_INSURANCE {
            public coverage: BASE_INSURANCECoverage;
            public coverageSpecified: boolean;
            public plan_key: string;
            public description: string;
            public premium: number;
            public premiumSpecified: boolean;
            public premium_mode: BASE_INSURANCEPremium_mode;
            public premium_modeSpecified: boolean;
            public cost_per_day: number;
            public cost_per_daySpecified: boolean;
            public insured_amount: number;
            public insured_amountSpecified: boolean;
            public insured_rate: number;
            public insured_rateSpecified: boolean;
            public expire_date: Date;
            public expire_dateSpecified: boolean;
            public last_pulled_date: Date;
            public last_pulled_dateSpecified: boolean;
        }
        export enum BASE_INSURANCECoverage {
            S,
    
            J,
    
            C,
    
            B
        }
        export enum BASE_INSURANCEPremium_mode {
            MR,
    
            LR,
    
            SP,
    
            DC,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_INSURANCE extends BASE_INSURANCE {
            public type: PERSONAL_INSURANCEType;
            public typeSpecified: boolean;
        }
        export enum PERSONAL_INSURANCEType {
            CREDIT_LIFE,
    
            CREDIT_DISABILITY,
    
            DEBT_CANCELLATION
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PAYMENT_TRANSFER_FROM_OTHER_INSTITUTION_INFO {
            public account_type: base_payment_account_type;
            public account_typeSpecified: boolean;
            public account_number: string;
            public name_on_account: string;
            public bank_routing_number: string;
            public bank_name: string;
            public bank_zip: string;
            public bank_city: string;
            public bank_state: string;
        }
        export enum base_payment_account_type {
            CHECKING,
    
            SAVINGS,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PAYMENT_TRANSFER_FROM_INTERNAL_ACCOUNT_INFO {
            public account_type: base_payment_account_type;
            public account_typeSpecified: boolean;
            public account_number: string;
            public name_on_account: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PAYMENT_MANUAL_NO_COUPON_INFO {
            public account_number: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PAYMENT_PAYROLL_DEDUCTION_INFO {
            public account_number: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PAYMENT_COUPON_BOOK_INFO {
            public account_number: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_PAYMENT_INFO_CHOICE {
            public Item: Object;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class SKIP_PAYMENT_INFO {
            public skip_payment_template_name: string;
            public is_position_of_skips_first_pay_skip: BASE_APPLICANTDeclined_answer_race_gender;
            public is_position_of_skips_first_pay_skipSpecified: boolean;
            public is_defer_skip_payments: BASE_APPLICANTDeclined_answer_race_gender;
            public is_defer_skip_paymentsSpecified: boolean;
            public skips_per_year: string;
            public position_of_skips: string;
            public skip_start_date: Date;
            public skip_start_dateSpecified: boolean;
            public skip_end_date: Date;
            public skip_end_dateSpecified: boolean;
            public skip_period_month_day_start: Date;
            public skip_period_month_day_startSpecified: boolean;
            public skip_period_month_day_end: Date;
            public skip_period_month_day_endSpecified: boolean;
        }
        /*[System.Xml.Serialization.XmlIncludeAttribute(typeof(PERSONAL_FUNDING_INFO))]
            [System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_FUNDING_INFO {
            public SKIP_PAYMENT_INFO: SKIP_PAYMENT_INFO;
            public PAYMENT_INFO: BASE_PAYMENT_INFO_CHOICE;
            public daily_periodic_rate: number;
            public daily_periodic_rateSpecified: boolean;
            public payment_frequency: BASE_FUNDING_INFOPayment_frequency;
            public payment_frequencySpecified: boolean;
            public funded_status: BASE_FUNDING_INFOFunded_status;
            public funded_statusSpecified: boolean;
            public loan_date: Date;
            public loan_dateSpecified: boolean;
            public funding_date: Date;
            public funding_dateSpecified: boolean;
        }
        export enum BASE_FUNDING_INFOPayment_frequency {
            ANNUALLY,
    
            //[System.Xml.Serialization.XmlEnumAttribute("SEMI-ANNUALLY")]
            SEMIANNUALLY,
    
            QUARTERLY,
    
            //[System.Xml.Serialization.XmlEnumAttribute("BI-MONTHLY")]
            BIMONTHLY,
    
            MONTHLY,
    
            //[System.Xml.Serialization.XmlEnumAttribute("QUAD-WEEKLY")]
            QUADWEEKLY,
    
            //[System.Xml.Serialization.XmlEnumAttribute("SEMI-MONTHLY")]
            SEMIMONTHLY,
    
            //[System.Xml.Serialization.XmlEnumAttribute("BI-WEEKLY")]
            BIWEEKLY,
    
            WEEKLY,
    
            //[System.Xml.Serialization.XmlEnumAttribute("SINGLE-PAYMENT")]
            SINGLEPAYMENT,
    
            //[System.Xml.Serialization.XmlEnumAttribute("TWICE-MONTHLY")]
            TWICEMONTHLY
        }
        export enum BASE_FUNDING_INFOFunded_status {
            FUN,
    
            UF,
    
            RF,
    
            PROC,
    
            RD,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_FUNDING_INFO extends BASE_FUNDING_INFO {
            public initial_amount_advanced: number;
            public initial_amount_advancedSpecified: boolean;
            public last_payment_date: Date;
            public last_payment_dateSpecified: boolean;
            public finance_charge: number;
            public finance_chargeSpecified: boolean;
            public total_financed_payment: number;
            public total_financed_paymentSpecified: boolean;
            public other_charges: number;
            public other_chargesSpecified: boolean;
            public other_charges_description: string;
            public amount_advanced: number;
            public amount_advancedSpecified: boolean;
            public prev_loan_liner_balance: number;
            public prev_loan_liner_balanceSpecified: boolean;
            public prev_payment_date: Date;
            public prev_payment_dateSpecified: boolean;
            public other_loans: number;
            public other_loansSpecified: boolean;
            public current_payment: number;
            public current_paymentSpecified: boolean;
            public current_payment_due_date: Date;
            public current_payment_due_dateSpecified: boolean;
            public filing_fee_amount: number;
            public filing_fee_amountSpecified: boolean;
            public filing_fee_type: PERSONAL_FUNDING_INFOFiling_fee_type;
            public filing_fee_typeSpecified: boolean;
            public interest_only_min_payment: number;
            public interest_only_min_paymentSpecified: boolean;
            public interest_only_max_payment: number;
            public interest_only_max_paymentSpecified: boolean;
            public first_payment_date: Date;
            public first_payment_dateSpecified: boolean;
            public funding_loan_term: string;
            public funding_rate: number;
            public funding_rateSpecified: boolean;
            public exact_monthly_payment: number;
            public exact_monthly_paymentSpecified: boolean;
            public exact_monthly_payment_original: number;
            public exact_monthly_payment_originalSpecified: boolean;
            public exact_last_monthly_payment: number;
            public exact_last_monthly_paymentSpecified: boolean;
            public additional_fees: number;
            public additional_feesSpecified: boolean;
            public additional_fees_comment: string;
            public initial_balloon_amount: number;
            public initial_balloon_amountSpecified: boolean;
            public funding_apr: number;
            public funding_aprSpecified: boolean;
            public index: number;
            public indexSpecified: boolean;
            public margin: number;
            public marginSpecified: boolean;
            public floor: number;
            public floorSpecified: boolean;
            public ceiling: number;
            public ceilingSpecified: boolean;
            public index_type: string;
            public loc_amount: number;
            public loc_amountSpecified: boolean;
            public loc_expire_date: Date;
            public loc_expire_dateSpecified: boolean;
            public initial_rate: number;
            public initial_rateSpecified: boolean;
            public rate_expire_date: Date;
            public rate_expire_dateSpecified: boolean;
            public doc_stamps_fee: number;
            public doc_stamps_feeSpecified: boolean;
            public doc_stamps_rate: number;
            public doc_stamps_rateSpecified: boolean;
            public doc_stamps_fee_is_manual: BASE_APPLICANTDeclined_answer_race_gender;
            public doc_stamps_fee_is_manualSpecified: boolean;
            public doc_stamps_type: PERSONAL_FUNDING_INFODoc_stamps_type;
            public doc_stamps_typeSpecified: boolean;
        }
        export enum PERSONAL_FUNDING_INFOFiling_fee_type {
            FINANCED,
    
            CASH,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum PERSONAL_FUNDING_INFODoc_stamps_type {
            FINANCED,
    
            CASH,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_ACH))]
            [System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_LOAN))]
            [System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_FEE))]
            [System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_DEPOSIT))]
            [System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_CHECK))]
            [System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_DISBURSEMENT {
            public amount: number;
            public amountSpecified: boolean;
            public reference_id: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_DISBURSEMENT_ACH extends BASE_DISBURSEMENT {
            public name_on_account: string;
            public account_number: string;
            public bank_routing_number: string;
            public bank_name: string;
            public bank_state: string;
            public account_type: BASE_DISBURSEMENT_ACHAccount_type;
            public account_typeSpecified: boolean;
            public transaction_type: BASE_DISBURSEMENT_ACHTransaction_type;
            public transaction_typeSpecified: boolean;
            public ach_is_primary_applicant: BASE_APPLICANTDeclined_answer_race_gender;
            public ach_is_primary_applicantSpecified: boolean;
            public payoff_date: Date;
            public payoff_dateSpecified: boolean;
            public interest_per_diem: number;
            public interest_per_diemSpecified: boolean;
        }
        export enum BASE_DISBURSEMENT_ACHAccount_type {
            CHECKING,
    
            SAVINGS,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_DISBURSEMENT_ACHTransaction_type {
            NORMAL,
    
            DEALER_RESERVE,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_DISBURSEMENT_LOAN extends BASE_DISBURSEMENT {
            public loan_suffix: string;
            public loan_is_repay_loan: string;
            public loan_description: string;
            public loan_is_same_account: BASE_DISBURSEMENT_LOANLoan_is_same_account;
            public loan_is_same_accountSpecified: boolean;
            public payoff_date: Date;
            public payoff_dateSpecified: boolean;
            public interest_per_diem: number;
            public interest_per_diemSpecified: boolean;
            public loan_type: string;
            public payment_type: string;
            public loan_account_number: string;
        }
        export enum BASE_DISBURSEMENT_LOANLoan_is_same_account {
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item,
    
            Y,
    
            N
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_DISBURSEMENT_FEE extends BASE_DISBURSEMENT {
            public prepaid_fees_financed: number;
            public prepaid_fees_financedSpecified: boolean;
            public fees_financed: number;
            public fees_financedSpecified: boolean;
            public fees_gl_account_number: string;
            public fee_description: string;
            public fee_linking_type: BASE_DISBURSEMENT_FEEFee_linking_type;
            public fee_linking_typeSpecified: boolean;
        }
        export enum BASE_DISBURSEMENT_FEEFee_linking_type {
            ATO1,
    
            ATO2,
    
            ATO3,
    
            ATO4,
    
            ATO5,
    
            ATO6,
    
            ATO7,
    
            ATO8,
    
            ATO9,
    
            ATO10,
    
            DSF,
    
            FF,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_DISBURSEMENT_DEPOSIT extends BASE_DISBURSEMENT {
            public deposit_account: string;
            public deposit_suffix: string;
            public deposit_type: string;
            public deposit_date_to_credit: Date;
            public deposit_date_to_creditSpecified: boolean;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_DISBURSEMENT_CHECK extends BASE_DISBURSEMENT {
            public check_number: string;
            public check_payable_to: string;
            public check_address_1: string;
            public check_address_2: string;
            public check_address_3: string;
            public check_zip: string;
            public check_remitter: string;
            public check_purpose: BASE_DISBURSEMENT_CHECKCheck_purpose;
            public check_purposeSpecified: boolean;
            public check_status: BASE_DISBURSEMENT_CHECKCheck_status;
            public check_statusSpecified: boolean;
            public check_print_option: number;
            public check_print_optionSpecified: boolean;
            public check_is_payee_only: BASE_APPLICANTDeclined_answer_race_gender;
            public check_is_payee_onlySpecified: boolean;
            public check_is_primary_applicant: BASE_APPLICANTDeclined_answer_race_gender;
            public check_is_primary_applicantSpecified: boolean;
            public payoff_date: Date;
            public payoff_dateSpecified: boolean;
            public interest_per_diem: number;
            public interest_per_diemSpecified: boolean;
            public check_remitter_contact_index: string;
            public check_remitter_contact_index2: string;
            public check_coremitter_contact_index: string;
            public is_payee_ofac_istwatch: BASE_APPLICANTDeclined_answer_race_gender;
            public is_payee_ofac_istwatchSpecified: boolean;
            public istwatch_transaction_date: string;
            public check_account_number: string;
        }
        export enum BASE_DISBURSEMENT_CHECKCheck_purpose {
            CLSAC,
    
            PAYBL,
    
            VLD,
    
            VLPRD,
    
            VLABD,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_DISBURSEMENT_CHECKCheck_status {
            NOT_PRINTED,
    
            PRINTED,
    
            VOIDED
        }
        /*[System.Xml.Serialization.XmlIncludeAttribute(typeof(PERSONAL_STATUS))]
            [System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_LOAN_STATUS {
            public loan_status: BASE_LOAN_STATUSLoan_status;
            public initial_entry_timestamp: Date;
            public initial_entry_timestampSpecified: boolean;
            public last_modified: Date;
            public last_modifiedSpecified: boolean;
            public submit_date: Date;
            public submit_dateSpecified: boolean;
            public underwrite_submit_date: Date;
            public underwrite_submit_dateSpecified: boolean;
            public is_locked: BASE_APPLICANTDeclined_answer_race_gender;
            public existing_balance: number;
            public existing_balanceSpecified: boolean;
            public existing_balance_date: Date;
            public existing_balance_dateSpecified: boolean;
            public delinquency_period: string;
            public delinquency_amount: number;
            public delinquency_amountSpecified: boolean;
            public approval_date: Date;
            public approval_dateSpecified: boolean;
            public declined_date: Date;
            public declined_dateSpecified: boolean;
            public first_approval_date: Date;
            public first_approval_dateSpecified: boolean;
            public next_payment_date: Date;
            public next_payment_dateSpecified: boolean;
            public response_date: Date;
            public response_dateSpecified: boolean;
            public counter_offer_date: Date;
            public counter_offer_dateSpecified: boolean;
            public app_receive_date: Date;
            public app_receive_dateSpecified: boolean;
            public deciding_date: Date;
            public deciding_dateSpecified: boolean;
            public booking_date: Date;
            public booking_dateSpecified: boolean;
            public booking_reference_id: string;
        }
        export enum BASE_LOAN_STATUSLoan_status {
            //[System.Xml.Serialization.XmlEnumAttribute("PRE-APP")]
            PREAPP,
    
            PEN,
    
            APP,
    
            AA,
    
            AD,
    
            AP,
    
            OFF,
    
            DEC,
    
            DUP,
    
            INC,
    
            DEN,
    
            CAN,
    
            INQ,
    
            NMI,
    
            CODEC,
    
            AP2S,
    
            MEMWAIT,
    
            WTHDRN,
    
            WTHDRNO,
    
            REF,
    
            AMD,
    
            MEMDC,
    
            REVIEW,
    
            FRAUD,
    
            CFI,
    
            CFIO,
    
            PRD,
    
            PRA
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_STATUS extends BASE_LOAN_STATUS {
            public payoff_date: Date;
            public payoff_dateSpecified: boolean;
            public amount_charge_off: number;
            public amount_charge_offSpecified: boolean;
        }
        
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_LOAN_INFO extends BASE_LOAN_INFO {
            public personal_loan_type: PERSONAL_LOAN_INFOPersonal_loan_type;
            public personal_loan_typeSpecified: boolean;
            public purpose_type: string;
            public amount_requested: number;
            public amount_requestedSpecified: boolean;
            public loan_term: string;
            public loan_term_requested: string;
            public amount_approved: number;
            public amount_approvedSpecified: boolean;
            public rate: number;
            public rateSpecified: boolean;
            public rate_type: PERSONAL_LOAN_INFORate_type;
            public rate_typeSpecified: boolean;
            public monthly_payment: number;
            public monthly_paymentSpecified: boolean;
            public purpose_description: string;
            public misc_implant: string;
            public extra_collateral_owner_1: string;
            public extra_collateral_owner_2: string;
            public is_LOC: BASE_APPLICANTDeclined_answer_race_gender;
            public is_LOCSpecified: boolean;
            public apr: number;
            public aprSpecified: boolean;
            public number_of_payments: number;
            public number_of_paymentsSpecified: boolean;
            public is_indirect_loan: PERSONAL_LOAN_INFOIs_indirect_loan;
            public is_indirect_loanSpecified: boolean;
            public is_balloon: PERSONAL_LOAN_INFOIs_balloon;
            public is_balloonSpecified: boolean;
            public is_overdraft: PERSONAL_LOAN_INFOIs_overdraft;
            public is_overdraftSpecified: boolean;
            public payment_percent: number;
            public payment_percentSpecified: boolean;
            public minimum_payment: number;
            public minimum_paymentSpecified: boolean;
            public reserve_amount: number;
            public reserve_amountSpecified: boolean;
            public is_hmda: BASE_APPLICANTDeclined_answer_race_gender;
            public is_hmdaSpecified: boolean;
            public is_hmda_manual: BASE_APPLICANTDeclined_answer_race_gender;
            public is_hmda_manualSpecified: boolean;
            public amount_approved_variance: number;
            public amount_approved_varianceSpecified: boolean;
            public change_in_circumstances_date: Date;
            public change_in_circumstances_dateSpecified: boolean;
            public product_conversion_date: Date;
            public product_conversion_dateSpecified: boolean;
            public is_advance_request: BASE_APPLICANTDeclined_answer_race_gender;
            public is_advance_requestSpecified: boolean;
            public down_payment: number;
            public down_paymentSpecified: boolean;
            public is_calculate_mapr: BASE_APPLICANTDeclined_answer_race_gender;
            public is_calculate_maprSpecified: boolean;
            public mapr: number;
            public maprSpecified: boolean;
        }
        export enum PERSONAL_LOAN_INFOPersonal_loan_type {
            UNSECURE,
    
            SECURE,
    
            SHARES,
    
            CD
        }
        export enum PERSONAL_LOAN_INFORate_type {
            F,
    
            V,
    
            S,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum PERSONAL_LOAN_INFOIs_indirect_loan {
            Y,
    
            N
        }
        export enum PERSONAL_LOAN_INFOIs_balloon {
            Y,
    
            N
        }
        export enum PERSONAL_LOAN_INFOIs_overdraft {
            Y,
    
            N
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class DECLARATIONS {
            public has_judge_bankrupt_foreclosure: BASE_APPLICANTDeclined_answer_race_gender;
            public has_judge_bankrupt_foreclosureSpecified: boolean;
            public has_declare_bankrupt: BASE_APPLICANTDeclined_answer_race_gender;
            public has_declare_bankruptSpecified: boolean;
            public has_chapter_13: BASE_APPLICANTDeclined_answer_race_gender;
            public has_chapter_13Specified: boolean;
            public has_lawsuit_party: BASE_APPLICANTDeclined_answer_race_gender;
            public has_lawsuit_partySpecified: boolean;
            public has_judgement: BASE_APPLICANTDeclined_answer_race_gender;
            public has_judgementSpecified: boolean;
            public has_foreclosure: BASE_APPLICANTDeclined_answer_race_gender;
            public has_foreclosureSpecified: boolean;
            public has_reposession: BASE_APPLICANTDeclined_answer_race_gender;
            public has_reposessionSpecified: boolean;
            public has_other_obligation: BASE_APPLICANTDeclined_answer_race_gender;
            public has_other_obligationSpecified: boolean;
            public has_past_due_bills: BASE_APPLICANTDeclined_answer_race_gender;
            public has_past_due_billsSpecified: boolean;
            public has_co_maker: BASE_APPLICANTDeclined_answer_race_gender;
            public has_co_makerSpecified: boolean;
            public co_maker_primary_name: string;
            public co_maker_creditor: string;
            public co_maker_amount: number;
            public co_maker_amountSpecified: boolean;
            public has_alias: BASE_APPLICANTDeclined_answer_race_gender;
            public has_aliasSpecified: boolean;
            public alias: string;
            public has_income_decline: BASE_APPLICANTDeclined_answer_race_gender;
            public has_income_declineSpecified: boolean;
            public has_suits_pending: BASE_APPLICANTDeclined_answer_race_gender;
            public has_suits_pendingSpecified: boolean;
            public has_alimony: BASE_APPLICANTDeclined_answer_race_gender;
            public has_alimonySpecified: boolean;
            public alimony_recipient: string;
            public alimony_recipient_address: string;
            public has_property_for_sale: BASE_APPLICANTDeclined_answer_race_gender;
            public has_property_for_saleSpecified: boolean;
        }
        /*[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_SHARE))]
            [System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_SAVINGS_ACCOUNT))]
            [System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_CERTIFICATE))]
            [System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_REAL_ESTATE))]
            [System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_VEHICLE))]
            [System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ASSET {
            public asset_type: BASE_ASSETAsset_type;
            public asset_value: number;
            public asset_valueSpecified: boolean;
            public property_address: string;
            public property_zip: string;
            public property_state: string;
            public property_city: string;
            public existing_loan_amount: number;
            public existing_loan_amountSpecified: boolean;
            public description: string;
            public account_number: string;
            public bank_name: string;
            public is_collateral: BASE_APPLICANTDeclined_answer_race_gender;
            public is_collateralSpecified: boolean;
            public ownership_type: BASE_ASSETOwnership_type;
            public other_owner_name: string;
            public other_owner_name2: string;
            public available_account_info: string;
            public stock_bond_mutualfund_share_count: string;
            public verification_status: BASE_ASSETVerification_status;
            public verification_statusSpecified: boolean;
            public other_owner_contact_index: string;
            public other_owner_contact_index2: string;
        }
        export enum BASE_ASSETAsset_type {
            CERTIFICATE,
    
            CASH_DEPOSIT,
    
            SHARE_BACK,
    
            STOCK,
    
            BOND,
    
            CHECKING_ACCOUNT,
    
            SAVINGS_ACCOUNT,
    
            REALESTATE,
    
            MACHINERY_AND_EQUIPMENT,
    
            FURNITURE_AND_FIXTURES,
    
            AUTOMOBILE,
    
            LIFE_INSURANCE,
    
            RETIREMENT_FUND,
    
            OWNED_BUSINESS,
    
            OTHER,
    
            OTHER_NON_LIQUID,
    
            BRIDGE_LOAN_NOT_DEPOSITED,
    
            CASH_ON_HAND,
    
            GIFT,
    
            MONEY_MARKET,
    
            MUTUAL_FUNDS,
    
            SECURED_BORROWER_FUNDS,
    
            TRUST_FUNDS,
    
            IRA,
    
            NET_SALE_PROCEEDS,
    
            GIFT_OF_EQUITY,
    
            AIRCRAFT,
    
            AS_EXTRACTED,
    
            CHATTEL,
    
            DOC_VESSEL,
    
            FARM_EQUIP,
    
            FARM_PROD,
    
            GEN_INTANGIBLES,
    
            INSTRUMENTS,
    
            INVENTORY,
    
            LEASE_AND_RENTS,
    
            MFG_HOUSING,
    
            NON_TITLED_PERSONAL_PROP,
    
            PARTNERSHIP_INTEREST,
    
            REAL_ESTATE_CONST,
    
            STANDING_TIMBER,
    
            WATERCRAFT
        }
        export enum BASE_ASSETOwnership_type {
            B,
    
            J,
    
            T
        }
        export enum BASE_ASSETVerification_status {
            //[System.Xml.Serialization.XmlEnumAttribute("N/A")]
            NA,
    
            UNVERIFIED,
    
            VERIFIED
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ASSET_SHARE extends BASE_ASSET {
            public expiration_date: Date;
            public expiration_dateSpecified: boolean;
            public pledge_type: string;
            public rate: number;
            public rateSpecified: boolean;
            public pledge_percent: number;
            public pledge_percentSpecified: boolean;
            public pledge_value_type: BASE_ASSET_SHAREPledge_value_type;
            public pledge_value_typeSpecified: boolean;
            public pledge_value: number;
            public pledge_valueSpecified: boolean;
        }
        export enum BASE_ASSET_SHAREPledge_value_type {
            PERCENT,
    
            PERCENTLOAN,
    
            AMOUNT
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ASSET_SAVINGS_ACCOUNT extends BASE_ASSET {
            public expiration_date: Date;
            public expiration_dateSpecified: boolean;
            public issue_date: Date;
            public issue_dateSpecified: boolean;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ASSET_CERTIFICATE extends BASE_ASSET {
            public certificate_number: string;
            public expiration_date: Date;
            public expiration_dateSpecified: boolean;
            public issue_date: Date;
            public issue_dateSpecified: boolean;
            public pledge_type: string;
            public rate: number;
            public rateSpecified: boolean;
            public pledge_percent: number;
            public pledge_percentSpecified: boolean;
            public pledge_value_type: string;
            public pledge_value: number;
            public pledge_valueSpecified: boolean;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ASSET_REAL_ESTATE extends BASE_ASSET {
            public property_type: BASE_ASSET_REAL_ESTATEProperty_type;
            public property_typeSpecified: boolean;
            public property_gross_rental_income: number;
            public property_gross_rental_incomeSpecified: boolean;
            public property_net_rental_income: number;
            public property_net_rental_incomeSpecified: boolean;
            public property_mortgage_payments: number;
            public property_mortgage_paymentsSpecified: boolean;
            public property_other_costs: number;
            public property_other_costsSpecified: boolean;
            public property_current_residence_indicator: BASE_APPLICANTDeclined_answer_race_gender;
            public property_current_residence_indicatorSpecified: boolean;
            public property_disposition: BASE_ASSET_REAL_ESTATEProperty_disposition;
            public property_dispositionSpecified: boolean;
            public is_subject_property: BASE_APPLICANTDeclined_answer_race_gender;
            public is_subject_propertySpecified: boolean;
            public property_tax_id: string;
            public is_financed_property: BASE_APPLICANTDeclined_answer_race_gender;
            public is_financed_propertySpecified: boolean;
        }
        export enum BASE_ASSET_REAL_ESTATEProperty_type {
            SFR,
    
            //[System.Xml.Serialization.XmlEnumAttribute("2 UNIT")]
            Item2UNIT,
    
            //[System.Xml.Serialization.XmlEnumAttribute("3 UNIT")]
            Item3UNIT,
    
            //[System.Xml.Serialization.XmlEnumAttribute("4 UNIT")]
            Item4UNIT,
    
            //[System.Xml.Serialization.XmlEnumAttribute("5+ UNIT")]
            Item5UNIT,
    
            TOWNHOUSE,
    
            //[System.Xml.Serialization.XmlEnumAttribute("HIGH RISE CONDO")]
            HIGHRISECONDO,
    
            //[System.Xml.Serialization.XmlEnumAttribute("LOW RISE CONDO")]
            LOWRISECONDO,
    
            PUD,
    
            //[System.Xml.Serialization.XmlEnumAttribute("MOBILE HOME")]
            MOBILEHOME,
    
            //[System.Xml.Serialization.XmlEnumAttribute("MANUFACTURED HOME")]
            MANUFACTUREDHOME,
    
            //[System.Xml.Serialization.XmlEnumAttribute("MIXED USE")]
            MIXEDUSE,
    
            CONDOTEL,
    
            COOP,
    
            COMMERCIAL,
    
            FARM,
    
            //[System.Xml.Serialization.XmlEnumAttribute("HOME BUSINESS")]
            HOMEBUSINESS,
    
            LAND,
    
            OTHER,
    
            MNL,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_ASSET_REAL_ESTATEProperty_disposition {
            S,
    
            H,
    
            P,
    
            R,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ASSET_VEHICLE extends BASE_ASSET {
            public vehicle_year: string;
            public vehicle_make: string;
            public vehicle_model: string;
            public vehicle_vin: string;
            public vehicle_title_owner: string;
            public vehicle_title_number: string;
            public vehicle_color: string;
            public vehicle_miles: number;
            public vehicle_milesSpecified: boolean;
            public vehicle_license_plate: string;
            public vehicle_seller: string;
            public vehicle_has_proof_insurance: BASE_APPLICANTDeclined_answer_race_gender;
            public vehicle_has_proof_insuranceSpecified: boolean;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_CONTACT_INFO {
            public preferred_contact_method: BASE_CONTACT_INFOPreferred_contact_method;
            public preferred_contact_methodSpecified: boolean;
            public cell_phone: string;
            public cell_phone_country: base_phone_country_alpha2;
            public fax_number: string;
            public fax_number_country: base_phone_country_alpha2;
            public email: string;
            public home_phone: string;
            public home_phone_country: base_phone_country_alpha2;
            public work_phone: string;
            public work_phone_country: base_phone_country_alpha2;
            public work_phone_extension: string;
            constructor() {
                this.cell_phone_country = base_phone_country_alpha2.US;
                this.fax_number_country = base_phone_country_alpha2.US;
                this.home_phone_country = base_phone_country_alpha2.US;
                this.work_phone_country = base_phone_country_alpha2.US;
            }
        }

        export enum base_phone_country_alpha2 {
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item,
    
            AX,
    
            AF,
    
            AL,
    
            DZ,
    
            AS,
    
            AD,
    
            AO,
    
            AI,
    
            AQ,
    
            AG,
    
            AR,
    
            AM,
    
            AW,
    
            AU,
    
            AT,
    
            AZ,
    
            BS,
    
            BH,
    
            BD,
    
            BB,
    
            BY,
    
            BE,
    
            BZ,
    
            BJ,
    
            BM,
    
            BT,
    
            BO,
    
            BA,
    
            BW,
    
            BV,
    
            BR,
    
            IO,
    
            BN,
    
            BG,
    
            BF,
    
            BI,
    
            KH,
    
            CM,
    
            CV,
    
            CA,
    
            KY,
    
            CF,
    
            TD,
    
            CL,
    
            CN,
    
            CX,
    
            CC,
    
            CO,
    
            KM,
    
            CD,
    
            CG,
    
            CK,
    
            CR,
    
            CI,
    
            HR,
    
            CU,
    
            CY,
    
            CZ,
    
            DK,
    
            DJ,
    
            DM,
    
            DO,
    
            TL,
    
            EC,
    
            SV,
    
            EG,
    
            GQ,
    
            ER,
    
            EE,
    
            ET,
    
            FK,
    
            FO,
    
            FJ,
    
            FI,
    
            FR,
    
            GF,
    
            PF,
    
            TF,
    
            GA,
    
            GM,
    
            GE,
    
            DE,
    
            GH,
    
            GI,
    
            GR,
    
            GL,
    
            GD,
    
            GP,
    
            GU,
    
            GT,
    
            GN,
    
            GG,
    
            GW,
    
            GY,
    
            HT,
    
            HM,
    
            HN,
    
            HK,
    
            HU,
    
            IS,
    
            IN,
    
            ID,
    
            IR,
    
            IQ,
    
            IE,
    
            IM,
    
            IL,
    
            IT,
    
            JM,
    
            JP,
    
            JE,
    
            JO,
    
            KZ,
    
            KE,
    
            KI,
    
            KW,
    
            KG,
    
            LA,
    
            LV,
    
            LB,
    
            LS,
    
            LR,
    
            LY,
    
            LI,
    
            LT,
    
            LU,
    
            MO,
    
            MK,
    
            MG,
    
            MW,
    
            MY,
    
            MV,
    
            ML,
    
            MT,
    
            MH,
    
            MQ,
    
            MR,
    
            MU,
    
            YT,
    
            MX,
    
            FM,
    
            MD,
    
            MC,
    
            MN,
    
            ME,
    
            MS,
    
            MA,
    
            MZ,
    
            MM,
    
            NA,
    
            NR,
    
            NP,
    
            NL,
    
            AN,
    
            NC,
    
            NZ,
    
            NI,
    
            NE,
    
            NG,
    
            NU,
    
            NF,
    
            KP,
    
            MP,
    
            NO,
    
            OM,
    
            PK,
    
            PW,
    
            PS,
    
            PA,
    
            PG,
    
            PY,
    
            PE,
    
            PH,
    
            PN,
    
            PL,
    
            PT,
    
            PR,
    
            QA,
    
            RE,
    
            RO,
    
            RW,
    
            RU,
    
            BL,
    
            SH,
    
            KN,
    
            LC,
    
            MF,
    
            PM,
    
            VC,
    
            WS,
    
            SM,
    
            ST,
    
            SA,
    
            SN,
    
            RS,
    
            SC,
    
            SL,
    
            SG,
    
            SK,
    
            SI,
    
            SB,
    
            SO,
    
            ZA,
    
            GS,
    
            KR,
    
            ES,
    
            LK,
    
            SD,
    
            SR,
    
            SJ,
    
            SZ,
    
            SE,
    
            CH,
    
            SY,
    
            TW,
    
            TJ,
    
            TZ,
    
            TH,
    
            TG,
    
            TK,
    
            TO,
    
            TT,
    
            TN,
    
            TR,
    
            TM,
    
            TC,
    
            TV,
    
            UG,
    
            UA,
    
            AE,
    
            GB,
    
            UY,
    
            US,
    
            UM,
    
            UZ,
    
            VU,
    
            VA,
    
            VN,
    
            VG,
    
            VI,
    
            VE,
    
            YE,
    
            WF,
    
            EH,
    
            ZM,
    
            ZW
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_REFERENCE {
            public first_name: string;
            public last_name: string;
            public address: string;
            public city: string;
            public state: string;
            public zip: string;
            public phone: string;
            public relationship: string;
            public email: string;
        }
      
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_MONTHLY_INCOME {
            public OTHER_INCOME: BASE_MONTHLY_INCOMEOTHER_INCOME[];
            public is_monthly_income_base_salary_manual: BASE_APPLICANTDeclined_answer_race_gender;
            public is_monthly_income_base_salary_manualSpecified: boolean;
            public is_bah_included: BASE_APPLICANTDeclined_answer_race_gender;
            public is_bah_includedSpecified: boolean;
            public monthly_income_base_salary: number;
            public monthly_income_base_salarySpecified: boolean;
            public is_tax_exempt_monthly_income_base_salary: BASE_APPLICANTDeclined_answer_race_gender;
            public is_hmda_exempt_monthly_income_base_salary: BASE_APPLICANTDeclined_answer_race_gender;
            public monthly_income_over_time: number;
            public monthly_income_over_timeSpecified: boolean;
            public monthly_income_bonus: number;
            public monthly_income_bonusSpecified: boolean;
            public monthly_income_commission: number;
            public monthly_income_commissionSpecified: boolean;
            public monthly_income_dividends: number;
            public monthly_income_dividendsSpecified: boolean;
            public monthly_income_net_rental: number;
            public monthly_income_net_rentalSpecified: boolean;
            public income_verify_method: string;
            constructor() {
                this.is_tax_exempt_monthly_income_base_salary = BASE_APPLICANTDeclined_answer_race_gender.N;
                this.is_hmda_exempt_monthly_income_base_salary = BASE_APPLICANTDeclined_answer_race_gender.N;
            }
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_MONTHLY_INCOMEOTHER_INCOME {
            public monthly_income: number;
            public monthly_incomeSpecified: boolean;
            public monthly_income_description: string;
            public is_tax_exempt: BASE_APPLICANTDeclined_answer_race_gender;
            public is_hmda_exempt: BASE_APPLICANTDeclined_answer_race_gender;
            constructor() {
                this.is_tax_exempt = BASE_APPLICANTDeclined_answer_race_gender.N;
                this.is_hmda_exempt = BASE_APPLICANTDeclined_answer_race_gender.N;
            }
        }

        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ADDRESS_THREE_LINE {
            public street_address_1: string;
            public street_address_2: string;
            public street_address_3: string;
            public country: string;
            public zip: string;
            public city: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ADDRESS_STRICT {
            public street_address: string;
            public street_no: string;
            public street_direction: BASE_ADDRESS_STRICTStreet_direction;
            public street_directionSpecified: boolean;
            public street_name: string;
            public street_type: string;
            public unit_no: string;
            public verification_date: Date;
            public verification_dateSpecified: boolean;
            public city: string;
            public state: string;
            public zip: string;
            public county: string;
        }
        export enum BASE_ADDRESS_STRICTStreet_direction {
            EAST,
    
            NORTH,
    
            NORTHEAST,
    
            SOUTH,
    
            SOUTHEAST,
    
            WEST,
    
            NORTHWEST,
    
            SOUTHWEST,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        /*[System.Xml.Serialization.XmlIncludeAttribute(typeof(PERSONAL_BASE_APPLICANT))]
            [System.Xml.Serialization.XmlIncludeAttribute(typeof(PERSONAL_APPLICANT))]
            [System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_APPLICANT {
            public ID_CARD: BASE_ID_CARD;
            public ID_CARD2: BASE_ID_CARD;
            public APPROVAL_DENIAL_REASON: string;
            public INCOME_COMMENTS: string;
            public APPLICANT_QUESTIONS: BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTION[];
            public borrower_id: string;
            public is_declined: string;
            public first_name: string;
            public last_name: string;
            public m_initial: string;
            public middle_name: string;
            public suffix: BASE_APPLICANTSuffix;
            public suffixSpecified: boolean;
            public ssn: string;
            public dob: Date;
            public dobSpecified: boolean;
            public dependents: string;
            public ages_of_dependents: string;
            public mother_maiden_name: string;
            public citizenship: BASE_APPLICANTCitizenship;
            public citizenshipSpecified: boolean;
            public member_number: string;
            public bankruptcy_score: string;
            public membership_months: string;
            public marital_status: BASE_APPLICANTMarital_status;
            public marital_statusSpecified: boolean;
            public gender: BASE_APPLICANTGender;
            public genderSpecified: boolean;
            public ethnicity: BASE_APPLICANTEthnicity;
            public ethnicitySpecified: boolean;
            public number_in_household: string;
            public member_type: BASE_APPLICANTMember_type;
            public member_typeSpecified: boolean;
            public race: base_race_type_single[];
            public credit_score: string;
            public custom_score: string;
            public custom_score_2: number;
            public custom_score_2Specified: boolean;
            public declined_answer_race_gender: BASE_APPLICANTDeclined_answer_race_gender;
            public declined_answer_race_genderSpecified: boolean;
            public not_applicable_race_gender: BASE_APPLICANTDeclined_answer_race_gender;
            public not_applicable_race_genderSpecified: boolean;
            public applicant_type: base_applicant_type;
            public is_non_signing_spouse: BASE_APPLICANTDeclined_answer_race_gender;
            public is_non_signing_spouseSpecified: boolean;
            public allow_direct_mail: BASE_APPLICANTDeclined_answer_race_gender;
            public allow_direct_mailSpecified: boolean;
            public allow_email_solicit: BASE_APPLICANTDeclined_answer_race_gender;
            public allow_email_solicitSpecified: boolean;
            public allow_telemarket: BASE_APPLICANTDeclined_answer_race_gender;
            public allow_telemarketSpecified: boolean;
            public allow_share_info: BASE_APPLICANTDeclined_answer_race_gender;
            public allow_share_infoSpecified: boolean;
            public security_code: string;
            public is_ofac_id_ver: BASE_APPLICANTDeclined_answer_race_gender;
            public is_ofac_id_verSpecified: boolean;
            public id_authentication_result: BASE_APPLICANTId_authentication_result;
            public id_authentication_resultSpecified: boolean;
            public is_fraud_prescore: BASE_APPLICANTDeclined_answer_race_gender;
            public is_fraud_prescoreSpecified: boolean;
            public legal_state_of_residency: string;
            public relation_to_primary: string;
            public is_fraud_fas: BASE_APPLICANTDeclined_answer_race_gender;
            public is_fraud_fasSpecified: boolean;//[System.Xml.Serialization.XmlEnumAttribute
            public is_high_risk_consumer: BASE_APPLICANTDeclined_answer_race_gender;
            public is_high_risk_consumerSpecified: boolean;
            public is_thin: BASE_APPLICANTDeclined_answer_race_gender;
            public is_thinSpecified: boolean;
            public contact_index: string;
            public mla_covered_status: string;
            public mla_covered_date: Date;
            public mla_covered_dateSpecified: boolean;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTION {
            public APPLICANT_QUESTION_ANSWER: BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTIONAPPLICANT_QUESTION_ANSWER[];
            public question_name: string;
            public question_type: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTIONAPPLICANT_QUESTION_ANSWER {
            public answer_text: string;
            public answer_value: string;
        }
        export enum BASE_APPLICANTSuffix {
            JR,
    
            SR,
    
            II,
    
            III,
    
            IV,
    
            V,
    
            VI,
    
            VII,
    
            VIII,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_APPLICANTCitizenship {
            USCITIZEN,
    
            PERMRESIDENT,
    
            NONPERMRESIDENT,
    
            NONRESIDENTALIEN,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_APPLICANTMarital_status {
            SEPARATED,
    
            UNMARRIED,
    
            MARRIED,
    
            DOMESTIC_PARTNER,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_APPLICANTGender {
            MALE,
    
            FEMALE,
    
            OTHER,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_APPLICANTEthnicity {
            HISPANIC,
    
            NOT_HISPANIC,
    
            //[System.Xml.Serialization.XmlEnumAttribute("")]
            Item
        }
        export enum BASE_APPLICANTMember_type {
            CURRENT,
    
            NEW,
    
            //[System.Xml.Serialization.XmlEnumAttribute("PRE-APPROVED")]
            PREAPPROVED
    
        }
        export enum base_race_type_single {
            AMERICAN_INDIAN,
    
            ASIAN,
    
            BLACK,
    
            PACIFIC_ISLANDER,
    
            WHITE
        }
        
        export enum BASE_APPLICANTId_authentication_result {
            FAIL,
    
            INCOMPLETE,
    
            PASS,
    
            REVIEW,
    
            NOT_REQUIRE
        }
        /*[System.Xml.Serialization.XmlIncludeAttribute(typeof(PERSONAL_LOAN))]
            [System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class APP {
            public version: number;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_BASE_APPLICANTPREVIOUS_ADDRESS extends BASE_ADDRESS_LOOSE {
            public occupancy_status: PERSONAL_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status;
            public occupancy_statusSpecified: boolean;
            public occupancy_duration: string;
            public other_occupancy_description: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_BASE_APPLICANTMAILING_ADDRESS extends BASE_ADDRESS_LOOSE {
            public is_current: BASE_APPLICANTDeclined_answer_race_gender;
            constructor() {
                super();
                this.is_current = BASE_APPLICANTDeclined_answer_race_gender.Y;
            }
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_BASE_APPLICANTFINANCIAL_INFO {
            public CURRENT_EMPLOYMENT: PERSONAL_BASE_APPLICANTFINANCIAL_INFOCURRENT_EMPLOYMENT[];
            public PREVIOUS_EMPLOYMENT: PERSONAL_BASE_APPLICANTFINANCIAL_INFOPREVIOUS_EMPLOYMENT[];
            public MONTHLY_INCOME: BASE_MONTHLY_INCOME;
            public MONTHLY_DEBT: BASE_MONTHLY_DEBT;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_BASE_APPLICANTFINANCIAL_INFOCURRENT_EMPLOYMENT extends BASE_EMPLOYMENT {
            public employee_of_lender_type: base_employee_of_lender_types;
            public employee_of_lender_typeSpecified: boolean;
            public is_employee_of_lender: BASE_APPLICANTDeclined_answer_race_gender;
            public is_employee_of_lenderSpecified: boolean;
            public profession_months: string;
        }
        export enum base_employee_of_lender_types {
            NONE,
    
            EMPLOYEE,
    
            MANAGER,
    
            EXECUTIVE,
    
            BOARDMEMBER,
    
            EMPLOYEEFAMILY,
    
            BOARDFAMILY,
    
            OTHER
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_BASE_APPLICANTFINANCIAL_INFOPREVIOUS_EMPLOYMENT extends BASE_EMPLOYMENT {
            public monthly_income: number;
            public monthly_incomeSpecified: boolean;
            public employment_end_date: Date;
            public employment_end_dateSpecified: boolean;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_ASSET_LISTASSET extends BASE_ASSET {
    
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_APPLICANTCREDIT_REPORT {
            public report_id: number;
            public report_format: PERSONAL_APPLICANTCREDIT_REPORTReport_format;
            public encoding: PERSONAL_APPLICANTCREDIT_REPORTEncoding;
            public encodingSpecified: boolean;
            public Value: string;
        }
        export enum PERSONAL_APPLICANTCREDIT_REPORTReport_format {
            RAW_XML,
    
            MCL_COMMON,
    
            MISMO
        }
        export enum PERSONAL_APPLICANTCREDIT_REPORTEncoding {
            CDATA,
    
            INNER_TEXT,
    
            BASE64
        }


        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_LOANLOAN_INFO extends PERSONAL_LOAN_INFO {
    
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_PRICE_ADJUSTMENT_LISTPRICE_ADJUSTMENT {
            public adjust_type: BASE_PRICE_ADJUSTMENT_LISTPRICE_ADJUSTMENTAdjust_type;
            public value: number;
            public valueSpecified: boolean;
            public description: string;
        }
        export enum BASE_PRICE_ADJUSTMENT_LISTPRICE_ADJUSTMENTAdjust_type {
            RATE,
    
            APR,
    
            PMT_P
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_TASK_LISTTASK {
            public ASSIGNTO: BASE_PARTY;
            public COMPLETE: BASE_PARTY;
            public CREATE: BASE_PARTY;
            public DUELOAN: BASE_PARTY;
            public APPLIEDSET: BASE_TASK_LISTTASKAPPLIEDSET;
            public REQUIRESET: BASE_TASK_LISTTASKREQUIRESET;
            public date_due: string;
            public date_completed: string;
            public date_created: string;
            public description: string;
            public required: string;
            public completed: string;
            public category: string;
            public priority: string;
            public position: string;
            public assignto_officer: string;
            public complete_officer: string;
            public create_officer: string;
            public dueloan_officer: string;
            public is_applied: string;
            public is_required: string;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_TASK_LISTTASKAPPLIEDSET {
            public conditionset_hash: string;
            public encoding: string;
            public Text: string[];
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class BASE_TASK_LISTTASKREQUIRESET {
            public conditionset_hash: string;
            public encoding: string;
            public Text: string[];
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_LOANFUNDING extends PERSONAL_FUNDING_INFO {
            public INSURANCE_OPTIONS: PERSONAL_LOANFUNDINGINSURANCE_OPTIONS;
            public CLOSE_END_INFO: BASE_CLOSE_END_INFO;
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_LOANFUNDINGINSURANCE_OPTIONS {
            public INSURANCE_OPTION: PERSONAL_INSURANCE[];
            public loan_class: PERSONAL_LOANFUNDINGINSURANCE_OPTIONSLoan_class;
            public loan_classSpecified: boolean;
            public description: string;
            public borrower_1_index: string;
            public borrower_1_is_spouse: string;
            public borrower_2_index: string;
            public borrower_2_is_spouse: string;
        }
        export enum PERSONAL_LOANFUNDINGINSURANCE_OPTIONSLoan_class {
            O,
    
            C
        }
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
            [System.SerializableAttribute()]
            [System.Diagnostics.DebuggerStepThroughAttribute()]
            [System.ComponentModel.DesignerCategoryAttribute("code")]
            [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_LOANCOMMENTS extends BASE_COMMENTS {
            public UNABLE_TO_FUND: Object;
        }

    
    
    
    /*[System.Xml.Serialization.XmlIncludeAttribute(typeof(PERSONAL_APPLICANT))]
        [System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [System.SerializableAttribute()]
        [System.Diagnostics.DebuggerStepThroughAttribute()]
        [System.ComponentModel.DesignerCategoryAttribute("code")]
        [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class PERSONAL_BASE_APPLICANT extends BASE_APPLICANT {
        public CURRENT_ADDRESS: PERSONAL_BASE_APPLICANTCURRENT_ADDRESS;
        public PREVIOUS_ADDRESS: PERSONAL_BASE_APPLICANTPREVIOUS_ADDRESS;
        public MAILING_ADDRESS: PERSONAL_BASE_APPLICANTMAILING_ADDRESS;
        public FINANCIAL_INFO: PERSONAL_BASE_APPLICANTFINANCIAL_INFO;
        public REFERENCE: BASE_REFERENCE[];
        public CONTACT_INFO: BASE_CONTACT_INFO;
        public ASSETS: BASE_ASSET[];
        public DECLARATIONS: DECLARATIONS;
        public paper_grade: string;
    }
    
    /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [System.SerializableAttribute()]
        [System.Diagnostics.DebuggerStepThroughAttribute()]
        [System.ComponentModel.DesignerCategoryAttribute("code")]
        [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class PERSONAL_APPLICANT extends PERSONAL_BASE_APPLICANT {
        public SPOUSE: PERSONAL_BASE_APPLICANT;
        public CREDIT_REPORT: PERSONAL_APPLICANTCREDIT_REPORT;
        public FOM_ANSWERED_QUESTIONS: FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTION[];
        public is_fraud_credit: BASE_APPLICANTDeclined_answer_race_gender;
        public is_fraud_creditSpecified: boolean;
        public is_ofac_credit: BASE_APPLICANTDeclined_answer_race_gender;
        public is_ofac_creditSpecified: boolean;
        public combined_unsecured_aggregate_amount_with_lender: number;
        public combined_unsecured_aggregate_amount_with_lenderSpecified: boolean;
        public combined_secured_aggregate_amount_with_lender_no_mortgage: number;
        public combined_secured_aggregate_amount_with_lender_no_mortgageSpecified: boolean;
    }
    
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [System.SerializableAttribute()]
        [System.Diagnostics.DebuggerStepThroughAttribute()]
        [System.ComponentModel.DesignerCategoryAttribute("code")]
        [System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
        export class PERSONAL_LOAN extends APP {
            public APPLICANTS: PERSONAL_LOANAPPLICANTS;
            public LOAN_INFO: PERSONAL_LOANLOAN_INFO;
            public PRICE_ADJUSTMENTS: BASE_PRICE_ADJUSTMENT_LISTPRICE_ADJUSTMENT[];
            public LOAN_STATUS: PERSONAL_STATUS;
            public DISBURSEMENTS: BASE_DISBURSEMENT[];
            public TASK_LIST: BASE_TASK_LISTTASK[];
            public FUNDING: PERSONAL_LOANFUNDING;
            public COMMENTS: PERSONAL_LOANCOMMENTS;
            public STRUCTURED_COMMENTS: BASE_STRUCTURED_COMMENTS;
            public CUSTOM_QUESTIONS: BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION[];
            public CONTACTS: BASE_LOAN_CONTACTS;
            public SYSTEM: PERSONAL_SYSTEM[];
            public HMDA_INFO: BASE_HMDA_INFO;
            public INTEGRATION_DATA_LIST: INTEGRATION_DATA[];
            public MODIFICATION_HISTORY: BASE_MODIFICATION_HISTORY;
            public EVENT_HISTORY: BASE_EVENT_HISTORY;
            public WEBMS_HISTORY: BASE_WEBMS_HISTORY;
        }
    
        /*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [System.SerializableAttribute()]
        [System.Diagnostics.DebuggerStepThroughAttribute()]
        [System.ComponentModel.DesignerCategoryAttribute("code")]
        [System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]
        [System.Xml.Serialization.XmlRootAttribute("PERSONAL_LOAN", Namespace = "http://www.meridianlink.com/CLF", IsNullable = false)]*/
        export class PERSONAL_LOAN11 extends PERSONAL_LOAN {
    
        }
    