<div style="text-align: center; position: relative;" mat-dialog-close>
  <span class="btn-close">
    <mat-icon>close</mat-icon>
  </span>
</div>

<section class="cc-wrapper">
  <div class="cc-box" [hidden]="!AppComponent.isDesktop">
    <div #ccBoxFlip class="cc-box--flip">
      <div class="cc-box__front" style="padding-bottom: 40px;">
        <div class="cc-box__logo">
          <p>{{getCardType(ccNumber.value) | uppercase}}</p>
        </div>
        <div class="cc-box__element">
          <label class="cc-form__label" for="cc-card-number-display"></label>
          <input *ngIf="ccForm.get('cardNumber').value.length <16" style="font-size:16px !important"
            class="cc-form__input cc-form__input--transparent cc-form__input--embosed" id="cc-card-number-display"
            aria-label="Payment card number" disabled="disabled" 
            [value]="ccForm.get('cardNumber').value | paymentCardNumber">
            <span *ngIf="ccForm.get('cardNumber').value.length==16" style="font-size: 16px !important;color: #fff;font-family: 'Inconsolata', monospace; text-shadow: 0 2px 1px rgb(0 0 0 / 30%);">************-{{ccForm.get('cardNumber').value.slice(-4)}}</span>
        </div>
        <div class="cc-box__element">
          <label class="cc-form__label" for="cc-holder-display">CARD HOLDER</label>
          <input style="font-size:16px !important"
            class="cc-form__input cc-form__input--transparent cc-form__input--embosed" id="cc-holder-display"
            aria-label="Card holder" disabled="disabled" [value]="ccForm.get('cardHolder').value | uppercase">
        </div>
        <div class="cc-box__element">
          <label class="cc-form__label" for="cc-valid-date-display">VALID THRU</label>
          <input style="font-size:16px !important"
            class="cc-form__input cc-form__input--left-align cc-form__input--transparent cc-form__input--embosed"
            id="cc-valid-date-display" aria-label="Card holder" disabled="disabled"
            [value]="ccForm.get('expirationMonth').value + '/' + ccForm.get('expirationYear').value | validThru">
        </div>
        <div class="cc-box__chip"></div>
      </div>
      <div class="cc-box__back cc-back-custom" >
        <div class="cc-box__strip">&nbsp;</div>
        <div class="cc-box__element">
          <input style="font-size:16px !important"
            class="cc-form__input cc-form__input--cursive cc-form__input--right-align" id="cc-ccv-display" class="mask-pass"
            aria-label="CCV" disabled="disabled" [value]="'CCV: ' + ccForm.get('ccv').value" title="CCV">
        </div>
      </div>
    </div>
  </div>

  <div style="position: relative; height: 50px;">
  </div>

  <div style="max-height: 450px; overflow-y: auto;">
    <form class="cc-form" [formGroup]="ccForm" autocomplete="off">
      <!-- <div class="cc-form__wrapper--long">
              <label for="cc-number" class="cc-form__label cc-form__label--first">Card number</label>
              <input #ccNumber class="cc-form__input" id="cc-number" aria-label="Card number" type="text" title="Card number" maxlength="19" formControlName="cardNumber" (focus)="ccBoxFlip.classList.remove('hover')">
              <div class="cc-form__error" *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('required')">
                  {{ccNumMissingTxt}}
              </div>
              <div class="cc-form__error" *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('minlength')">
                  {{ccNumTooShortTxt}}
              </div>
              <div class="cc-form__error" *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('maxlength')">
                  {{ccNumTooLongTxt}}
              </div>
              <div class="cc-form__error" *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('numbersOnly')">
                  {{ccNumContainsLettersTxt}}
              </div>
              <div class="cc-form__error" *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('checksum')">
                  {{ccNumChecksumInvalidTxt}}
              </div>
          </div> -->

      <mat-form-field appearance="outline" class="cc-form__wrapper--long">
        <mat-label>Card Number</mat-label>
        <input matInput #ccNumber id="cc-number" aria-label="Card number" type="text" title="Card number" maxlength="19" class="mask-pass" #cardNumber  mask="0000-0000-0000-0000" 
          formControlName="cardNumber" (focus)="ccBoxFlip.classList.remove('hover')">
          <mat-icon
          style="cursor: pointer"
          matSuffix
          (mousedown)="removeClass('#cc-number', 'mask-pass')"
          (mouseup)="addClass('#cc-number', 'mask-pass')"
          [inline]="true">
          {{ cardNumber.className.indexOf("mask-pass")> -1 ? "visibility_off" : "visibility" }}
          </mat-icon>
        <mat-error
          *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('required')">
          {{ccNumMissingTxt}}
        </mat-error>
        <mat-error
          *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('minlength')">
          {{ccNumTooShortTxt}}
        </mat-error>
        <mat-error
          *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('maxlength')">
          {{ccNumTooLongTxt}}
        </mat-error>
        <mat-error
          *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('numbersOnly')">
          {{ccNumContainsLettersTxt}}
        </mat-error>
        <mat-error
          *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('checksum')">
          {{ccNumChecksumInvalidTxt}}
        </mat-error>
      </mat-form-field>

      <!-- <div class="cc-form__wrapper--long">
              <label for="cc-holder-name" class="cc-form__label">Card Holder name</label>
              <input class="cc-form__input" id="cc-holder-name" aria-label="Card holder name" type="text" title="Card holder name" maxlength="22" formControlName="cardHolder" (focus)="ccBoxFlip.classList.remove('hover')">
              <div class="cc-form__error" *ngIf="validateCardHolder && ccForm.get('cardHolder').touched &&
               ccForm.get('cardHolder').hasError('required')">
                  {{cardHolderMissingTxt}}
              </div>
              <div class="cc-form__error" *ngIf="validateCardHolder && ccForm.get('cardHolder').touched &&
               ccForm.get('cardHolder').hasError('maxlength')">
                  {{cardHolderTooLongTxt}}
              </div>
          </div> -->

      <mat-form-field appearance="outline" class="cc-form__wrapper--long">
        <mat-label>Card Holder Name</mat-label>
        <input matInput id="cc-holder-name" aria-label="Card holder name" type="text" title="Card holder name"
          maxlength="22" formControlName="cardHolder" (focus)="ccBoxFlip.classList.remove('hover')">
        <mat-error *ngIf="validateCardHolder && ccForm.get('cardHolder').touched &&
               ccForm.get('cardHolder').hasError('required')">
          {{cardHolderMissingTxt}}
        </mat-error>
        <mat-error *ngIf="validateCardHolder && ccForm.get('cardHolder').touched &&
               ccForm.get('cardHolder').hasError('maxlength')">
          {{cardHolderTooLongTxt}}
        </mat-error>
      </mat-form-field>


      <div class="cc-form__wrapper--long row" style="display: flex; align-items: flex-end;">
        <mat-form-field appearance="outline" class="col cc-form__wrapper cc-form__wrapper--short">
          <mat-label>Month</mat-label>
          <mat-select id="cc-expiration-month" aria-label="Expiration month" formControlName="expirationMonth">
            <mat-option *ngFor="let month of months" value="{{month}}" (click)="ccBoxFlip.classList.remove('hover')">
              {{month}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col cc-form__wrapper cc-form__wrapper--short">
          <mat-label>Year</mat-label>
          <mat-select id="cc-expiration-year" aria-label="Expiration year" formControlName="expirationYear">
            <mat-option *ngFor="let year of years" value="{{year}}" (click)="ccBoxFlip.classList.remove('hover')">
              {{year}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col cc-form__wrapper cc-form__wrapper--short">
          <mat-label>CVV</mat-label>
          <input matInput class="mask-pass" id="cc-ccv" aria-label="CCV" type="text" title="CCV" minlength="3" maxlength="4" type="text" #cvv 
            formControlName="ccv" (focus)="ccBoxFlip.classList.add('hover')">
            <mat-icon
            style="cursor: pointer"
            matSuffix
            (mousedown)="removeClass('#cc-ccv', 'mask-pass')"
            (mouseup)="addClass('#cc-ccv', 'mask-pass')"
            [inline]="true">
            {{ cvv.className.indexOf("mask-pass")> -1 ? "visibility_off" : "visibility" }}
            </mat-icon>
        </mat-form-field>
      </div>

      <div class="custom-control custom-checkbox mb-4" [ngClass]="{'custom-checkbox-mobile': !AppComponent.isDesktop}">
        <input [(ngModel)]="cardDetails.billing_ADDRESS_is_current" (ngModelChange)="billing_ADDRESS_is_currentChanged()"
          formControlName="billing_ADDRESS_is_current" type="checkbox" class="custom-control-input" id="billingAddress"
          value="showhide">
        <label class="custom-control-label" for="billingAddress">Billing address is same as the current address</label>
      </div>
<!-- 
      public billing_street_address: string;
      public billing_city: string;
      public billing_state: string;
      public billing_zip: string; -->

      <div *ngIf="!cardDetails.billing_ADDRESS_is_current" style="padding:15px">
        <div class="row">

            <mat-form-field class="col-md-6" provideReadonly appearance="outline">
              <mat-label>Address</mat-label>
              <input matInput formControlName="billing_street_address" [(ngModel)]="cardDetails.billing_street_address" required>
            </mat-form-field>

            <mat-form-field class="col-md-6"  appearance="outline">
              <mat-label>City</mat-label>
              <input matInput formControlName="billing_city" mask="S*" maxlength="35" 
              [(ngModel)]="cardDetails.billing_city" required>
            </mat-form-field>
        </div>
        <div class="row">

            <mat-form-field class="col-md-6"  appearance="outline">
              <mat-label>State</mat-label>
              <mat-select formControlName="billing_state" [(ngModel)]="cardDetails.billing_state" required>
                <mat-option *ngFor="let item of states | async" [value]="item.value">
                  {{item.displayName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-md-6"  appearance="outline">
              <mat-label>Zip</mat-label>
              <input type="text" formControlName="billing_zip" matInput [(ngModel)]="cardDetails.billing_zip" [pattern]="zipPattern"
                required>
              <mat-error *ngIf="ccForm.controls['billing_zip'].invalid">
                Zip is invalid
              </mat-error>
            </mat-form-field>

        </div>
      </div>

      <div class="cc-form__error" *ngIf="validateExpirationMonth && ccForm.get('expirationMonth').touched &&
                 ccForm.get('expirationMonth').hasError('required')">
        {{expirationMonthMissingTxt}}
      </div>
      <div class="cc-form__error" *ngIf="validateExpirationMonth && ccForm.get('expirationYear').touched &&
                 ccForm.get('expirationYear').hasError('required')">
        {{expirationYearMissingTxt}}
      </div>
      <div class="cc-form__error" *ngIf="validateCardExpiration && ccForm.get('expirationMonth').touched &&
        ccForm.get('expirationYear').touched && ccForm.hasError('expiration')">
        {{cardExpiredTxt}}
      </div>
      <div class="cc-form__error"
        *ngIf="validateCCV && ccForm.get('ccv').touched && ccForm.get('ccv').hasError('required')">
        {{ccvMissingTxt}}
      </div>
      <div class="cc-form__error"
        *ngIf="validateCCV && ccForm.get('ccv').touched && ccForm.get('ccv').hasError('minlength')">
        {{ccvNumTooShortTxt}}
      </div>
      <div class="cc-form__error"
        *ngIf="validateCCV && ccForm.get('ccv').touched && ccForm.get('ccv').hasError('maxlength')">
        {{ccvNumTooLongTxt}}
      </div>
      <div class="cc-form__error"
        *ngIf="validateCCV && ccForm.get('ccv').touched && ccForm.get('ccv').hasError('numbersOnly')">
        {{ccvContainsLettersTxt}}
      </div>

    </form>
  </div>
</section>

<div class="row segment-action-btns {{AppComponent.isDesktop ? '' :'fixed-bottom'}}" mat-dialog-actions>
  <button [disabled]="ccForm.invalid" (click)="emitSavedCard()" style="width: 70%; margin: auto;"
    class="btn btn-custom btn-continue btn-block">Submit</button>
</div>
