export enum LoanTypeEnum {
    BL,

    /// <remarks/>
    CC,

    /// <remarks/>
    ML,

    /// <remarks/>
    PL,

    /// <remarks/>
    VL,

    /// <remarks/>
    HE
}