import {Injectable} from "@angular/core";
import {Http, Response, RequestOptionsArgs, Headers, RequestOptions} from "@angular/http";
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { XPRESS_LOAN } from 'src/models/xpress_loan.model';
import { LoanTypeEnum } from 'src/models/loan-type.enum';
import { FUNDDEPOSIT_INPUTREQUEST } from 'src/models/fund-deposit.model';
import { CommonService } from './common.service';
import { AppComponent } from "src/app/app.component";
import { ApplicantLoanComponent } from "src/app/applicant-loan/applicant-loan.component";
import { FinalPageComponent } from "src/components/final-page/final-page.component";
import { HttpClient } from "@angular/common/http";


@Injectable(
    {
        providedIn: "root"
    }
)
export class ApiService {

    authService: any ={};
    private options : RequestOptions;


    private cache = {

        productCategories: []
    }

    constructor(private http : Http,
        private httpClient: HttpClient,
         private commonService: CommonService) {
        this.options = new RequestOptions();
        var openIdTokenParams = JSON.parse(localStorage.getItem('openIdTokenParams'));
        if (openIdTokenParams && openIdTokenParams.access_token) {
            this.authService.accessToken = openIdTokenParams.access_token;
        }
    }

    // protected getAuthHeader(includeJsonContentType?: boolean): RequestOptions {
    //     if(!this.authService.accessToken){
    //         var openIdTokenParams = JSON.parse(localStorage.getItem('openIdTokenParams'));
    //         if (openIdTokenParams && openIdTokenParams.access_token) {
    //             this.authService.accessToken = openIdTokenParams.access_token;
    //         }
    //     }
    //     let headers = new Headers(
    //         // { 'Authorization': 'Bearer ' + this.authService.accessToken }
    //         );

    //     if (includeJsonContentType)
    //         headers.append("Content-Type", "application/json");

    //     headers.append("Accept", `application/json, text/plain, */*`);

    //     return new RequestOptions({ headers: headers });
    // }

    private handleError_submit(error : Response) {
        //should redirect to final page also
        ApplicantLoanComponent.forceFinalPageError = true;
        FinalPageComponent.isLoading = false;
        return Observable.throw(error.json());
    }

    private handleError(error : Response) {
        console.log(error);
        // if(error.status == 404){
        //     alert('404 error');
        //     return;
        // }
        return Observable.throw(error.json());
        //throw new Error(error.json())
    }

    submitXpressApp(xpressLoan: XPRESS_LOAN,comboId :string = '', captchatoken? :string, baseType?: number):Observable<any>{
        var body ={
            xPRESS_LOAN: xpressLoan,
            // callPlaidDeposit: AppComponent.direct_deposit_answer=='Yes',
            // plaidDepositAccountSubtype: AppComponent.direct_deposit_subtype
            fromSite: window.location.href
        }

        var opts = this.commonService.getAuthHeader(true);
        opts.headers.append('comboid', comboId);

        return this
            .http
            .post(this.commonService.baseUrl + `/api/xpressLoan/submit?captchaToken=${captchatoken}&baseType=${baseType}` 
            , body, opts).pipe(
            map((response : Response) => {
                var result = response.json();
                AppComponent.xaAppSubmittedSuccess.emit(result);
                return result;
            })
            ,
            catchError(this.handleError_submit)
            )
    }

    fundDeposit(request):Observable<any>{
        return this
            .httpClient
            .post(this.commonService.baseUrl + "/api/xpressLoan/fund-deposit", request, this.commonService.getAuthHeaderNew(true));
    }

    
    submitDecisionLoan(selectedLoanType: LoanTypeEnum, comboId: string='' , decisionLoan):Observable<any>{
        var opts = this.commonService.getAuthHeader(true);
        opts.headers.append('comboid', comboId);
        return this
            .http
            .post(this.commonService.baseUrl + "/api/decisionLoan/submit/" + selectedLoanType, decisionLoan,opts ).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    checkBadMember(ssn):Observable<any>{
        return this
            .http
            .get(this.commonService.baseUrl + "/api/xpressLoan/check-bad-member/"+ ssn, this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    getFirstDefaultProduct():Observable<any>{
        return this
            .http
            .get(this.commonService.baseUrl + "/api/product/get-first-default-product/?refChannel="+ApplicantLoanComponent.refChannel, this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    getCategories():Observable<any>{
        return this
            .http
            .get(this.commonService.baseUrl + "/api/product/get-categories/?refChannel="+ApplicantLoanComponent.refChannel, this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    //Employment endpoints

    getEmployerNames():Observable<any>{
        return this
            .http
            .get(this.commonService.baseUrl + "/api/employment/get-employer-names/", this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    getEmploymentStatuses():Observable<any>{
        return this
            .http
            .get(this.commonService.baseUrl + "/api/employment/get-employment-statuses/", this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    getOccupationStatuses():Observable<any>{
        return this
            .http
            .get(this.commonService.baseUrl + "/api/employment/get-occupation-statuses/", this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    getStates():Observable<any>{
        return this
            .http
            .get(this.commonService.baseUrl + "/api/employment/get-states/", this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    getStaticLinks():Observable<any>{
        return this
            .httpClient
            .get(this.commonService.baseUrl + "/api/staticLinks/get-static-links/", this.commonService.getAuthHeaderNew(true));
    }

    getPayGrades():Observable<any>{
        return this
            .http
            .get(this.commonService.baseUrl + "/api/employment/get-pay-grades/", this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    getQuestions(appId, applicantIndex, isJoint):Observable<any>{
        return this.httpClient.get(this.commonService.baseUrl + `/api/xpressLoan/get-questions/${appId}/${applicantIndex}/${isJoint}`, this.commonService.getAuthHeaderNew(true))
    }

    submitAnswers(appId, applicantIndex, isJoint, session_id, answers):Observable<any>{
        return this
            .http
            .post(this.commonService.baseUrl + `/api/xpressLoan/submit-answers/${appId}/${applicantIndex}/${isJoint}/${session_id}`, answers, this.commonService.getAuthHeader(true)).pipe(
            map((response : Response) => {
                return response.json();
            })
            ,
            catchError(this.handleError)
            )
    }

    getCurrentStatus(appId):Observable<any>{
        return this
            .httpClient
            .get(this.commonService.baseUrl + `/api/NmaoWf/get-current-app-status/${appId}`, this.commonService.getAuthHeaderNew(true))
    }

    pulleFundsReport(appId, applicantIndex, isJoint):Observable<any>{
        return this
            .httpClient
            .get(this.commonService.baseUrl + `/api/xpressLoan/pull-eFunds-report/${appId}/${applicantIndex}/${isJoint}`, this.commonService.getAuthHeaderNew(true))
    }

    pullCreditReport(appId):Observable<any>{
        return this
            .httpClient
            .get(this.commonService.baseUrl + `/api/xpressLoan/pull-credit-report/${appId}/`, this.commonService.getAuthHeaderNew(true));
    }

    symitarBook(appId):Observable<any>{
        return this
            .httpClient
            .post(this.commonService.baseUrl + `/api/xpressLoan/symitar-book/${appId}/`, {}, this.commonService.getAuthHeaderNew(true));
    }

    getVehiclePurposes(): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/product/get-vehicle-purposes`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getAutoMakes(): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/product/get-auto-makes`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getMemberReferences(): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/member/get-references?channelId=`+ApplicantLoanComponent.currentChannel?.id, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getServedAreas(): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/employment/get-served-areas`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      
      getQualifyingZips(): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/employment/get-qualifying-zips`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

            
      getEligibilityRelationships(): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/employment/get-eligibility-relationship`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      //Docu-Sign

      getDocuSignDocs(appId: string, productGroupId, productId): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/docuSign/generate-docu-sign/${appId}/${productGroupId}/${productId}`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getLoan(loanId): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/xpressLoan/get-loan/${loanId}`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getMemberNumber(loanId): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/xpressLoan/get-member-number/${loanId}`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        )
      }

      getLoanDecision(loanId): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/xpressLoan/get-loan-decision/${loanId}`, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getBank(routingNumber): Observable<any> {
        return this
        .http
        .get(`https://www.routingnumbers.info/api/data.json?rn=${routingNumber}`).pipe(
        map((response : Response) => {
            return response.json();
        })
        )
      }

      getNmaoConfigurations(): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/product/get-nmao-configurations`).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getQualificationAssessment(): Observable<any> {
        return this
        .httpClient
        .get(this.commonService.baseUrl + `/api/qualificationAssessment/get-QualificationAssessment`)
      }

      getChannelByCurrentUri(channelUri): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/channel/get-channel?channelUri=${channelUri}` ).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
      }

      getFundingSources(baseType, channelUri): Observable<any> {
          return this
          .http
          .get(this.commonService.baseUrl + `/api/Funding/get-funding-sources/${baseType}/${channelUri}` ).pipe(
          map((response : Response) => {
              return response.json();
          })
          ,
          catchError(this.handleError)
          )
      }

      getLoanApiLog(appId, stepId): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/loanApiLog/get-loan-api-log/${appId}/${stepId}` ).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
    }

    getLoanApiLogByApiLink(appId, apiLink): Observable<any> {
        return this
        .http
        .get(this.commonService.baseUrl + `/api/loanApiLog/get-loan-api-log-by-link/${appId}/${apiLink}` ).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
    }
}