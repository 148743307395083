import { Component, EventEmitter, OnInit, Output, Input, ViewEncapsulation, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { CardValidator } from './card-validator';
import { ICardDetails } from './i-card-details';
import { CardDetails } from './card-details';
import { PaymentCardService } from './payment-card.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponent } from 'src/app/app.component';
import { Observable } from 'rxjs';
import { ApiService } from 'src/shared/api.service';
import * as $ from 'jquery'

/**
 * NgPaymentCard without any dependencies other then ReactiveFormsModule
 */
@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CreditCardFormComponent implements OnInit {
  /**
   * FormGroup available publicly
   */
  public ccForm: FormGroup;

  /**
   * List of months
   */
  public months: Array<string> = [];

  /**
   * List of years
   */
  public years: Array<number> = [];

  /**
   * Validation message for missing payment card number
   */
  @Input()
  public ccNumMissingTxt? = 'Card number is required';

  /**
   * Validation message for too short payment card number
   */
  @Input()
  public ccNumTooShortTxt? = 'Card number is too short';

  /**
   * Validation message for too long payment card number
   */
  @Input()
  public ccNumTooLongTxt? = 'Card number is too long';

  /**
   * Validation message for payment card number that contains characters other than digits
   */
  @Input()
  public ccNumContainsLettersTxt? = 'Card number can contain digits only';

  /**
   * Validation message for invalid payment card  number (Luhn's validation)
   */
  @Input()
  public ccNumChecksumInvalidTxt? = 'Provided card number is invalid';

  /**
   * Validation message for missing card holder name
   */
  @Input()
  public cardHolderMissingTxt? = 'Card holder name is required';

  /**
   * Validation message for too long card holder name
   */
  @Input()
  public cardHolderTooLongTxt? = 'Card holder name is too long';

  /**
   * Validation message for missing expiration month
   */
  @Input()
  public expirationMonthMissingTxt? = 'Expiration month is required';

  /**
   * Validation message for missing expiration year
   */
  @Input()
  public expirationYearMissingTxt? = 'Expiration year is required';

  /**
   * Validation message for missing CCV number
   */
  @Input()
  public ccvMissingTxt? = 'CCV number is required';

  /**
   * Validation message for too short CCV number
   */
  @Input()
  public ccvNumTooShortTxt? = 'CCV number is too short';

  /**
   * Validation message for too long CCV number
   */
  @Input()
  public ccvNumTooLongTxt? = 'CCV number is too long';

  /**
   * Validation message for incorrect CCV number containing characters other than digits
   */
  @Input()
  public ccvContainsLettersTxt? = 'CCV number can contain digits only';

  /**
   * Validation message for expired card
   */
  @Input()
  public cardExpiredTxt? = 'Card has expired';

  /**
   * Switch validation of the payment card number
   */
  @Input()
  public validateCCNum? = true;

  /**
   * Switch validation of the payment card holder
   */
  @Input()
  public validateCardHolder? = true;

  /**
   * Switch validation of the payment card expiration month
   */
  @Input()
  public validateExpirationMonth? = true;

  /**
   * Switch validation of the payment card expiration year
   */
  @Input()
  public validateExpirationYear? = true;

  /**
   * Switch validation of the payment card expiration
   */
  @Input()
  public validateCardExpiration? = true;

  /**
   * Switch validation of the payment card CCV number
   */
  @Input()
  public validateCCV? = true;

  /**
   * EventEmitter for payment card object
   */
  @Output()
  public formSaved: EventEmitter<ICardDetails> = new EventEmitter<CardDetails>();

  existingCard: CardDetails;

  AppComponent = AppComponent;


  cardDetails: ICardDetails ={} as any;

  zipPattern = `^[0-9]{5}(?:-[0-9]{4})?$`;

  public states: Observable<any[]>;

  constructor(private _ccService: PaymentCardService, 
    private apiService: ApiService,
    private _fb: FormBuilder , public dialogRef: MatDialogRef<CreditCardFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any ) {

    if(data.existingCard){
     this.existingCard= <CardDetails>data.existingCard;
     this.cardDetails = this.existingCard;



    }
    if(data.cardDetails) {
      this.ccForm = data.cardDetails
    }
  }

  public ngOnInit(): void {
    this.buildForm();
    this.assignDateValues();
    this.getStates();

    if(this.existingCard){
      this.ccForm.controls['cardNumber'].setValue(this.existingCard.cardNumber);
      this.ccForm.controls['cardHolder'].setValue(this.existingCard.cardHolder);
      this.ccForm.controls['expirationMonth'].setValue(this.existingCard.expirationMonth);
      this.ccForm.controls['expirationYear'].setValue(this.existingCard.expirationYear);
      this.ccForm.controls['ccv'].setValue(this.existingCard.ccv);
    }
  }

  getStates() {
    this.states = this.apiService.getStates();
  }

  /**
   * Populate months and years
   */
  private assignDateValues(): void {
    this.months = PaymentCardService.getMonths();
    this.years = PaymentCardService.getYears();
  }

  /**
   * Build reactive form
   */
  private buildForm(): void {
    this.ccForm = this._fb.group(
      {
        cardNumber: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(12),
            Validators.maxLength(19),
            CardValidator.numbersOnly,
            CardValidator.checksum,
          ]),
        ],
        cardHolder: ['', Validators.compose([Validators.required, Validators.maxLength(22)])],
        expirationMonth: ['', Validators.required],
        expirationYear: ['', Validators.required],
        ccv: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
            CardValidator.numbersOnly,
          ]),
        ],
        billing_ADDRESS_is_current: ['',],
        billing_street_address:['',],
        billing_city:['',],
        billing_state:['',],
        billing_zip:['',],
      },
      {
        validator: CardValidator.expiration,
      }
    );
  }

  /**
   * Returns payment card type based on payment card number
   */
  public getCardType(ccNum: string): string | null {
    return PaymentCardService.getCardType(ccNum);
  }

  /**
   * Callback function that emits payment card details after user clicks submit, or press enter
   */
  public emitSavedCard(): void {
    this.cardDetails = <CardDetails>this.ccForm.value;
    this.formSaved.emit(this.cardDetails);

    this.dialogRef.close(this.cardDetails)
  }

  billing_ADDRESS_is_currentChanged(){
    if(this.cardDetails.billing_ADDRESS_is_current){
      //remove control
      this.ccForm.removeControl('billing_street_address');
      this.ccForm.removeControl('billing_city');
      this.ccForm.removeControl('billing_state');
      this.ccForm.removeControl('billing_zip');
    }else{
      this.ccForm.addControl('billing_street_address',new FormControl('', Validators.required));
      this.ccForm.addControl('billing_city', new FormControl('', Validators.required));
      this.ccForm.addControl('billing_state', new FormControl('', Validators.required));
      this.ccForm.addControl('billing_zip', new FormControl('', Validators.required));
    }
  }

  addClass(id, className){
    $(id).addClass(className);
  }

  removeClass(id, className){
    $(id).removeClass(className);
  }
}