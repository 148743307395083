import { Component, OnInit, Inject } from '@angular/core';
import { XPRESS_FUNDING_SOURCESFUNDING_SOURCE, XPRESS_FUNDING_SOURCESFUNDING_SOURCEBank_account_type, XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type } from 'src/models/xpress_loan.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/shared/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-bank-form',
  templateUrl: './bank-form.component.html',
  styleUrls: ['./bank-form.component.css']
})
export class BankFormComponent implements OnInit {
  fundingSource: XPRESS_FUNDING_SOURCESFUNDING_SOURCE = {} as any;
  bankAccountTypes = XPRESS_FUNDING_SOURCESFUNDING_SOURCEBank_account_type;

  public states = [];
  public bankForm: FormGroup;
  invalidRoutingNumnerMsg = "";

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public dialogRef: MatDialogRef<BankFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.fundingSource.funding_type = XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type.BANK;
    if(data.existingBank){
      this.fundingSource = data.existingBank;
      this.bankForm = data.bankDetails;
    }

   }

  ngOnInit(): void {
    this.bankForm = this.fb.group({
      bankAccountType: ['', [Validators.required]],
      bank_name_on_card: ['', [Validators.required]],
      bank_account_number: ['', Validators.required],
      routing_number: ['', [Validators.required]],
      bank_bankname: ['', [Validators.required]],
      bank_bankstate: ['', [Validators.required, Validators.maxLength(2), ]],
    })
    this.getStates();
  }

  public submit(): void {
    this.dialogRef.close(this.fundingSource)
  }

  getBank(){
    this.invalidRoutingNumnerMsg = "";
    this.apiService.getBank(this.fundingSource.routing_number).subscribe(r=>{
      if(r && r.customer_name){
        this.fundingSource.bank_bankname = r.customer_name;
        this.fundingSource.bank_bankstate = r.state;
      
      }else{
        this.invalidRoutingNumnerMsg = "Invalid Routing Number.";
        console.log(this.invalidRoutingNumnerMsg)
      }

    }, err=>{
      this.invalidRoutingNumnerMsg = "Invalid Routing Number.";;
    });
  }

  getStates(){
    this.apiService.getStates().subscribe( r => {
      this.states = r;
    })
  }

  checkBankState(a){
    console.log(a)
    var temp = _.find(this.states,i => i.value.toUpperCase() == a.toUpperCase())
    if (temp) {
      this.bankForm.controls["bank_bankstate"].setErrors(null);
    }else{

       this.bankForm.controls["bank_bankstate"].setErrors({'incorrect': false});
    }
    console.log(this.bankForm.controls["bank_bankstate"])
  }

}
