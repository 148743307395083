import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Http, Response, RequestOptionsArgs, Headers, RequestOptions, ResponseContentType } from "@angular/http";
import { Observable } from 'rxjs';
import { ApplicantLoanComponent } from "src/app/applicant-loan/applicant-loan.component";
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class CommonService {

    accessToken: any = {};
    private options: RequestOptions;
    private _baseUrl = "";
    private testSSNs :any= []
    
    constructor(private http: Http,private httpClient: HttpClient ) {
        this.options = new RequestOptions();

        if(localStorage.getItem('openIdTokenParams')){
            var openIdTokenParams = JSON.parse(localStorage.getItem('openIdTokenParams'));
            if (openIdTokenParams && openIdTokenParams.access_token) {
                this.accessToken = openIdTokenParams.access_token;
            }
        }else{
            this.accessToken = localStorage.getItem('accessToken');
        }

        this.httpClient.get('/assets/test-ssns.json').subscribe(r=>{
            this.testSSNs = r;
        })
    }

    public get baseUrl (){
        if(!this._baseUrl){
            this._baseUrl = environment.baseUrl
        }
        return this._baseUrl;
    }

    public setBaseUrl (ssn){
       var isTestUrl = false;
        var value = environment.baseUrl;
        ssn = ssn.replace(/-/g, '');
        if(this.testSSNs.indexOf(ssn)>-1){
            value = environment.baseUrl_TEST;
            isTestUrl = true;
        }
        console.log(ssn)
        console.log(value)
         this._baseUrl = value;
         return isTestUrl;
    }

    public getAuthHeader(includeJsonContentType?: boolean): RequestOptions {
        if (!this.accessToken) {
            if(localStorage.getItem('openIdTokenParams')){
                var openIdTokenParams = JSON.parse(localStorage.getItem('openIdTokenParams'));
                if (openIdTokenParams && openIdTokenParams.access_token) {
                    this.accessToken = openIdTokenParams.access_token;
                }
            }else{
                this.accessToken = localStorage.getItem('accessToken');
            }
        }
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.accessToken });

        if (includeJsonContentType)
            headers.append("Content-Type", "application/json");

        headers.append("Accept", `application/json, text/plain, */*`);
        headers.append("ref-channel", ApplicantLoanComponent.refChannel);

        return new RequestOptions({ headers: headers });
    }

    
    public getAuthHeaderNew(includeJsonContentType?: boolean): any {
        if (!this.accessToken) {
            if(localStorage.getItem('openIdTokenParams')){
                var openIdTokenParams = JSON.parse(localStorage.getItem('openIdTokenParams'));
                if (openIdTokenParams && openIdTokenParams.access_token) {
                    this.accessToken = openIdTokenParams.access_token;
                }
            }else{
                this.accessToken = localStorage.getItem('accessToken');
            }
        }
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.accessToken });

        if (includeJsonContentType)
            headers.append("Content-Type", "application/json");

        headers.append("timeout", "10000");
        headers.append("Accept", `application/json, text/plain, */*`);
        headers.append("ref-channel", ApplicantLoanComponent.refChannel);

        return { headers: headers };
    }
 
    public handleError(error : Response) {
        if(error.status === 401){
            window.location.href="/login";
        }
        return Observable.throw(error.json());
    }

}