<form [formGroup]="myForm" class="{{ApplicantLoanComponent.currentChannel?.channelName}}">
  <!-- <div class="col-md-12 mt-3">
        <h4 class="mb-3">How much do you wish to deposit to <span style="color: #4EA1D3;">Premier Checking?</span></h4>
        <div style="display: grid;">
          <mat-hint>Minium Deposit: {{'20.00' | currency}}</mat-hint>
          <mat-form-field appearance="outline" style="width: 25%;">
            <mat-label>Total Deposit</mat-label>
            <input matInput name="amount_deposit" [min]="20">
          </mat-form-field>
        </div>
      </div> -->
  <div class="col-md-12 mt-3">
    <div class="segment-title text-left" *ngFor="let aa of interestedAccounts">
      <span>
        How much do you want to deposit to {{aa.account_name}}?
      </span>
      <mat-hint style="margin-top: 26px;">min deposit: {{aa.min_deposit | currency}}</mat-hint>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Total Deposit</mat-label>
        <input matInput formControlName="{{aa.account_name}}" (blur)="unfocusTotalDeposit($event.target.value)"
          mask="separator.2" prefix="$" thousandSeparator="," name="amount_deposit" [min]="aa.min_deposit"
          [(ngModel)]="aa.amount_deposit" required>
      </mat-form-field>
    </div>
  </div>

  <div class="col-md-12 mt-3">
    <div class="segment-title text-left" style="margin-bottom: 40px">
      How do you wish to fund your new account?
    </div>
    <!-- <div class="row col-md-12" style="margin-bottom: 3rem;">
      <button mat-raised-button class="btn funding-btn font-18-heavy col-md-4 mr-4" [disabled]="isDisabledCC()"
        (click)="openCreditCardForm()">Credit Card
        (Max
        $500.00)</button>

      <button style=" white-space: inherit;" mat-raised-button class="btn funding-btn col font-18-heavy" [disabled]="isDisabledBank()" (click)="openBankForm()">Bank Transfer (Max $12,000)</button>
    </div> -->

    <mat-radio-group [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedFundingSource"  aria-label="Select an option" class="example-radio-group">
      <mat-radio-button  [disabled]="isDisabled(funding.maxAmount)" *ngFor="let funding of fundingSources, let i = index" [value]="i"
        (click)="chooseFundingMethod(funding.funding_typeAsString, i, $event);">{{funding.displayName || funding.funding_typeAsString}}
        <button *ngIf="selectMethodCard == 'BANK' && i == 0 && !isDisabled(funding.maxAmount)" class="btn btn-continue funding-submit"
        (click)="openBankForm()">Add Bank</button>
        <button *ngIf="selectMethodCard == 'CREDITCARD' && i == 1 && !isDisabled(funding.maxAmount)" class="btn btn-continue funding-submit"
          (click)="openCreditCardForm(cardDetails)">{{cardDetails? 'Edit Card' : 'Add Card'}}</button>
   
      </mat-radio-button>
    </mat-radio-group>

    <div class="add-card" *ngIf="cardDetails && cardDetails.cardHolder && selectedFundingSource==1">
      <div #ccBoxFlip class="cc-box--flip">
        <div class="cc-box__front" style="padding-bottom: 40px;">
          <div class="cc-box__element">
            <label class="cc-form__label" for="cc-card-number-display"></label>
            <!-- <input style="font-size:16px !important"
              class="cc-form__input cc-form__input--transparent cc-form__input--embosed" id="cc-card-number-display"
              aria-label="Payment card number" disabled="disabled" [value]="cardDetails.cardNumber" type="password"> -->
              <span style="font-size: 16px !important;color: #fff;font-family: 'Inconsolata', monospace; text-shadow: 0 2px 1px rgb(0 0 0 / 30%);">************-{{cardDetails?.cardNumber?.slice(-4)}}</span>
          </div>
          <div class="cc-box__element">
            <label class="cc-form__label" for="cc-holder-display">CARD HOLDER</label>
            <input style="font-size:16px !important"
              class="cc-form__input cc-form__input--transparent cc-form__input--embosed" id="cc-holder-display"
              aria-label="Card holder" disabled="disabled" [value]="cardDetails.cardHolder | uppercase">
          </div>
          <div class="cc-box__element">
            <label class="cc-form__label" for="cc-valid-date-display">VALID THRU</label>
            <input style="font-size:16px !important"
              class="cc-form__input cc-form__input--left-align cc-form__input--transparent cc-form__input--embosed"
              id="cc-valid-date-display" aria-label="Card holder" disabled="disabled"
              [value]="cardDetails.expirationMonth +'/'+ cardDetails.expirationYear">
          </div>
          <div class="cc-box__chip"></div>
        </div>
        <div class="cc-box__back cc-back-custom">
          <div class="cc-box__strip">&nbsp;</div>
          <div class="cc-box__element">
            <input style="font-size:16px !important"
              class="cc-form__input cc-form__input--cursive cc-form__input--right-align mask-pass" id="cc-ccv-display" type="text"
              aria-label="CCV" disabled="disabled" [value]="cardDetails.ccv" title="CCV">
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="bankDetails && bankDetails.bank_name_on_card && selectedFundingSource==0">
      <div class="cc-box--bank">
        <div style="margin-left: 20px;">
          <span style="font-weight: bold;"> Bank Account</span>
          <span class="p-2 bank-item text-right" (click)="openBankForm(bankDetails)">
            <mat-icon>create</mat-icon>
          </span>
        </div>
       
       
    
        <div class="p-2 border-bottom bank-item d-flex">
          <label class="cc-form__label font-weight-bold" for="cc-holder-display">Name On Account:</label>
          <label class="cc-form__label text-right" for="cc-holder-display">{{bankDetails.bank_name_on_card}}</label>
        </div>
        
        <div class="p-2 border-bottom bank-item d-flex">
          <label class="cc-form__label font-weight-bold" for="cc-holder-display">Account Number:</label>
          <label class="cc-form__label text-right" for="cc-holder-display">{{bankDetails.bank_account_number}}</label>
        </div>
    
        <div class="p-2 border-bottom bank-item d-flex">
          <label class="cc-form__label font-weight-bold" for="cc-holder-display">Financial Institution:</label>
          <label class="cc-form__label text-right" for="cc-holder-display">{{bankDetails.bank_bankname}}</label>
        </div>

        <div class="p-2 border-bottom bank-item d-flex">
          <label class="cc-form__label font-weight-bold" for="cc-holder-display">Financial Institution
            State:</label>
          <label class="cc-form__label text-right" for="cc-holder-display">{{bankDetails.bank_bankstate}}</label>
        </div>
      </div>
    </div>

  </div>


  <div class="row col-md-12 mt-5" mat-dialog-actions>
    <div class="col-md-2" *ngIf="isLoading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      Loading...
    </div>
    <div class="col-md-12" *ngIf="!isLoading"> 

      <div *ngIf="captchaRequired">
        <form [formGroup]="reactiveForm">
          <re-captcha siteKey="6Lc789QZAAAAAJlFbqM5DlKR2-4UowIY427A092R" (resolved)=resolvedCaptchaToken($event)
            formControlName="recaptchaReactive"></re-captcha>
          <button [disabled]="reactiveForm.invalid" (click)="submitXA()"
            class="btn btn-custom btn-primary btn-continue btn-lg btn-block" style="margin-top: 10px;">Submit</button>
        </form>
      </div>

      <button *ngIf="!captchaRequired" (click)="submitXA()" class="btn btn-custom btn-continue btn-block funding-submit"
        [disabled]="(myForm.invalid || isLoading || (!xpressLoan.FUNDING_SOURCES || xpressLoan.FUNDING_SOURCES.length ==0)) && !isCash">
        Continue
      </button>
    </div>
  </div>
</form>