<div class="text-center" style="position: relative; line-height: 40px;">
  <span class="segment-title">
    {{isEdit? 'Update Applicant':'Joint Applicant'}}
  </span>
  <span (click)="close()" class="btn-close" [ngClass]="{'onMobile': !AppComponent.isDesktop}">
    <mat-icon>close</mat-icon>
  </span>
</div>
<div mat-dialog-content class="jointApplicant container">
  <app-common-applicant-form [pageBinding]="'jointApplicant'" *ngIf="creditCardApplicant" [jointModal]="true"
  [isJointApp]="true"
     [(creditCardApplicant)]="creditCardApplicant"
    [(vehicleApplicant)]="vehicleApplicant" [(personalApplicant)]="personalApplicant"
    [(xpressApplicant)]="xpressApplicant" [(validationErrors)]="validationErrorsJointApp"
    [(eligibilityModel)]="eligibilityModel"
    >
  </app-common-applicant-form>


  <div *ngIf="!formIsValid" style="max-width: 90%;">
    <app-validations-error-list [validationErrors]="validationErrorsJointApp" (selectedError)="focusControl($event)">
    </app-validations-error-list>
  </div>

  <div *ngIf="hasProductType2()" class="custom-control custom-checkbox mb-4" [ngClass]="{'custom-checkbox-mobile': !AppComponent.isDesktop}">
    <input [(ngModel)]="creditJointCheckboxModel" [ngModelOptions]="{standalone: true}"  type="checkbox" class="custom-control-input" id="creditJointCheckbox"
      value="showhide">
    <label class="custom-control-label" for="creditJointCheckbox">Does the joint applicant authorize the loan request and the pulling of their credit?</label>
  </div>

  <div  class="segment-action-btns {{ApplicantLoanComponent.currentChannel?.channelName}}" class="m-3">
    <button [disabled]="!checkValid() || (hasProductType2() && !creditJointCheckboxModel) " (click)="saveJointApp()" class="btn btn-custom btn-continue btn-lg btn-block">Continue</button>
  </div>
</div>


<!-- 
<div mat-dialog-actions>
    <a  style="width: 100%;padding: 15px;" href="javascript:void()"><button  (click)="saveJointApp()" class="btn btn-primary btn-lg btn-block">Save</button></a>
</div> -->


