

<section style="width: 100%;height: 100%;background: white;" class="{{ApplicantLoanComponent.currentChannel?.channelName}}">
    <div style="position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%);transform: translate(-50%, -50%);">
        <div mat-dialog-content class="segment-content mb-4">
            Please click “Continue” to move to the next step. (This may take a few seconds, so please do not close this page, refresh, or hit the back button).
             <!-- ({{eventName}}) -->
        </div>
        <div class="row segment-action-btns" mat-dialog-actions style="padding: 0 !important;">
          <button mat-raised-button class="btn btn-custom btn-continue btn-block col" onClick='parent.SampleFunction()'>Continue</button>
        </div>
    </div>

</section>
