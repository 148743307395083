import { Component, OnInit, Inject, Input, ChangeDetectorRef, NgZone } from '@angular/core';
import { LoanTypeEnum } from 'src/models/loan-type.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/shared/api.service';
import { PERSONAL_LOAN, PERSONAL_LOAN_INFOPersonal_loan_type } from 'src/models/personal_loan.model';
import * as _ from 'lodash'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { XPRESS_APPLICANT } from 'src/models/xpress_loan.model';

@Component({
  selector: 'app-docu-sign',
  templateUrl: './docu-sign.component.html',
  styleUrls: ['./docu-sign.component.css']
})
export class DocuSignComponent implements OnInit {
    
  appId: string ="";
  decisionAppId: string ="";
  questions = [];
  session_id ="";
  htmlResult = "";
  isLoading= true;
  hasJointApp = false;
  currentAppIndex = '0';
  currentFullName = '';
  selectedProducts: any[];
  applicants : XPRESS_APPLICANT[];
  docuSignUrl :string;
  AppComponent = AppComponent;
  constructor(public dialogRef: MatDialogRef<DocuSignComponent>,
     private apiService: ApiService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef, 
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.appId = data.appId;
      this.decisionAppId = data.decisionAppId;
      // this.applicants = data.applicants;
      this.selectedProducts = data.selectedProducts;


      (<any>window).SampleFunction= this.SampleFunction.bind(this);

   }
   applicantForm: FormGroup = new FormGroup({});
   ngOnInit(): void {

    this.getDocs(this.appId, -1, -1);

    
    // AppComponent.docuSignCallbacked.subscribe(r=>{

      
    // })

  }

  SampleFunction() {
    this.ngZone.run(()=>{

      //if(!this.decisionAppId){
        this.dialogRef.close();
        this.cdr.detectChanges();
      // }else{

      //   var productType2 = _.find(this.selectedProducts, p=>{
      //     return p.productGroupId >=4;
      //   });
      //   this.isLoading = true;
      //   this.getDocs(this.decisionAppId, productType2.productGroupId, productType2.id);
      //   setTimeout(()=>{
      //     delete this.decisionAppId;
      //   },200)

      // }




    })
    // setTimeout(()=>{


    // },100)

   }

  private getDocs(id, productGroupId, productId) {
    this.apiService.getDocuSignDocs(id,  productGroupId, productId).subscribe(r => {
      this.isLoading = false;
      //this.session_id = r.session_id;
      this.docuSignUrl = r.OUTPUT.RESPONSE.URL;
    });
  }

  save(){
    var answers = _.map(this.questions, q=>{
      return {
        answer_text: (q.selectedAnswer +1 ).toString()
      }
    }) 
    this.apiService.submitAnswers(this.appId, this.currentAppIndex, 'false', this.session_id, answers).subscribe(r=>{
     
        this.dialogRef.close();

    });
  }



  
}
