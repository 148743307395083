import {Injectable, EventEmitter} from "@angular/core";
import * as _ from 'lodash';
import { VEHICLE_APPLICANT, VEHICLE_LOAN, VEHICLE_FINANCINGProcessing_type, VEHICLE_FINANCINGValue_type, VEHICLE_FINANCINGCommercial_title } from 'src/models/vehicle_loan.model';
import { base_phone_country_alpha2, base_employee_of_lender_types, XPRESS_APPLICANT, XPRESS_LOAN, XPRESS_ACCOUNT_TYPESACCOUNT_TYPETerm_type, XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type, account_type } from 'src/models/xpress_loan.model';
import { BASE_APPLICANTDeclined_answer_race_gender, CREDITCARD_APPLICANT, CREDITCARD_LOAN } from 'src/models/creditcard_loan.model';
import { PERSONAL_APPLICANT, PERSONAL_LOAN } from 'src/models/personal_loan.model';
import { FinalPageEnum } from 'src/models/common.model';
import { LoanTypeEnum } from 'src/models/loan-type.enum';
import { ApiService } from './api.service';
import { ApplicantLoanComponent } from 'src/app/applicant-loan/applicant-loan.component';
import { FinalPageComponent } from "src/components/final-page/final-page.component";
import * as moment from "moment";



@Injectable(
    {
        providedIn: "root"
    }
)
export class LoanAppService {


    constructor(private apiService: ApiService)    {

    }





    fillOtherLoans(xpressLoan, vehicleLoan, personalLoan, creditCardLoan){

      //init ssn
      if (xpressLoan.APPLICANTS.length > 0) {
        const ssn = _.cloneDeep(xpressLoan.APPLICANTS[0].ssn);
        personalLoan.APPLICANTS.APPLICANT[0].ssn = vehicleLoan.APPLICANTS.APPLICANT[0].ssn = creditCardLoan.APPLICANTS.APPLICANT[0].ssn = xpressLoan.APPLICANTS[0].ssn = ssn ? ssn.replace(/\D/g, "") : "";
        
        if (vehicleLoan.APPLICANTS.APPLICANT[0].SPOUSE || personalLoan.APPLICANTS.APPLICANT[0].SPOUSE) {
          const ssn1 = _.cloneDeep(xpressLoan.APPLICANTS[0].SPOUSE.ssn);
          personalLoan.APPLICANTS.APPLICANT[0].SPOUSE.ssn = vehicleLoan.APPLICANTS.APPLICANT[0].SPOUSE.ssn = xpressLoan.APPLICANTS[0].SPOUSE.ssn = ssn1 ? ssn1.replace(/\D/g, "") : "";
        }
        if (creditCardLoan.APPLICANTS.APPLICANT[0].SPOUSE) {
          const ssn1 = _.cloneDeep(xpressLoan.APPLICANTS[0].SPOUSE.ssn);
          creditCardLoan.APPLICANTS.APPLICANT[0].SPOUSE.ssn = xpressLoan.APPLICANTS[0].SPOUSE.ssn = ssn1 ? ssn1.replace(/\D/g, "") : "";
        }
      }

        var commonFields = ['CUSTOM_QUESTIONS'];
        _.each(commonFields, f=>{
          try{
            xpressLoan[f] = vehicleLoan[f] = personalLoan[f] = creditCardLoan[f];
          }catch{}
        })

      }

    submitDecisionLoan(loanType, creditCardLoan, vehicleLoan, personalLoan) {
        var data;
        switch (loanType) {
          case LoanTypeEnum.CC:
            data = creditCardLoan;
            break;
          case LoanTypeEnum.VL:
            data = vehicleLoan;
            break;
          case LoanTypeEnum.PL:
            data = personalLoan;
            break;
        }
        return this.apiService.submitDecisionLoan(loanType, '',data)
      }

    processFinalPage(soResponses, XAId) {
      ApplicantLoanComponent.currentStepIdChanged.emit({ step: 3, soResponses: soResponses })
      FinalPageComponent.forceRefresh.emit(true);
    }

    initVehicleLoan(vehicleLoan){
      
        vehicleLoan.VEHICLES.VEHICLE= [{
          mileage:"0" ,
          //is_new_vehicle:BASE_APPLICANTDeclined_answer_race_gender.Y,
          vehicle_sales_price:3.14 ,
          vehicle_value:3.14 ,
          vehicle_value_type:"RETAIL" ,
          is_rebuilt_restored:BASE_APPLICANTDeclined_answer_race_gender.Y ,
          processing_type:VEHICLE_FINANCINGProcessing_type.MPLM ,
          vehicle_options:"String" ,
          value_type:VEHICLE_FINANCINGValue_type.NONE,
          vin:"String",
            year:"0" ,
            make:"",
          //  model:"", 
            license:"String",
              nada_info:"String", 
              body_type:"String", 
              commercial_title: VEHICLE_FINANCINGCommercial_title.NONE
        } as any];
    }


}