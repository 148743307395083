import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { XPRESS_LOAN, XPRESS_APPLICANT, BASE_FUNDING_INFOPayment_frequency, BASE_LOAN_STATUSLoan_status, base_phone_country_alpha2, base_employee_of_lender_types, XPRESS_LOAN_INFOStatus, XPRESS_LOAN_INFOEntity_type } from 'src/models/xpress_loan.model';
import { CREDITCARD_LOAN11, CREDITCARD_APPLICANT, CREDITCARD_LOANCOMMENTS, CREDITCARD_FUNDING_INFOPayment_method, CREDITCARD_LOANFUNDINGINSURANCE_OPTIONSLoan_class, CREDITCARD_LOANFUNDING, BASE_APPLICANTDeclined_answer_race_gender, CREDITCARD_LOAN_INFOCard_type } from 'src/models/creditcard_loan.model';
import { VEHICLE_LOAN, VEHICLE_LOANVEHICLES, VEHICLE_LOANFUNDING, VEHICLE_LOANCOMMENTS, VEHICLE_APPLICANT } from 'src/models/vehicle_loan.model';
import { LoanTypeEnum } from 'src/models/loan-type.enum';
import { PERSONAL_LOAN, PERSONAL_APPLICANT, PERSONAL_LOANFUNDING } from 'src/models/personal_loan.model';
import { ApplicantService } from 'src/shared/applicant.service';
import { CommonApplicantFormComponent } from 'src/components/step-one/common-applicant-form/common-applicant-form.component';
import { AuthService } from 'src/shared/auth.service';
import { CommonService } from 'src/shared/common.service';
import { ValidationErrors } from '@angular/forms';
import { StepOneComponent } from 'src/components/step-one/step-one.component';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { base_applicant_type, BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION, FinalPageEnum } from 'src/models/common.model';
import { LoanAppService } from 'src/shared/loan-app.service';
import { AppComponent } from '../app.component';
import { JointApplicantModalComponent } from 'src/components/step-one/joint-applicant-modal/joint-applicant-modal.component';
import { ApiService } from 'src/shared/api.service';
import { MatDialog } from '@angular/material/dialog';
import { BankFormComponent } from 'src/components/funding-page/bank-form/bank-form.component';
import { ActivatedRoute } from '@angular/router';
import { AnswerQuestionsComponent } from 'src/components/review-page/answer-questions/answer-questions.component';
import { AtomicService } from 'src/shared/atomic.service';
import { FinalPageComponent } from 'src/components/final-page/final-page.component';
import { MemberService } from 'src/shared/member.service';
import { Util } from 'src/shared/util';
import { LoanApiLogService } from 'src/shared/loan-api-log.service';
import { NmaoWfService } from 'src/shared/nmao-wf.service';
import { FUNDDEPOSIT_INPUTREQUEST } from 'src/models/fund-deposit.model';




@Component({
  selector: 'app-applicant-loan',
  templateUrl: './applicant-loan.component.html',
  styleUrls: ['./applicant-loan.component.css']
})
export class ApplicantLoanComponent implements OnInit {

  steps = [
    {
      id: 1,
      headerText: "Application"
    },
    {
      id: 2,
      headerText: "Review"
    },
    {
      id: 3,
      headerText: "Confirm"
    }
  ]

  currentStepId: any = {};

  creditCardLoan: CREDITCARD_LOAN11 = new CREDITCARD_LOAN11();

  vehicleLoan: VEHICLE_LOAN = new VEHICLE_LOAN();
  personalLoan: PERSONAL_LOAN = new PERSONAL_LOAN();

  xpressLoan: XPRESS_LOAN = new XPRESS_LOAN();

  eligibilityModel ={
    qualifyingEmployer:{},
    eligibilityRelationship:{
      isChecked: false,
      name:"",
      phone:"",
    },
    employee_of_lender_type:{
      isChecked: false
    }
  }

  xpressLoanResponse: any;

  isThereError: boolean;

  selectedLoanType: LoanTypeEnum;
  selectedProducts = [];
  public static addJointApp: EventEmitter<any> = new EventEmitter<any>();
  soResponses: any ;

  applicationType: number;
  public static employerNameChanged: EventEmitter<any> = new EventEmitter<any>();

  public static copyAddressFromMainApp: EventEmitter<boolean> = new EventEmitter<boolean>();

  public static currentStepIdChanged: EventEmitter<any> = new EventEmitter<any>();
  public static forceFinalPageError = false;

  public static currentChannel: any;
  public static refChannel : string = '';
  public static pcQuery : string = '';

  public static isCoBrand = false;

  public static defaultPrimarySavingProduct;

  sessionId = '';

  validationErrors: ValidationErrors = {};

  AppComponent =  AppComponent;
  ApplicantLoanComponent = ApplicantLoanComponent;
  customQuestions: BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION[] = [
    //hearAboutUsModel 0
    {
      CUSTOM_QUESTION_ANSWER: [{ answer_text: "", answer_value: "" }],
      question_name: "{REFERRAL}",
      question_type: "DROPDOWN"
    },
    // promotion code 1
    {
      CUSTOM_QUESTION_ANSWER: [{ answer_text: "", answer_value: "" }],
      question_name: "{PROMO}",
      question_type: "TEXTBOX"
    },
    // WITHHOLDING 2
    {
      CUSTOM_QUESTION_ANSWER: [],
      question_name: "{WITHHOLDING}",
      question_type: "DROPDOWN"
    },

    // consent 3
    {
      CUSTOM_QUESTION_ANSWER: [],
      question_name: "{TCPA}",
      question_type: "DROPDOWN"
    },

    //consentToContactMP 4
    {
      CUSTOM_QUESTION_ANSWER: [],
      question_name: "{TCPA_MKT}",
      question_type: "DROPDOWN"
    },


    //Text Opt-In 5
    {
      CUSTOM_QUESTION_ANSWER: [],
      question_name: "Text Opt-In",
      question_type: "DROPDOWN"
    },

    //APPFROM 6
    {
      CUSTOM_QUESTION_ANSWER: [{ answer_text: "ONLINE", answer_value: "ONLINE" }],
      question_name: "{APPFROM}",
      question_type: "CHECKBOX"
    },

  
  ];


  constructor(private applicantService: ApplicantService, private authService: AuthService, private commonService: CommonService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private atomicService: AtomicService,
    private memberService: MemberService,
    private loanAppService: LoanAppService,
    private nmaoWfService: NmaoWfService,
    private loanApiLogService: LoanApiLogService) {
    

    this.authService.getToken().subscribe(async r => {
      this.commonService.accessToken = r.access_token;
      this.xpressLoan.version = this.vehicleLoan.version = this.personalLoan.version = this.creditCardLoan.version = 5.248;
      
      this.currentStepId = 1;
      // setTimeout(()=>{
      //       this.soResponses ={appId : '8cfc8987f9ab46189e90921a711b72e2', applicationType : 1}
      //       AppComponent.direct_deposit_answer = "Yes";

           
            // setTimeout(()=>{
            //   ApplicantLoanComponent.currentStepIdChanged.emit({ step: 2.5, soResponses: this.soResponses, selectedProducts: this.selectedProducts});
            // },5000)

      //     },8000)

      //   setTimeout(()=>{

      //       //AppComponent.direct_deposit_answer = "Yes";

      //       ApplicantLoanComponent.currentStepIdChanged.emit({ step: 2});
         
      // },6000)


    })

    this.route.queryParams.subscribe(params => {
      console.log(params)
      ApplicantLoanComponent.refChannel = params['ref'];
      ApplicantLoanComponent.pcQuery = params['pc']?.toLowerCase();
      this.apiService.getChannelByCurrentUri(ApplicantLoanComponent.refChannel).subscribe(r=>{
        if(r){
          ApplicantLoanComponent.currentChannel = r;
          ApplicantLoanComponent.isCoBrand = r.channelTypeId == 3;
          if(ApplicantLoanComponent.isCoBrand){
            if(ApplicantLoanComponent.currentChannel.channelName.toLowerCase() == 'spyder'){
              this.eligibilityModel.qualifyingEmployer ={displayName:'Spyder/Surfrider', value:6006}
            }else{
              this.eligibilityModel.qualifyingEmployer ={displayName:'Surfrider Foundation', value:4018}
            }

            let questionAppFrom = _.find(this.customQuestions, cq=>{
              return cq.question_name == '{APPFROM}'
            }) 
            var section = _.find(ApplicantLoanComponent.currentChannel.channelSectionSubscriptions, css=>{
              return css.sectionId == 1;
            })
            if(section){
              var md = JSON.parse(section.metaData);
              questionAppFrom.CUSTOM_QUESTION_ANSWER = [{ answer_text: md.name, answer_value: md.value }]

              this.customQuestions.push(
                {
                  CUSTOM_QUESTION_ANSWER: [{ answer_text: md.name, answer_value: md.value }],
                  question_name: "BDOREFERRAL",
                  question_type: "DROPDOWN"
                },
              )
            }

           
              console.log($('#bg-main-id'))
              // $('#bg-main-id').css('color', 'black');   
              // $('#bg-main-id').css('background-color', 'black');    
              if(ApplicantLoanComponent.currentChannel.accentColor){

                $('#bg-main-id').css('background-image', `linear-gradient(to top right,${ApplicantLoanComponent.currentChannel.accentColor.split('-')[0]},${ApplicantLoanComponent.currentChannel.accentColor.split('-')[1]} )`);
              }
              
            
            
          }
        }
      })

      //test getQuestions()
      //id e51acaa340f242c89026e60a846f186a
      // const XAId = '8401f72c0dc44c4bae0a5fae0f7ea119'
      // this.dialog.open(AnswerQuestionsComponent, {
      //   disableClose: true,
      //   panelClass: AppComponent.isDesktop ? 'col-md-9' : 'full-screen',
      //   data: {
      //     appId: XAId,
      //     //decisionAppId: decisionAppId,
      //     hasJointApp: true,
      //      applicants: [
      //        {
      //         first_name: 'MainFN',
      //         last_name: 'Kimbrough',
      //         SPOUSE:{
      //           first_name: 'JointFN',
      //           last_name: 'Massara',
      //         }
      //        }
      //      ],
      //     // selectedProducts: this.selectedProducts,
      //     // soResponses: this.soResponses,
      //     // applicationType: this.applicationType
      //   }
      // })

      //test atomic

      // this.atomicService.showAtomicDeposit('0000132647','checking').subscribe(r=>{
      // })

      //test get member_num
      // this.apiService.getMemberNumber('84e2a006d6c142e9bcdb20e0849bef93').subscribe(async getMemberNumberRes=>{
      //   console.log(getMemberNumberRes)
      // }, err=>{
      //   console.log(err)
      // });

  });

  }

  ngOnInit(): void {
    this.sessionId = Util.generateId();
    ApplicantLoanComponent.currentStepIdChanged.subscribe(r=>{
      if(r.step){
        this.currentStepChanging(r);
      }
     
      this.memberService.postUserSession({
        lastPage: r.lastPage || this.currentStepId,
        appId: this.soResponses?.appId,
        lastPageMetaData: r.lastPageMetaData,
        productsJson: JSON.stringify(this.selectedProducts),
        sessionId:  this.sessionId,
        fullName: !!!this.xpressLoan.APPLICANTS? '' : this.xpressLoan?.APPLICANTS[0]?.first_name  + " " + this.xpressLoan?.APPLICANTS[0]?.last_name
      }).subscribe(r=>{});
    })
    ApplicantLoanComponent.currentStepIdChanged.emit({ step: 1, soResponses: null })

    // this.dialog.open(BankFormComponent, {
    //   panelClass: AppComponent.isDesktop ? 'col-md-4' : 'full-screen',
    //   data:{
    //     existingBank: {}
    //   },
    //   autoFocus: true,
    //   disableClose: true
    // })
    
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "If you proceed then your form won't be saved and you will have to refill the form.";
      e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
          return confirmationMessage;              // Gecko, WebKit, Chrome <34
      });


    this.initData();

    this.initDataVehicle();
    this.initDataPersonal();
    ApplicantLoanComponent.addJointApp.subscribe(r => {




      //joint applicant
      this.creditCardLoan.APPLICANTS.APPLICANT[0].SPOUSE = r.creditCardApplicant;
  

      this.xpressLoan.APPLICANTS[0].SPOUSE = r.xpressApplicant;
      this.vehicleLoan.APPLICANTS.APPLICANT[0].SPOUSE  = r.vehicleApplicant;
      this.personalLoan.APPLICANTS.APPLICANT[0].SPOUSE = r.personalApplicant;

      //SET APPLICANT_TYPE FOR DECISION
      this.creditCardLoan.APPLICANTS.APPLICANT[0].SPOUSE.applicant_type = this.vehicleLoan.APPLICANTS.APPLICANT[0].SPOUSE.applicant_type= this.personalLoan.APPLICANTS.APPLICANT[0].SPOUSE.applicant_type = base_applicant_type.C;

     if(this.xpressLoan.APPLICANTS[0].SPOUSE.ssn === this.xpressLoan.APPLICANTS[0].ssn){

        this.validationErrors.duplicatedSsn =  {
          'hasSamessn': 'hasSamessn'
         } as ValidationErrors;
     }else{
        delete  this.validationErrors.duplicatedSsn ;
     }
    })

    // ApplicantLoanComponent.employerNameChanged.subscribe(r => {
    //   this.xpressLoan.FOM_ANSWERED_QUESTIONS = r;
    //   if (this.xpressLoan.LOAN_INFO) {
        
    //   }

    // })

    ApplicantLoanComponent.copyAddressFromMainApp.subscribe(r=>{
      JointApplicantModalComponent.copyAddressFromMainApp.emit(this.creditCardLoan.APPLICANTS.APPLICANT[0]);
    })

  }



  initDataVehicle() {
    var vehicleApplicant = this.applicantService.initVehicleApplicant();

    this.vehicleLoan.APPLICANTS = {
      APPLICANT: [vehicleApplicant],
      selected_product_borrower_id: ""
    };
    this.vehicleLoan.VEHICLES = new VEHICLE_LOANVEHICLES();
    this.vehicleLoan.LOAN_INFO = {
      //amount_requested:3.14,
      //loan_term:"3",
      rate_code: "",
      max_underwrite_amount: "0",
      epl_fees_total: 0,
      is_claimed: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_complete_consumer: BASE_APPLICANTDeclined_answer_race_gender.N,
      require_product_refresh: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_addon: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_amendment: BASE_APPLICANTDeclined_answer_race_gender.N,
      solve_for: "TERM",
      is_possible_dupe: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_workout_loan: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_solvefor_manual: BASE_APPLICANTDeclined_answer_race_gender.N,
      tier: "0",
      tier_previous: "0",
      is_prequalification_applied: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_ofac: BASE_APPLICANTDeclined_answer_race_gender.N,
      has_balance_transfer: BASE_APPLICANTDeclined_answer_race_gender.N,
      card_type: CREDITCARD_LOAN_INFOCard_type.CREDIT,
      card_number: "",
      expire_date: "",
      card_name: "",
      apr: 0,
      intro_apr: 0,
      apr_balance_transfer: 0,
      intro_apr_balance_transfer: 0.000, apr_cash_advances: 0.000,
      intro_apr_cash_advances:
        0.000, apr_other: 0.000, intro_apr_other: 0.000,
      monthly_payment: 0.00, credit_limit: 0.00,
      current_credit_limit: 0.00,
      requested_credit_limit: 0.00,
      requested_card_name: "",

      CARD_HOLDERS: [{
        order_card: BASE_APPLICANTDeclined_answer_race_gender.N,
        first_name: "",
        last_name: "",
        m_initial: "",

      }],
      amount_approved_variance: 0.00,
      is_advance_request: BASE_APPLICANTDeclined_answer_race_gender.N,
      purpose_type: "",
      current_card_rate: 0.000,
      current_card_margin: 0.000
    } as any;

    this.vehicleLoan.PRICE_ADJUSTMENTS = [];

    this.vehicleLoan.LOAN_STATUS =
      {
        loan_status: BASE_LOAN_STATUSLoan_status.PEN,
        initial_entry_timestamp: "2015-05-19T16:51:11-07:00",
        last_modified: "2015-05-19T16:53:30-07:00",
        is_locked: BASE_APPLICANTDeclined_answer_race_gender.N,
        existing_balance: 0.00,
        delinquency_period: "0",
        delinquency_amount: 0.00,
        app_receive_date: "2015-05-19"
      } as any;

    this.vehicleLoan.FUNDING = {
      daily_periodic_rate: 0,
      payment_frequency: BASE_FUNDING_INFOPayment_frequency.MONTHLY,
      payment_method: CREDITCARD_FUNDING_INFOPayment_method.MINPAYMENT,
      misc_implant: "",
      index: 0,
      margin: 0,
      floor: 0,
      ceiling: 0,
      payment_method_set_payment: 0,
      INSURANCE_OPTIONS: { loan_class: CREDITCARD_LOANFUNDINGINSURANCE_OPTIONSLoan_class.O, description: "" } as any
    } as any as VEHICLE_LOANFUNDING;
    this.vehicleLoan.DISBURSEMENTS = [];
    this.vehicleLoan.TASK_LIST = [];
    this.vehicleLoan.COMMENTS = new VEHICLE_LOANCOMMENTS();

    this.loanAppService.initVehicleLoan(this.vehicleLoan)

  }

  initDataPersonal() {
    var personalApplicant = this.applicantService.initPersonalApplicant();

    this.personalLoan.APPLICANTS = {
      APPLICANT: [personalApplicant],
      selected_product_borrower_id: ""
    };
    this.personalLoan.LOAN_INFO = {
      //amount_requested:3.14,
      //loan_term: "3",
      rate_code: "",
      max_underwrite_amount: "0",
      epl_fees_total: 0,
      is_claimed: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_complete_consumer: BASE_APPLICANTDeclined_answer_race_gender.N,
      require_product_refresh: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_addon: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_amendment: BASE_APPLICANTDeclined_answer_race_gender.N,
      solve_for: "TERM",
      is_possible_dupe: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_workout_loan: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_solvefor_manual: BASE_APPLICANTDeclined_answer_race_gender.N,
      tier: "0",
      tier_previous: "0",
      is_prequalification_applied: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_ofac: BASE_APPLICANTDeclined_answer_race_gender.N,
      has_balance_transfer: BASE_APPLICANTDeclined_answer_race_gender.N,
      card_type: CREDITCARD_LOAN_INFOCard_type.CREDIT,
      card_number: "",
      expire_date: "",
      card_name: "",
      apr: 0,
      intro_apr: 0,
      apr_balance_transfer: 0,
      intro_apr_balance_transfer: 0.000, apr_cash_advances: 0.000,
      intro_apr_cash_advances:
        0.000, apr_other: 0.000, intro_apr_other: 0.000,
      monthly_payment: 0.00, credit_limit: 0.00,
      current_credit_limit: 0.00,
      requested_credit_limit: 0.00,
      requested_card_name: "",

      CARD_HOLDERS: [{
        order_card: BASE_APPLICANTDeclined_answer_race_gender.N,
        first_name: "",
        last_name: "",
        m_initial: "",

      }],
      amount_approved_variance: 0.00,
      is_advance_request: BASE_APPLICANTDeclined_answer_race_gender.N,
      purpose_type: "",
      current_card_rate: 0.000,
      current_card_margin: 0.000
    } as any;

    this.personalLoan.PRICE_ADJUSTMENTS = [];

    this.personalLoan.LOAN_STATUS =
      {
        loan_status: BASE_LOAN_STATUSLoan_status.PEN,
        initial_entry_timestamp: "2015-05-19T16:51:11-07:00",
        last_modified: "2015-05-19T16:53:30-07:00",
        is_locked: BASE_APPLICANTDeclined_answer_race_gender.N,
        existing_balance: 0.00,
        delinquency_period: "0",
        delinquency_amount: 0.00,
        app_receive_date: "2015-05-19"
      } as any;

    this.personalLoan.FUNDING = {
      daily_periodic_rate: 0,
      payment_frequency: BASE_FUNDING_INFOPayment_frequency.MONTHLY,
      payment_method: CREDITCARD_FUNDING_INFOPayment_method.MINPAYMENT,
      misc_implant: "",
      index: 0,
      margin: 0,
      floor: 0,
      ceiling: 0,
      payment_method_set_payment: 0,
      INSURANCE_OPTIONS: { loan_class: CREDITCARD_LOANFUNDINGINSURANCE_OPTIONSLoan_class.O, description: "" } as any
    } as any as PERSONAL_LOANFUNDING;
    this.personalLoan.DISBURSEMENTS = [];
    this.personalLoan.TASK_LIST = [];
    this.personalLoan.COMMENTS = new VEHICLE_LOANCOMMENTS();
  }

  initData() {

    this.xpressLoan.SYSTEM = this.vehicleLoan.SYSTEM = this.personalLoan.SYSTEM = this.creditCardLoan.SYSTEM = [
      {
        BRANCH: {
          name: "test"
        } as any
      } as any
    ]
    this.xpressLoan.LOAN_INFO = {
      // is_new_customer:base_YN.Y,
      // incomplete_path:"",
      requirement_description_value: "",
      // status: XPRESS_LOAN_INFOStatus.NEW,
      // account_position:1,
      // entity_type:XPRESS_LOAN_INFOEntity_type.P,
      // is_claimed:base_YN.N,
      // is_locked:base_YN.N,
      // is_minor:base_YN.N,
      // is_lender_disclosure_checked:base_YN.Y,
      // is_possible_dupe:base_YN.N,
      // is_high_risk_consumer:base_YN.N
    } as any;

    this.creditCardLoan.APPLICANTS = {
      APPLICANT: [this.applicantService.initCreditCardApplicant()],
      selected_product_borrower_id: ""
    };

    this.xpressLoan.APPLICANTS = [this.applicantService.initXpressApplicant()];
    this.xpressLoan.COMMENTS = {} as any;

    this.creditCardLoan.LOAN_INFO = {
      rate_code: "",
      max_underwrite_amount: "0",
      epl_fees_total: 0,
      is_claimed: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_complete_consumer: BASE_APPLICANTDeclined_answer_race_gender.N,
      require_product_refresh: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_addon: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_amendment: BASE_APPLICANTDeclined_answer_race_gender.N,
      solve_for: "TERM",
      is_possible_dupe: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_workout_loan: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_solvefor_manual: BASE_APPLICANTDeclined_answer_race_gender.N,
      tier: "0",
      tier_previous: "0",
      is_prequalification_applied: BASE_APPLICANTDeclined_answer_race_gender.N,
      is_ofac: BASE_APPLICANTDeclined_answer_race_gender.N,
      has_balance_transfer: BASE_APPLICANTDeclined_answer_race_gender.N,
      card_type: CREDITCARD_LOAN_INFOCard_type.CREDIT,
      purpose_type: "New Card",
      card_number: "",
      expire_date: "",
      card_name: "",
      apr: 0,
      intro_apr: 0,
      apr_balance_transfer: 0,
      intro_apr_balance_transfer: 0.000, apr_cash_advances: 0.000,
      intro_apr_cash_advances:
        0.000, apr_other: 0.000, intro_apr_other: 0.000,
      monthly_payment: 0.00, credit_limit: 0.00,
      current_credit_limit: 0.00,
      requested_credit_limit: 0.00,
      requested_card_name: "",

      CARD_HOLDERS: [{
        order_card: BASE_APPLICANTDeclined_answer_race_gender.N,
        first_name: "",
        last_name: "",
        m_initial: "",

      }],
      amount_approved_variance: 0.00,
      is_advance_request: BASE_APPLICANTDeclined_answer_race_gender.N,

      current_card_rate: 0.000,
      current_card_margin: 0.000
    } as any;
    this.creditCardLoan.PRICE_ADJUSTMENTS = [];

    this.creditCardLoan.LOAN_STATUS =
      {
        loan_status: BASE_LOAN_STATUSLoan_status.PEN,
        initial_entry_timestamp: "2015-05-19T16:51:11-07:00",
        last_modified: "2015-05-19T16:53:30-07:00",
        is_locked: BASE_APPLICANTDeclined_answer_race_gender.N,
        existing_balance: 0.00,
        delinquency_period: "0",
        delinquency_amount: 0.00,
        app_receive_date: "2015-05-19"
      } as any;

    this.creditCardLoan.FUNDING = {
      daily_periodic_rate: 0,
      payment_frequency: BASE_FUNDING_INFOPayment_frequency.MONTHLY,
      payment_method: CREDITCARD_FUNDING_INFOPayment_method.MINPAYMENT,
      misc_implant: "",
      index: 0,
      margin: 0,
      floor: 0,
      ceiling: 0,
      payment_method_set_payment: 0,
      INSURANCE_OPTIONS: { loan_class: CREDITCARD_LOANFUNDINGINSURANCE_OPTIONSLoan_class.O, description: "" } as any
    } as any as CREDITCARD_LOANFUNDING;
    this.creditCardLoan.COMMENTS = new CREDITCARD_LOANCOMMENTS();
  }

  

  currentStepChanging(val) {



    if (val.selectedLoanType) { this.selectedLoanType = val.selectedLoanType; }
    if (val.selectedProducts) { this.selectedProducts = val.selectedProducts; }
    if (val.applicationType) { this.applicationType = val.applicationType; }

      this.soResponses = val.soResponses;
    



    this.xpressLoanResponse = val.xpressLoanResponse;

    this.isThereError = val.isThereError;

    // if(val.step == 3){
    //   // getLoan first
    //   this.apiService.getLoan()
    // }else{
    //   this.currentStepId = val.step;
    //   this.scrollToTopPage()
    // }

    this.currentStepId = val.step;
    this.scrollToTopPage()


  }

  @ViewChild("stepOne") stepOne: StepOneComponent;

  selectedProductChange() {
    this.stepOne.continue()
  }

  productsHaveChanged(e) {
    this.selectedProducts = e;
    //console.log(e)
    var ccLoanProduct = _.find(this.selectedProducts, {loan_type: LoanTypeEnum.CC, productGroupId:4});
    if(ccLoanProduct!=null){
      this.creditCardLoan.LOAN_INFO.requested_card_name = this.creditCardLoan.LOAN_INFO.card_name  = ccLoanProduct.name;
      this.creditCardLoan.LOAN_INFO.purpose_type= "New Card";
      
    }
  }

  scrollToTopPage() {
    $("html, body").stop().animate({ scrollTop: 0 }, 500, 'swing'); //scropTop of page
  }

}
