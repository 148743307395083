import { Injectable, EventEmitter } from "@angular/core";
import * as _ from 'lodash';
import { VEHICLE_APPLICANT, VEHICLE_LOAN, VEHICLE_FINANCINGProcessing_type, VEHICLE_FINANCINGValue_type, VEHICLE_FINANCINGCommercial_title } from 'src/models/vehicle_loan.model';
import { base_phone_country_alpha2, base_employee_of_lender_types, XPRESS_APPLICANT, XPRESS_LOAN, XPRESS_ACCOUNT_TYPESACCOUNT_TYPETerm_type, XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type, account_type, XPRESS_LOAN_INFOStatus } from 'src/models/xpress_loan.model';
import { BASE_APPLICANTDeclined_answer_race_gender, CREDITCARD_APPLICANT, CREDITCARD_LOAN } from 'src/models/creditcard_loan.model';
import { PERSONAL_APPLICANT, PERSONAL_LOAN } from 'src/models/personal_loan.model';
import { APP_STATUSValue, FinalPageEnum } from 'src/models/common.model';
import { LoanTypeEnum } from 'src/models/loan-type.enum';
import { ApiService } from './api.service';
import { ApplicantLoanComponent } from 'src/app/applicant-loan/applicant-loan.component';
import { FinalPageComponent } from "src/components/final-page/final-page.component";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { AnswerQuestionsComponent } from "src/components/review-page/answer-questions/answer-questions.component";
import { AppComponent } from "src/app/app.component";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./common.service";
import { environment } from "src/environments/environment";
import { DocuSignComponent } from "src/components/review-page/docu-sign/docu-sign.component";
import { Observable } from "rxjs";
import { FlowLoadingPopupComponent } from "src/components/flow-loading-popup/flow-loading-popup.component";
import { AtomicService } from "./atomic.service";
import { JSEncrypt } from 'jsencrypt';
import {  publicKey } from './config';


@Injectable(
  {
    providedIn: "root"
  }
)
export class NmaoWfService {

	$encrypt: any;
  flowLoadingDialog;
  didAddApprovedAccounts = false;

  constructor(private apiService: ApiService, private dialog: MatDialog, 
    private atomicService: AtomicService,
    private httpClient: HttpClient, private commonService: CommonService) {
      
      this.$encrypt = new JSEncrypt();
  }

   getTestXaByJson(jsonFile: string):Observable<any>{
      return this.httpClient.get('assets/xa_tests_json/'+ jsonFile)
  }

  async getCurrentStatus(loanId){
    var getCurrentStatusResult = await this.apiService.getCurrentStatus(loanId).toPromise();
    console.log('getCurrentStatusResult: ');
    console.log( getCurrentStatusResult);
    return getCurrentStatusResult;
  }

  async openPreciseId(XAId, decisionAppId, xpressLoan, selectedProducts, soResponses, applicationType) {
    var modal = await this.dialog.open(AnswerQuestionsComponent, {
      disableClose: true,
      scrollStrategy:  AppComponent.scrollStrategy,
      panelClass: AppComponent.isDesktop ? 'col-md-9' : 'full-screen',
      data: {
        appId: XAId,
        decisionAppId: decisionAppId,
        hasJointApp: (!!xpressLoan.APPLICANTS[0].SPOUSE),
        applicants: xpressLoan.APPLICANTS,
        selectedProducts: selectedProducts,
        soResponses: soResponses,
        applicationType: applicationType
      }
    });
    var answerQuestionDecision = await modal.afterClosed().toPromise();

    soResponses.answerQuestionDecision = answerQuestionDecision;
    return answerQuestionDecision;

  }

  async preciseId_check(preciseIdResults=[], xaId, selectedFundingType){
    if(AppComponent.nmaoConfigs.preciseIdConfig.checkD && (!preciseIdResults || preciseIdResults.length ==0)){
      return false;
    }
    for (let index = 0; index < preciseIdResults.length; index++) {
      const preciseIdResult = preciseIdResults[index];
      if(AppComponent.nmaoConfigs.preciseIdConfig.checkD ){ //only check if config null or config true
        if(!preciseIdResult.OUTPUT.RESPONSE || preciseIdResult.OUTPUT.RESPONSE["@decision"]?.toLowerCase() != 'accept'){
          return false;
        }
      }
    }
    console.log('preciseId passed'); 

   return await this.checkFraudOrDup( xaId, selectedFundingType)

   
  }

  async eFundsCall(appId, applicationIndex = '0', isJoint = 'false') {
    //qualifie
    this.showLoading("eFund Report..")
    var eFundsReportResult = await this.apiService.pulleFundsReport(appId, applicationIndex, isJoint).toPromise();
    console.log('eFundsReportResult');
    console.log(eFundsReportResult);
    return eFundsReportResult;
  }

  async eFundsCall_check(eFundsReportResult,   xaId, selectedFundingType){
    if(
      eFundsReportResult?.OUTPUT?.RESPONSE
      &&
       (AppComponent.nmaoConfigs.eFundConfig.checkQD ? eFundsReportResult?.OUTPUT?.RESPONSE["@qualifile_decision"]?.toLowerCase() == "accept" : true )  //only check if config null or config true
      && (AppComponent.nmaoConfigs.eFundConfig.checkPIM ? eFundsReportResult?.OUTPUT?.RESPONSE["@passed_identity_manager"]?.toLowerCase()  == "y" : true) //only check if config null or config true
      && (AppComponent.nmaoConfigs.eFundConfig.checkPO ? eFundsReportResult?.OUTPUT?.RESPONSE["@passed_ofac"]?.toLowerCase()  == "y"  : true)           //only check if config null or config true
    )
    {
      //do nothing
      console.log('eFundsReport passed'); 

      return  await this.checkFraudOrDup( xaId, selectedFundingType);

   
    }else{ 
      console.log('eFundsReport NOT passed'); 
      return false;
    }
  }

  async creditPullCall(appId) {
    // credit report
    this.showLoading("credit Report..")
    var creditReportResult = await this.apiService.pullCreditReport(appId).toPromise();
    return creditReportResult;
  }

  async creditPullCall_check(creditReportResult,xaId, selectedFundingType) {
    console.log('creditReportResult');
    console.log(creditReportResult);

    if (
      creditReportResult?.OUTPUT?.RESPONSE
      && (AppComponent.nmaoConfigs.creditReportConfig.checkIS ? creditReportResult.OUTPUT.RESPONSE["@is_successful"]?.toLowerCase() == "y" : true)
    ) {
      //do nothing
      console.log('creditReport passed');
      return this.checkFraudOrDup( xaId, selectedFundingType);
      
    } else {
      console.log('creditReport NOT passed');
      return false;
    }
  }

  symitarBookResult_check(symitarBookResult){
    var memberNumber = symitarBookResult?.OUTPUT?.XML?.APPLICATION?.AccountInfo?.AccountNumber;
    return memberNumber;
  }

   addApprovedAccount_setAppStatus(loanId,appStatus: XPRESS_LOAN_INFOStatus,  selectedFundingType: XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type){
    //new api here
    console.log('addApprovedAccount_setAppStatus:' + selectedFundingType)
    this.showLoading("Approved accounts..")
    this.didAddApprovedAccounts = true;
    return this
    .httpClient
    .post(this.commonService.baseUrl + "/api/NmaoWf/update-loan-AddApprovedAccounts-SetStatus/"+loanId, {status: appStatus, fundingType:selectedFundingType });
  }

  setAppStatusFragment(loanId,appStatus: APP_STATUSValue ){
    return this
    .httpClient
    .post(this.commonService.baseUrl + "/api/NmaoWf/set-status-fragment/"+loanId, {status: appStatus});
  }


  // async setAppToInstantApprove(loanId){
  //   return this
  //   .httpClient
  //   .post(environment.soApiUrl + "xpressLoan/set-app-to-instant-approve", {loanId: loanId});
  // }

  async fundingCall(data){
    this.showLoading("fundingCall..")
  try{
    var fundingCallResult = await this.apiService.fundDeposit(data).toPromise();
     console.log('fundingCall');  console.log(fundingCallResult);
     return fundingCallResult;
  }catch{
    return null;
  }
    
  }

  async fundingCallResult_check(loanId,type: XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type ){
    this.showLoading("fundingCallCheck..")
   var fundingCallResultCheck = await this
    .httpClient
    .get(this.commonService.baseUrl + `/api/NmaoWf/check-funding-call-result/${loanId}/${type}`).toPromise() as any;
    console.log('fundingCallResult_check');  console.log(fundingCallResultCheck);
    return fundingCallResultCheck.result;
  }

  async symitarBook(appId,soResponses){
        //call symitar-book
        this.showLoading("symitarBook..")
        var symitarBookResult = await this.apiService.symitarBook(appId).toPromise();
        console.log('symitarBookResult');  console.log(symitarBookResult);
        soResponses.xpress.symitarBookOutputJson = symitarBookResult;
      return symitarBookResult;
  }

  async presentAtomic(appId){
   
    if(AppComponent.direct_deposit_subtype &&  AppComponent.direct_deposit_answer == 'Yes'){
      var accountNumberRes = await this.atomicService.getAccountNumberApprovedAccount(appId, AppComponent.direct_deposit_subtype)
      .toPromise()
      .catch(error => { // (**)
        console.error(`The unknown error has occurred: ${error}`);
        return null;
      });

      if(accountNumberRes){
        var accountNumber = accountNumberRes._body;
        if(accountNumber){
        await  this.atomicService.showAtomicDeposit(appId, accountNumber, AppComponent.direct_deposit_subtype)
      }
    }
    }
  }

  

   async openDocuSign(appId, decisionAppId,selectedProducts, ) {

    var modal = await this.dialog.open(DocuSignComponent, {
      disableClose: true,
      scrollStrategy:  AppComponent.scrollStrategy,
      data: {
        selectedProducts: selectedProducts,
        appId: appId,
        decisionAppId: decisionAppId
      },
      panelClass: AppComponent.isDesktop ? 'col-md-12' : 'full-screen',
      autoFocus: false,
      hasBackdrop: true,
      
    });

    var docuSignResult = await modal.afterClosed().toPromise();

    // if(AppComponent.nmaoConfigs.preciseIdConfig.checkD && (!this.preciseIdResults || this.preciseIdResults.length ==0)){
    //   this.dialogRef.close();
    //   ApplicantLoanComponent.forceFinalPageError = true;
    //   //ApplicantLoanComponent.currentStepIdChanged.emit({ step: 3, soResponses: this.soResponses, finalPageEnum: FinalPageEnum.ErrorReceived });
    //   return;
    // }
    // for (let index = 0; index < this.preciseIdResults.length; index++) {
    //   const preciseIdResult = this.preciseIdResults[index];
    //   if(AppComponent.nmaoConfigs.preciseIdConfig.checkD ){ //only check if config null or config true
    //     if(!preciseIdResult.OUTPUT.RESPONSE || preciseIdResult.OUTPUT.RESPONSE["@decision"].toLowerCase() != 'accept'){
    //       this.dialogRef.close();
    //       ApplicantLoanComponent.forceFinalPageError = true;
    //       ApplicantLoanComponent.currentStepIdChanged.emit({lastPage: "Final page forced by preciseIdResult check failed", lastPageMetaData: JSON.stringify(preciseIdResult?.OUTPUT.RESPONSE) })
    //       return;
    //     }
    //   }
    // }
    // console.log('preciseId passed'); 

    // this.dialogRef.close();
  }

  displayFinalPage(finalPageEnum: FinalPageEnum, baseType = 1){
    this.hideLoading();
    FinalPageComponent.isLoading = false;
   
    if(baseType == 2 && finalPageEnum == FinalPageEnum.Congratulation){
      finalPageEnum = FinalPageEnum.Wait2Days
    }
    FinalPageComponent.finalPageEnum = finalPageEnum;
    ApplicantLoanComponent.currentStepIdChanged.emit({ step: 3,  finalPageEnum: finalPageEnum});
    
  }


  async checkFraudOrDup(XAId: string, selectedFundingType){
    var currentStatus = await this.getCurrentStatus(XAId);
    if(currentStatus.status=='FRAUD'|| currentStatus.status=='DUP'){
       await this.addApprovedAccount_setAppStatus(XAId, 
        currentStatus.status=='FRAUD'?XPRESS_LOAN_INFOStatus.FRAUD: XPRESS_LOAN_INFOStatus.DUP, selectedFundingType).toPromise()
      this.displayFinalPage(FinalPageEnum.Wait2Days);
      await this.setAppStatusFragment(XAId, currentStatus.status=='FRAUD'?APP_STATUSValue.FRAUD:APP_STATUSValue.DUP ).toPromise()
      return false;
    }
    return true;
  }


  showLoading(loadingMessage){
    if(!this.flowLoadingDialog){
      this.flowLoadingDialog = this.dialog.open(FlowLoadingPopupComponent,
        {
          disableClose: true,
          scrollStrategy:  AppComponent.scrollStrategy,
          data:{
            loadingMessage: loadingMessage
          }
        })
    }else{
      //this.flowLoadingDialog.componentInstance.data =
      this.flowLoadingDialog.componentInstance.updateMessage(loadingMessage)
    }
    

  }

  hideLoading(){
    if(this.flowLoadingDialog){
      this.flowLoadingDialog.close()
    }
  }

  encryptRsa(jsonString){
    // const encryptRsa = new EncryptRsa();
    // const encryptedText = encryptRsa.encryptStringWithRsaPublicKey({ 
    //   text: jsonString,   
    //   publicKey:``,
    // });
    if (jsonString.length > 117){
      throw new Error("length should be smaller than 117 chars");
    }
    this.$encrypt.setPublicKey(publicKey);
    var cypherText = this.$encrypt.encrypt(jsonString);
    return cypherText
  }

}