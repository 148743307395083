import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-flow-loading-popup',
  templateUrl: './flow-loading-popup.component.html',
  styleUrls: ['./flow-loading-popup.component.css']
})
export class FlowLoadingPopupComponent implements OnInit {
  loadingMessage = '';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.loadingMessage = data.loadingMessage
  }

  ngOnInit(): void {
  }

  updateMessage(msg){
    this.loadingMessage = msg
  }

}
