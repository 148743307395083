import { BASE_ADDRESS_LOOSE } from './base-address-loose.model';
import { FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTIONFOM_ANSWER, FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTION, BASE_EMPLOYMENTEmployment_status, BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION, base_YN, BASE_CONTACT_INFOPreferred_contact_method, base_applicant_type } from './common.model';

/*[System.Xml.Serialization.XmlIncludeAttribute(typeof(XPRESS_LOAN))]
[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class APP {
    public version: number;
}

/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_LOAN extends APP {
    public APPLICANTS: XPRESS_APPLICANT[];
    public COMMENTS: XPRESS_LOANCOMMENTS;
    public STRUCTURED_COMMENTS: BASE_STRUCTURED_COMMENTS;
    public LOAN_INFO: XPRESS_LOAN_INFO;
    public CUSTOM_QUESTIONS: BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION[];
    public SYSTEM: XPRESS_SYSTEM[];
    public APPROVED_ACCOUNTS: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPE[];
    public INTERESTED_ACCOUNTS: XPRESS_LOANINTERESTED_ACCOUNTS;
    public FUTURE_ACCOUNTS: XPRESS_ACCOUNT_TYPESACCOUNT_TYPE[];
    public TASK_LIST: BASE_TASK_LISTTASK[];
    public FUNDING_SOURCES: XPRESS_FUNDING_SOURCESFUNDING_SOURCE[];
    public MEMBERSHIP_FEE: XPRESS_MEMBERSHIP_FEE;
    public BUSINESS_INFO: XPRESS_LOANBUSINESS_INFO;
    public DEDUCTIONS: XPRESS_LOANDEDUCTIONS;
    public BENEFICIARIES: XPRESS_BENEFICIARY[];
    public ACCOUNT_FUNDING: XPRESS_ACCOUNT_FUNDING;
    public SPECIAL_INFO: SPECIAL_INFO;
    public FOM_ANSWERED_QUESTIONS: FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTION[];
    public ADDITIONAL_SERVICES: INTERNAL_TRANSFER_TYPE[];
    public INTEGRATION_DATA_LIST: INTEGRATION_DATA[];
    public MODIFICATION_HISTORY: BASE_MODIFICATION_HISTORY;
    public EVENT_HISTORY: BASE_EVENT_HISTORY;
    public WEBMS_HISTORY: BASE_WEBMS_HISTORY;
    public BENEFICIAL_OWNERS: BENEFICIAL_OWNERS;
}

/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]
[System.Xml.Serialization.XmlRootAttribute("XPRESS_LOAN", Namespace = "http://www.meridianlink.com/CLF", IsNullable = false)]*/
export class XPRESS_LOAN14 extends XPRESS_LOAN {

}

/*[System.Xml.Serialization.XmlIncludeAttribute(typeof(XPRESS_APPLICANT))]
[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_BASE_APPLICANT {
    public CURRENT_ADDRESS: XPRESS_BASE_APPLICANTCURRENT_ADDRESS;
    public PREVIOUS_ADDRESS: XPRESS_BASE_APPLICANTPREVIOUS_ADDRESS;
    public MAILING_ADDRESS: XPRESS_BASE_APPLICANTMAILING_ADDRESS;
    public FINANCIAL_INFO: XPRESS_BASE_APPLICANTFINANCIAL_INFO;
    public ID_CARD: BASE_ID_CARD;
    public ID_CARD2: BASE_ID_CARD;
    public APPROVAL_DENIAL_REASON: string;
    public APPLICANT_QUESTIONS: BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTION[];
    public REFERENCE: BASE_REFERENCE;
    public ATM_CARD_LIST: ATM_CARD_TYPE[];
    public DEBIT_BUREAU_INFO: XPRESS_BASE_APPLICANTDEBIT_BUREAU_INFO;
    public FOM_ANSWERED_QUESTIONS: FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTION[];
    public is_debit_bureau_required: XPRESS_BASE_APPLICANTIs_debit_bureau_required;
    public is_debit_bureau_requiredSpecified: boolean;
    public borrower_id: string;
    public is_declined: string;
    public mother_maiden_name: string;
    public first_name: string;
    public last_name: string;
    public middle_name: string;
    public m_initial: string;
    public cell_phone: string;
    public cell_phone_country: base_phone_country_alpha2;
    public home_phone: string;
    public home_phone_country: base_phone_country_alpha2;
    public work_phone: string;
    public work_phone_extension: string;
    public work_phone_country: base_phone_country_alpha2;
    public bankruptcy_score: string;
    public marital_status: string;
    public citizenship: string;
    public preferred_contact_method: BASE_CONTACT_INFOPreferred_contact_method;
    public preferred_contact_methodSpecified: boolean;
    public suffix: XPRESS_BASE_APPLICANTSuffix;
    public suffixSpecified: boolean;
    public dob: Date;
    public dobSpecified: boolean;
    public email: string;
    public ssn: string;
    public gender: XPRESS_BASE_APPLICANTGender;
    public genderSpecified: boolean;
    public credit_score: string;
    public custom_score: string;
    public member_number: string;
    public xa_requirement_id: string;
    public FOM_answers: string;
    public id_authentication_result: XPRESS_BASE_APPLICANTId_authentication_result;
    public id_authentication_resultSpecified: boolean;
    public id_ver_result: XPRESS_BASE_APPLICANTId_ver_result;
    public id_ver_resultSpecified: boolean;
    public pre_score_results: XPRESS_BASE_APPLICANTPre_score_results;
    public pre_score_resultsSpecified: boolean;
    public pre_score_failure_reasons: string;
    public pre_score_failure_code: string;
    public is_ofac_debit: base_YN;
    public is_ofac_debitSpecified: boolean;
    public is_ofac_bridger: base_YN;
    public is_ofac_bridgerSpecified: boolean;
    public is_ofac_ist_watch: base_YN;
    public is_ofac_ist_watchSpecified: boolean;
    public is_high_risk_consumer: base_YN;
    public is_high_risk_consumerSpecified: boolean;
    public is_ssn_tax_id: base_YN;
    public security_code: string;
    public allow_direct_mail: base_YN;
    public allow_direct_mailSpecified: boolean;
    public allow_email_solicit: base_YN;
    public allow_email_solicitSpecified: boolean;
    public allow_telemarket: base_YN;
    public allow_telemarketSpecified: boolean;
    public allow_share_info: base_YN;
    public allow_share_infoSpecified: boolean;
    public is_ofac_id_ver: base_YN;
    public is_ofac_id_verSpecified: boolean;
    public is_fraud_prescore: base_YN;
    public is_fraud_prescoreSpecified: boolean;
    public relationship: string;
    public role_type: string;
    public relation_to_primary: string;
    public is_fraud_fas: base_YN;
    public is_fraud_fasSpecified: boolean;
    public mla_covered_status: string;
    public mla_covered_date: Date;
    public mla_covered_dateSpecified: boolean;
    public AnyAttr: any;
    constructor() {
        this.cell_phone_country = base_phone_country_alpha2.US;
        this.home_phone_country = base_phone_country_alpha2.US;
        this.work_phone_country = base_phone_country_alpha2.US;
        this.is_ssn_tax_id = base_YN.N;
    }
}

/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_APPLICANT extends XPRESS_BASE_APPLICANT {
    public SPOUSE: XPRESS_BASE_APPLICANT;
    public CREDIT_REPORT: XPRESS_APPLICANTCREDIT_REPORT;
    public ACCOUNT_TRANSFERS: XPRESS_ACCOUNT_TRANSFER[];
    public is_equity_owner: XPRESS_APPLICANTIs_equity_owner;
    public is_equity_ownerSpecified: boolean;
    public is_passed_id_verification: XPRESS_APPLICANTIs_passed_id_verification;
    public is_passed_id_verificationSpecified: boolean;
    public co_is_passed_id_verification: XPRESS_APPLICANTCo_is_passed_id_verification;
    public co_is_passed_id_verificationSpecified: boolean;
    public joint_account_option: XPRESS_APPLICANTJoint_account_option;
    public joint_account_optionSpecified: boolean;
    public is_credit_union_advocate: XPRESS_APPLICANTIs_credit_union_advocate;
    public is_credit_union_advocateSpecified: boolean;
    public number_in_household: number;
    public number_in_householdSpecified: boolean;
    public percent_business_owned: number;
    public percent_business_ownedSpecified: boolean;
    public is_fraud_credit: base_YN;
    public is_fraud_creditSpecified: boolean;
    public is_ofac_credit: base_YN;
    public is_ofac_creditSpecified: boolean;
    public combined_unsecured_aggregate_amount_with_lender: number;
    public combined_unsecured_aggregate_amount_with_lenderSpecified: boolean;
    public combined_secured_aggregate_amount_with_lender_no_mortgage: number;
    public combined_secured_aggregate_amount_with_lender_no_mortgageSpecified: boolean;
}

/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_CURRENT_ADDRESS_CHOICE_XPRESS {
    public Item: Object;
    public LOOSE_ADDRESS: BASE_ADDRESS_LOOSE;
    public verification_date: Date;
    public verification_dateSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_BASE_APPLICANTCURRENT_ADDRESS extends BASE_CURRENT_ADDRESS_CHOICE_XPRESS {
    public occupancy_status: XPRESS_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status;
    public occupancy_statusSpecified: boolean;
    public occupancy_duration: string;
    public other_occupancy_description: string;
}
export enum XPRESS_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status {
    LIVEWITHPARENTS,

    OWN,

    RENT,

    BUYING,

    OTHER,

    GOVQUARTERS,

    BARRACKS,

    LEASE,

    SUBLEASE,

    PERSONALRESIDENCE,


    Item
}

/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/

/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class INTERNAL_TRANSFER_TYPE {
    public transfer_from_id: string;
    public transfer_from_id_type: account_relation_type;
    public transfer_to_id: string;
    public transfer_to_id_type: account_relation_type;
    public transfer_to_account_type: string;
    public frequency: string;
    public scheduled_day: string;
    public amount: number;
    public amountSpecified: boolean;
    public start_date: Date;
    public start_dateSpecified: boolean;
    public end_date: Date;
    public end_dateSpecified: boolean;
}
export enum account_relation_type {
    I,

    E
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class SPECIAL_INFO {
    public FOM_ANSWERED_QUESTIONS: FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTION[];
    public DEBIT_BUREAU_INFO: DEBIT_BUREAU_INFO;
    public name: string;
    public additional_name_1: string;
    public additional_name_2: string;
    public tax_id: string;
    public is_tax_id_ssn: SPECIAL_INFOIs_tax_id_ssn;
    public is_tax_id_ssnSpecified: boolean;
    public security_code: string;
    public address1: string;
    public address2: string;
    public city: string;
    public state: string;
    public zip: string;
    public country: string;
    public address_verification_date: Date;
    public address_verification_dateSpecified: boolean;
    public is_mailing_current: base_YN;
    public is_mailing_currentSpecified: boolean;
    public mailing_address1: string;
    public mailing_address2: string;
    public mailing_city: string;
    public mailing_state: string;
    public mailing_zip: string;
    public mailing_country: string;
    public mailing_address_verification_date: Date;
    public mailing_address_verification_dateSpecified: boolean;
    public establish_date: Date;
    public establish_dateSpecified: boolean;
    public member_number: string;
    public phone: string;
    public cell_phone: string;
    public fax: string;
    public email: string;
    public special_account_type_id: string;
    public special_account_type_code: string;
    public is_ofac_ist_watch: base_YN;
    public is_ofac_ist_watchSpecified: boolean;
    public allow_direct_mail: base_YN;
    public allow_direct_mailSpecified: boolean;
    public allow_email_solicit: base_YN;
    public allow_email_solicitSpecified: boolean;
    public allow_telemarket: base_YN;
    public allow_telemarketSpecified: boolean;
    public allow_share_info: base_YN;
    public allow_share_infoSpecified: boolean;
    public is_ofac_debit: base_YN;
    public is_ofac_debitSpecified: boolean;
    public is_ofac_bridger: base_YN;
    public is_ofac_bridgerSpecified: boolean;
    public id_ver_result: SPECIAL_INFOId_ver_result;
    public id_ver_resultSpecified: boolean;
}


/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class DEBIT_BUREAU_INFO {
    public EFUNDS_INFO: DEBIT_BUREAU_INFOEFUNDS_INFO;
    public FIS_BIZCHEX_INFO: DEBIT_BUREAU_INFOFIS_BIZCHEX_INFO;
    public DELUXE_INFO: DEBIT_BUREAU_INFODELUXE_INFO;
    public TELECHECK_INFO: DEBIT_BUREAU_INFOTELECHECK_INFO;
    public RETAIL_BANKING_INFO: DEBIT_BUREAU_INFORETAIL_BANKING_INFO;
    public PRECISEID_EWS_INFO: DEBIT_BUREAU_INFOPRECISEID_EWS_INFO;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class DEBIT_BUREAU_INFOEFUNDS_INFO {
    public qualifile_reason_code1: string;
    public qualifile_reason_code2: string;
    public qualifile_reason_code3: string;
    public qualifile_reason_code4: string;
    public qualifile_reason_code5: string;
    public qualifile_reason_text1: string;
    public qualifile_reason_text2: string;
    public qualifile_reason_text3: string;
    public qualifile_reason_text4: string;
    public qualifile_reason_text5: string;
    public ssn_begin_issue_date: string;
    public ssn_state_issued: string;
    public qualifile_score: string;
    public qualifile_decision: DEBIT_BUREAU_INFOEFUNDS_INFOQualifile_decision;
    public qualifile_decisionSpecified: boolean;
    public qualifile_transaction_date: Date;
    public qualifile_transaction_dateSpecified: boolean;
    public id_verification_result: DEBIT_BUREAU_INFOEFUNDS_INFOId_verification_result;
    public id_verification_resultSpecified: boolean;
    public id_verification_transaction_date: Date;
    public id_verification_transaction_dateSpecified: boolean;
    public id_verification_transaction_id: string;
    public passed_ofac: DEBIT_BUREAU_INFOEFUNDS_INFOPassed_ofac;
    public passed_ofacSpecified: boolean;
    public ofac_transaction_date: Date;
    public ofac_transaction_dateSpecified: boolean;
    public ofac_transaction_id: string;
    public chex_id: string;
    public qualifile_override_reason: string;
    public qualifile_override_loan_officer_id: string;
    public ssn_validation: string;
    public is_qualifile_manual_entry: base_YN;
    public is_qualifile_manual_entrySpecified: boolean;
    public qualifile_manual_entry_loan_officer_id: string;
    public id_verification_user_id: string;
    public id_verification_user_type: DEBIT_BUREAU_INFOEFUNDS_INFOId_verification_user_type;
    public id_verification_user_typeSpecified: boolean;
    public ofac_user_id: string;
    public ofac_user_type: DEBIT_BUREAU_INFOEFUNDS_INFOOfac_user_type;
    public ofac_user_typeSpecified: boolean;
    public qualifile_user_id: string;
    public qualifile_user_type: DEBIT_BUREAU_INFOEFUNDS_INFOQualifile_user_type;
    public qualifile_user_typeSpecified: boolean;
    public id_verification_override_reason: string;
    public ofac_override_reason: string;
}
export enum DEBIT_BUREAU_INFOEFUNDS_INFOQualifile_decision {
    ACCEPT,

    APPROVE,

    DECLINE,

    DENY,

    REVIEW,


    Item,

    OVERRIDDEN,

    PLEASECALL,

    CONSUMER,

    INVALIDSSN
}
export enum DEBIT_BUREAU_INFOEFUNDS_INFOId_verification_result {
    PASSED,

    FAILED,

    OVERRIDDEN,

    NORECORDFOUND,

    NODECISION,

    INVALIDSSN,

    MINOR,


    Item
}
export enum DEBIT_BUREAU_INFOEFUNDS_INFOPassed_ofac {
    Y,

    N,

    O,


    Item
}

export enum DEBIT_BUREAU_INFOEFUNDS_INFOId_verification_user_type {
    C,

    L,

    U,


    Item
}
export enum DEBIT_BUREAU_INFOEFUNDS_INFOOfac_user_type {
    C,

    L,

    U,


    Item
}
export enum DEBIT_BUREAU_INFOEFUNDS_INFOQualifile_user_type {
    C,

    L,

    U,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class DEBIT_BUREAU_INFOFIS_BIZCHEX_INFO {
    public fis_bizchex_risk_score: string;
    public fis_bizchex_decision: DEBIT_BUREAU_INFOFIS_BIZCHEX_INFOFis_bizchex_decision;
    public fis_bizchex_decisionSpecified: boolean;
    public fis_bizchex_transaction_date: Date;
    public fis_bizchex_transaction_dateSpecified: boolean;
    public passed_ofac: DEBIT_BUREAU_INFOFIS_BIZCHEX_INFOPassed_ofac;
    public passed_ofacSpecified: boolean;
    public ofac_transaction_date: Date;
    public ofac_transaction_dateSpecified: boolean;
    public ofac_transaction_id: string;
    public fis_bizchex_id: string;
    public fis_bizchex_override_reason: string;
    public fis_bizchex_override_loan_officer_id: string;
    public is_fis_bizchex_manual_entry: base_YN;
    public is_fis_bizchex_manual_entrySpecified: boolean;
    public fis_bizchex_manual_entry_loan_officer_id: string;
    public ofac_user_id: string;
    public ofac_user_type: DEBIT_BUREAU_INFOFIS_BIZCHEX_INFOOfac_user_type;
    public ofac_user_typeSpecified: boolean;
    public fis_bizchex_user_id: string;
    public fis_bizchex_user_type: DEBIT_BUREAU_INFOFIS_BIZCHEX_INFOFis_bizchex_user_type;
    public fis_bizchex_user_typeSpecified: boolean;
    public ofac_override_reason: string;
    public ofac_override_loan_officer_id: string;
}
export enum DEBIT_BUREAU_INFOFIS_BIZCHEX_INFOFis_bizchex_decision {
    ACCEPT,

    DECLINE,

    REVIEW,


    Item
}
export enum DEBIT_BUREAU_INFOFIS_BIZCHEX_INFOPassed_ofac {
    Y,

    N,

    O,


    Item
}
export enum DEBIT_BUREAU_INFOFIS_BIZCHEX_INFOOfac_user_type {
    C,

    L,

    U,


    Item
}
export enum DEBIT_BUREAU_INFOFIS_BIZCHEX_INFOFis_bizchex_user_type {
    C,

    L,

    U,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class DEBIT_BUREAU_INFODELUXE_INFO {
    public deluxe_decision: DEBIT_BUREAU_INFODELUXE_INFODeluxe_decision;
    public deluxe_decisionSpecified: boolean;
    public deluxe_transaction_date: Date;
    public deluxe_transaction_dateSpecified: boolean;
    public has_ofac_match: DEBIT_BUREAU_INFODELUXE_INFOHas_ofac_match;
    public has_ofac_matchSpecified: boolean;
}
export enum DEBIT_BUREAU_INFODELUXE_INFODeluxe_decision {
    CAUTION,

    DECLINE,

    PROCEED,

    REVERIFY,

    VICTIMMESSAGE,


    Item
}
export enum DEBIT_BUREAU_INFODELUXE_INFOHas_ofac_match {
    Y,

    N,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class DEBIT_BUREAU_INFOTELECHECK_INFO {
    public telecheck_response_code: DEBIT_BUREAU_INFOTELECHECK_INFOTelecheck_response_code;
    public telecheck_response_codeSpecified: boolean;
    public telecheck_transaction_date: Date;
    public telecheck_transaction_dateSpecified: boolean;
    public telecheck_approval_code: string;
    public telecheck_score: string;
}
export enum DEBIT_BUREAU_INFOTELECHECK_INFOTelecheck_response_code {
    Item07,

    Item95,

    Item03,

    Item09,

    Item12,

    Item13,

    Item41,

    Item69,

    Item72,

    Item89,

    Item08,

    Item73,

    Item88,

    Item98,

    Item27,

    Item15,

    Item16,

    Item44,

    Item49,

    Item97,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class DEBIT_BUREAU_INFORETAIL_BANKING_INFO {
    public retail_banking_decision: DEBIT_BUREAU_INFORETAIL_BANKING_INFORetail_banking_decision;
    public retail_banking_decisionSpecified: boolean;
    public retail_banking_transaction_date: Date;
    public retail_banking_transaction_dateSpecified: boolean;
    public retail_banking_has_ofac_match: DEBIT_BUREAU_INFORETAIL_BANKING_INFORetail_banking_has_ofac_match;
    public retail_banking_has_ofac_matchSpecified: boolean;
}
export enum DEBIT_BUREAU_INFORETAIL_BANKING_INFORetail_banking_decision {
    APPROVED,

    DECLINED,

    PENDING,

    UNDECISIONED,


    Item
}
export enum DEBIT_BUREAU_INFORETAIL_BANKING_INFORetail_banking_has_ofac_match {
    Y,

    N,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class DEBIT_BUREAU_INFOPRECISEID_EWS_INFO {
    public preciseid_ews_decision: DEBIT_BUREAU_INFOPRECISEID_EWS_INFOPreciseid_ews_decision;
    public preciseid_ews_decisionSpecified: boolean;
    public preciseid_ews_transaction_date: Date;
    public preciseid_ews_transaction_dateSpecified: boolean;
    public overall_severity: string;
}
export enum DEBIT_BUREAU_INFOPRECISEID_EWS_INFOPreciseid_ews_decision {
    PROCEED,

    DECLINE,


    Item
}
export enum SPECIAL_INFOIs_tax_id_ssn {
    Y,

    N
}
export enum SPECIAL_INFOId_ver_result {
    INCOMPLETE,

    PASS,

    FAIL,

    OVERRIDDEN,

    REVIEW
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class DEDUCTION_TYPE {
    public added_date: string;
    public agent_id: string;
    public amount: number;
    public amountSpecified: boolean;
    public approved: base_YN;
    public approvedSpecified: boolean;
    public deduction_item_id: string;
    public deduction_mode: string;
    public deduction_type: string;
    public edited_date: string;
    public effective_date: string;
    public offering_id: string;
    public offering_name: string;
    public offering_type: string;
    public package_id: string;
    public package_name: string;
    public signed: base_YN;
    public signedSpecified: boolean;
    public ssn: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class COMPANY_INFO_TYPE {
    public CURRENT_ADDRESS: COMPANY_INFO_TYPECURRENT_ADDRESS;
    public MAILING_ADDRESS: COMPANY_INFO_TYPEMAILING_ADDRESS;
    public PREVIOUS_ADDRESS: COMPANY_INFO_TYPEPREVIOUS_ADDRESS;
    public FOM_ANSWERED_QUESTIONS: FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTION[];
    public DEBIT_BUREAU_INFO: DEBIT_BUREAU_INFO;
    public tax_id: string;
    public member_number: string;
    public allow_direct_mail: base_YN;
    public allow_direct_mailSpecified: boolean;
    public allow_email_solicit: base_YN;
    public allow_email_solicitSpecified: boolean;
    public allow_telemarket: base_YN;
    public allow_telemarketSpecified: boolean;
    public allow_share_info: base_YN;
    public allow_share_infoSpecified: boolean;
    public business_type: string;
    public business_type_other: string;
    public business_description: string;
    public business_account_type_code: string;
    public is_tax_id_ssn: base_YN;
    public security_code: string;
    public company_name: string;
    public additional_name_1: string;
    public additional_name_2: string;
    public phone: string;
    public cell_phone: string;
    public fax: string;
    public industry: string;
    public email: string;
    public establish_date: Date;
    public establish_dateSpecified: boolean;
    public state_registered: string;
    public annual_revenue: number;
    public annual_revenueSpecified: boolean;
    public num_employees: number;
    public num_employeesSpecified: boolean;
    public primary_bank: string;
    public is_ofac_istwatch: base_YN;
    public is_ofac_istwatchSpecified: boolean;
    public is_ofac_bridger: base_YN;
    public is_ofac_bridgerSpecified: boolean;
    public id_ver_result: COMPANY_INFO_TYPEId_ver_result;
    public id_ver_resultSpecified: boolean;
    public is_ofac_debit: base_YN;
    public is_ofac_debitSpecified: boolean;
    public is_high_risk_business: base_YN;
    public is_high_risk_businessSpecified: boolean;
    public AnyAttr: any;
    constructor() {
        this.is_tax_id_ssn = base_YN.N;
    }
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class COMPANY_INFO_TYPECURRENT_ADDRESS extends BASE_CURRENT_ADDRESS_CHOICE_XPRESS {
    public occupancy_status: XPRESS_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status;
    public occupancy_statusSpecified: boolean;
    public occupancy_duration: string;
    public other_occupancy_description: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class COMPANY_INFO_TYPEMAILING_ADDRESS extends BASE_ADDRESS_LOOSE {
    public is_current: base_YN;
    constructor() {
        super();
        this.is_current = base_YN.Y;
    }
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class COMPANY_INFO_TYPEPREVIOUS_ADDRESS extends BASE_ADDRESS_LOOSE {
    public occupancy_status: XPRESS_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status;
    public occupancy_statusSpecified: boolean;
    public occupancy_duration: string;
    public other_occupancy_description: string;
}
export enum COMPANY_INFO_TYPEId_ver_result {
    INCOMPLETE,

    PASS,

    FAIL,

    OVERRIDDEN,

    REVIEW
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class DIRECT_DEPOSIT_TYPE {
    public direct_deposit_type: DIRECT_DEPOSIT_TYPEDirect_deposit_type;
    public direct_deposit_typeSpecified: boolean;
    public state: string;
    public account_type: string;
    public account_number: string;
    public company_name: string;
    public id_number: string;
    public company_phone: string;
    public company_fax: string;
    public payroll_id: string;
    public payment_type: DIRECT_DEPOSIT_TYPEPayment_type;
    public payment_typeSpecified: boolean;
    public branch_of_service: DIRECT_DEPOSIT_TYPEBranch_of_service;
    public branch_of_serviceSpecified: boolean;
    public other: string;
    public allotment_of_payment: base_YN;
    public allotment_of_paymentSpecified: boolean;
    public amount: number;
    public amountSpecified: boolean;
    public name1: string;
    public name2: string;
    public agency_name: string;
    public agency_address: string;
    public agency_city: string;
    public agency_state: string;
    public agency_zip: string;
}
export enum DIRECT_DEPOSIT_TYPEDirect_deposit_type {
    EMP,

    GOV
}
export enum DIRECT_DEPOSIT_TYPEPayment_type {
    CIVIL_RETIRE,

    FED_SALARY,

    MIL_ACTIVE,

    MIL_RETIRE,

    MIL_SURVIVOR,

    OTHER,

    RAIL_RETIRE,

    SOC_SEC,

    SUP_SEC,

    VA
}
export enum DIRECT_DEPOSIT_TYPEBranch_of_service {
    COAST_GUARD,

    AIR_FORCE,

    ARMY,

    CIA,

    MARINES,

    NAVY,

    ORS
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class APPROVED_ACCOUNT_LINK_TYPE {
    public approved_account_type_link_id: string;
    public reference_type: account_relation_type;
    public reference_id: string;
    public is_linked: base_YN;
    public is_linkedSpecified: boolean;
    public date_linked: Date;
    public date_linkedSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TYPES {
    public ACCOUNT_TYPE: XPRESS_ACCOUNT_TYPESACCOUNT_TYPE[];
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TYPESACCOUNT_TYPE {
    public SERVICES: XPRESS_ACCOUNT_TYPESACCOUNT_TYPESERVICE[];
    public PRODUCT_CUSTOM_QUESTIONS_INTERESTED: BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION[];
    public account_type: account_type;
    public apy: number;
    public apySpecified: boolean;
    public rate: number;
    public rateSpecified: boolean;
    public amount_deposit: number;
    public amount_depositSpecified: boolean;
    public account_name: string;
    public account_number: string;
    public suffix: string;
    public interest_rate_type: string;
    public cd_additions_permitted: string;
    public cd_renewability: string;
    public issue_date: Date;
    public issue_dateSpecified: boolean;
    public maturity_date: Date;
    public maturity_dateSpecified: boolean;
    public product_code: string;
    public rate_code: string;
    public term: number;
    public termSpecified: boolean;
    public bundle_code: string;
    public underwrite_result: XPRESS_ACCOUNT_TYPESACCOUNT_TYPEUnderwrite_result;
    public underwrite_resultSpecified: boolean;
    public term_type: XPRESS_ACCOUNT_TYPESACCOUNT_TYPETerm_type;
    public term_typeSpecified: boolean;
    public AnyAttr: any;
    public min_deposit: number;
    public min_depositSpecified: boolean;
    public max_deposit: number;
    public max_depositSpecified: boolean;
    public is_require_confirmation: base_YN;
    public is_require_confirmationSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TYPESACCOUNT_TYPESERVICE {
    public description: string;
    public service_type: string;
    public service_code: string;
}

export enum account_type {
    CD,

    CHECKING,

    SAVINGS,

    MONEY_MARKET,

    SAVINGS_BONDS,

    IRA,

    OTHER,

    LOANS,

    IRACD,

    HSA,


    Item
}
export enum XPRESS_ACCOUNT_TYPESACCOUNT_TYPEUnderwrite_result {
    S,

    P,

    Q,

    F,


    Item
}
export enum XPRESS_ACCOUNT_TYPESACCOUNT_TYPETerm_type {
    D,

    M
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_FUNDING {
    public bank_bankstate: string;
    public bank_bankname: string;
    public funding_type: XPRESS_ACCOUNT_FUNDINGFunding_type;
    public funding_typeSpecified: boolean;
    public bank_account_type: XPRESS_ACCOUNT_FUNDINGBank_account_type;
    public bank_account_typeSpecified: boolean;
    public transfer_account_type: XPRESS_ACCOUNT_FUNDINGTransfer_account_type;
    public transfer_account_typeSpecified: boolean;
    public name_on_card: string;
    public routing_number: string;
    public bank_account_number: string;
    public cc_card_type: string;
    public cc_card_number: string;
    public cc_card_exp_date: Date;
    public cc_card_exp_dateSpecified: boolean;
    public amount_deposit: number;
    public amount_depositSpecified: boolean;
    public bank_name_on_card: string;
    public transfer_account_number: string;
    public billing_street_address: string;
    public billing_city: string;
    public billing_state: string;
    public billing_zip: string;
    public source_of_funds: string;
    public future_funding_first_name: string;
    public future_funding_last_name: string;
    public future_funding_middle_name: string;
    public future_funding_suffix: string;
    public future_funding_ssn: string;
    public future_funding_ira_type: XPRESS_ACCOUNT_FUNDINGFuture_funding_ira_type;
    public future_funding_ira_typeSpecified: boolean;
    public future_funding_contribution_type: string;
    public future_funding_account_number: string;
}
export enum XPRESS_ACCOUNT_FUNDINGFunding_type {
    MAIL,

    CREDITCARD,

    BANK,

    INTERNALTRANSFER,

    GUARANTY_COUPON,

    CASH,

    FUTURE
}
export enum XPRESS_ACCOUNT_FUNDINGBank_account_type {
    CHECKING,

    SAVINGS
}
export enum XPRESS_ACCOUNT_FUNDINGTransfer_account_type {
    CHECKING,

    SAVINGS,

    MONEY_MARKET
}
export enum XPRESS_ACCOUNT_FUNDINGFuture_funding_ira_type {
    COVERDELL,

    ROTH,

    ROTHBEN,

    ROTHQUAL,

    SEP,

    TRADITIONAL,

    TRADITIONALBEN,

    TRADITIONALQUAL,

    SIMPLEIRA,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_BENEFICIARY {
    public DEBIT_BUREAU_INFO: DEBIT_BUREAU_INFO;
    public first_name: string;
    public last_name: string;
    public phone: string;
    public email: string;
    public address: string;
    public address2: string;
    public city: string;
    public state: string;
    public zip: string;
    public dob: Date;
    public dobSpecified: boolean;
    public relationship: string;
    public ssn: string;
    public country: string;
    public middle_name: string;
    public suffix: string;
    public initials: string;
    public work_phone: string;
    public work_phone_extension: string;
    public percent_share: number;
    public percent_shareSpecified: boolean;
    public is_trust: string;
    public beneficiary_priority: string;
    public is_ofac_ist_watch: base_YN;
    public is_ofac_ist_watchSpecified: boolean;
    public is_ofac_bridger: base_YN;
    public is_ofac_bridgerSpecified: boolean;
    public is_ofac_debit: base_YN;
    public is_ofac_debitSpecified: boolean;
    public address_verification_date: Date;
    public address_verification_dateSpecified: boolean;
    constructor() {
        this.country = "USA";
    }
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_MEMBERSHIP_FEE {
    public membership_fee_funding_date: Date;
    public membership_fee_funding_dateSpecified: boolean;
    public membership_fee_funding_amount: string;
    public membership_fee_funding_status: string;
    public membership_fee_funding_source_id: string;
    public membership_fee_funding_base_fee: string;
    public membership_fee_funding_fom_fee: string;
    public membership_fee_funding_manual_fee: string;
    public is_manual_membership_fee: base_YN;
    public is_manual_membership_feeSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_LOAN_INFO {
    public submit_date: Date;
    public submit_dateSpecified: boolean;
    public is_new_customer: base_YN;
    public is_new_customerSpecified: boolean;
    public incomplete_path: string;
    public qualifying_requirement: string;
    public instant_membership_requirement_id: string;
    public status: XPRESS_LOAN_INFOStatus;
    public create_date: Date;
    public create_dateSpecified: boolean;
    public requirement_description_value: string;
    public account_position: number;
    public account_positionSpecified: boolean;
    public entity_type: XPRESS_LOAN_INFOEntity_type;
    public entity_typeSpecified: boolean;
    public special_account_type: XPRESS_LOAN_INFOSpecial_account_type;
    public special_account_typeSpecified: boolean;
    public last_modified: Date;
    public last_modifiedSpecified: boolean;
    public is_claimed: base_YN;
    public is_claimedSpecified: boolean;
    public is_locked: base_YN;
    public is_lockedSpecified: boolean;
    public first_funding_date: Date;
    public first_funding_dateSpecified: boolean;
    public is_minor: base_YN;
    public is_minorSpecified: boolean;
    public is_lender_disclosure_checked: base_YN;
    public is_lender_disclosure_checkedSpecified: boolean;
    public is_possible_dupe: base_YN;
    public is_possible_dupeSpecified: boolean;
    public is_possible_dupe_manual: base_YN;
    public is_possible_dupe_manualSpecified: boolean;
    public approval_date: Date;
    public approval_dateSpecified: boolean;
    public declined_date: Date;
    public declined_dateSpecified: boolean;
    public campaign_code: string;
    public booking_date: Date;
    public booking_dateSpecified: boolean;
    public booking_reference_id: string;
    public is_high_risk_consumer: base_YN;
    public is_high_risk_consumerSpecified: boolean;
    public is_high_risk_consumer_manual: base_YN;
    public is_high_risk_consumer_manualSpecified: boolean;
    public is_high_risk_business: base_YN;
    public is_high_risk_businessSpecified: boolean;
    public is_high_risk_business_manual: base_YN;
    public is_high_risk_business_manualSpecified: boolean;
    public AnyAttr: any;
}
export enum XPRESS_LOAN_INFOStatus {
    NEW,

    APPROVED,

    DECLINED,

    REFERRED,

    CANCELED,

    CODEC,

    INCOMPLETE,

    PENDING,

    INSTANTAPPROVED,

    FRAUD,

    AP,

    NMI,

    DUP,

    AMD
}
export enum XPRESS_LOAN_INFOEntity_type {
    B,

    P,

    S
}
export enum XPRESS_LOAN_INFOSpecial_account_type {

    Item,

    ESTATE,

    MEMORIAL,

    SPECIAL_FUND,

    TRUST
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BENEFICIAL_OWNERS {
    public BENEFICIAL_OWNER: BENEFICIAL_OWNERSBENEFICIAL_OWNER[];
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BENEFICIAL_OWNERSBENEFICIAL_OWNER {
    public ID_CARD: BASE_ID_CARD;
    public first_name: string;
    public middle_name: string;
    public last_name: string;
    public suffix: string;
    public dob: Date;
    public dobSpecified: boolean;
    public is_tin_ssn: base_YN;
    public is_tin_ssnSpecified: boolean;
    public ssn: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public is_beneficial_owner: base_YN;
    public is_beneficial_ownerSpecified: boolean;
    public percent_business_owned: number;
    public percent_business_ownedSpecified: boolean;
    public is_control: base_YN;
    public is_controlSpecified: boolean;
    public is_authorized_credit_report: base_YN;
    public is_authorized_credit_reportSpecified: boolean;
    public citizenship: BENEFICIAL_OWNERSBENEFICIAL_OWNERCitizenship;
    public citizenshipSpecified: boolean;
    public control_title: BENEFICIAL_OWNERSBENEFICIAL_OWNERControl_title;
    public control_titleSpecified: boolean;
    public is_ofac_istwatch: base_YN;
    public is_ofac_istwatchSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_ID_CARD {
    public card_type: string;
    public card_number: string;
    public state: string;
    public country: string;
    public exp_date: Date;
    public exp_dateSpecified: boolean;
    public date_issued: Date;
    public date_issuedSpecified: boolean;
}
export enum BENEFICIAL_OWNERSBENEFICIAL_OWNERCitizenship {
    USCITIZEN,

    PERMRESIDENT,

    NONPERMRESIDENT
}
export enum BENEFICIAL_OWNERSBENEFICIAL_OWNERControl_title {
    AGENT,

    CORP_REP,

    MANAGER,

    OWNER,

    PARTNER,

    PRESIDENT,

    SECRETARY,

    TREASURER,

    VICE_PRESIDENT,

    MEMBER,

    DIRECTOR,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_WEBMS_HISTORY {
    public MESSAGE: BASE_WEBMS_HISTORYMESSAGE[];
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_WEBMS_HISTORYMESSAGE {
    public time: Date;
    public timeSpecified: boolean;
    public name: string;
    public type: string;
    public text: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_EVENT_HISTORY {
    public EVENT: BASE_EVENT_HISTORYEVENT[];
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_EVENT_HISTORYEVENT {
    public timestamp: Date;
    public timestampSpecified: boolean;
    public username: string;
    public $event: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_MODIFICATION_HISTORY {
    public AUDIT: BASE_MODIFICATION_HISTORYAUDIT[];
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_MODIFICATION_HISTORYAUDIT {
    public modification_date: Date;
    public modification_dateSpecified: boolean;
    public modifier_name: string;
    public modifier_type: string;
    public Text: string[];
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_LOAN_CONTACTS {
    public CONTACT_INFO: BASE_LOAN_CONTACTSCONTACT_INFO[];
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_LOAN_CONTACTSCONTACT_INFO {
    public contact_type: BASE_LOAN_CONTACTSCONTACT_INFOContact_type;
    public nss_borrower_index: string;
    public first_name: string;
    public middle_name: string;
    public last_name: string;
    public suffix: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public phone: string;
    public work_phone: string;
    public fax: string;
    public cell: string;
    public pager: string;
    public email: string;
    public company_name: string;
    public business_entity_type: base_business_entity_type;
    public business_entity_typeSpecified: boolean;
    public role_type: string;
    public department_name: string;
    public company_address: string;
    public company_city: string;
    public company_state: string;
    public company_zip: string;
    public company_phone: string;
    public company_fax: string;
    public county: string;
    public notes: string;
    public ssn: string;
    public dob: Date;
    public dobSpecified: boolean;
    public is_ssn_taxid: base_YN;
    public is_ssn_taxidSpecified: boolean;
    public paac: string;
}
export enum BASE_LOAN_CONTACTSCONTACT_INFOContact_type {
    ASSETOWN,

    INSAGENT,

    TITLEDPER,

    OTHER,

    SELLER,

    NSS,

    APPLICANT,

    BUSINESS,

    TRUST
}
export enum base_business_entity_type {
    JOINT_VEN,

    ASSN,

    UNINCORPORATED_ASSN,

    PARTNER,

    GEN_PARTNER,

    CORP,

    SCORP,

    CCORP,

    NONPROFIT,

    LLC,

    LLP,

    SP,

    LIMPARTNER,

    CCORPLLC,

    SCORPLLC,

    PARTNERLLC,

    SPLLC,

    ORG_OR_CLUB,

    NONPROFITCORP,

    NONPROFITORG,

    PROFESSIONALCORP,

    PUBLICFUNDS,

    NONMEMBER,

    IOLTA,

    UAHROF,

    GOVERNMENT,

    TRUST,

    OTHER,

    IOLA,

    NGO,

    INDIVIDUAL,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class INTEGRATION_DATA {

}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class DECLARATIONS {
    public has_judge_bankrupt_foreclosure: base_YN;
    public has_judge_bankrupt_foreclosureSpecified: boolean;
    public has_declare_bankrupt: base_YN;
    public has_declare_bankruptSpecified: boolean;
    public has_chapter_13: base_YN;
    public has_chapter_13Specified: boolean;
    public has_lawsuit_party: base_YN;
    public has_lawsuit_partySpecified: boolean;
    public has_judgement: base_YN;
    public has_judgementSpecified: boolean;
    public has_foreclosure: base_YN;
    public has_foreclosureSpecified: boolean;
    public has_reposession: base_YN;
    public has_reposessionSpecified: boolean;
    public has_other_obligation: base_YN;
    public has_other_obligationSpecified: boolean;
    public has_past_due_bills: base_YN;
    public has_past_due_billsSpecified: boolean;
    public has_co_maker: base_YN;
    public has_co_makerSpecified: boolean;
    public co_maker_primary_name: string;
    public co_maker_creditor: string;
    public co_maker_amount: number;
    public co_maker_amountSpecified: boolean;
    public has_alias: base_YN;
    public has_aliasSpecified: boolean;
    public alias: string;
    public has_income_decline: base_YN;
    public has_income_declineSpecified: boolean;
    public has_suits_pending: base_YN;
    public has_suits_pendingSpecified: boolean;
    public has_alimony: base_YN;
    public has_alimonySpecified: boolean;
    public alimony_recipient: string;
    public alimony_recipient_address: string;
    public has_property_for_sale: base_YN;
    public has_property_for_saleSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_HMDA_XML {
    public ITEM: BASE_HMDA_XMLITEM[];
    public version: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_HMDA_XMLITEM {
    public key: string;
    public value: string;
    public Value: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_HMDA_INFO {
    public property_address: string;
    public property_city: string;
    public property_state: string;
    public property_type: BASE_HMDA_INFOProperty_type;
    public property_typeSpecified: boolean;
    public property_zip: string;
    public is_hoepa: base_YN;
    public is_hoepaSpecified: boolean;
    public is_hoepa_manual: base_YN;
    public is_hoepa_manualSpecified: boolean;
    public lien_position: number;
    public lien_positionSpecified: boolean;
    public hmda_loan_purpose: string;
    public property_occupancy_status: BASE_HMDA_INFOProperty_occupancy_status;
    public property_occupancy_statusSpecified: boolean;
    public msa_number: string;
    public property_tax_id: string;
    public census_tract_number: string;
    public property_county: string;
    public property_county_code: string;
    public state_code: string;
    public interview_method: BASE_HMDA_INFOInterview_method;
    public interview_methodSpecified: boolean;
    public purchaser: BASE_HMDA_INFOPurchaser;
    public purchaserSpecified: boolean;
    public property_address_verification_date: Date;
    public property_address_verification_dateSpecified: boolean;
}
export enum BASE_HMDA_INFOProperty_type {
    SFR,

    Item2UNIT,

    Item3UNIT,

    Item4UNIT,

    Item5UNIT,

    TOWNHOUSE,

    HIGHRISECONDO,

    LOWRISECONDO,

    PUD,

    MOBILEHOME,

    MANUFACTUREDHOME,

    MIXEDUSE,

    CONDOTEL,

    COOP,

    COMMERCIAL,

    FARM,

    HOMEBUSINESS,

    LAND,

    OTHER,

    MNL,


    Item
}
export enum BASE_HMDA_INFOProperty_occupancy_status {
    INVESTMENT,

    PRIMARYRESIDENCE,

    SECONDHOME,


    Item
}
export enum BASE_HMDA_INFOInterview_method {
    TELEPHONE,

    FACETOFACE,

    INTERNET,

    MAIL,

    FAX,


    Item
}
export enum BASE_HMDA_INFOPurchaser {
    NOT_ORIGINATED,

    FANNIE_MAE,

    GINNIE_MAE,

    FREDDIE_MAC,

    FARMER_MAC,

    PRIVATE,

    COMMERCIAL,

    LIFE_INSURANCE,

    AFFILIATE,

    OTHER
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_CLOSE_END_INFO {
    public ATO: BASE_CLOSE_END_INFOATO[];
    public amount_owed_to_lender: number;
    public amount_owed_to_lenderSpecified: boolean;
    public amount_refinanced: number;
    public amount_refinancedSpecified: boolean;
    public cash_prepaid_finance_charge: number;
    public cash_prepaid_finance_chargeSpecified: boolean;
    public mapr_cash_fee: number;
    public mapr_cash_feeSpecified: boolean;
    public filing_fee_amount: number;
    public filing_fee_amountSpecified: boolean;
    public filing_fee_type: BASE_CLOSE_END_INFOFiling_fee_type;
    public filing_fee_typeSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_CLOSE_END_INFOATO {
    public name: string;
    public memo: string;
    public amount: number;
    public amountSpecified: boolean;
    public is_PPFC: base_YN;
    public is_PPFCSpecified: boolean;
    public is_mapr: base_YN;
    public is_maprSpecified: boolean;
    public funding_method: BASE_CLOSE_END_INFOATOFunding_method;
    public funding_methodSpecified: boolean;
}
export enum BASE_CLOSE_END_INFOATOFunding_method {
    IL,

    SD,

    SL,

    EL
}
export enum BASE_CLOSE_END_INFOFiling_fee_type {
    FINANCED,

    CASH,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_LOAN_STATUS {
    public loan_status: BASE_LOAN_STATUSLoan_status;
    public initial_entry_timestamp: Date;
    public initial_entry_timestampSpecified: boolean;
    public last_modified: Date;
    public last_modifiedSpecified: boolean;
    public submit_date: Date;
    public submit_dateSpecified: boolean;
    public underwrite_submit_date: Date;
    public underwrite_submit_dateSpecified: boolean;
    public is_locked: base_YN;
    public existing_balance: number;
    public existing_balanceSpecified: boolean;
    public existing_balance_date: Date;
    public existing_balance_dateSpecified: boolean;
    public delinquency_period: string;
    public delinquency_amount: number;
    public delinquency_amountSpecified: boolean;
    public approval_date: Date;
    public approval_dateSpecified: boolean;
    public declined_date: Date;
    public declined_dateSpecified: boolean;
    public first_approval_date: Date;
    public first_approval_dateSpecified: boolean;
    public next_payment_date: Date;
    public next_payment_dateSpecified: boolean;
    public response_date: Date;
    public response_dateSpecified: boolean;
    public counter_offer_date: Date;
    public counter_offer_dateSpecified: boolean;
    public app_receive_date: Date;
    public app_receive_dateSpecified: boolean;
    public deciding_date: Date;
    public deciding_dateSpecified: boolean;
    public booking_date: Date;
    public booking_dateSpecified: boolean;
    public booking_reference_id: string;
}
export enum BASE_LOAN_STATUSLoan_status {
    PREAPP,

    PEN,

    APP,

    AA,

    AD,

    AP,

    OFF,

    DEC,

    DUP,

    INC,

    DEN,

    CAN,

    INQ,

    NMI,

    CODEC,

    AP2S,

    MEMWAIT,

    WTHDRN,

    WTHDRNO,

    REF,

    AMD,

    MEMDC,

    REVIEW,

    FRAUD,

    CFI,

    CFIO,

    PRD,

    PRA
}
/*[System.Xml.Serialization.XmlIncludeAttribute(typeof(XPRESS_SYSTEM))]
[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_SYSTEM {
    public PROCESSOR: BASE_PARTY;
    public LOAN_OFFICER: BASE_PARTY;
    public BOOKING_OFFICER: BASE_PARTY;
    public APPROVAL_OFFICER: BASE_PARTY;
    public COUNTER_OFFER_OFFICER: BASE_PARTY;
    public FUNDER: BASE_PARTY;
    public DECLINING_OFFICER: BASE_PARTY;
    public DECIDING_OFFICER: BASE_PARTY;
    public ORIGINATOR: BASE_SYSTEMORIGINATOR;
    public BRANCH: BASE_BRANCH;
    public LENDER: BASE_SYSTEMLENDER;
    public ORGANIZATION: BASE_PARTY;
    public INSURANCE_SELLER: BASE_PARTY;
    public CLINIC: BASE_PARTY;
    public CLINIC_WORKER: BASE_PARTY;
    public INSURANCE_LAST_PULLED_OFFICER: BASE_PARTY;
    public source: string;
    public origination_ip: string;
    public external_source: string;
    public loan_number: string;
    public reference_number_type: string;
    public AnyAttr: any;
    constructor() {
        this.source = "GATEWAY";
        this.origination_ip = "";
    }
}
/*[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_BRANCH))]
[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_PARTY {
    public reference_id: string;
    public name: string;
    public code: string;
    public phone: string;
    public fax: string;
    public email: string;
    public title: string;
    public teller_number: string;
    public terminal_id: string;
    public AnyAttr: any;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_BRANCH extends BASE_PARTY {
    public ADDRESS: BASE_ADDRESS_LOOSE;
    public reference_id2: string;
    public deluxe_org_reporting_unit: string;
    public ews_org_reporting_unit: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_SYSTEMORIGINATOR extends BASE_PARTY {
    public type: BASE_SYSTEMORIGINATORType;
}
export enum BASE_SYSTEMORIGINATORType {
    C,

    L,

    P,

    O,

    S,

    Z
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_SYSTEMLENDER extends BASE_PARTY {
    public ADDRESS: BASE_ADDRESS_LOOSE;
    public cuna_client_id: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_SYSTEM extends BASE_SYSTEM {
    public type: XPRESS_SYSTEMType;
}
export enum XPRESS_SYSTEMType {
    LPQ,

    DAPP,

    OTHER
}
/*[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_ACH))]
[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_LOAN))]
[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_FEE))]
[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_DEPOSIT))]
[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_CHECK))]
[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_DISBURSEMENT {
    public amount: number;
    public amountSpecified: boolean;
    public reference_id: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_DISBURSEMENT_ACH extends BASE_DISBURSEMENT {
    public name_on_account: string;
    public account_number: string;
    public bank_routing_number: string;
    public bank_name: string;
    public bank_state: string;
    public account_type: BASE_DISBURSEMENT_ACHAccount_type;
    public account_typeSpecified: boolean;
    public transaction_type: BASE_DISBURSEMENT_ACHTransaction_type;
    public transaction_typeSpecified: boolean;
    public ach_is_primary_applicant: base_YN;
    public ach_is_primary_applicantSpecified: boolean;
    public payoff_date: Date;
    public payoff_dateSpecified: boolean;
    public interest_per_diem: number;
    public interest_per_diemSpecified: boolean;
}
export enum BASE_DISBURSEMENT_ACHAccount_type {
    CHECKING,

    SAVINGS,


    Item
}
export enum BASE_DISBURSEMENT_ACHTransaction_type {
    NORMAL,

    DEALER_RESERVE,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_DISBURSEMENT_LOAN extends BASE_DISBURSEMENT {
    public loan_suffix: string;
    public loan_is_repay_loan: string;
    public loan_description: string;
    public loan_is_same_account: BASE_DISBURSEMENT_LOANLoan_is_same_account;
    public loan_is_same_accountSpecified: boolean;
    public payoff_date: Date;
    public payoff_dateSpecified: boolean;
    public interest_per_diem: number;
    public interest_per_diemSpecified: boolean;
    public loan_type: string;
    public payment_type: string;
    public loan_account_number: string;
}
export enum BASE_DISBURSEMENT_LOANLoan_is_same_account {

    Item,

    Y,

    N
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_DISBURSEMENT_FEE extends BASE_DISBURSEMENT {
    public prepaid_fees_financed: number;
    public prepaid_fees_financedSpecified: boolean;
    public fees_financed: number;
    public fees_financedSpecified: boolean;
    public fees_gl_account_number: string;
    public fee_description: string;
    public fee_linking_type: BASE_DISBURSEMENT_FEEFee_linking_type;
    public fee_linking_typeSpecified: boolean;
}
export enum BASE_DISBURSEMENT_FEEFee_linking_type {
    ATO1,

    ATO2,

    ATO3,

    ATO4,

    ATO5,

    ATO6,

    ATO7,

    ATO8,

    ATO9,

    ATO10,

    DSF,

    FF,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_DISBURSEMENT_DEPOSIT extends BASE_DISBURSEMENT {
    public deposit_account: string;
    public deposit_suffix: string;
    public deposit_type: string;
    public deposit_date_to_credit: Date;
    public deposit_date_to_creditSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_DISBURSEMENT_CHECK extends BASE_DISBURSEMENT {
    public check_number: string;
    public check_payable_to: string;
    public check_address_1: string;
    public check_address_2: string;
    public check_address_3: string;
    public check_zip: string;
    public check_remitter: string;
    public check_purpose: BASE_DISBURSEMENT_CHECKCheck_purpose;
    public check_purposeSpecified: boolean;
    public check_status: BASE_DISBURSEMENT_CHECKCheck_status;
    public check_statusSpecified: boolean;
    public check_print_option: number;
    public check_print_optionSpecified: boolean;
    public check_is_payee_only: base_YN;
    public check_is_payee_onlySpecified: boolean;
    public check_is_primary_applicant: base_YN;
    public check_is_primary_applicantSpecified: boolean;
    public payoff_date: Date;
    public payoff_dateSpecified: boolean;
    public interest_per_diem: number;
    public interest_per_diemSpecified: boolean;
    public check_remitter_contact_index: string;
    public check_remitter_contact_index2: string;
    public check_coremitter_contact_index: string;
    public is_payee_ofac_istwatch: base_YN;
    public is_payee_ofac_istwatchSpecified: boolean;
    public istwatch_transaction_date: string;
    public check_account_number: string;
}
export enum BASE_DISBURSEMENT_CHECKCheck_purpose {
    CLSAC,

    PAYBL,

    VLD,

    VLPRD,

    VLABD,


    Item
}
export enum BASE_DISBURSEMENT_CHECKCheck_status {
    NOT_PRINTED,

    PRINTED,

    VOIDED
}
/*[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_SHARE))]
[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_SAVINGS_ACCOUNT))]
[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_CERTIFICATE))]
[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_REAL_ESTATE))]
[System.Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_VEHICLE))]
[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_ASSET {
    public asset_type: BASE_ASSETAsset_type;
    public asset_value: number;
    public asset_valueSpecified: boolean;
    public property_address: string;
    public property_zip: string;
    public property_state: string;
    public property_city: string;
    public existing_loan_amount: number;
    public existing_loan_amountSpecified: boolean;
    public description: string;
    public account_number: string;
    public bank_name: string;
    public is_collateral: base_YN;
    public is_collateralSpecified: boolean;
    public ownership_type: BASE_ASSETOwnership_type;
    public other_owner_name: string;
    public other_owner_name2: string;
    public available_account_info: string;
    public stock_bond_mutualfund_share_count: string;
    public verification_status: BASE_ASSETVerification_status;
    public verification_statusSpecified: boolean;
    public other_owner_contact_index: string;
    public other_owner_contact_index2: string;
}
export enum BASE_ASSETAsset_type {
    CERTIFICATE,

    CASH_DEPOSIT,

    SHARE_BACK,

    STOCK,

    BOND,

    CHECKING_ACCOUNT,

    SAVINGS_ACCOUNT,

    REALESTATE,

    MACHINERY_AND_EQUIPMENT,

    FURNITURE_AND_FIXTURES,

    AUTOMOBILE,

    LIFE_INSURANCE,

    RETIREMENT_FUND,

    OWNED_BUSINESS,

    OTHER,

    OTHER_NON_LIQUID,

    BRIDGE_LOAN_NOT_DEPOSITED,

    CASH_ON_HAND,

    GIFT,

    MONEY_MARKET,

    MUTUAL_FUNDS,

    SECURED_BORROWER_FUNDS,

    TRUST_FUNDS,

    IRA,

    NET_SALE_PROCEEDS,

    GIFT_OF_EQUITY,

    AIRCRAFT,

    AS_EXTRACTED,

    CHATTEL,

    DOC_VESSEL,

    FARM_EQUIP,

    FARM_PROD,

    GEN_INTANGIBLES,

    INSTRUMENTS,

    INVENTORY,

    LEASE_AND_RENTS,

    MFG_HOUSING,

    NON_TITLED_PERSONAL_PROP,

    PARTNERSHIP_INTEREST,

    REAL_ESTATE_CONST,

    STANDING_TIMBER,

    WATERCRAFT
}
export enum BASE_ASSETOwnership_type {
    B,

    J,

    T
}
export enum BASE_ASSETVerification_status {
    NA,

    UNVERIFIED,

    VERIFIED
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_ASSET_SHARE extends BASE_ASSET {
    public expiration_date: Date;
    public expiration_dateSpecified: boolean;
    public pledge_type: string;
    public rate: number;
    public rateSpecified: boolean;
    public pledge_percent: number;
    public pledge_percentSpecified: boolean;
    public pledge_value_type: BASE_ASSET_SHAREPledge_value_type;
    public pledge_value_typeSpecified: boolean;
    public pledge_value: number;
    public pledge_valueSpecified: boolean;
}
export enum BASE_ASSET_SHAREPledge_value_type {
    PERCENT,

    PERCENTLOAN,

    AMOUNT
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_ASSET_SAVINGS_ACCOUNT extends BASE_ASSET {
    public expiration_date: Date;
    public expiration_dateSpecified: boolean;
    public issue_date: Date;
    public issue_dateSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_ASSET_CERTIFICATE extends BASE_ASSET {
    public certificate_number: string;
    public expiration_date: Date;
    public expiration_dateSpecified: boolean;
    public issue_date: Date;
    public issue_dateSpecified: boolean;
    public pledge_type: string;
    public rate: number;
    public rateSpecified: boolean;
    public pledge_percent: number;
    public pledge_percentSpecified: boolean;
    public pledge_value_type: string;
    public pledge_value: number;
    public pledge_valueSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_ASSET_REAL_ESTATE extends BASE_ASSET {
    public property_type: BASE_ASSET_REAL_ESTATEProperty_type;
    public property_typeSpecified: boolean;
    public property_gross_rental_income: number;
    public property_gross_rental_incomeSpecified: boolean;
    public property_net_rental_income: number;
    public property_net_rental_incomeSpecified: boolean;
    public property_mortgage_payments: number;
    public property_mortgage_paymentsSpecified: boolean;
    public property_other_costs: number;
    public property_other_costsSpecified: boolean;
    public property_current_residence_indicator: base_YN;
    public property_current_residence_indicatorSpecified: boolean;
    public property_disposition: BASE_ASSET_REAL_ESTATEProperty_disposition;
    public property_dispositionSpecified: boolean;
    public is_subject_property: base_YN;
    public is_subject_propertySpecified: boolean;
    public property_tax_id: string;
    public is_financed_property: base_YN;
    public is_financed_propertySpecified: boolean;
}
export enum BASE_ASSET_REAL_ESTATEProperty_type {
    SFR,

    Item2UNIT,

    Item3UNIT,

    Item4UNIT,

    Item5UNIT,

    TOWNHOUSE,

    HIGHRISECONDO,

    LOWRISECONDO,

    PUD,

    MOBILEHOME,

    MANUFACTUREDHOME,

    MIXEDUSE,

    CONDOTEL,

    COOP,

    COMMERCIAL,

    FARM,

    HOMEBUSINESS,

    LAND,

    OTHER,

    MNL,


    Item
}
export enum BASE_ASSET_REAL_ESTATEProperty_disposition {
    S,

    H,

    P,

    R,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_ASSET_VEHICLE extends BASE_ASSET {
    public vehicle_year: string;
    public vehicle_make: string;
    public vehicle_model: string;
    public vehicle_vin: string;
    public vehicle_title_owner: string;
    public vehicle_title_number: string;
    public vehicle_color: string;
    public vehicle_miles: number;
    public vehicle_milesSpecified: boolean;
    public vehicle_license_plate: string;
    public vehicle_seller: string;
    public vehicle_has_proof_insurance: base_YN;
    public vehicle_has_proof_insuranceSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class PAYMENT_TRANSFER_FROM_OTHER_INSTITUTION_INFO {
    public account_type: base_payment_account_type;
    public account_typeSpecified: boolean;
    public account_number: string;
    public name_on_account: string;
    public bank_routing_number: string;
    public bank_name: string;
    public bank_zip: string;
    public bank_city: string;
    public bank_state: string;
}
export enum base_payment_account_type {
    CHECKING,

    SAVINGS,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class PAYMENT_TRANSFER_FROM_INTERNAL_ACCOUNT_INFO {
    public account_type: base_payment_account_type;
    public account_typeSpecified: boolean;
    public account_number: string;
    public name_on_account: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class PAYMENT_MANUAL_NO_COUPON_INFO {
    public account_number: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class PAYMENT_PAYROLL_DEDUCTION_INFO {
    public account_number: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class PAYMENT_COUPON_BOOK_INFO {
    public account_number: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_PAYMENT_INFO_CHOICE {
    public Item: Object;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class SKIP_PAYMENT_INFO {
    public skip_payment_template_name: string;
    public is_position_of_skips_first_pay_skip: base_YN;
    public is_position_of_skips_first_pay_skipSpecified: boolean;
    public is_defer_skip_payments: base_YN;
    public is_defer_skip_paymentsSpecified: boolean;
    public skips_per_year: string;
    public position_of_skips: string;
    public skip_start_date: Date;
    public skip_start_dateSpecified: boolean;
    public skip_end_date: Date;
    public skip_end_dateSpecified: boolean;
    public skip_period_month_day_start: Date;
    public skip_period_month_day_startSpecified: boolean;
    public skip_period_month_day_end: Date;
    public skip_period_month_day_endSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_FUNDING_INFO {
    public SKIP_PAYMENT_INFO: SKIP_PAYMENT_INFO;
    public PAYMENT_INFO: BASE_PAYMENT_INFO_CHOICE;
    public daily_periodic_rate: number;
    public daily_periodic_rateSpecified: boolean;
    public payment_frequency: BASE_FUNDING_INFOPayment_frequency;
    public payment_frequencySpecified: boolean;
    public funded_status: BASE_FUNDING_INFOFunded_status;
    public funded_statusSpecified: boolean;
    public loan_date: Date;
    public loan_dateSpecified: boolean;
    public funding_date: Date;
    public funding_dateSpecified: boolean;
}
export enum BASE_FUNDING_INFOPayment_frequency {
    ANNUALLY,

    SEMIANNUALLY,

    QUARTERLY,

    BIMONTHLY,

    MONTHLY,

    QUADWEEKLY,

    SEMIMONTHLY,

    BIWEEKLY,

    WEEKLY,

    SINGLEPAYMENT,

    TWICEMONTHLY
}
export enum BASE_FUNDING_INFOFunded_status {
    FUN,

    UF,

    RF,

    PROC,

    RD,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_STRUCTURED_COMMENTS {
    public STRUCTURED_INTERNAL_COMMENTS: BASE_STRUCTURED_COMMENT[];
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_STRUCTURED_COMMENT {
    public MODIFIER: BASE_PARTY;
    public modifier_type: BASE_STRUCTURED_COMMENTModifier_type;
    public modifier_typeSpecified: boolean;
    public last_modified_date: Date;
    public last_modified_dateSpecified: boolean;
    public creation_date: Date;
    public creation_dateSpecified: boolean;
    public message: string;
}
export enum BASE_STRUCTURED_COMMENTModifier_type {
    L,

    Z,

    U
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_CONTACT_INFO {
    public preferred_contact_method: BASE_CONTACT_INFOPreferred_contact_method;
    public preferred_contact_methodSpecified: boolean;
    public cell_phone: string;
    public cell_phone_country: base_phone_country_alpha2;
    public fax_number: string;
    public fax_number_country: base_phone_country_alpha2;
    public email: string;
    public home_phone: string;
    public home_phone_country: base_phone_country_alpha2;
    public work_phone: string;
    public work_phone_country: base_phone_country_alpha2;
    public work_phone_extension: string;
    constructor() {
        this.cell_phone_country = base_phone_country_alpha2.US;
        this.fax_number_country = base_phone_country_alpha2.US;
        this.home_phone_country = base_phone_country_alpha2.US;
        this.work_phone_country = base_phone_country_alpha2.US;
    }
}

export enum base_phone_country_alpha2 {

    Item,

    AX,

    AF,

    AL,

    DZ,

    AS,

    AD,

    AO,

    AI,

    AQ,

    AG,

    AR,

    AM,

    AW,

    AU,

    AT,

    AZ,

    BS,

    BH,

    BD,

    BB,

    BY,

    BE,

    BZ,

    BJ,

    BM,

    BT,

    BO,

    BA,

    BW,

    BV,

    BR,

    IO,

    BN,

    BG,

    BF,

    BI,

    KH,

    CM,

    CV,

    CA,

    KY,

    CF,

    TD,

    CL,

    CN,

    CX,

    CC,

    CO,

    KM,

    CD,

    CG,

    CK,

    CR,

    CI,

    HR,

    CU,

    CY,

    CZ,

    DK,

    DJ,

    DM,

    DO,

    TL,

    EC,

    SV,

    EG,

    GQ,

    ER,

    EE,

    ET,

    FK,

    FO,

    FJ,

    FI,

    FR,

    GF,

    PF,

    TF,

    GA,

    GM,

    GE,

    DE,

    GH,

    GI,

    GR,

    GL,

    GD,

    GP,

    GU,

    GT,

    GN,

    GG,

    GW,

    GY,

    HT,

    HM,

    HN,

    HK,

    HU,

    IS,

    IN,

    ID,

    IR,

    IQ,

    IE,

    IM,

    IL,

    IT,

    JM,

    JP,

    JE,

    JO,

    KZ,

    KE,

    KI,

    KW,

    KG,

    LA,

    LV,

    LB,

    LS,

    LR,

    LY,

    LI,

    LT,

    LU,

    MO,

    MK,

    MG,

    MW,

    MY,

    MV,

    ML,

    MT,

    MH,

    MQ,

    MR,

    MU,

    YT,

    MX,

    FM,

    MD,

    MC,

    MN,

    ME,

    MS,

    MA,

    MZ,

    MM,

    NA,

    NR,

    NP,

    NL,

    AN,

    NC,

    NZ,

    NI,

    NE,

    NG,

    NU,

    NF,

    KP,

    MP,

    NO,

    OM,

    PK,

    PW,

    PS,

    PA,

    PG,

    PY,

    PE,

    PH,

    PN,

    PL,

    PT,

    PR,

    QA,

    RE,

    RO,

    RW,

    RU,

    BL,

    SH,

    KN,

    LC,

    MF,

    PM,

    VC,

    WS,

    SM,

    ST,

    SA,

    SN,

    RS,

    SC,

    SL,

    SG,

    SK,

    SI,

    SB,

    SO,

    ZA,

    GS,

    KR,

    ES,

    LK,

    SD,

    SR,

    SJ,

    SZ,

    SE,

    CH,

    SY,

    TW,

    TJ,

    TZ,

    TH,

    TG,

    TK,

    TO,

    TT,

    TN,

    TR,

    TM,

    TC,

    TV,

    UG,

    UA,

    AE,

    GB,

    UY,

    US,

    UM,

    UZ,

    VU,

    VA,

    VN,

    VG,

    VI,

    VE,

    YE,

    WF,

    EH,

    ZM,

    ZW
}


/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_MONTHLY_INCOME {
    public OTHER_INCOME: BASE_MONTHLY_INCOMEOTHER_INCOME[];
    public is_monthly_income_base_salary_manual: base_YN;
    public is_monthly_income_base_salary_manualSpecified: boolean;
    public is_bah_included: base_YN;
    public is_bah_includedSpecified: boolean;
    public monthly_income_base_salary: number;
    public monthly_income_base_salarySpecified: boolean;
    public is_tax_exempt_monthly_income_base_salary: base_YN;
    public is_hmda_exempt_monthly_income_base_salary: base_YN;
    public monthly_income_over_time: number;
    public monthly_income_over_timeSpecified: boolean;
    public monthly_income_bonus: number;
    public monthly_income_bonusSpecified: boolean;
    public monthly_income_commission: number;
    public monthly_income_commissionSpecified: boolean;
    public monthly_income_dividends: number;
    public monthly_income_dividendsSpecified: boolean;
    public monthly_income_net_rental: number;
    public monthly_income_net_rentalSpecified: boolean;
    public income_verify_method: string;
    constructor() {
        this.is_tax_exempt_monthly_income_base_salary = base_YN.N;
        this.is_hmda_exempt_monthly_income_base_salary = base_YN.N;
    }
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_MONTHLY_INCOMEOTHER_INCOME {
    public monthly_income: number;
    public monthly_incomeSpecified: boolean;
    public monthly_income_description: string;
    public is_tax_exempt: base_YN;
    public is_hmda_exempt: base_YN;
    constructor() {
        this.is_tax_exempt = base_YN.N;
        this.is_hmda_exempt = base_YN.N;
    }
}



/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_INSURANCE {
    public coverage: BASE_INSURANCECoverage;
    public coverageSpecified: boolean;
    public plan_key: string;
    public description: string;
    public premium: number;
    public premiumSpecified: boolean;
    public premium_mode: BASE_INSURANCEPremium_mode;
    public premium_modeSpecified: boolean;
    public cost_per_day: number;
    public cost_per_daySpecified: boolean;
    public insured_amount: number;
    public insured_amountSpecified: boolean;
    public insured_rate: number;
    public insured_rateSpecified: boolean;
    public expire_date: Date;
    public expire_dateSpecified: boolean;
    public last_pulled_date: Date;
    public last_pulled_dateSpecified: boolean;
}
export enum BASE_INSURANCECoverage {
    S,

    J,

    C,

    B
}
export enum BASE_INSURANCEPremium_mode {
    MR,

    LR,

    SP,

    DC,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_CURRENT_ADDRESS_CHOICE {
    public Item: Object;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_ADDRESS_STRICT {
    public street_address: string;
    public street_no: string;
    public street_direction: BASE_ADDRESS_STRICTStreet_direction;
    public street_directionSpecified: boolean;
    public street_name: string;
    public street_type: string;
    public unit_no: string;
    public verification_date: Date;
    public verification_dateSpecified: boolean;
    public city: string;
    public state: string;
    public zip: string;
    public county: string;
}
export enum BASE_ADDRESS_STRICTStreet_direction {
    EAST,

    NORTH,

    NORTHEAST,

    SOUTH,

    SOUTHEAST,

    WEST,

    NORTHWEST,

    SOUTHWEST,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_ADDRESS_THREE_LINE {
    public street_address_1: string;
    public street_address_2: string;
    public street_address_3: string;
    public country: string;
    public zip: string;
    public city: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_APPLICANT {
    public ID_CARD: BASE_ID_CARD;
    public ID_CARD2: BASE_ID_CARD;
    public APPROVAL_DENIAL_REASON: string;
    public INCOME_COMMENTS: string;
    public APPLICANT_QUESTIONS: BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTION[];
    public borrower_id: string;
    public is_declined: string;
    public first_name: string;
    public last_name: string;
    public m_initial: string;
    public middle_name: string;
    public suffix: BASE_APPLICANTSuffix;
    public suffixSpecified: boolean;
    public ssn: string;
    public dob: Date;
    public dobSpecified: boolean;
    public dependents: string;
    public ages_of_dependents: string;
    public mother_maiden_name: string;
    public citizenship: BASE_APPLICANTCitizenship;
    public citizenshipSpecified: boolean;
    public member_number: string;
    public bankruptcy_score: string;
    public membership_months: string;
    public marital_status: BASE_APPLICANTMarital_status;
    public marital_statusSpecified: boolean;
    public gender: BASE_APPLICANTGender;
    public genderSpecified: boolean;
    public ethnicity: BASE_APPLICANTEthnicity;
    public ethnicitySpecified: boolean;
    public number_in_household: string;
    public member_type: BASE_APPLICANTMember_type;
    public member_typeSpecified: boolean;
    public race: base_race_type_single[];
    public credit_score: string;
    public custom_score: string;
    public custom_score_2: number;
    public custom_score_2Specified: boolean;
    public declined_answer_race_gender: base_YN;
    public declined_answer_race_genderSpecified: boolean;
    public not_applicable_race_gender: base_YN;
    public not_applicable_race_genderSpecified: boolean;
    public applicant_type: base_applicant_type;
    public is_non_signing_spouse: base_YN;
    public is_non_signing_spouseSpecified: boolean;
    public allow_direct_mail: base_YN;
    public allow_direct_mailSpecified: boolean;
    public allow_email_solicit: base_YN;
    public allow_email_solicitSpecified: boolean;
    public allow_telemarket: base_YN;
    public allow_telemarketSpecified: boolean;
    public allow_share_info: base_YN;
    public allow_share_infoSpecified: boolean;
    public security_code: string;
    public is_ofac_id_ver: base_YN;
    public is_ofac_id_verSpecified: boolean;
    public id_authentication_result: BASE_APPLICANTId_authentication_result;
    public id_authentication_resultSpecified: boolean;
    public is_fraud_prescore: base_YN;
    public is_fraud_prescoreSpecified: boolean;
    public legal_state_of_residency: string;
    public relation_to_primary: string;
    public is_fraud_fas: base_YN;
    public is_fraud_fasSpecified: boolean;
    public is_high_risk_consumer: base_YN;
    public is_high_risk_consumerSpecified: boolean;
    public is_thin: base_YN;
    public is_thinSpecified: boolean;
    public contact_index: string;
    public mla_covered_status: string;
    public mla_covered_date: Date;
    public mla_covered_dateSpecified: boolean;
    public AnyAttr: any;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTION {
    public APPLICANT_QUESTION_ANSWER: BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTIONAPPLICANT_QUESTION_ANSWER[];
    public question_name: string;
    public question_type: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTIONAPPLICANT_QUESTION_ANSWER {
    public answer_text: string;
    public answer_value: string;
}
export enum BASE_APPLICANTSuffix {
    JR,

    SR,

    II,

    III,

    IV,

    V,

    VI,

    VII,

    VIII
}
export enum BASE_APPLICANTCitizenship {
    USCITIZEN,

    PERMRESIDENT,

    NONPERMRESIDENT,

    NONRESIDENTALIEN,


    Item
}
export enum BASE_APPLICANTMarital_status {
    SEPARATED,

    UNMARRIED,

    MARRIED,

    DOMESTIC_PARTNER,


    Item
}
export enum BASE_APPLICANTGender {
    MALE,

    FEMALE,

    OTHER,


    Item
}
export enum BASE_APPLICANTEthnicity {
    HISPANIC,

    NOT_HISPANIC,


    Item
}
export enum BASE_APPLICANTMember_type {
    CURRENT,

    NEW,

    PREAPPROVED,


    Item
}
export enum base_race_type_single {
    AMERICAN_INDIAN,

    ASIAN,

    BLACK,

    PACIFIC_ISLANDER,

    WHITE
}

export enum BASE_APPLICANTId_authentication_result {
    FAIL,

    INCOMPLETE,

    PASS,

    REVIEW,

    NOT_REQUIRE
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_COMMENTS {
    public DECISION_COMMENTS: Object;
    public EXTERNAL_COMMENTS: Object;
    public INTERNAL_COMMENTS: Object;
    public STIPULATIONS: Object;
    public FRAUD_WARNINGS: Object;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TRANSFER {
    public ACCOUNT_INFO: XPRESS_ACCOUNT_TRANSFERACCOUNT_INFO[];
    public account_transfer_type: XPRESS_ACCOUNT_TRANSFERAccount_transfer_type;
    public account_transfer_typeSpecified: boolean;
    public employer: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public company: string;
    public bank_name: string;
    public close_date: Date;
    public close_dateSpecified: boolean;
    public account_number: string;
    public partial_transfer_amount: number;
    public partial_transfer_amountSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TRANSFERACCOUNT_INFO {
    public account_number: string;
    public account_type: account_type;
    public account_typeSpecified: boolean;
}
export enum XPRESS_ACCOUNT_TRANSFERAccount_transfer_type {
    FININS,

    AUTPAY,

    DIRDEP
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class ATM_CARD_LINK_TYPE {
    public card_link_id: string;
    public reference_type: account_relation_type;
    public reference_id: string;
    public is_linked: base_YN;
    public is_linkedSpecified: boolean;
    public date_linked: Date;
    public date_linkedSpecified: boolean;
    public description: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class ATM_CARD_TYPE {
    public ATM_CARD_LINK_LIST: ATM_CARD_LINK_TYPE[];
    public is_created: base_YN;
    public is_createdSpecified: boolean;
    public pin_offset: string;
    public is_rewards: base_YN;
    public is_rewardsSpecified: boolean;
    public rewards: string;
    public is_nsfod: base_YN;
    public is_nsfodSpecified: boolean;
    public free_transactions: string;
    public account_number: string;
    public primary_checking_link_id: string;
    public primary_savings_link_id: string;
    public primary_money_market_link_id: string;
    public primary_creditline_link_id: string;
    public card_type: string;
    public is_order_pin: base_YN;
    public is_order_pinSpecified: boolean;
    public expire_date: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_REFERENCE {
    public first_name: string;
    public last_name: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public phone: string;
    public relationship: string;
    public email: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_ADDRESS_THREE_LINE_XPRESS {
    public street_address_1: string;
    public street_address_2: string;
    public street_address_3: string;
    public country: string;
    public zip: string;
    public city: string;
}

/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_BASE_APPLICANTPREVIOUS_ADDRESS extends BASE_ADDRESS_LOOSE {
    public occupancy_status: XPRESS_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status;
    public occupancy_statusSpecified: boolean;
    public occupancy_duration: string;
    public other_occupancy_description: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_BASE_APPLICANTMAILING_ADDRESS extends BASE_ADDRESS_LOOSE {
    public is_current: base_YN;
    constructor() {
        super();
        this.is_current = base_YN.Y;
    }
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_BASE_APPLICANTFINANCIAL_INFO {
    public monthly_liability: number;
    public monthly_liabilitySpecified: boolean;
    public monthly_rent: number;
    public monthly_rentSpecified: boolean;
    public temp_monthly_housing_cost: number;
    public temp_monthly_housing_costSpecified: boolean;
    public monthly_income_base_salary: number;
    public monthly_income_base_salarySpecified: boolean;
    public is_monthly_income_base_salary_manual: base_YN;
    public is_monthly_income_base_salary_manualSpecified: boolean;
    public is_bah_included: base_YN;
    public is_bah_includedSpecified: boolean;
    public employment_status: XPRESS_BASE_APPLICANTFINANCIAL_INFOEmployment_status;
    public employment_statusSpecified: boolean;
    public other_employment_description: string;
    public employment_start_date: Date;
    public employment_start_dateSpecified: boolean;
    public employed_months: number;
    public employed_monthsSpecified: boolean;
    public employer: string;
    public pay_grade: string;
    public ets: Date;
    public etsSpecified: boolean;
    public employee_of_lender_type: base_employee_of_lender_types;
    public employee_of_lender_typeSpecified: boolean;
    public occupation: string;
    public employment_address: string;
    public employment_zip: string;
    public employment_city: string;
    public employment_state: string;
    public supervisor_name: string;
    public profession_months: string;
    public employment_weekly_hours: number;
    public employment_weekly_hoursSpecified: boolean;
    public employment_business_type: string;
}
export enum XPRESS_BASE_APPLICANTFINANCIAL_INFOEmployment_status {
    MI,

    OT,

    EM,

    HO,

    OW,

    RE,

    SE,

    ST,

    UN,

    RM,

    GOV
}
export enum base_employee_of_lender_types {
    NONE,

    EMPLOYEE,

    MANAGER,

    EXECUTIVE,

    BOARDMEMBER,

    EMPLOYEEFAMILY,

    BOARDFAMILY,

    OTHER
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_BASE_APPLICANTDEBIT_BUREAU_INFO extends DEBIT_BUREAU_INFO {

}
export enum XPRESS_BASE_APPLICANTIs_debit_bureau_required {
    Y,

    N,


    Item
}

export enum XPRESS_BASE_APPLICANTSuffix {
    JR,

    SR,

    II,

    III,

    IV,

    V,

    VI,

    VII,

    VIII,


    Item
}
export enum XPRESS_BASE_APPLICANTGender {
    MALE,

    FEMALE,


    Item
}
export enum XPRESS_BASE_APPLICANTId_authentication_result {
    FAIL,

    INCOMPLETE,

    PASS,

    REVIEW,

    NOT_REQUIRE
}
export enum XPRESS_BASE_APPLICANTId_ver_result {
    INCOMPLETE,

    PASS,

    FAIL,

    OVERRIDDEN,

    REVIEW
}
export enum XPRESS_BASE_APPLICANTPre_score_results {
    PASS,

    FAIL,

    UNKNOWN
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_APPLICANTCREDIT_REPORT {
    public report_id: number;
    public report_format: XPRESS_APPLICANTCREDIT_REPORTReport_format;
    public encoding: XPRESS_APPLICANTCREDIT_REPORTEncoding;
    public encodingSpecified: boolean;
    public Value: string;
}
export enum XPRESS_APPLICANTCREDIT_REPORTReport_format {
    RAW_XML,

    MCL_COMMON,

    MISMO
}
export enum XPRESS_APPLICANTCREDIT_REPORTEncoding {
    CDATA,

    INNER_TEXT,

    BASE64
}
export enum XPRESS_APPLICANTIs_equity_owner {
    Y,

    N,


    Item
}
export enum XPRESS_APPLICANTIs_passed_id_verification {
    Y,

    N,


    Item
}
export enum XPRESS_APPLICANTCo_is_passed_id_verification {
    Y,

    N,


    Item
}
export enum XPRESS_APPLICANTJoint_account_option {
    ALL,

    CHECKING,


    Item
}
export enum XPRESS_APPLICANTIs_credit_union_advocate {
    Y,

    N,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_LOANCOMMENTS extends BASE_COMMENTS {

}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPE {
    public PRICE_ADJUSTMENTS: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEPRICE_ADJUSTMENT[];
    public SERVICES: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPESERVICE[];
    public ACCOUNT_TYPE_RELATIONS: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPERELATION[];
    public APPROVED_CD_INFO: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_CD_INFO;
    public APPROVED_IRA_INFO: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFO;
    public APPROVED_ACCOUNT_LINKS: APPROVED_ACCOUNT_LINK_TYPE[];
    public DIRECT_DEPOSIT: DIRECT_DEPOSIT_TYPE[];
    public PRODUCT_CUSTOM_QUESTIONS_APPROVED: BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION[];
    public id: string;
    public clf_approved_account_type_id: string;
    public clf_internal_transfer_id: string;
    public is_created: base_YN;
    public is_createdSpecified: boolean;
    public account_type: account_type;
    public apy: number;
    public apySpecified: boolean;
    public rate: number;
    public rateSpecified: boolean;
    public amount_deposit: number;
    public amount_depositSpecified: boolean;
    public account_name: string;
    public account_number: string;
    public suffix: string;
    public interest_rate_type: string;
    public cd_additions_permitted: string;
    public cd_renewability: string;
    public issue_date: Date;
    public issue_dateSpecified: boolean;
    public maturity_date: Date;
    public maturity_dateSpecified: boolean;
    public product_code: string;
    public rate_code: string;
    public term: number;
    public termSpecified: boolean;
    public bundle_code: string;
    public underwrite_result: XPRESS_ACCOUNT_TYPESACCOUNT_TYPEUnderwrite_result;
    public underwrite_resultSpecified: boolean;
    public term_type: XPRESS_ACCOUNT_TYPESACCOUNT_TYPETerm_type;
    public term_typeSpecified: boolean;
    public AnyAttr: any;
    public check_routing_number: string;
    public funding_date: Date;
    public funding_dateSpecified: boolean;
    public funding_source_id: string;
    public clf_funding_source_id: string;
    public source_of_funds: string;
    public funded_status: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEFunded_status;
    public funded_statusSpecified: boolean;
    public is_rate_override: base_YN;
    public is_rate_overrideSpecified: boolean;
    public AnyAttr1: any;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEPRICE_ADJUSTMENT {
    public description: string;
    public adjust_type: string;
    public value: number;
    public valueSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPESERVICE {
    public description: string;
    public service_type: string;
    public service_code: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPERELATION {
    public benefactor_type: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPERELATIONBenefactor_type;
    public benefactor_typeSpecified: boolean;
    public ssn: string;
    public beneficiary_priority: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPERELATIONBeneficiary_priority;
    public beneficiary_prioritySpecified: boolean;
    public beneficiary_percent_share: number;
    public beneficiary_percent_shareSpecified: boolean;
    public AnyAttr: any;
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPERELATIONBenefactor_type {
    P,

    C,

    J
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPERELATIONBeneficiary_priority {
    P,

    C,

    G,

    DB,

    RI,

    SRI,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_CD_INFO {
    public payment_frequency: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_CD_INFOPayment_frequency;
    public payment_frequencySpecified: boolean;
    public payment_method: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_CD_INFOPayment_method;
    public payment_methodSpecified: boolean;
    public payment_account_number: string;
    public payment_account_type: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_CD_INFOPayment_account_type;
    public payment_account_typeSpecified: boolean;
    public payment_routing_number: string;
    public payment_fi_name: string;
    public payment_fi_city: string;
    public payment_fi_state: string;
    public payment_fi_zip: string;
    public is_interest_rate_an_exception: base_YN;
    public is_interest_rate_an_exceptionSpecified: boolean;
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_CD_INFOPayment_frequency {
    MATURITY,

    MONTHLY,

    QUARTERLY,

    SEMIANNUALLY,

    ANNUALLY,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_CD_INFOPayment_method {
    ACHTOCHECKING,

    ACHTOSAVINGS,

    CHECK,

    COMPOUND,

    INTERNALTRANSFER,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_CD_INFOPayment_account_type {
    CHECKING,

    SAVINGS,

    MONEY_MARKET,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFO {
    public ira_type: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOIra_type;
    public ira_typeSpecified: boolean;
    public contribution_type: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOContribution_type;
    public contribution_typeSpecified: boolean;
    public contribution_year: string;
    public is_primary_retirement_account: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOIs_primary_retirement_account;
    public is_primary_retirement_accountSpecified: boolean;
    public custodian_name: string;
    public custodian_address: string;
    public custodian_city: string;
    public custodian_state: string;
    public custodian_zip: string;
    public custodian_phone: string;
    public account_transferring_from: string;
    public transfer_from_type: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOTransfer_from_type;
    public transfer_from_typeSpecified: boolean;
    public transfer_percent: number;
    public transfer_percentSpecified: boolean;
    public close_ira: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOClose_ira;
    public close_iraSpecified: boolean;
    public place_in_conduit_ira: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOPlace_in_conduit_ira;
    public place_in_conduit_iraSpecified: boolean;
    public asset_description: string;
    public amount_in_ira: number;
    public amount_in_iraSpecified: boolean;
    public amount_to_be_transferred: number;
    public amount_to_be_transferredSpecified: boolean;
    public transfer_instruction: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOTransfer_instruction;
    public transfer_instructionSpecified: boolean;
    public required_min_distribution: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFORequired_min_distribution;
    public required_min_distributionSpecified: boolean;
    public can_change_beneficiary: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOCan_change_beneficiary;
    public can_change_beneficiarySpecified: boolean;
    public continue_service_after_age_of_maturity: XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOContinue_service_after_age_of_maturity;
    public continue_service_after_age_of_maturitySpecified: boolean;
    public deposit_year: string;
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOIra_type {
    COVERDELL,

    ROTH,

    ROTHBEN,

    ROTHQUAL,

    SEP,

    TRADITIONAL,

    TRADITIONALBEN,

    TRADITIONALQUAL,

    SIMPLEIRA,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOContribution_type {
    CONTRIBUTORY,

    CONVERSION,

    RECHARACTERIZATION,

    REGULAR,

    ROLLOVER,

    ROLLOVEROFROTH,

    SEP,

    TRANSFER,

    INDIRECTCONVERSION,

    DIRECTROLLOVER,

    INDIRECTROLLOVER,

    SALARYDEDUCTION,


    Item,

    COVERDELL_ANNUAL,

    COVERDELL_ROLLOVER,

    COVERDELL_TRANSFER,

    COVERDELL_CONTRIBUT,

    COVERDELL_REGULAR,

    COVERDELL_INDIRCONV,

    COVERDELL_DIRROLL,

    COVERDELL_INDIRROLL
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOIs_primary_retirement_account {
    Y,

    N,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOTransfer_from_type {
    SIMPLE,

    TRADITIONAL,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOClose_ira {
    Y,

    N,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOPlace_in_conduit_ira {
    Y,

    N,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOTransfer_instruction {
    MATURITY,

    IMMEDIATELY,

    TRANSFERINKIND,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFORequired_min_distribution {
    DISTRIBUTEPRIOR,

    INCLUDEAMOUNT,

    SEGREGATE,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOCan_change_beneficiary {
    Y,

    N,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEAPPROVED_IRA_INFOContinue_service_after_age_of_maturity {
    Y,

    N,


    Item
}
export enum XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPEFunded_status {
    RF,

    FUN,

    UF,

    PROC,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_LOANINTERESTED_ACCOUNTS extends XPRESS_ACCOUNT_TYPES {

}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_TASK_LISTTASK {
    public ASSIGNTO: BASE_PARTY;
    public COMPLETE: BASE_PARTY;
    public CREATE: BASE_PARTY;
    public DUELOAN: BASE_PARTY;
    public APPLIEDSET: BASE_TASK_LISTTASKAPPLIEDSET;
    public REQUIRESET: BASE_TASK_LISTTASKREQUIRESET;
    public date_due: string;
    public date_completed: string;
    public date_created: string;
    public description: string;
    public required: string;
    public completed: string;
    public category: string;
    public priority: string;
    public position: string;
    public assignto_officer: string;
    public complete_officer: string;
    public create_officer: string;
    public dueloan_officer: string;
    public is_applied: string;
    public is_required: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_TASK_LISTTASKAPPLIEDSET {
    public conditionset_hash: string;
    public encoding: string;
    public Text: string[];
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_TASK_LISTTASKREQUIRESET {
    public conditionset_hash: string;
    public encoding: string;
    public Text: string[];
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_FUNDING_SOURCESFUNDING_SOURCE {
    public funding_source_id: string;
    public clf_funding_source_id: string;
    public funding_type: XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type;
    public funding_typeSpecified: boolean;
    public name_on_card: string;
    public routing_number: string;
    public cc_card_type: string;
    public cc_card_number: string;
    public cc_card_last4: string;
    public cc_card_exp_date: Date;
    public cc_card_exp_dateSpecified: boolean;
    public cc_charge_failure_count: number;
    public cc_charge_failure_countSpecified: boolean;
    public bank_name_on_card: string;
    public bank_account_number: string;
    public bank_bankstate: string;
    public bank_bankname: string;
    public bank_account_type: XPRESS_FUNDING_SOURCESFUNDING_SOURCEBank_account_type;
    public bank_account_typeSpecified: boolean;
    public transfer_account_number: string;
    public billing_street_address: string;
    public billing_city: string;
    public billing_state: string;
    public billing_zip: string;
    public microdeposit_amount_1: number;
    public microdeposit_amount_1Specified: boolean;
    public microdeposit_amount_2: number;
    public microdeposit_amount_2Specified: boolean;
    public microdeposit_failure_count: number;
    public microdeposit_failure_countSpecified: boolean;
    public microdeposit_status: XPRESS_FUNDING_SOURCESFUNDING_SOURCEMicrodeposit_status;
    public microdeposit_statusSpecified: boolean;
    public transfer_account_type: XPRESS_FUNDING_SOURCESFUNDING_SOURCETransfer_account_type;
    public transfer_account_typeSpecified: boolean;
    public future_funding_first_name: string;
    public future_funding_last_name: string;
    public future_funding_middle_name: string;
    public future_funding_suffix: string;
    public future_funding_ssn: string;
    public future_funding_ira_type: XPRESS_FUNDING_SOURCESFUNDING_SOURCEFuture_funding_ira_type;
    public future_funding_ira_typeSpecified: boolean;
    public future_funding_contribution_type: string;
    public future_funding_account_number: string;
    public paypal_paykey: string;
    public AnyAttr: any;
}
export enum XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type {
    MAIL,

    CREDITCARD,

    BANK,

    INTERNALTRANSFER,

    GUARANTY_COUPON,

    CASH,

    FUTURE,

    NOT_FUNDING,

    PAYPAL
}
export enum XPRESS_FUNDING_SOURCESFUNDING_SOURCEBank_account_type {
    CHECKING,

    SAVINGS
}
export enum XPRESS_FUNDING_SOURCESFUNDING_SOURCEMicrodeposit_status {

    Item,

    FAIL,

    PASS,

    PEND
}
export enum XPRESS_FUNDING_SOURCESFUNDING_SOURCETransfer_account_type {
    CHECKING,

    SAVINGS,

    MONEY_MARKET
}
export enum XPRESS_FUNDING_SOURCESFUNDING_SOURCEFuture_funding_ira_type {
    COVERDELL,

    ROTH,

    ROTHBEN,

    ROTHQUAL,

    SEP,

    TRADITIONAL,

    TRADITIONALBEN,

    TRADITIONALQUAL,

    SIMPLEIRA,


    Item
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_LOANBUSINESS_INFO {
    public COMPANY_INFO: COMPANY_INFO_TYPE;
    public DBA_INFO: COMPANY_INFO_TYPE;
    public BUSINESS_ID_INFO: XPRESS_LOANBUSINESS_INFOBUSINESS_ID_INFO;
    public is_dba: base_YN;
    public is_dbaSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_LOANBUSINESS_INFOBUSINESS_ID_INFO {
    public BUSINESS_ID: XPRESS_LOANBUSINESS_INFOBUSINESS_ID_INFOBUSINESS_ID;
    public BUSINESS_ID2: XPRESS_LOANBUSINESS_INFOBUSINESS_ID_INFOBUSINESS_ID2;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_LOANBUSINESS_INFOBUSINESS_ID_INFOBUSINESS_ID {
    public business_id_type: string;
    public business_id_number: string;
    public business_id_state: string;
    public business_id_country: string;
    public business_id_expire_date: Date;
    public business_id_expire_dateSpecified: boolean;
    public business_id_date_issued: Date;
    public business_id_date_issuedSpecified: boolean;
    public business_id_verification_date: Date;
    public business_id_verification_dateSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_LOANBUSINESS_INFOBUSINESS_ID_INFOBUSINESS_ID2 {
    public business_id_type: string;
    public business_id_number: string;
    public business_id_state: string;
    public business_id_country: string;
    public business_id_expire_date: Date;
    public business_id_expire_dateSpecified: boolean;
    public business_id_date_issued: Date;
    public business_id_date_issuedSpecified: boolean;
    public business_id_verification_date: Date;
    public business_id_verification_dateSpecified: boolean;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class XPRESS_LOANDEDUCTIONS {
    public DEDUCTION: DEDUCTION_TYPE;
}