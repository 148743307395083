import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Http, Response, RequestOptionsArgs, Headers, RequestOptions, ResponseContentType } from "@angular/http";
import { Observable, Subscriber } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppComponent } from "src/app/app.component";
import { FinalPageComponent } from "src/components/final-page/final-page.component";
import { environment } from 'src/environments/environment';
import { BASE_CONTACT_INFOPreferred_contact_method } from "src/models/common.model";
import { XPRESS_APPLICANT } from "src/models/xpress_loan.model";
import { CommonService } from './common.service';
declare  var Atomic : any;

@Injectable({
    providedIn: 'root'
})
export class AtomicService {

    authService: any ={};
    private options : RequestOptions;

    constructor(
        private httpClient: HttpClient,
        private http: Http, private commonService: CommonService
    ){

        this.options = new RequestOptions();
        var openIdTokenParams = JSON.parse(localStorage.getItem('openIdTokenParams'));
        if (openIdTokenParams && openIdTokenParams.access_token) {
            this.authService.accessToken = openIdTokenParams.access_token;
        }
    }


    private handleError(error: Response){
        return Observable.throw(error.json());
    }

    getAccountNumberApprovedAccount(loanId, direct_deposit_subtype): Observable<any>{
        return this.http.get(this.commonService.baseUrl + "/api/XpressLoan/get-account-number-approved-account/" + loanId + "/" + direct_deposit_subtype, this.commonService.getAuthHeader(true));
    }

    createAtomicLog(atomicLog): Observable<any>{
        return this
      .http
      .post(this.commonService.baseUrl + "/api/deposit/atomic-log", atomicLog, this.commonService.getAuthHeader(true)).pipe(
      map((response : Response) => {
          
          let result = response.json() as any;
          return result;

      })
      ,
      catchError(this.handleError)
      )
    }

    showAtomicDeposit(xaId, account_number: string, subtype, source ="NMAO-SKYONE"): Promise<any>{
        //FinalPageComponent.isLoading = false;
      //throw new Error('not active');
      
      if(subtype.toLowerCase().indexOf('checking')> -1){
        subtype = 'checking';
      }else{
        subtype = 'savings';
      }
      let atomicLog  = {
        accountNumber: account_number,
        accountSubtype: subtype,
        appId: xaId,
        source :  source
      };

     return new Promise(async (resolve, reject) =>  {
        var result = await this.httpClient.post(this.commonService.baseUrl + "/api/deposit/atomic-access-token", atomicLog, this.commonService.getAuthHeaderNew(true)).toPromise() as any;
   
        console.log(result)
   
          Atomic.transact({
              publicToken: result.data.publicToken,
              tasks: [{ "product": "deposit" }],
              // Called when the user finishes the transaction
              onFinish: function (data) {
                resolve(data)
              },
              // Called when the user exits Transact prematurely
              onClose: function (data) {
                AppComponent.direct_deposit_shown_failed = true
                resolve(data)
                 
                  
              },
              // Called when the user interacts 
              onInteraction: function(data) {}
          });
        
  
        })
         
    }

}
