import { getLocaleDayNames } from '@angular/common';
import { Component, OnInit, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ApplicantLoanComponent } from 'src/app/applicant-loan/applicant-loan.component';
import { FinalPageEnum } from 'src/models/common.model';
import { ApiService } from 'src/shared/api.service';
import { AtomicService } from 'src/shared/atomic.service';
import { LoanAppService } from 'src/shared/loan-app.service';


declare var gtag:any;

@Component({
  selector: 'app-final-page',
  templateUrl: './final-page.component.html',
  styleUrls: ['./final-page.component.css']
})
export class FinalPageComponent implements OnInit {

  @Input() soResponses: any;
  static finalPageEnum: FinalPageEnum = FinalPageEnum.None;
  @Input() isThereError: boolean;
  @Input() selectedLoanType: any;
  FinalPageEnum = FinalPageEnum;
  public static isLoading = false;
  FinalPageComponent = FinalPageComponent;
  AppComponent = AppComponent;
  ApplicantLoanComponent= ApplicantLoanComponent;

  public static forceRefresh : EventEmitter<boolean> = new EventEmitter(false);

  public static dataFinal : any={};


  decisionStatus="";
  xpressStatus="";
  memberNumber: string;
  symitarResult: string;
  symitarException: string;
  staticLinks = {
    "Final.Congratulation.RegisterBank":"",
    "Final.Congratulation.DD":"",
    "Final.Congratulation.Refer":"",
    "Skyone":"",
  }


  constructor(private apiService: ApiService, private loanAppService: LoanAppService, private changeDetectorRef: ChangeDetectorRef,
    private atomicService: AtomicService
    ) {

      //get static links
     this.apiService.getStaticLinks().subscribe(r=>{
       for (let index = 0; index < r.length; index++) {
        const element = r[index];
        this.staticLinks[element.name] = element.link
       }
     })
  }

  ngOnInit(): void {

    //this.initPage();
    FinalPageComponent.isLoading = false;
  }

  // initTest(){
  //   this.soResponses={
  //     xpress:{
  //       symitarBookOutputJson:{
  //         OUTPUT :{
  //           XML :{
  //             APPLICATION:{
  //               AccountInfo:{
  //                 AccountNumber: "123456"
  //               },
  //               Result: "Success",
  //               Exception: ""
  //             }
  //           }
  //         }
  //       }
  //     },

  //     decision:{
       
  //     }
  //   }
  // }


  getThankYouImage(){
    if(ApplicantLoanComponent.isCoBrand && ApplicantLoanComponent.currentChannel.channelName.toLowerCase() == 'spyder'){
     //  return '../../assets/images/final_page_img.png';
      return `../../assets/images/final_page_img_spyder.png`;
    }
    return '../../assets/images/final_page_img.png';
  }

  initPage(){
   

// this.memberNumber = '0000132647'
// this.finalPageEnum = FinalPageEnum.Congratulation;
// return;

if(ApplicantLoanComponent.forceFinalPageError){
  FinalPageComponent.finalPageEnum = FinalPageEnum.ErrorReceived;
  return;
}
FinalPageComponent.isLoading = false;
   //this.loanAppService.getLoanStatus(this.soResponses.appId, this.soResponses.decisionAppId).
  //  FinalPageComponent.isLoading = true;
  //   if(this.soResponses.appId){
  //     this.apiService.getLoan(this.soResponses.appId).subscribe(r => {
  //       this.initPart2();
  //       this.callShowAtomic();
  //     },err=>{
  //       this.initPart2();
  //       FinalPageComponent.isLoading = false;
  //     });
  //   }
  

    
  }

  // private initPart2() {
  //   try {
  //     this.memberNumber = this.soResponses.xpress.symitarBookOutputJson.OUTPUT.XML.APPLICATION.AccountInfo.AccountNumber;
  //     this.symitarResult = this.soResponses.xpress.symitarBookOutputJson.OUTPUT.XML.APPLICATION.Result;
  //     this.symitarException = this.soResponses.xpress.symitarBookOutputJson.OUTPUT.XML.APPLICATION.Exception;

  //     //if(!this.soResponses.decision || !this.soResponses.decision.outputJson){ // XA only
  //     //Add Result = Success, and Exception is NULL to get congratulations page
  //     if (this.memberNumber && this.memberNumber.length > 0 && this.symitarResult == "Success" && !this.symitarException) {
  //       this.finalPageEnum = FinalPageEnum.Congratulation;
  //     } else {
  //       this.finalPageEnum = FinalPageEnum.Wait2Days;
  //     }

  //     // incase the app has loan app
  //     if (this.soResponses.decision && this.soResponses.decision.outputJson) {
  //       var result = JSON.parse(this.soResponses.decision.outputJson);
  //       this.decisionStatus = result.OUTPUT.RESPONSE.DECISION['@status'];
  //       // If Decision status is 'APPROVED' or 'INSTANT APPROVED' then we will deliver congratulations page, every other status goes to Wait2Days
  //       if (this.memberNumber && (this.decisionStatus == 'APPROVED' || this.decisionStatus == 'INSTANT APPROVED')) {
  //         this.finalPageEnum = FinalPageEnum.Congratulation;
  //       } else {
  //         this.finalPageEnum = FinalPageEnum.Wait2Days;
  //       }
  //     }

  //   } catch (err) {
  //     this.finalPageEnum = FinalPageEnum.Wait2Days;
  //     console.log(err);
  //   }
  // }


    sendEventToGTM(){
      gtag('event', 'membership-Application',{
        'value' : 'Member app completed'
      })

      if(this.selectedLoanType.applicationType == 2 ){
        var id = this.selectedLoanType.id;
        console.log(id)
        switch (id) {
          case 6: // Personal Loans
            gtag('event','personal-loan-completed',{
              'value': 'Personal Loan Completed'
            })
            break;
          case 5 : // Vehicle Loans
            gtag('event', 'vehicle-loan-completed',{
              'value' : 'Vehicle Loan App Completed'
            })
            break;

            case 4 : //Credit Cards
            gtag('event', 'credit-card-completed',{
              'value' : 'Credit Card App Completed'
            })
            break;
          default:
            break;
        }
      }
    }






}
