import { AbstractControl } from '@angular/forms';

export class Util {
  public static getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  public static getErrorMessage(item) {
    if(item.key == "duplicatedSsn"){
      return `Primary Applicant and Joint Applicant's SSN is same, please enter respective SSN.`
    }
    var controlName = item.key.split(';')[1];
    const formatName = controlName.split('_');
    var newStr = '';
    for (let i = 0; i < formatName.length; i++) {
      var element = formatName[i];
      newStr += `${element[0].toUpperCase() + element.slice(1)} `;
    }

    const formatControlName = newStr;
    var msg = ''
    if (item.value.required) {
      msg = 'is required.'
    }
    if (item.value.maxlength) {
      msg = `'s length should be less than ${item.value.maxlength.requiredLength}.`
    }
    if (item.value.ageMax) {
      msg = `, please enter a valid Date of Birth`
    }
    if (item.value.pattern) {
      msg = ` is invalid format.`
    }
   
    return `${formatControlName} ${msg}`;
  }

  public static generateId(){
   return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }
}