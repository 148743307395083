import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Http, Response, RequestOptionsArgs, Headers, RequestOptions, ResponseContentType } from "@angular/http";
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';


@Injectable({
    providedIn: 'root'
})
export class MemberService {

    authService: any ={};

    private options : RequestOptions;

    constructor(
        private http: Http, private commonService: CommonService,
        private httpClient: HttpClient
    ){

        this.options = new RequestOptions();
        var openIdTokenParams = JSON.parse(localStorage.getItem('openIdTokenParams'));
        if (openIdTokenParams && openIdTokenParams.access_token) {
            this.authService.accessToken = openIdTokenParams.access_token;
        }
    }


    private handleError(error: Response){
        return Observable.throw(error.json());
    }


    checkMemberSubmit(){
        return this.http.get(this.commonService.baseUrl + `/api/member/check-member-submit`, this.commonService.getAuthHeader(true)).pipe(
            map( (res: Response)=> {
                return res.json()
            }),
            catchError(this.handleError)
        )
    }

    postUserSession(userSession): Observable<any>{
        return this.http.post(this.commonService.baseUrl + `/api/userSession/post-session-item`, userSession)
    }

    checkIsHuman(token){
        return this.httpClient.get(this.commonService.baseUrl + `/api/member/check-human/?captchaToken=${token}`)
    }
}