<div mat-dialog-title style="text-align: center;">
  <!-- <h2 style="display: contents;">
      e-Sign 
  </h2> -->
</div>

<div mat-dialog-content style="min-height: 80vh; overflow-x: hidden;">
  <div *ngIf="isLoading" style="text-align: center;margin-top: 150px;">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
        Loading...
  </div>
  <div class="second-row {{AppComponent.isDesktop ? '' :'fixed-bottom'}}" *ngIf="!isLoading && docuSignUrl" >
    <iframe  [src]="docuSignUrl | safe" title="e-sign">
    
    </iframe>
  </div>


</div>
