import {Injectable} from "@angular/core";
import * as _ from 'lodash';
import { VEHICLE_APPLICANT, VEHICLE_LOAN } from 'src/models/vehicle_loan.model';
import {  base_phone_country_alpha2, base_employee_of_lender_types, XPRESS_APPLICANT, XPRESS_LOAN, XPRESS_ACCOUNT_TYPESACCOUNT_TYPETerm_type, account_type } from 'src/models/xpress_loan.model';
import { BASE_APPLICANTDeclined_answer_race_gender, CREDITCARD_APPLICANT, CREDITCARD_LOAN } from 'src/models/creditcard_loan.model';
import { PERSONAL_APPLICANT, PERSONAL_LOAN } from 'src/models/personal_loan.model';
import { BASE_CONTACT_INFOPreferred_contact_method, BASE_MONTHLY_DEBT } from 'src/models/common.model';
import * as moment from "moment";


@Injectable(
    {
        providedIn: "root"
    }
)
export class ApplicantService {


    constructor()    {

    }

    initApplicants(){

    }

    initXpressApplicant(){
       var xpressApplicant: XPRESS_APPLICANT ={
        FINANCIAL_INFO : {}as any,
        ID_CARD : {
            card_type: "DRIVERS_LICENSE"
        } as any
       } as any;
       return xpressApplicant;
    }

    initCreditCardApplicant(){
       var creditCardApplicant :CREDITCARD_APPLICANT = {
            is_declined: "N",
            first_name:  "",
            last_name: "",
            m_initial: "",
            middle_name: "",
            ssn: "",
            dob: "",
            ages_of_dependents: "",
            mother_maiden_name: "",
            citizenship: "",
            member_number: "",
            bankruptcy_score: "656",
            membership_months: "0",
            gender: "FEMALE",
            number_in_household: "1",
            credit_score: "700",
            custom_score: "0",
            custom_score_2: "0",
            applicant_type: "P",
            allow_direct_mail: "Y",
            allow_email_solicit: "Y",
            allow_telemarket: "Y",
            allow_share_info: "Y",
            is_ofac_id_ver: "N",
            id_authentication_result: "INCOMPLETE", is_fraud_prescore: "N", is_fraud_fas: "N", is_fraud_credit: "N", is_ofac_credit: "N", combined_unsecured_aggregate_amount_with_lender: 0.00, borrower_id: "0",
            CONTACT_INFO: {
              preferred_contact_method: BASE_CONTACT_INFOPreferred_contact_method.CELL,
              cell_phone: "",
              cell_phone_country: base_phone_country_alpha2.US,
              fax_number: "",
              fax_number_country: base_phone_country_alpha2.US,
              home_phone: "",
              home_phone_country: base_phone_country_alpha2.US,
              work_phone: "", work_phone_extension: "",
              work_phone_country: base_phone_country_alpha2.US, email: ""
            } as any,
            CURRENT_ADDRESS: {
  
            },
            MAILING_ADDRESS:{
             
            },
            FINANCIAL_INFO: {
              CURRENT_EMPLOYMENT: [{
                employment_start_date: new Date(),
                employed_months: "0",
                employment_address: "",
                employment_city: "",
                employment_state: "",
                employment_zip: "",
                occupation: "",
                // employer: "APPLE",
                employment_phone: "",
                //employment_status: BASE_EMPLOYMENTEmployment_status.GOV,
                employment_weekly_hours: 0,
                is_employee_of_lender: BASE_APPLICANTDeclined_answer_race_gender.N,
                employee_of_lender_type: base_employee_of_lender_types.NONE,
                profession_months: "0"
              } as any],
              PREVIOUS_EMPLOYMENT: [{
                monthly_income: 0, employed_months: "0", employment_address: "", employment_city: "", employment_state: "", occupation: "", employer: "", employment_phone: "", employment_weekly_hours: 0
              } as any],
              MONTHLY_DEBT: new BASE_MONTHLY_DEBT(),
              MONTHLY_INCOME: {
                is_monthly_income_base_salary_manual: BASE_APPLICANTDeclined_answer_race_gender.Y,
                // monthly_income_base_salary: 0,
                is_tax_exempt_monthly_income_base_salary: BASE_APPLICANTDeclined_answer_race_gender.N,
                monthly_income_over_time: 0,
                monthly_income_bonus: 0,
                income_verify_method: ""
              } as any
            }
          } as any;

        return creditCardApplicant;
    }

    initVehicleApplicant(){
        var vehicleApplicant : VEHICLE_APPLICANT = {
          CONTACT_INFO: {
            preferred_contact_method: BASE_CONTACT_INFOPreferred_contact_method.CELL,
            cell_phone: "",
            cell_phone_country: base_phone_country_alpha2.US,
            fax_number: "",
            fax_number_country: base_phone_country_alpha2.US,
            home_phone: "",
            home_phone_country: base_phone_country_alpha2.US,
            work_phone: "", work_phone_extension: "",
            work_phone_country: base_phone_country_alpha2.US, email: ""
          } as any,
          CURRENT_ADDRESS: {

          },
          // MAILING_ADDRESS:{
           
          // },
          FINANCIAL_INFO: {
            CURRENT_EMPLOYMENT: [{
              employment_start_date: new Date(),
              employed_months: "0",
              employment_address: "",
              employment_city: "",
              employment_state: "",
              employment_zip: "",
              occupation: "",
              employment_phone: "",
              employment_status:0,
              employment_weekly_hours: 0,
              is_employee_of_lender: BASE_APPLICANTDeclined_answer_race_gender.N,
              employee_of_lender_type: base_employee_of_lender_types.NONE,
              profession_months: "0"
            } as any],
            PREVIOUS_EMPLOYMENT: [{
              monthly_income: 0, employed_months: "0", employment_address: "", employment_city: "", employment_state: "", occupation: "", employer: "", employment_phone: "", employment_weekly_hours: 0
            } as any],
            MONTHLY_DEBT: new BASE_MONTHLY_DEBT(),
            MONTHLY_INCOME: {
              is_monthly_income_base_salary_manual: BASE_APPLICANTDeclined_answer_race_gender.Y,
              // monthly_income_base_salary: 0,
              is_tax_exempt_monthly_income_base_salary: BASE_APPLICANTDeclined_answer_race_gender.N,
              monthly_income_over_time: 0,
              monthly_income_bonus: 0,
              income_verify_method: ""
            } as any
          }
        } as any;
        return vehicleApplicant;
    }
    
    initPersonalApplicant(){
        var personalApplicant : PERSONAL_APPLICANT = {
          CONTACT_INFO: {
            preferred_contact_method: BASE_CONTACT_INFOPreferred_contact_method.CELL,
            cell_phone: "",
            cell_phone_country: base_phone_country_alpha2.US,
            fax_number: "",
            fax_number_country: base_phone_country_alpha2.US,
            home_phone: "",
            home_phone_country: base_phone_country_alpha2.US,
            work_phone: "", work_phone_extension: "",
            work_phone_country: base_phone_country_alpha2.US, email: ""
          } as any,
          CURRENT_ADDRESS: {

          },
  
          FINANCIAL_INFO: {
            CURRENT_EMPLOYMENT: [{
              employment_start_date: new Date(),
              employed_months: "0",
              employment_address: "",
              employment_city: "",
              employment_state: "",
              employment_zip: "",
              occupation: "",
              employment_phone: "",
              employment_status:0,
              employment_weekly_hours: 0,
              is_employee_of_lender: BASE_APPLICANTDeclined_answer_race_gender.N,
              employee_of_lender_type: base_employee_of_lender_types.NONE,
              profession_months: "0"
            } as any],
            PREVIOUS_EMPLOYMENT: [{
              monthly_income: 0, employed_months: "0", employment_address: "", employment_city: "", employment_state: "", occupation: "", employer: "", employment_phone: "", employment_weekly_hours: 0
            } as any],
            MONTHLY_DEBT: new BASE_MONTHLY_DEBT(),
            MONTHLY_INCOME: {
              is_monthly_income_base_salary_manual: BASE_APPLICANTDeclined_answer_race_gender.Y,
              // monthly_income_base_salary: 0,
              is_tax_exempt_monthly_income_base_salary: BASE_APPLICANTDeclined_answer_race_gender.N,
              monthly_income_over_time: 0,
              monthly_income_bonus: 0,
              income_verify_method: ""
            } as any
          }
        } as any;

        return personalApplicant;
    }

    fillOtherApplicants(xpressApplicant, vehicleApplicant, personalApplicant, creditCardApplicant){
        var commonFields = ['first_name', 'last_name', 'dob','CURRENT_ADDRESS','PREVIOUS_ADDRESS','MAILING_ADDRESS','mother_maiden_name', 'FOM_ANSWERED_QUESTIONS'];
        _.each(commonFields, f=>{
          try{
          xpressApplicant[f] = vehicleApplicant[f] = personalApplicant[f] = creditCardApplicant[f];
          }catch{}
        })
         xpressApplicant.preferred_contact_method = vehicleApplicant.CONTACT_INFO.preferred_contact_method =  personalApplicant.CONTACT_INFO.preferred_contact_method = creditCardApplicant.CONTACT_INFO.preferred_contact_method;
         console.log(vehicleApplicant.CONTACT_INFO.preferred_contact_method )
         xpressApplicant.preferred_contact_methodSpecified = true
         xpressApplicant.email= vehicleApplicant.CONTACT_INFO.email = personalApplicant.CONTACT_INFO.email  = creditCardApplicant.CONTACT_INFO.email;
         xpressApplicant.cell_phone = vehicleApplicant.CONTACT_INFO.cell_phone=  personalApplicant.CONTACT_INFO.cell_phone= creditCardApplicant.CONTACT_INFO.cell_phone;
         xpressApplicant.home_phone = vehicleApplicant.CONTACT_INFO.home_phone = personalApplicant.CONTACT_INFO.home_phone =creditCardApplicant.CONTACT_INFO.home_phone;
         xpressApplicant.work_phone = vehicleApplicant.CONTACT_INFO.work_phone = personalApplicant.CONTACT_INFO.work_phone =creditCardApplicant.CONTACT_INFO.work_phone;
         xpressApplicant.FINANCIAL_INFO.monthly_income_base_salary= 
         vehicleApplicant.FINANCIAL_INFO.MONTHLY_INCOME.monthly_income_base_salary = personalApplicant.FINANCIAL_INFO.MONTHLY_INCOME.monthly_income_base_salary =  creditCardApplicant.FINANCIAL_INFO.MONTHLY_INCOME.monthly_income_base_salary;


         var employmentFields = ['employment_status', 'employer', 'occupation','pay_grade','employed_months', 'employment_start_date','employment_business_type', 'employee_of_lender_type','employee_of_lender_typeSpecified'];
         _.each(employmentFields, f=>{
          try{
          xpressApplicant.FINANCIAL_INFO[f] =  vehicleApplicant.FINANCIAL_INFO.CURRENT_EMPLOYMENT[0][f] = personalApplicant.FINANCIAL_INFO.CURRENT_EMPLOYMENT[0][f] = creditCardApplicant.FINANCIAL_INFO.CURRENT_EMPLOYMENT[0][f];
          }catch{}
        })

        xpressApplicant.FINANCIAL_INFO["monthly_liability"]  =  vehicleApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_liability"] = personalApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_liability"] = creditCardApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_liability"];

        creditCardApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_housing_cost"] = creditCardApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_rent"] =  creditCardApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_liability"];

        xpressApplicant.FINANCIAL_INFO["monthly_rent"]  =  vehicleApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_rent"] = personalApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_rent"] = creditCardApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_rent"];
         vehicleApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_housing_cost"] = personalApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_housing_cost"] = creditCardApplicant.FINANCIAL_INFO.MONTHLY_DEBT["monthly_housing_cost"];

      }

      initInterestedAccounts(selectedProducts){
        selectedProducts = _.filter(selectedProducts,p=>{
          return  p.applicationType ==1;
        })
        var interestedAccounts = _.map(selectedProducts, p=>{
          
          var acc =
          {
            "min_deposit": p.minDeposit,
            "account_name":  p.name,
            "account_type":  p.accountType,
            "account_number": "",
            "rate": p.rate || 0,
            "apy": p.apy || 0,
            "suffix":"",
            "is_rate_override": "N",
            //"clf_approved_account_type_id": "3",
            "interest_rate_type": "FIXED",
            "cd_additions_permitted": "N",
            "cd_renewability": "",
            "term_type": "M",
            "term": p.maxTerm || 0,
            "termSpecified" : !!p.maxTerm,
            "product_code": p.productCode
          } as any;
          if(p.maxTerm && p.productGroupId == 3){
            acc.maturity_date= moment().add(p.maxTerm,'month').toDate();
            acc.maturity_dateSpecified = true;
          }else{
            acc.maturity_date= new Date();
            acc.maturity_dateSpecified = false;
          }

          return acc;

        })

        return interestedAccounts;
      }

}