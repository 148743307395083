<div style="text-align: center; position: relative;" mat-dialog-close>
  <span class="btn-close">
    <mat-icon>close</mat-icon>
  </span>
</div>

<section class="cc-wrapper row" mat-dialog-content style="min-height: 50vh;overflow: hidden;">

  <form class="cc-form" [formGroup]="bankForm" >
  <div class="col-md-12">
    <mat-form-field appearance="outline">
      <mat-label>Account Type</mat-label>
      <mat-select formControlName="bankAccountType" [(ngModel)]="fundingSource.bank_account_type" required>
        <mat-option *ngFor="let item of bankAccountTypes | keys" [value]="item.key">
          {{item.value[0] + item.value.slice(1).toLowerCase()}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md-12">
    <mat-form-field appearance="outline">
      <mat-label>Name On Account</mat-label>
      <input formControlName="bank_name_on_card" required matInput [(ngModel)]="fundingSource.bank_name_on_card">
    </mat-form-field>
  </div>

  <div class="col-md-12">
    <mat-form-field appearance="outline">
      <mat-label>Account Number</mat-label>
      <input formControlName="bank_account_number" required matInput [(ngModel)]="fundingSource.bank_account_number">
    </mat-form-field>
  </div>

  <div class="col-md-12">
    <span class="alert alert-danger" *ngIf="invalidRoutingNumnerMsg">{{invalidRoutingNumnerMsg}}</span>
    <mat-form-field appearance="outline">
      <mat-label>Routing Number</mat-label>
      <input formControlName="routing_number" required matInput [(ngModel)]="fundingSource.routing_number" (focusout)="getBank()">
      
    </mat-form-field>

  </div>
  <div class="col-md-12">
    <mat-form-field appearance="outline">
      <mat-label>Financial Institution</mat-label>
      <input formControlName="bank_bankname" matInput required [(ngModel)]="fundingSource.bank_bankname">
    </mat-form-field> 
  </div>
   <div class="col-md-12">
    <mat-form-field appearance="outline">
      <mat-label>Financial Institution State</mat-label>
      <input style="text-transform: uppercase;" formControlName="bank_bankstate" required matInput [(ngModel)]="fundingSource.bank_bankstate"
        mask="AA" (ngModelChange)="checkBankState($event)" type="text"
      >
      <mat-error *ngIf="bankForm.controls['bank_bankstate'].errors">
        Invalid State(only abbreviation needed)
      </mat-error>
    </mat-form-field>
  </div> 
</form>

</section>

<div class="row" style="margin: 0 auto 40px;" mat-dialog-actions>
  <div class="col-md-12">
    <button [disabled]="bankForm.invalid || invalidRoutingNumnerMsg" (click)="submit()" class="btn btn-custom btn-continue btn-block">Submit</button>
  </div>
</div>
