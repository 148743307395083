import { Component, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicantLoanComponent } from 'src/app/applicant-loan/applicant-loan.component';


@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialog {
  public confirmMessage: string;
  ApplicantLoanComponent = ApplicantLoanComponent;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.confirmMessage = data.confirmMessage;
  }

}