import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute } from '@angular/router';
import { ApplicantLoanComponent } from 'src/app/applicant-loan/applicant-loan.component';

@Component({
  selector: 'app-docu-sign-callback',
  templateUrl: './docu-sign-callback.component.html',
  styleUrls: ['./docu-sign-callback.component.css']
})
export class DocuSignCallbackComponent implements OnInit {

  eventName ="";
  ApplicantLoanComponent = ApplicantLoanComponent;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.eventName = this.activatedRoute.snapshot.queryParams["a"];
  }

  close(){
    AppComponent.docuSignCallbacked.emit(null);
  }


}
