import { Component, OnInit, Inject, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { CREDITCARD_APPLICANT, BASE_APPLICANTDeclined_answer_race_gender } from 'src/models/creditcard_loan.model';
import { XPRESS_APPLICANT, base_phone_country_alpha2, base_employee_of_lender_types } from 'src/models/xpress_loan.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApplicantService } from 'src/shared/applicant.service';
import { VEHICLE_APPLICANT } from 'src/models/vehicle_loan.model';
import { PERSONAL_APPLICANT } from 'src/models/personal_loan.model';
import { ValidationErrors } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';
import * as _ from 'lodash';
import { Util } from 'src/shared/util';
import * as  $ from 'jquery';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialog } from 'src/components/confirmation-dialog/confirmation-dialog.component';
import { ApplicantLoanComponent } from 'src/app/applicant-loan/applicant-loan.component';

@Component({
  selector: 'app-joint-applicant-modal',
  templateUrl: './joint-applicant-modal.component.html',
  styleUrls: ['./joint-applicant-modal.component.css']
})
export class JointApplicantModalComponent implements OnInit {

  creditCardApplicant: CREDITCARD_APPLICANT;
  xpressApplicant: XPRESS_APPLICANT ;
  mainApp: XPRESS_APPLICANT;
  vehicleApplicant: VEHICLE_APPLICANT;
  personalApplicant: PERSONAL_APPLICANT;
  eligibilityModel:any;
  creditJointCheckboxModel= false;
  selectedProducts =[];

  isEdit = false;
  formIsValid = true;
  validationErrorsJointApp: ValidationErrors= {};
  util = Util;
  AppComponent = AppComponent;
  ApplicantLoanComponent = ApplicantLoanComponent;
  public static copyAddressFromMainApp: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private pl: Platform,
    private applicantService: ApplicantService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<JointApplicantModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private changeDetector: ChangeDetectorRef) {
    this.isEdit = data.isEdit;
    this.selectedProducts= data.selectedProducts;

    this.eligibilityModel = this.data.eligibilityModel || { qualifyingEmployer:{}};
    this.mainApp= data.mainApp;
    if(this.isEdit){
      this.creditCardApplicant = data.creditCardApplicant;
      this.xpressApplicant = data.xpressApplicant;
    }else{
      this.creditCardApplicant = this.applicantService.initCreditCardApplicant();
      this.xpressApplicant = this.applicantService.initXpressApplicant();
      
      this.vehicleApplicant = this.applicantService.initVehicleApplicant();
      this.personalApplicant = this.applicantService.initPersonalApplicant();
      
    }
  }

  ngOnInit(): void {
    JointApplicantModalComponent.copyAddressFromMainApp.subscribe(r=>{
      this.creditCardApplicant.CURRENT_ADDRESS = _.cloneDeep(r.CURRENT_ADDRESS);
    })
  }

  save(){


    this.applicantService.fillOtherApplicants(this.xpressApplicant, this.vehicleApplicant, this.personalApplicant, this.creditCardApplicant);

    if(this.isEdit){
      this.updateApplicant();
    }else{
      this.saveJointApp();
    }
  }

  saveJointApp(){

    this.formIsValid = true;
    _.forOwn(this.validationErrorsJointApp, (value, key)=>{
      if(value){
        this.formIsValid = false;
      }
    })

    if(!this.formIsValid) return;

    this.dialogRef.close({
      creditCardApplicant: this.creditCardApplicant,
      xpressApplicant: this.xpressApplicant,
      personalApplicant: this.personalApplicant,
      vehicleApplicant: this.vehicleApplicant,
      creditJointCheckboxModel: this.creditJointCheckboxModel
    });
  }

  updateApplicant(){

  }

  focusControl(key){
    var idContainer = key.split(';')[0];
    var name = key.split(';')[1];
    $(`.jointApplicant #${idContainer} *[formControlName="${name}"]`)[0].focus()
  }

  close(){

    let dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      scrollStrategy:  AppComponent.scrollStrategy,
      data: {
        confirmMessage: "If you proceed then your form won't be saved and you will have to refill the form.",
      },
      panelClass: AppComponent.isDesktop ? 'col-md-4' : 'full-screen',
      autoFocus: false,
      hasBackdrop: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close();
      }
    });

    
  }

  hasProductType2(){
    return  !!_.find(this.selectedProducts, { applicationType: 2 });
  }

  checkValid(){
    if(this.mainApp.ssn === this.xpressApplicant.ssn)
    {
      //
    

      this.validationErrorsJointApp.duplicatedSsn =  {
        'hasSamessn': 'hasSamessn'
       } as ValidationErrors;
    
  
      return true;
    }
    
    else{
      delete  this.validationErrorsJointApp.duplicatedSsn ;
   }
    return true;
  }

}
