import { Injectable } from "@angular/core";
import { Http, Response, RequestOptionsArgs, Headers, RequestOptions, ResponseContentType } from "@angular/http";
import { Observable, Subscriber } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FinalPageComponent } from "src/components/final-page/final-page.component";
import { environment } from 'src/environments/environment';
import { BASE_CONTACT_INFOPreferred_contact_method } from "src/models/common.model";
import { XPRESS_APPLICANT } from "src/models/xpress_loan.model";
import { CommonService } from './common.service';
declare var Plaid:any;

@Injectable({
    providedIn: 'root'
})
export class PlaidService {

    authService: any ={};
    private _baseUrl = "";
    private options : RequestOptions;

    constructor(
        private http: Http, private commonService: CommonService
    ){
        this._baseUrl  = environment.baseUrl + "/api/";
        this.options = new RequestOptions();
        var openIdTokenParams = JSON.parse(localStorage.getItem('openIdTokenParams'));
        if (openIdTokenParams && openIdTokenParams.access_token) {
            this.authService.accessToken = openIdTokenParams.access_token;
        }
    }


    private handleError(error: Response){
        return Observable.throw(error.json());
    }


    createDepositSwitchAlt(depositSwitch){
        return this
        .http
        .post(this._baseUrl + "plaid/create-deposit-switch-alt", depositSwitch, this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
    }

    exchangeToken(publicToken){
        return this
        .http
        .get(this._baseUrl + "plaid/exchange-token/"+publicToken , this.commonService.getAuthHeader(true)).pipe(
        map((response : Response) => {
            return response.json();
        })
        ,
        catchError(this.handleError)
        )
    }

    showPlaidDeposit(applicant: XPRESS_APPLICANT, account_number: string): Observable<any>{

        var phone = "";
        if(applicant.preferred_contact_method == BASE_CONTACT_INFOPreferred_contact_method.CELL) {phone = applicant.cell_phone}
        if(applicant.preferred_contact_method == BASE_CONTACT_INFOPreferred_contact_method.HOME) {phone = applicant.home_phone}
        if(applicant.preferred_contact_method == BASE_CONTACT_INFOPreferred_contact_method.WORK) {phone = applicant.work_phone}

        var depositSwitch = {

            "target_account": {
               "account_number": account_number, //"000031567",
            },
            "target_user": {
               "given_name": applicant.first_name,
               "family_name": applicant.last_name,
               "phone": phone,
               "address": {
                 "street": applicant.CURRENT_ADDRESS.LOOSE_ADDRESS.street_address_1,
                 "city": applicant.CURRENT_ADDRESS.LOOSE_ADDRESS.city,
                 "region": applicant.CURRENT_ADDRESS.LOOSE_ADDRESS.state,
                 "postal_code": applicant.CURRENT_ADDRESS.LOOSE_ADDRESS.zip,
                 "country":"US"
                },
               "email": applicant.email,
             }
          }

          return new Observable(subscriber =>{
            this.createDepositSwitchAlt(depositSwitch).subscribe(r=>{
                const handler = Plaid.create({
                  token: r.link_token,
                  onSuccess: (public_token, metadata) => {
                    console.log(public_token);
                    console.log(metadata)
                    subscriber.next(metadata);
                    FinalPageComponent.isLoading = false;
    
                    //it's done here. we don't need to get access_token
    
                    // this.exchangeToken(public_token).subscribe(exToken=>{
                    //   console.log('result: ' + JSON.stringify(exToken))
                    //   console.log('access_token: ' + exToken.access_token)
                    // })
          
                  },
                  onLoad: () => {},
                  onExit: (err, metadata) => {
                    subscriber.error(metadata);
                    FinalPageComponent.isLoading = false;
                  },
                  onEvent: (eventName, metadata) => {
                    console.log(eventName)
                  },
                  receivedRedirectUri: null,
                });
                //console.log(handler)
                setTimeout(()=>{
                  handler.open()
                  console.log(handler)
                },1)
              })
          })

         
    }

}