<mat-card class="example-card">
    <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle>
    <mat-card-title>Shiba Inu</mat-card-title> -->
    <mat-card-content>
      <p>Please wait while we submit your application. This may take a few minutes.</p>
      <!-- <p>{{ loadingMessage }}</p> -->
    </mat-card-content>
    <mat-divider inset></mat-divider>

    <mat-card-footer>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
