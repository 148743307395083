import { Component, HostListener, EventEmitter } from '@angular/core';
import { AuthService } from 'src/shared/auth.service';
import { CommonService } from 'src/shared/common.service';
import { Platform } from '@angular/cdk/platform';
import { PlatformLocation } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/components/confirmation-dialog/confirmation-dialog.component';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { ApplicantLoanComponent } from './applicant-loan/applicant-loan.component';
import { ApiService } from 'src/shared/api.service';
import { AtomicService } from 'src/shared/atomic.service';
import * as _ from 'lodash';
import { FUNDDEPOSIT_INPUTREQUEST } from 'src/models/fund-deposit.model';
import { NmaoWfService } from 'src/shared/nmao-wf.service';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
(window as any).global = window;
import * as  $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'so-client-app';
  public static isDesktop: Boolean;
  public static nmaoConfigs: any ={};
  public static QualificationAssessment =[];
  MOBILE_WIDTH = 768;
  public static scrollStrategy: ScrollStrategy;
  public static direct_deposit = true;
  public static direct_deposit_shown_failed = false;
  public static direct_deposit_answer="";
  public static direct_deposit_subtype="";
  public static direct_deposit_subtypes = [];
  public static  xaAppSubmittedSuccess: EventEmitter<string> = new  EventEmitter<string>();

  public static docuSignCallbacked: EventEmitter<any> = new EventEmitter<any>();
  isCoBrand = false;
  ApplicantLoanComponent = ApplicantLoanComponent;

  logger = function()
  {
      var oldConsoleLog = null;
      var pub = {} as any;

      pub.enableLogger =  function enableLogger() 
                          {
                              if(oldConsoleLog == null)
                                  return;

                              window['console']['log'] = oldConsoleLog;
                          };

      pub.disableLogger = function disableLogger()
                          {
                              oldConsoleLog = console.log;
                              window['console']['log'] = function() {};
                          };

      return pub;
  }();

  public constructor(private authService: AuthService,
    private readonly sso: ScrollStrategyOptions, 
    location: PlatformLocation,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private atomicLog: AtomicService,
    private nmaoWfService: NmaoWfService,
    public commonService: CommonService, private pl: Platform) {
      AppComponent.scrollStrategy = this.sso.noop(); // or close()/block()/reposition()
    this.initializeApp();
    location.onPopState(() => {

      // var r = confirm("You pressed a Back button! Are you sure?!");

      let dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true,
        scrollStrategy:  AppComponent.scrollStrategy,
        data: {
          confirmMessage: "If you proceed then your form won't be saved and you will have to refill the form.",
        },
        panelClass: AppComponent.isDesktop ? 'col-md-6' : 'full-screen',
        autoFocus: false,
        hasBackdrop: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
                  // Call Back button programmatically as per user confirmation.
                  history.go(-2);
                  // Uncomment below line to redirect to the previous page instead.
                  // window.location.href = document.referrer // Note: IE11 is not supporting this.
        }else{
          history.pushState(null, null, window.location.pathname);
        }
        history.pushState(null, null, window.location.pathname);
      });
      
    });
    this.activatedRoute.queryParams.subscribe(params => {
      ApplicantLoanComponent.refChannel = params['ref'];
      this.apiService.getChannelByCurrentUri(ApplicantLoanComponent.refChannel).subscribe(r=>{
        if(r){
          ApplicantLoanComponent.currentChannel = r;
          this.isCoBrand = r.channelTypeId == 3;
        }
      })
    });
   

  }

  ngAfterViewInit(){


  }

  public static init_direct_deposit_subtypes(products){
    var productTypesCheck = ['savings','checking']
    AppComponent.direct_deposit_subtypes =  _.map(
      _.filter(products, pr=> {return pr.isDDAQualified && productTypesCheck.indexOf(pr.productType.toLowerCase()) > -1 })
      , i=>{
      return {name: i.name, value:i.name}
    })

    AppComponent.direct_deposit_subtype = AppComponent.direct_deposit_subtypes[0].name;
  }

  initializeApp() {


      // AppComponent.isDesktop = (!this.pl.isBrowser && (this.pl.ANDROID || this.pl.IOS)) ? false : true;
      AppComponent.isDesktop = (this.pl.isBrowser && window.innerWidth > this.MOBILE_WIDTH) ? true : false;
      this.apiService.getNmaoConfigurations().subscribe(r=>{
        AppComponent.nmaoConfigs = r;
        var logVar = AppComponent.nmaoConfigs.clientLog.consoleLog;
        if(logVar){
          this.logger.enableLogger();
          console.log('log enabled');
        }else{
          this.logger.disableLogger();
        }
      });

      this.apiService.getQualificationAssessment().subscribe(r=>{
        AppComponent.QualificationAssessment = r;
      })

      AppComponent.xaAppSubmittedSuccess.subscribe((r: any)=>{
        //create atomic log
        var outputJsonObj = JSON.parse(r.outputJson);
        var XAId =outputJsonObj.OUTPUT.RESPONSE['@loan_id'];
        let atomicLog = {
          appId: XAId,
          didUserOpted: AppComponent.direct_deposit_answer == 'Yes',
          isAtomicReady: AppComponent.direct_deposit_answer != ''
        }
        this.atomicLog.createAtomicLog(atomicLog).subscribe(r=>{})
        
      })

  }


}
