import { BASE_ADDRESS_LOOSE } from './base-address-loose.model';
import { FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTIONFOM_ANSWER, FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTION, BASE_EMPLOYMENTEmployment_status, BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION, BASE_MONTHLY_DEBT, BASE_LOAN_INFO, BASE_EMPLOYMENT, BASE_CONTACT_INFOPreferred_contact_method, base_applicant_type } from './common.model';

    export class VEHICLE_LOANAPPLICANTS {
        public APPLICANT: VEHICLE_APPLICANT[];
        public selected_product_borrower_id: string;
    }


    export enum VEHICLE_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status {
        LIVEWITHPARENTS,

        OWN,

        RENT,

        BUYING,

        OTHER,

        GOVQUARTERS,

        BARRACKS,

        LEASE,

        SUBLEASE,

        PERSONALRESIDENCE,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_CURRENT_ADDRESS_CHOICE {
        public Item: Object;
        public LOOSE_ADDRESS: BASE_ADDRESS_LOOSE;
    }

    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_BUSINESS_INFORMATIONS {
        public business_name: string;
        public business_trade_name: string;
        public business_industry: string;
        public number_of_employees: string;
        public business_tax_id: string;
        public business_type: VEHICLE_BUSINESS_INFORMATIONSBusiness_type;
        public business_typeSpecified: boolean;
        public establish_date: Date;
        public establish_dateSpecified: boolean;
        public months_in_business: string;
        public is_borrower_prepared: BASE_APPLICANTDeclined_answer_race_gender;
        public is_borrower_preparedSpecified: boolean;
        public is_cpa_prepared: BASE_APPLICANTDeclined_answer_race_gender;
        public is_cpa_preparedSpecified: boolean;
        public is_income_tax_statements_available: BASE_APPLICANTDeclined_answer_race_gender;
        public is_income_tax_statements_availableSpecified: boolean;
    }
    export enum VEHICLE_BUSINESS_INFORMATIONSBusiness_type {
        //[Xml.Serialization.XmlEnumAttribute("")]
        Item,

        CORP,

        LLC,

        PARTNER,

        PROPRIETORSHIP,

        OTHER
    }
    export enum BASE_APPLICANTDeclined_answer_race_gender {
        Y,

        N,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FINANCING {
        public Item: Object;
        public TITLE: VEHICLE_FINANCINGTITLE;
        public INSURANCE: VEHICLE_FINANCINGINSURANCE;
        public mileage: string;
        public is_new_vehicle: BASE_APPLICANTDeclined_answer_race_gender;
        public is_new_vehicleSpecified: boolean;
        public vehicle_sales_price: number;
        public vehicle_sales_priceSpecified: boolean;
        public vehicle_value: number;
        public vehicle_valueSpecified: boolean;
        public vehicle_value_type: string;
        public is_rebuilt_restored: BASE_APPLICANTDeclined_answer_race_gender;
        public is_rebuilt_restoredSpecified: boolean;
        public processing_type: VEHICLE_FINANCINGProcessing_type;
        public processing_typeSpecified: boolean;
        public vehicle_options: string;
        public value_type: VEHICLE_FINANCINGValue_type;
        public value_typeSpecified: boolean;
        public vin: string;
        public vehicle_type: VEHICLE_TRADE_INVehicle_type;
        public vehicle_typeSpecified: boolean;
        public year: string;
        public make: string;
        public model: string;
        public empty_weight: number;
        public empty_weightSpecified: boolean;
        public carrying_capacity: number;
        public carrying_capacitySpecified: boolean;
        public length: number;
        public lengthSpecified: boolean;
        public license: string;
        public nada_info: string;
        public body_type: string;
        public commercial_title: VEHICLE_FINANCINGCommercial_title;
        public commercial_titleSpecified: boolean;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FINANCINGCLEAR_TITLE {
        public current_name_on_title: string;
        public current_name_on_title2: string;
        public new_names_on_title: string;
        public new_names_on_title2: string;
        public name_for_collateral_purposes: string;
        public name_for_collateral_purposes2: string;
        public current_name_on_title_contact_index: string;
        public current_name_on_title_contact_index2: string;
        public new_names_on_title_contact_index: string;
        public new_names_on_title_contact_index2: string;
        public name_for_collateral_purposes_contact_index: string;
        public name_for_collateral_purposes_contact_index2: string;
        public state: string;
        public aka: string;
        public business_tax_id: string;
        public title_recording_fees: number;
        public title_recording_feesSpecified: boolean;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FINANCINGDEALERSHIP_PROCESSING {
        public name: string;
        public is_on_watchlist: BASE_APPLICANTDeclined_answer_race_gender;
        public is_on_watchlistSpecified: boolean;
        public watchlist_reason: string;
        public dealer_number: string;
        public address: string;
        public county: string;
        public zip: string;
        public city: string;
        public state: string;
        public phone: string;
        public fax: string;
        public name_on_title: string;
        public name_on_title2: string;
        public name_for_title_purpose: string;
        public name_for_title_purpose2: string;
        public name_on_title_contact_index: string;
        public name_on_title_contact_index2: string;
        public name_for_title_purpose_contact_index: string;
        public name_for_title_purpose_contact_index2: string;
        public title_state: string;
        public aka: string;
        public business_tax_id: string;
        public title_recording_fees: number;
        public title_recording_feesSpecified: boolean;
        public reserve_type: VEHICLE_FINANCINGDEALERSHIP_PROCESSINGReserve_type;
        public reserve_typeSpecified: boolean;
        public reserve_value: number;
        public reserve_valueSpecified: boolean;
        public primary_reserve_type: VEHICLE_FINANCINGDEALERSHIP_PROCESSINGPrimary_reserve_type;
        public primary_reserve_typeSpecified: boolean;
        public primary_reserve_value: number;
        public primary_reserve_valueSpecified: boolean;
        public secondary_reserve_type: VEHICLE_FINANCINGDEALERSHIP_PROCESSINGSecondary_reserve_type;
        public secondary_reserve_typeSpecified: boolean;
        public secondary_reserve_value: number;
        public secondary_reserve_valueSpecified: boolean;
        public reserve_selection_type: VEHICLE_FINANCINGDEALERSHIP_PROCESSINGReserve_selection_type;
        public reserve_selection_typeSpecified: boolean;
        public bank_account_number: string;
        public routing_number: string;
        public bank_account_type: string;
        public reference_number: string;
        public main_contact: string;
        public contact_email: string;
    }
    export enum VEHICLE_FINANCINGDEALERSHIP_PROCESSINGReserve_type {
        //[Xml.Serialization.XmlEnumAttribute("")]
        Item,

        FLAT_FEE,

        RESERVE,

        PERC_FIN,

        TPF,

        TFF,

        TPFBS
    }
    export enum VEHICLE_FINANCINGDEALERSHIP_PROCESSINGPrimary_reserve_type {
        //[Xml.Serialization.XmlEnumAttribute("")]
        Item,

        FLAT_FEE,

        RESERVE,

        PERC_FIN,

        TPF,

        TFF,

        TPFBS
    }
    export enum VEHICLE_FINANCINGDEALERSHIP_PROCESSINGSecondary_reserve_type {
        //[Xml.Serialization.XmlEnumAttribute("")]
        Item,

        FLAT_FEE,

        RESERVE,

        PERC_FIN,

        TPF,

        TFF,

        TPFBS
    }
    export enum VEHICLE_FINANCINGDEALERSHIP_PROCESSINGReserve_selection_type {
        //[Xml.Serialization.XmlEnumAttribute("")]
        Item,

        HIGH,

        LOW
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FINANCINGHOLDS_TITLE {
        public current_name_on_title: string;
        public current_name_on_title2: string;
        public account_number_paidoff: string;
        public new_names_on_title: string;
        public new_names_on_title2: string;
        public name_for_collateral_purpose: string;
        public name_for_collateral_purpose2: string;
        public current_name_on_title_contact_index: string;
        public current_name_on_title_contact_index2: string;
        public new_names_on_title_contact_index: string;
        public new_names_on_title_contact_index2: string;
        public name_for_collateral_purpose_contact_index: string;
        public name_for_collateral_purpose_contact_index2: string;
        public state: string;
        public aka: string;
        public title_recording_fees: number;
        public title_recording_feesSpecified: boolean;
        public title_retained_by: string;
        public title_retained_date: string;
        public title_location: string;
        public business_tax_id: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FINANCINGMEMBER_LIEN_PROCESSING {
        public current_name_on_title: string;
        public current_name_on_title2: string;
        public new_names_on_title: string;
        public new_names_on_title2: string;
        public name_for_collateral_purposes: string;
        public name_for_collateral_purposes2: string;
        public current_name_on_title_contact_index: string;
        public current_name_on_title_contact_index2: string;
        public new_names_on_title_contact_index: string;
        public new_names_on_title_contact_index2: string;
        public name_for_collateral_purposes_contact_index: string;
        public name_for_collateral_purposes_contact_index2: string;
        public state: string;
        public aka: string;
        public business_tax_id: string;
        public title_recording_fees: number;
        public title_recording_feesSpecified: boolean;
        public seller_first_name: string;
        public seller_middle_name: string;
        public seller_name_contact_index: string;
        public seller_name_contact_index2: string;
        public seller_last_name: string;
        public seller_suffix: string;
        public seller_first_name2: string;
        public seller_middle_name2: string;
        public seller_last_name2: string;
        public seller_suffix2: string;
        public seller_address: string;
        public seller_county: string;
        public seller_zip: string;
        public seller_city: string;
        public seller_state: string;
        public seller_home_phone: string;
        public seller_work_phone: string;
        public seller_ssn4: string;
        public seller_dob: Date;
        public seller_dobSpecified: boolean;
        public lienholder_information: string;
        public lienholder_account_name: string;
        public lienholder_account_name_contact_index: string;
        public lienholder_account_name2: string;
        public lienholder_account_name_contact_index2: string;
        public lienholder_account_number: string;
        public lienholder_address: string;
        public lienholder_county: string;
        public lienholder_zip: string;
        public lienholder_city: string;
        public lienholder_state: string;
        public lienholder_payoff: number;
        public lienholder_payoffSpecified: boolean;
        public lienholder_payoff_date: Date;
        public lienholder_payoff_dateSpecified: boolean;
        public lienholder_payoff_per_diem: number;
        public lienholder_payoff_per_diemSpecified: boolean;
        public lienholder_phone: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FINANCINGMEMBER_PROCESSING {
        public current_name_on_title: string;
        public current_name_on_title2: string;
        public new_names_on_title: string;
        public new_names_on_title2: string;
        public name_for_collateral_purposes: string;
        public name_for_collateral_purposes2: string;
        public current_name_on_title_contact_index: string;
        public current_name_on_title_contact_index2: string;
        public new_names_on_title_contact_index: string;
        public new_names_on_title_contact_index2: string;
        public name_for_collateral_purposes_contact_index: string;
        public name_for_collateral_purposes_contact_index2: string;
        public state: string;
        public aka: string;
        public business_tax_id: string;
        public title_recording_fees: number;
        public title_recording_feesSpecified: boolean;
        public seller_name_contact_index: string;
        public seller_name_contact_index2: string;
        public seller_first_name: string;
        public seller_middle_name: string;
        public seller_last_name: string;
        public seller_suffix: string;
        public seller_first_name2: string;
        public seller_middle_name2: string;
        public seller_last_name2: string;
        public seller_suffix2: string;
        public seller_ssn4: string;
        public seller_dob: Date;
        public seller_dobSpecified: boolean;
        public seller_address: string;
        public seller_county: string;
        public seller_zip: string;
        public seller_city: string;
        public seller_state: string;
        public seller_home_phone: string;
        public seller_work_phone: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FINANCINGPREVIOUS_LIEN_HOLDER {
        public current_name_on_title: string;
        public current_name_on_title2: string;
        public new_names_on_title: string;
        public new_names_on_title2: string;
        public name_for_collateral_purposes: string;
        public name_for_collateral_purposes2: string;
        public current_name_on_title_contact_index: string;
        public current_name_on_title_contact_index2: string;
        public new_names_on_title_contact_index: string;
        public new_names_on_title_contact_index2: string;
        public name_for_collateral_purposes_contact_index: string;
        public name_for_collateral_purposes_contact_index2: string;
        public state: string;
        public aka: string;
        public business_tax_id: string;
        public title_recording_fees: number;
        public title_recording_feesSpecified: boolean;
        public lienholder_information: string;
        public lienholder_account_name: string;
        public lienholder_account_name2: string;
        public lienholder_account_name_contact_index: string;
        public lienholder_account_name_contact_index2: string;
        public lienholder_account_number: string;
        public lienholder_address: string;
        public lienholder_county: string;
        public lienholder_zip: string;
        public lienholder_city: string;
        public lienholder_state: string;
        public lienholder_payoff: number;
        public lienholder_payoffSpecified: boolean;
        public lienholder_payoff_date: Date;
        public lienholder_payoff_dateSpecified: boolean;
        public lienholder_payoff_per_diem: number;
        public lienholder_payoff_per_diemSpecified: boolean;
        public lienholder_phone: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FINANCINGUCC_FILING {
        public name: string;
        public name_contact_index: string;
        public ssn: string;
        public name2: string;
        public name_contact_index2: string;
        public ssn2: string;
        public name3: string;
        public name_contact_index3: string;
        public ssn3: string;
        public state: string;
        public aka: string;
        public name_for_collateral_purpose: string;
        public name_for_collateral_purpose2: string;
        public name_for_collateral_purpose_contact_index: string;
        public name_for_collateral_purpose_contact_index2: string;
        public business_tax_id: string;
        public title_recording_fees: number;
        public title_recording_feesSpecified: boolean;
        public title_state: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FINANCINGTITLE {
        public state_current: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FINANCINGINSURANCE {
        public insurance_agent_contact_index: string;
        public policy_number: string;
        public policy_effective_date: Date;
        public policy_effective_dateSpecified: boolean;
        public policy_expire_date: Date;
        public policy_expire_dateSpecified: boolean;
    }
    export enum VEHICLE_FINANCINGProcessing_type {
        MPLM,

        DE,

        RE,

        CT,

        MP,

        UCC,

        HT,

        HTNIF
    }
    export enum VEHICLE_FINANCINGValue_type {
        //[Xml.Serialization.XmlEnumAttribute("")]
        Item,

        NONE,

        NADAR,

        NADAT,

        NADAW,

        //[Xml.Serialization.XmlEnumAttribute("NADAW")]
        NADAW1,

        KLYR,

        KLYT,

        KLYW,

        MSRP,

        INV
    }
    export enum VEHICLE_TRADE_INVehicle_type {
        CAR,

        AIRCRAFT,

        ATV,

        BIGRIG,

        BOAT,

        FARMVEHICLE,

        JETSKI,

        MANUFACTUREHOME,

        MOTORCYCLE,

        MOTORHOME,

        RV,

        SNOWMOBILE,

        TRAILER,

        TRAVELTRAILER,

        TRUCKCAMPER,

        UTV,

        MOTOR,

        MOWER,

        COMMERCIALTRAILER,

        HEAVYEQUIPMENT,

        VAN,

        LIMOUSINE,

        OTHER
    }
    export enum VEHICLE_FINANCINGCommercial_title {
        NONE,

        INDIVIDUAL,

        BUSINESS
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_TRADE_IN {
        public trade_value: number;
        public trade_valueSpecified: boolean;
        public trade_payoff: number;
        public trade_payoffSpecified: boolean;
        public trade_payment: number;
        public trade_paymentSpecified: boolean;
        public vin: string;
        public vehicle_type: VEHICLE_TRADE_INVehicle_type;
        public vehicle_typeSpecified: boolean;
        public year: string;
        public make: string;
        public model: string;
        public empty_weight: number;
        public empty_weightSpecified: boolean;
        public carrying_capacity: number;
        public carrying_capacitySpecified: boolean;
        public length: number;
        public lengthSpecified: boolean;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BENEFICIAL_OWNERS {
        public BENEFICIAL_OWNER: BENEFICIAL_OWNERSBENEFICIAL_OWNER[];
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BENEFICIAL_OWNERSBENEFICIAL_OWNER {
        public ID_CARD: BASE_ID_CARD;
        public first_name: string;
        public middle_name: string;
        public last_name: string;
        public suffix: string;
        public dob: Date;
        public dobSpecified: boolean;
        public is_tin_ssn: BASE_APPLICANTDeclined_answer_race_gender;
        public is_tin_ssnSpecified: boolean;
        public ssn: string;
        public address: string;
        public city: string;
        public state: string;
        public zip: string;
        public is_beneficial_owner: BASE_APPLICANTDeclined_answer_race_gender;
        public is_beneficial_ownerSpecified: boolean;
        public percent_business_owned: number;
        public percent_business_ownedSpecified: boolean;
        public is_control: BASE_APPLICANTDeclined_answer_race_gender;
        public is_controlSpecified: boolean;
        public is_authorized_credit_report: BASE_APPLICANTDeclined_answer_race_gender;
        public is_authorized_credit_reportSpecified: boolean;
        public citizenship: BENEFICIAL_OWNERSBENEFICIAL_OWNERCitizenship;
        public citizenshipSpecified: boolean;
        public control_title: BENEFICIAL_OWNERSBENEFICIAL_OWNERControl_title;
        public control_titleSpecified: boolean;
        public is_ofac_istwatch: BASE_APPLICANTDeclined_answer_race_gender;
        public is_ofac_istwatchSpecified: boolean;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_ID_CARD {
        public card_type: string;
        public card_number: string;
        public state: string;
        public country: string;
        public exp_date: Date;
        public exp_dateSpecified: boolean;
        public date_issued: Date;
        public date_issuedSpecified: boolean;
    }
    export enum BENEFICIAL_OWNERSBENEFICIAL_OWNERCitizenship {
        USCITIZEN,

        PERMRESIDENT,

        NONPERMRESIDENT,

        NONRESIDENTALIEN,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BENEFICIAL_OWNERSBENEFICIAL_OWNERControl_title {
        AGENT,

        CORP_REP,

        MANAGER,

        OWNER,

        PARTNER,

        PRESIDENT,

        SECRETARY,

        TREASURER,

        VICE_PRESIDENT,

        MEMBER,

        DIRECTOR,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_WEBMS_HISTORY {
        public MESSAGE: BASE_WEBMS_HISTORYMESSAGE[];
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_WEBMS_HISTORYMESSAGE {
        public time: Date;
        public timeSpecified: boolean;
        public name: string;
        public type: string;
        public text: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_EVENT_HISTORY {
        public EVENT: BASE_EVENT_HISTORYEVENT[];
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_EVENT_HISTORYEVENT {
        public timestamp: Date;
        public timestampSpecified: boolean;
        public username: string;
        public $event: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_MODIFICATION_HISTORY {
        public AUDIT: BASE_MODIFICATION_HISTORYAUDIT[];
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_MODIFICATION_HISTORYAUDIT {
        public modification_date: Date;
        public modification_dateSpecified: boolean;
        public modifier_name: string;
        public modifier_type: string;
        public Text: string[];
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_LOAN_CONTACTS {
        public CONTACT_INFO: BASE_LOAN_CONTACTSCONTACT_INFO[];
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_LOAN_CONTACTSCONTACT_INFO {
        public contact_type: BASE_LOAN_CONTACTSCONTACT_INFOContact_type;
        public nss_borrower_index: string;
        public first_name: string;
        public middle_name: string;
        public last_name: string;
        public suffix: string;
        public address: string;
        public city: string;
        public state: string;
        public zip: string;
        public phone: string;
        public work_phone: string;
        public fax: string;
        public cell: string;
        public pager: string;
        public email: string;
        public company_name: string;
        public business_entity_type: base_business_entity_type;
        public business_entity_typeSpecified: boolean;
        public role_type: string;
        public department_name: string;
        public company_address: string;
        public company_city: string;
        public company_state: string;
        public company_zip: string;
        public company_phone: string;
        public company_fax: string;
        public county: string;
        public notes: string;
        public ssn: string;
        public dob: Date;
        public dobSpecified: boolean;
        public is_ssn_taxid: BASE_APPLICANTDeclined_answer_race_gender;
        public is_ssn_taxidSpecified: boolean;
        public paac: string;
    }
    export enum BASE_LOAN_CONTACTSCONTACT_INFOContact_type {
        ASSETOWN,

        INSAGENT,

        TITLEDPER,

        OTHER,

        SELLER,

        NSS,

        APPLICANT,

        BUSINESS,

        TRUST
    }
    export enum base_business_entity_type {
        JOINT_VEN,

        ASSN,

        UNINCORPORATED_ASSN,

        PARTNER,

        GEN_PARTNER,

        CORP,

        SCORP,

        CCORP,

        NONPROFIT,

        LLC,

        LLP,

        SP,

        LIMPARTNER,

        CCORPLLC,

        SCORPLLC,

        PARTNERLLC,

        SPLLC,

        ORG_OR_CLUB,

        NONPROFITCORP,

        NONPROFITORG,

        PROFESSIONALCORP,

        PUBLICFUNDS,

        NONMEMBER,

        IOLTA,

        UAHROF,

        GOVERNMENT,

        TRUST,

        OTHER,

        IOLA,

        NGO,

        INDIVIDUAL,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class INTEGRATION_DATA {

    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_HMDA_XML {
        public ITEM: BASE_HMDA_XMLITEM[];
        public version: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_HMDA_XMLITEM {
        public key: string;
        public value: string;
        public Value: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_HMDA_INFO {
        public property_address: string;
        public property_city: string;
        public property_state: string;
        public property_type: BASE_HMDA_INFOProperty_type;
        public property_typeSpecified: boolean;
        public property_zip: string;
        public is_hoepa: BASE_APPLICANTDeclined_answer_race_gender;
        public is_hoepaSpecified: boolean;
        public is_hoepa_manual: BASE_APPLICANTDeclined_answer_race_gender;
        public is_hoepa_manualSpecified: boolean;
        public lien_position: number;
        public lien_positionSpecified: boolean;
        public hmda_loan_purpose: string;
        public property_occupancy_status: BASE_HMDA_INFOProperty_occupancy_status;
        public property_occupancy_statusSpecified: boolean;
        public msa_number: string;
        public property_tax_id: string;
        public census_tract_number: string;
        public property_county: string;
        public property_county_code: string;
        public state_code: string;
        public interview_method: BASE_HMDA_INFOInterview_method;
        public interview_methodSpecified: boolean;
        public purchaser: BASE_HMDA_INFOPurchaser;
        public purchaserSpecified: boolean;
        public property_address_verification_date: Date;
        public property_address_verification_dateSpecified: boolean;
    }
    export enum BASE_HMDA_INFOProperty_type {
        SFR,

        //[Xml.Serialization.XmlEnumAttribute("2 UNIT")]
        Item2UNIT,

        //[Xml.Serialization.XmlEnumAttribute("3 UNIT")]
        Item3UNIT,

        //[Xml.Serialization.XmlEnumAttribute("4 UNIT")]
        Item4UNIT,

        //[Xml.Serialization.XmlEnumAttribute("5+ UNIT")]
        Item5UNIT,

        TOWNHOUSE,

        //[Xml.Serialization.XmlEnumAttribute("HIGH RISE CONDO")]
        HIGHRISECONDO,

        //[Xml.Serialization.XmlEnumAttribute("LOW RISE CONDO")]
        LOWRISECONDO,

        PUD,

        //[Xml.Serialization.XmlEnumAttribute("MOBILE HOME")]
        MOBILEHOME,

        //[Xml.Serialization.XmlEnumAttribute("MANUFACTURED HOME")]
        MANUFACTUREDHOME,

        //[Xml.Serialization.XmlEnumAttribute("MIXED USE")]
        MIXEDUSE,

        CONDOTEL,

        COOP,

        COMMERCIAL,

        FARM,

        //[Xml.Serialization.XmlEnumAttribute("HOME BUSINESS")]
        HOMEBUSINESS,

        LAND,

        OTHER,

        MNL,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_HMDA_INFOProperty_occupancy_status {
        INVESTMENT,

        //[Xml.Serialization.XmlEnumAttribute("PRIMARY RESIDENCE")]
        PRIMARYRESIDENCE,

        //[Xml.Serialization.XmlEnumAttribute("SECOND HOME")]
        SECONDHOME,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_HMDA_INFOInterview_method {
        TELEPHONE,

        FACETOFACE,

        INTERNET,

        MAIL,

        FAX,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_HMDA_INFOPurchaser {
        NOT_ORIGINATED,

        FANNIE_MAE,

        GINNIE_MAE,

        FREDDIE_MAC,

        FARMER_MAC,

        PRIVATE,

        COMMERCIAL,

        LIFE_INSURANCE,

        AFFILIATE,

        OTHER
    }
    /*[Xml.Serialization.XmlIncludeAttribute(typeof(VEHICLE_SYSTEM))]
        [CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_SYSTEM {
        public PROCESSOR: BASE_PARTY;
        public LOAN_OFFICER: BASE_PARTY;
        public BOOKING_OFFICER: BASE_PARTY;
        public APPROVAL_OFFICER: BASE_PARTY;
        public COUNTER_OFFER_OFFICER: BASE_PARTY;
        public FUNDER: BASE_PARTY;
        public DECLINING_OFFICER: BASE_PARTY;
        public DECIDING_OFFICER: BASE_PARTY;
        public ORIGINATOR: BASE_SYSTEMORIGINATOR;
        public BRANCH: BASE_BRANCH;
        public LENDER: BASE_SYSTEMLENDER;
        public ORGANIZATION: BASE_PARTY;
        public INSURANCE_SELLER: BASE_PARTY;
        public CLINIC: BASE_PARTY;
        public CLINIC_WORKER: BASE_PARTY;
        public INSURANCE_LAST_PULLED_OFFICER: BASE_PARTY;
        public source: string;
        public origination_ip: string;
        public external_source: string;
        public loan_number: string;
        public reference_number_type: string;
        public AnyAttr: any;
        constructor() {
            this.source = "GATEWAY";
            this.origination_ip = "";
        }
    }
    /*[Xml.Serialization.XmlIncludeAttribute(typeof(BASE_BRANCH))]
        [CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_PARTY {
        public reference_id: string;
        public name: string;
        public code: string;
        public phone: string;
        public fax: string;
        public email: string;
        public title: string;
        public teller_number: string;
        public terminal_id: string;
        public AnyAttr: any;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_BRANCH extends BASE_PARTY {
        public ADDRESS: BASE_ADDRESS_LOOSE;
        public reference_id2: string;
        public deluxe_org_reporting_unit: string;
        public ews_org_reporting_unit: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_SYSTEMORIGINATOR extends BASE_PARTY {
        public type: BASE_SYSTEMORIGINATORType;
    }
    export enum BASE_SYSTEMORIGINATORType {
        C,

        L,

        P,

        O,

        S,

        Z
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_SYSTEMLENDER extends BASE_PARTY {
        public ADDRESS: BASE_ADDRESS_LOOSE;
        public cuna_client_id: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_SYSTEM extends BASE_SYSTEM {
        public GAP_MRC_SELLER: BASE_PARTY;
        public GAP_SELLER: BASE_PARTY;
        public SC_SELLER: BASE_PARTY;
        public CLOSING_BRANCH: BASE_BRANCH;
        public GAP_LAST_PULLED_OFFICER: BASE_PARTY;
        public SC_LAST_PULLED_OFFICER: BASE_PARTY;
        public type: VEHICLE_SYSTEMType;
    }
    export enum VEHICLE_SYSTEMType {
        LPQ,

        DAPP,

        OTHER
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_STRUCTURED_COMMENTS {
        public STRUCTURED_INTERNAL_COMMENTS: BASE_STRUCTURED_COMMENT[];
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_STRUCTURED_COMMENT {
        public MODIFIER: BASE_PARTY;
        public modifier_type: BASE_STRUCTURED_COMMENTModifier_type;
        public modifier_typeSpecified: boolean;
        public last_modified_date: Date;
        public last_modified_dateSpecified: boolean;
        public creation_date: Date;
        public creation_dateSpecified: boolean;
        public message: string;
    }
    export enum BASE_STRUCTURED_COMMENTModifier_type {
        L,

        Z,

        U
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_COMMENTS {
        public DECISION_COMMENTS: Object;
        public EXTERNAL_COMMENTS: Object;
        public INTERNAL_COMMENTS: Object;
        public STIPULATIONS: Object;
        public FRAUD_WARNINGS: Object;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_CLOSE_END_INFO {
        public ATO: BASE_CLOSE_END_INFOATO[];
        public amount_owed_to_lender: number;
        public amount_owed_to_lenderSpecified: boolean;
        public amount_refinanced: number;
        public amount_refinancedSpecified: boolean;
        public cash_prepaid_finance_charge: number;
        public cash_prepaid_finance_chargeSpecified: boolean;
        public mapr_cash_fee: number;
        public mapr_cash_feeSpecified: boolean;
        public filing_fee_amount: number;
        public filing_fee_amountSpecified: boolean;
        public filing_fee_type: BASE_CLOSE_END_INFOFiling_fee_type;
        public filing_fee_typeSpecified: boolean;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_CLOSE_END_INFOATO {
        public name: string;
        public memo: string;
        public amount: number;
        public amountSpecified: boolean;
        public is_PPFC: BASE_APPLICANTDeclined_answer_race_gender;
        public is_PPFCSpecified: boolean;
        public is_mapr: BASE_APPLICANTDeclined_answer_race_gender;
        public is_maprSpecified: boolean;
        public funding_method: BASE_CLOSE_END_INFOATOFunding_method;
        public funding_methodSpecified: boolean;
    }
    export enum BASE_CLOSE_END_INFOATOFunding_method {
        IL,

        SD,

        SL,

        EL
    }
    export enum BASE_CLOSE_END_INFOFiling_fee_type {
        FINANCED,

        CASH,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[Xml.Serialization.XmlIncludeAttribute(typeof(VEHICLE_INSURANCE))]
        [CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_INSURANCE {
        public coverage: BASE_INSURANCECoverage;
        public coverageSpecified: boolean;
        public plan_key: string;
        public description: string;
        public premium: number;
        public premiumSpecified: boolean;
        public premium_mode: BASE_INSURANCEPremium_mode;
        public premium_modeSpecified: boolean;
        public cost_per_day: number;
        public cost_per_daySpecified: boolean;
        public insured_amount: number;
        public insured_amountSpecified: boolean;
        public insured_rate: number;
        public insured_rateSpecified: boolean;
        public expire_date: Date;
        public expire_dateSpecified: boolean;
        public last_pulled_date: Date;
        public last_pulled_dateSpecified: boolean;
    }
    export enum BASE_INSURANCECoverage {
        S,

        J,

        C,

        B
    }
    export enum BASE_INSURANCEPremium_mode {
        MR,

        LR,

        SP,

        DC,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_INSURANCE extends BASE_INSURANCE {
        public type: VEHICLE_INSURANCEType;
        public typeSpecified: boolean;
        public is_financed: BASE_APPLICANTDeclined_answer_race_gender;
        public effective_date: Date;
        public effective_dateSpecified: boolean;
        constructor() {
            super();
            this.is_financed = BASE_APPLICANTDeclined_answer_race_gender.Y;
        }
    }
    export enum VEHICLE_INSURANCEType {
        CREDIT_LIFE,

        CREDIT_DISABILITY,

        DEBT_CANCELLATION,

        GAP,

        SERVICE_CONTRACT
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class PAYMENT_TRANSFER_FROM_OTHER_INSTITUTION_INFO {
        public account_type: base_payment_account_type;
        public account_typeSpecified: boolean;
        public account_number: string;
        public name_on_account: string;
        public bank_routing_number: string;
        public bank_name: string;
        public bank_zip: string;
        public bank_city: string;
        public bank_state: string;
    }
    export enum base_payment_account_type {
        CHECKING,

        SAVINGS,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class PAYMENT_TRANSFER_FROM_INTERNAL_ACCOUNT_INFO {
        public account_type: base_payment_account_type;
        public account_typeSpecified: boolean;
        public account_number: string;
        public name_on_account: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class PAYMENT_MANUAL_NO_COUPON_INFO {
        public account_number: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class PAYMENT_PAYROLL_DEDUCTION_INFO {
        public account_number: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class PAYMENT_COUPON_BOOK_INFO {
        public account_number: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_PAYMENT_INFO_CHOICE {
        public Item: Object;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class SKIP_PAYMENT_INFO {
        public skip_payment_template_name: string;
        public is_position_of_skips_first_pay_skip: BASE_APPLICANTDeclined_answer_race_gender;
        public is_position_of_skips_first_pay_skipSpecified: boolean;
        public is_defer_skip_payments: BASE_APPLICANTDeclined_answer_race_gender;
        public is_defer_skip_paymentsSpecified: boolean;
        public skips_per_year: string;
        public position_of_skips: string;
        public skip_start_date: Date;
        public skip_start_dateSpecified: boolean;
        public skip_end_date: Date;
        public skip_end_dateSpecified: boolean;
        public skip_period_month_day_start: Date;
        public skip_period_month_day_startSpecified: boolean;
        public skip_period_month_day_end: Date;
        public skip_period_month_day_endSpecified: boolean;
    }
    /*[Xml.Serialization.XmlIncludeAttribute(typeof(VEHICLE_FUNDING_INFO))]
        [CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_FUNDING_INFO {
        public SKIP_PAYMENT_INFO: SKIP_PAYMENT_INFO;
        public PAYMENT_INFO: BASE_PAYMENT_INFO_CHOICE;
        public daily_periodic_rate: number;
        public daily_periodic_rateSpecified: boolean;
        public payment_frequency: BASE_FUNDING_INFOPayment_frequency;
        public payment_frequencySpecified: boolean;
        public funded_status: BASE_FUNDING_INFOFunded_status;
        public funded_statusSpecified: boolean;
        public loan_date: Date;
        public loan_dateSpecified: boolean;
        public funding_date: Date;
        public funding_dateSpecified: boolean;
    }
    export enum BASE_FUNDING_INFOPayment_frequency {
        ANNUALLY,

        //[Xml.Serialization.XmlEnumAttribute("SEMI-ANNUALLY")]
        SEMIANNUALLY,

        QUARTERLY,

        //[Xml.Serialization.XmlEnumAttribute("BI-MONTHLY")]
        BIMONTHLY,

        MONTHLY,

        //[Xml.Serialization.XmlEnumAttribute("QUAD-WEEKLY")]
        QUADWEEKLY,

        //[Xml.Serialization.XmlEnumAttribute("SEMI-MONTHLY")]
        SEMIMONTHLY,

        //[Xml.Serialization.XmlEnumAttribute("BI-WEEKLY")]
        BIWEEKLY,

        WEEKLY,

        //[Xml.Serialization.XmlEnumAttribute("SINGLE-PAYMENT")]
        SINGLEPAYMENT,

        //[Xml.Serialization.XmlEnumAttribute("TWICE-MONTHLY")]
        TWICEMONTHLY
    }
    export enum BASE_FUNDING_INFOFunded_status {
        FUN,

        UF,

        RF,

        PROC,

        RD,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_FUNDING_INFO extends BASE_FUNDING_INFO {
        public initial_amount_advanced: number;
        public initial_amount_advancedSpecified: boolean;
        public last_payment_date: Date;
        public last_payment_dateSpecified: boolean;
        public finance_charge: number;
        public finance_chargeSpecified: boolean;
        public total_financed_payment: number;
        public total_financed_paymentSpecified: boolean;
        public other_charges: number;
        public other_chargesSpecified: boolean;
        public other_charges_description: string;
        public amount_advanced: number;
        public amount_advancedSpecified: boolean;
        public prev_loan_liner_balance: number;
        public prev_loan_liner_balanceSpecified: boolean;
        public prev_payment_date: Date;
        public prev_payment_dateSpecified: boolean;
        public other_loans: number;
        public other_loansSpecified: boolean;
        public current_payment: number;
        public current_paymentSpecified: boolean;
        public current_payment_due_date: Date;
        public current_payment_due_dateSpecified: boolean;
        public filing_fee_amount: number;
        public filing_fee_amountSpecified: boolean;
        public filing_fee_type: VEHICLE_FUNDING_INFOFiling_fee_type;
        public filing_fee_typeSpecified: boolean;
        public interest_only_min_payment: number;
        public interest_only_min_paymentSpecified: boolean;
        public interest_only_max_payment: number;
        public interest_only_max_paymentSpecified: boolean;
        public first_payment_date: Date;
        public first_payment_dateSpecified: boolean;
        public loan_term: string;
        public buy_rate: number;
        public buy_rateSpecified: boolean;
        public extended_rate: number;
        public extended_rateSpecified: boolean;
        public reserve_amount: number;
        public reserve_amountSpecified: boolean;
        public suggested_buydown_amount: number;
        public suggested_buydown_amountSpecified: boolean;
        public primary_reserve_amount: number;
        public primary_reserve_amountSpecified: boolean;
        public secondary_reserve_amount: number;
        public secondary_reserve_amountSpecified: boolean;
        public contract_rate: number;
        public contract_rateSpecified: boolean;
        public exact_monthly_payment: number;
        public exact_monthly_paymentSpecified: boolean;
        public exact_monthly_payment_original: number;
        public exact_monthly_payment_originalSpecified: boolean;
        public exact_last_monthly_payment: number;
        public exact_last_monthly_paymentSpecified: boolean;
        public additional_fees: number;
        public additional_feesSpecified: boolean;
        public additional_fees_comment: string;
        public funding_acquisition_fee: number;
        public funding_acquisition_feeSpecified: boolean;
        public apr: number;
        public aprSpecified: boolean;
        public index: number;
        public indexSpecified: boolean;
        public margin: number;
        public marginSpecified: boolean;
        public floor: number;
        public floorSpecified: boolean;
        public ceiling: number;
        public ceilingSpecified: boolean;
        public index_type: string;
        public initial_rate: number;
        public initial_rateSpecified: boolean;
        public rate_expire_date: Date;
        public rate_expire_dateSpecified: boolean;
        public doc_stamps_fee: number;
        public doc_stamps_feeSpecified: boolean;
        public doc_stamps_rate: number;
        public doc_stamps_rateSpecified: boolean;
        public doc_stamps_fee_is_manual: BASE_APPLICANTDeclined_answer_race_gender;
        public doc_stamps_fee_is_manualSpecified: boolean;
        public doc_stamps_type: VEHICLE_FUNDING_INFODoc_stamps_type;
        public doc_stamps_typeSpecified: boolean;
        public funding_money_factor: number;
        public funding_money_factorSpecified: boolean;
        public funding_agreed_upon_value: number;
        public funding_agreed_upon_valueSpecified: boolean;
        public funding_tax_rate: number;
        public funding_tax_rateSpecified: boolean;
        public funding_equivalent_interest_rate: number;
        public funding_equivalent_interest_rateSpecified: boolean;
        public funding_allowable_annual_mileage: string;
        public dealer_funding_date: Date;
        public dealer_funding_dateSpecified: boolean;
    }
    export enum VEHICLE_FUNDING_INFOFiling_fee_type {
        FINANCED,

        CASH,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum VEHICLE_FUNDING_INFODoc_stamps_type {
        FINANCED,

        CASH,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_ACH))]
        [Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_LOAN))]
        [Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_FEE))]
        [Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_DEPOSIT))]
        [Xml.Serialization.XmlIncludeAttribute(typeof(BASE_DISBURSEMENT_CHECK))]
        [CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_DISBURSEMENT {
        public amount: number;
        public amountSpecified: boolean;
        public reference_id: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_DISBURSEMENT_ACH extends BASE_DISBURSEMENT {
        public name_on_account: string;
        public account_number: string;
        public bank_routing_number: string;
        public bank_name: string;
        public bank_state: string;
        public account_type: BASE_DISBURSEMENT_ACHAccount_type;
        public account_typeSpecified: boolean;
        public transaction_type: BASE_DISBURSEMENT_ACHTransaction_type;
        public transaction_typeSpecified: boolean;
        public ach_is_primary_applicant: BASE_APPLICANTDeclined_answer_race_gender;
        public ach_is_primary_applicantSpecified: boolean;
        public payoff_date: Date;
        public payoff_dateSpecified: boolean;
        public interest_per_diem: number;
        public interest_per_diemSpecified: boolean;
    }
    export enum BASE_DISBURSEMENT_ACHAccount_type {
        CHECKING,

        SAVINGS,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_DISBURSEMENT_ACHTransaction_type {
        NORMAL,

        DEALER_RESERVE,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_DISBURSEMENT_LOAN extends BASE_DISBURSEMENT {
        public loan_suffix: string;
        public loan_is_repay_loan: string;
        public loan_description: string;
        public loan_is_same_account: BASE_DISBURSEMENT_LOANLoan_is_same_account;
        public loan_is_same_accountSpecified: boolean;
        public payoff_date: Date;
        public payoff_dateSpecified: boolean;
        public interest_per_diem: number;
        public interest_per_diemSpecified: boolean;
        public loan_type: string;
        public payment_type: string;
        public loan_account_number: string;
    }
    export enum BASE_DISBURSEMENT_LOANLoan_is_same_account {
        //[Xml.Serialization.XmlEnumAttribute("")]
        Item,

        Y,

        N
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_DISBURSEMENT_FEE extends BASE_DISBURSEMENT {
        public prepaid_fees_financed: number;
        public prepaid_fees_financedSpecified: boolean;
        public fees_financed: number;
        public fees_financedSpecified: boolean;
        public fees_gl_account_number: string;
        public fee_description: string;
        public fee_linking_type: BASE_DISBURSEMENT_FEEFee_linking_type;
        public fee_linking_typeSpecified: boolean;
    }
    export enum BASE_DISBURSEMENT_FEEFee_linking_type {
        ATO1,

        ATO2,

        ATO3,

        ATO4,

        ATO5,

        ATO6,

        ATO7,

        ATO8,

        ATO9,

        ATO10,

        DSF,

        FF,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_DISBURSEMENT_DEPOSIT extends BASE_DISBURSEMENT {
        public deposit_account: string;
        public deposit_suffix: string;
        public deposit_type: string;
        public deposit_date_to_credit: Date;
        public deposit_date_to_creditSpecified: boolean;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_DISBURSEMENT_CHECK extends BASE_DISBURSEMENT {
        public check_number: string;
        public check_payable_to: string;
        public check_address_1: string;
        public check_address_2: string;
        public check_address_3: string;
        public check_zip: string;
        public check_remitter: string;
        public check_purpose: BASE_DISBURSEMENT_CHECKCheck_purpose;
        public check_purposeSpecified: boolean;
        public check_status: BASE_DISBURSEMENT_CHECKCheck_status;
        public check_statusSpecified: boolean;
        public check_print_option: number;
        public check_print_optionSpecified: boolean;
        public check_is_payee_only: BASE_APPLICANTDeclined_answer_race_gender;
        public check_is_payee_onlySpecified: boolean;
        public check_is_primary_applicant: BASE_APPLICANTDeclined_answer_race_gender;
        public check_is_primary_applicantSpecified: boolean;
        public payoff_date: Date;
        public payoff_dateSpecified: boolean;
        public interest_per_diem: number;
        public interest_per_diemSpecified: boolean;
        public check_remitter_contact_index: string;
        public check_remitter_contact_index2: string;
        public check_coremitter_contact_index: string;
        public is_payee_ofac_istwatch: BASE_APPLICANTDeclined_answer_race_gender;
        public is_payee_ofac_istwatchSpecified: boolean;
        public istwatch_transaction_date: string;
        public check_account_number: string;
    }
    export enum BASE_DISBURSEMENT_CHECKCheck_purpose {
        CLSAC,

        PAYBL,

        VLD,

        VLPRD,

        VLABD,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_DISBURSEMENT_CHECKCheck_status {
        NOT_PRINTED,

        PRINTED,

        VOIDED
    }
    /*[Xml.Serialization.XmlIncludeAttribute(typeof(VEHICLE_STATUS))]
        [CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_LOAN_STATUS {
        public loan_status: BASE_LOAN_STATUSLoan_status;
        public initial_entry_timestamp: Date;
        public initial_entry_timestampSpecified: boolean;
        public last_modified: Date;
        public last_modifiedSpecified: boolean;
        public submit_date: Date;
        public submit_dateSpecified: boolean;
        public underwrite_submit_date: Date;
        public underwrite_submit_dateSpecified: boolean;
        public is_locked: BASE_APPLICANTDeclined_answer_race_gender;
        public existing_balance: number;
        public existing_balanceSpecified: boolean;
        public existing_balance_date: Date;
        public existing_balance_dateSpecified: boolean;
        public delinquency_period: string;
        public delinquency_amount: number;
        public delinquency_amountSpecified: boolean;
        public approval_date: Date;
        public approval_dateSpecified: boolean;
        public declined_date: Date;
        public declined_dateSpecified: boolean;
        public first_approval_date: Date;
        public first_approval_dateSpecified: boolean;
        public next_payment_date: Date;
        public next_payment_dateSpecified: boolean;
        public response_date: Date;
        public response_dateSpecified: boolean;
        public counter_offer_date: Date;
        public counter_offer_dateSpecified: boolean;
        public app_receive_date: Date;
        public app_receive_dateSpecified: boolean;
        public deciding_date: Date;
        public deciding_dateSpecified: boolean;
        public booking_date: Date;
        public booking_dateSpecified: boolean;
        public booking_reference_id: string;
    }
    export enum BASE_LOAN_STATUSLoan_status {
        //[Xml.Serialization.XmlEnumAttribute("PRE-APP")]
        PREAPP,

        PEN,

        APP,

        AA,

        AD,

        AP,

        OFF,

        DEC,

        DUP,

        INC,

        DEN,

        CAN,

        INQ,

        NMI,

        CODEC,

        AP2S,

        MEMWAIT,

        WTHDRN,

        WTHDRNO,

        REF,

        AMD,

        MEMDC,

        REVIEW,

        FRAUD,

        CFI,

        CFIO,

        PRD,

        PRA
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_STATUS extends BASE_LOAN_STATUS {
        public payoff_date: Date;
        public payoff_dateSpecified: boolean;
        public amount_charge_off: number;
        public amount_charge_offSpecified: boolean;
    }
   
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_LOAN_INFO extends BASE_LOAN_INFO {
        public total_sales_price: number;
        public total_sales_priceSpecified: boolean;
        public total_vehicle_value: number;
        public total_vehicle_valueSpecified: boolean;
        public amount_requested: number;
        public amount_requestedSpecified: boolean;
        public down_payment: number;
        public down_paymentSpecified: boolean;
        public loan_term: string;
        public contract_rate: number;
        public contract_rateSpecified: boolean;
        public trade_allowance: number;
        public trade_allowanceSpecified: boolean;
        public initial_balloon_amount: number;
        public initial_balloon_amountSpecified: boolean;
        public estimated_vehicle_payment: number;
        public estimated_vehicle_paymentSpecified: boolean;
        public is_residual_loan: BASE_APPLICANTDeclined_answer_race_gender;
        public is_residual_loanSpecified: boolean;
        public rebate: number;
        public rebateSpecified: boolean;
        public tax: number;
        public taxSpecified: boolean;
        public license: number;
        public licenseSpecified: boolean;
        public additional_items_front_end: number;
        public additional_items_front_endSpecified: boolean;
        public additional_items_back_end: number;
        public additional_items_back_endSpecified: boolean;
        public credit_life: number;
        public credit_lifeSpecified: boolean;
        public credit_disability: number;
        public credit_disabilitySpecified: boolean;
        public debt_cancellation: number;
        public debt_cancellationSpecified: boolean;
        public max_advance: string;
        public max_advance_value: number;
        public max_advance_valueSpecified: boolean;
        public max_soft_advance_value: number;
        public max_soft_advance_valueSpecified: boolean;
        public max_hard_advance_value: number;
        public max_hard_advance_valueSpecified: boolean;
        public amount_approved: number;
        public amount_approvedSpecified: boolean;
        public request_type: string;
        public purpose_type: string;
        public inspected_by: string;
        public inspection_date: Date;
        public inspection_dateSpecified: boolean;
        public vehicle_purchase_date: Date;
        public vehicle_purchase_dateSpecified: boolean;
        public rate_type: VEHICLE_LOAN_INFORate_type;
        public rate_typeSpecified: boolean;
        public misc_implant: string;
        public number_of_payments: number;
        public number_of_paymentsSpecified: boolean;
        public is_indirect_loan: VEHICLE_LOAN_INFOIs_indirect_loan;
        public is_indirect_loanSpecified: boolean;
        public is_business: VEHICLE_LOAN_INFOIs_business;
        public is_businessSpecified: boolean;
        public is_balloon: VEHICLE_LOAN_INFOIs_balloon;
        public is_balloonSpecified: boolean;
        public is_hmda: BASE_APPLICANTDeclined_answer_race_gender;
        public is_hmdaSpecified: boolean;
        public is_hmda_manual: BASE_APPLICANTDeclined_answer_race_gender;
        public is_hmda_manualSpecified: boolean;
        public amount_approved_variance: number;
        public amount_approved_varianceSpecified: boolean;
        public change_in_circumstances_date: Date;
        public change_in_circumstances_dateSpecified: boolean;
        public product_conversion_date: Date;
        public product_conversion_dateSpecified: boolean;
        public is_advance_request: BASE_APPLICANTDeclined_answer_race_gender;
        public is_advance_requestSpecified: boolean;
        public is_calculate_mapr: BASE_APPLICANTDeclined_answer_race_gender;
        public is_calculate_maprSpecified: boolean;
        public mapr: number;
        public maprSpecified: boolean;
        public customer_money_factor: number;
        public customer_money_factorSpecified: boolean;
        public lender_money_factor: number;
        public lender_money_factorSpecified: boolean;
        public residual_value: number;
        public residual_valueSpecified: boolean;
        public gross_cap_cost: number;
        public gross_cap_costSpecified: boolean;
        public cap_cost_reduction: number;
        public cap_cost_reductionSpecified: boolean;
        public adjusted_cap_cost: number;
        public adjusted_cap_costSpecified: boolean;
        public lease_payment: number;
        public lease_paymentSpecified: boolean;
        public max_lease_payment: number;
        public max_lease_paymentSpecified: boolean;
        public indirect_preapproval_conversion_date: Date;
        public indirect_preapproval_conversion_dateSpecified: boolean;
    }
    export enum VEHICLE_LOAN_INFORate_type {
        F,

        V,

        S,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum VEHICLE_LOAN_INFOIs_indirect_loan {
        Y,

        N
    }
    export enum VEHICLE_LOAN_INFOIs_business {
        Y,

        N
    }
    export enum VEHICLE_LOAN_INFOIs_balloon {
        Y,

        N
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class DECLARATIONS {
        public has_judge_bankrupt_foreclosure: BASE_APPLICANTDeclined_answer_race_gender;
        public has_judge_bankrupt_foreclosureSpecified: boolean;
        public has_declare_bankrupt: BASE_APPLICANTDeclined_answer_race_gender;
        public has_declare_bankruptSpecified: boolean;
        public has_chapter_13: BASE_APPLICANTDeclined_answer_race_gender;
        public has_chapter_13Specified: boolean;
        public has_lawsuit_party: BASE_APPLICANTDeclined_answer_race_gender;
        public has_lawsuit_partySpecified: boolean;
        public has_judgement: BASE_APPLICANTDeclined_answer_race_gender;
        public has_judgementSpecified: boolean;
        public has_foreclosure: BASE_APPLICANTDeclined_answer_race_gender;
        public has_foreclosureSpecified: boolean;
        public has_reposession: BASE_APPLICANTDeclined_answer_race_gender;
        public has_reposessionSpecified: boolean;
        public has_other_obligation: BASE_APPLICANTDeclined_answer_race_gender;
        public has_other_obligationSpecified: boolean;
        public has_past_due_bills: BASE_APPLICANTDeclined_answer_race_gender;
        public has_past_due_billsSpecified: boolean;
        public has_co_maker: BASE_APPLICANTDeclined_answer_race_gender;
        public has_co_makerSpecified: boolean;
        public co_maker_primary_name: string;
        public co_maker_creditor: string;
        public co_maker_amount: number;
        public co_maker_amountSpecified: boolean;
        public has_alias: BASE_APPLICANTDeclined_answer_race_gender;
        public has_aliasSpecified: boolean;
        public alias: string;
        public has_income_decline: BASE_APPLICANTDeclined_answer_race_gender;
        public has_income_declineSpecified: boolean;
        public has_suits_pending: BASE_APPLICANTDeclined_answer_race_gender;
        public has_suits_pendingSpecified: boolean;
        public has_alimony: BASE_APPLICANTDeclined_answer_race_gender;
        public has_alimonySpecified: boolean;
        public alimony_recipient: string;
        public alimony_recipient_address: string;
        public has_property_for_sale: BASE_APPLICANTDeclined_answer_race_gender;
        public has_property_for_saleSpecified: boolean;
    }
    /*[Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_SHARE))]
        [Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_SAVINGS_ACCOUNT))]
        [Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_CERTIFICATE))]
        [Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_REAL_ESTATE))]
        [Xml.Serialization.XmlIncludeAttribute(typeof(BASE_ASSET_VEHICLE))]
        [CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_ASSET {
        public asset_type: BASE_ASSETAsset_type;
        public asset_value: number;
        public asset_valueSpecified: boolean;
        public property_address: string;
        public property_zip: string;
        public property_state: string;
        public property_city: string;
        public existing_loan_amount: number;
        public existing_loan_amountSpecified: boolean;
        public description: string;
        public account_number: string;
        public bank_name: string;
        public is_collateral: BASE_APPLICANTDeclined_answer_race_gender;
        public is_collateralSpecified: boolean;
        public ownership_type: BASE_ASSETOwnership_type;
        public other_owner_name: string;
        public other_owner_name2: string;
        public available_account_info: string;
        public stock_bond_mutualfund_share_count: string;
        public verification_status: BASE_ASSETVerification_status;
        public verification_statusSpecified: boolean;
        public other_owner_contact_index: string;
        public other_owner_contact_index2: string;
    }
    export enum BASE_ASSETAsset_type {
        CERTIFICATE,

        CASH_DEPOSIT,

        SHARE_BACK,

        STOCK,

        BOND,

        CHECKING_ACCOUNT,

        SAVINGS_ACCOUNT,

        REALESTATE,

        MACHINERY_AND_EQUIPMENT,

        FURNITURE_AND_FIXTURES,

        AUTOMOBILE,

        LIFE_INSURANCE,

        RETIREMENT_FUND,

        OWNED_BUSINESS,

        OTHER,

        OTHER_NON_LIQUID,

        BRIDGE_LOAN_NOT_DEPOSITED,

        CASH_ON_HAND,

        GIFT,

        MONEY_MARKET,

        MUTUAL_FUNDS,

        SECURED_BORROWER_FUNDS,

        TRUST_FUNDS,

        IRA,

        NET_SALE_PROCEEDS,

        GIFT_OF_EQUITY,

        AIRCRAFT,

        AS_EXTRACTED,

        CHATTEL,

        DOC_VESSEL,

        FARM_EQUIP,

        FARM_PROD,

        GEN_INTANGIBLES,

        INSTRUMENTS,

        INVENTORY,

        LEASE_AND_RENTS,

        MFG_HOUSING,

        NON_TITLED_PERSONAL_PROP,

        PARTNERSHIP_INTEREST,

        REAL_ESTATE_CONST,

        STANDING_TIMBER,

        WATERCRAFT
    }
    export enum BASE_ASSETOwnership_type {
        B,

        J,

        T
    }
    export enum BASE_ASSETVerification_status {
        //[Xml.Serialization.XmlEnumAttribute("N/A")]
        NA,

        UNVERIFIED,

        VERIFIED
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_ASSET_SHARE extends BASE_ASSET {
        public expiration_date: Date;
        public expiration_dateSpecified: boolean;
        public pledge_type: string;
        public rate: number;
        public rateSpecified: boolean;
        public pledge_percent: number;
        public pledge_percentSpecified: boolean;
        public pledge_value_type: BASE_ASSET_SHAREPledge_value_type;
        public pledge_value_typeSpecified: boolean;
        public pledge_value: number;
        public pledge_valueSpecified: boolean;
    }
    export enum BASE_ASSET_SHAREPledge_value_type {
        PERCENT,

        PERCENTLOAN,

        AMOUNT
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_ASSET_SAVINGS_ACCOUNT extends BASE_ASSET {
        public expiration_date: Date;
        public expiration_dateSpecified: boolean;
        public issue_date: Date;
        public issue_dateSpecified: boolean;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_ASSET_CERTIFICATE extends BASE_ASSET {
        public certificate_number: string;
        public expiration_date: Date;
        public expiration_dateSpecified: boolean;
        public issue_date: Date;
        public issue_dateSpecified: boolean;
        public pledge_type: string;
        public rate: number;
        public rateSpecified: boolean;
        public pledge_percent: number;
        public pledge_percentSpecified: boolean;
        public pledge_value_type: string;
        public pledge_value: number;
        public pledge_valueSpecified: boolean;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_ASSET_REAL_ESTATE extends BASE_ASSET {
        public property_type: BASE_ASSET_REAL_ESTATEProperty_type;
        public property_typeSpecified: boolean;
        public property_gross_rental_income: number;
        public property_gross_rental_incomeSpecified: boolean;
        public property_net_rental_income: number;
        public property_net_rental_incomeSpecified: boolean;
        public property_mortgage_payments: number;
        public property_mortgage_paymentsSpecified: boolean;
        public property_other_costs: number;
        public property_other_costsSpecified: boolean;
        public property_current_residence_indicator: BASE_APPLICANTDeclined_answer_race_gender;
        public property_current_residence_indicatorSpecified: boolean;
        public property_disposition: BASE_ASSET_REAL_ESTATEProperty_disposition;
        public property_dispositionSpecified: boolean;
        public is_subject_property: BASE_APPLICANTDeclined_answer_race_gender;
        public is_subject_propertySpecified: boolean;
        public property_tax_id: string;
        public is_financed_property: BASE_APPLICANTDeclined_answer_race_gender;
        public is_financed_propertySpecified: boolean;
    }
    export enum BASE_ASSET_REAL_ESTATEProperty_type {
        SFR,

        //[Xml.Serialization.XmlEnumAttribute("2 UNIT")]
        Item2UNIT,

        //[Xml.Serialization.XmlEnumAttribute("3 UNIT")]
        Item3UNIT,

        //[Xml.Serialization.XmlEnumAttribute("4 UNIT")]
        Item4UNIT,

        //[Xml.Serialization.XmlEnumAttribute("5+ UNIT")]
        Item5UNIT,

        TOWNHOUSE,

        //[Xml.Serialization.XmlEnumAttribute("HIGH RISE CONDO")]
        HIGHRISECONDO,

        //[Xml.Serialization.XmlEnumAttribute("LOW RISE CONDO")]
        LOWRISECONDO,

        PUD,

        //[Xml.Serialization.XmlEnumAttribute("MOBILE HOME")]
        MOBILEHOME,

        //[Xml.Serialization.XmlEnumAttribute("MANUFACTURED HOME")]
        MANUFACTUREDHOME,

        //[Xml.Serialization.XmlEnumAttribute("MIXED USE")]
        MIXEDUSE,

        CONDOTEL,

        COOP,

        COMMERCIAL,

        FARM,

        //[Xml.Serialization.XmlEnumAttribute("HOME BUSINESS")]
        HOMEBUSINESS,

        LAND,

        OTHER,

        MNL,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_ASSET_REAL_ESTATEProperty_disposition {
        S,

        H,

        P,

        R,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_ASSET_VEHICLE extends BASE_ASSET {
        public vehicle_year: string;
        public vehicle_make: string;
        public vehicle_model: string;
        public vehicle_vin: string;
        public vehicle_title_owner: string;
        public vehicle_title_number: string;
        public vehicle_color: string;
        public vehicle_miles: number;
        public vehicle_milesSpecified: boolean;
        public vehicle_license_plate: string;
        public vehicle_seller: string;
        public vehicle_has_proof_insurance: BASE_APPLICANTDeclined_answer_race_gender;
        public vehicle_has_proof_insuranceSpecified: boolean;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_CONTACT_INFO {
        public preferred_contact_method: BASE_CONTACT_INFOPreferred_contact_method;
        public preferred_contact_methodSpecified: boolean;
        public cell_phone: string;
        public cell_phone_country: base_phone_country_alpha2;
        public fax_number: string;
        public fax_number_country: base_phone_country_alpha2;
        public email: string;
        public home_phone: string;
        public home_phone_country: base_phone_country_alpha2;
        public work_phone: string;
        public work_phone_country: base_phone_country_alpha2;
        public work_phone_extension: string;
        constructor() {
            this.cell_phone_country = base_phone_country_alpha2.US;
            this.fax_number_country = base_phone_country_alpha2.US;
            this.home_phone_country = base_phone_country_alpha2.US;
            this.work_phone_country = base_phone_country_alpha2.US;
        }
    }

    export enum base_phone_country_alpha2 {
        //[Xml.Serialization.XmlEnumAttribute("")]
        Item,

        AX,

        AF,

        AL,

        DZ,

        AS,

        AD,

        AO,

        AI,

        AQ,

        AG,

        AR,

        AM,

        AW,

        AU,

        AT,

        AZ,

        BS,

        BH,

        BD,

        BB,

        BY,

        BE,

        BZ,

        BJ,

        BM,

        BT,

        BO,

        BA,

        BW,

        BV,

        BR,

        IO,

        BN,

        BG,

        BF,

        BI,

        KH,

        CM,

        CV,

        CA,

        KY,

        CF,

        TD,

        CL,

        CN,

        CX,

        CC,

        CO,

        KM,

        CD,

        CG,

        CK,

        CR,

        CI,

        HR,

        CU,

        CY,

        CZ,

        DK,

        DJ,

        DM,

        DO,

        TL,

        EC,

        SV,

        EG,

        GQ,

        ER,

        EE,

        ET,

        FK,

        FO,

        FJ,

        FI,

        FR,

        GF,

        PF,

        TF,

        GA,

        GM,

        GE,

        DE,

        GH,

        GI,

        GR,

        GL,

        GD,

        GP,

        GU,

        GT,

        GN,

        GG,

        GW,

        GY,

        HT,

        HM,

        HN,

        HK,

        HU,

        IS,

        IN,

        ID,

        IR,

        IQ,

        IE,

        IM,

        IL,

        IT,

        JM,

        JP,

        JE,

        JO,

        KZ,

        KE,

        KI,

        KW,

        KG,

        LA,

        LV,

        LB,

        LS,

        LR,

        LY,

        LI,

        LT,

        LU,

        MO,

        MK,

        MG,

        MW,

        MY,

        MV,

        ML,

        MT,

        MH,

        MQ,

        MR,

        MU,

        YT,

        MX,

        FM,

        MD,

        MC,

        MN,

        ME,

        MS,

        MA,

        MZ,

        MM,

        NA,

        NR,

        NP,

        NL,

        AN,

        NC,

        NZ,

        NI,

        NE,

        NG,

        NU,

        NF,

        KP,

        MP,

        NO,

        OM,

        PK,

        PW,

        PS,

        PA,

        PG,

        PY,

        PE,

        PH,

        PN,

        PL,

        PT,

        PR,

        QA,

        RE,

        RO,

        RW,

        RU,

        BL,

        SH,

        KN,

        LC,

        MF,

        PM,

        VC,

        WS,

        SM,

        ST,

        SA,

        SN,

        RS,

        SC,

        SL,

        SG,

        SK,

        SI,

        SB,

        SO,

        ZA,

        GS,

        KR,

        ES,

        LK,

        SD,

        SR,

        SJ,

        SZ,

        SE,

        CH,

        SY,

        TW,

        TJ,

        TZ,

        TH,

        TG,

        TK,

        TO,

        TT,

        TN,

        TR,

        TM,

        TC,

        TV,

        UG,

        UA,

        AE,

        GB,

        UY,

        US,

        UM,

        UZ,

        VU,

        VA,

        VN,

        VG,

        VI,

        VE,

        YE,

        WF,

        EH,

        ZM,

        ZW
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_REFERENCE {
        public first_name: string;
        public last_name: string;
        public address: string;
        public city: string;
        public state: string;
        public zip: string;
        public phone: string;
        public relationship: string;
        public email: string;
    }
    
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_MONTHLY_INCOME {
        public OTHER_INCOME: BASE_MONTHLY_INCOMEOTHER_INCOME[];
        public is_monthly_income_base_salary_manual: BASE_APPLICANTDeclined_answer_race_gender;
        public is_monthly_income_base_salary_manualSpecified: boolean;
        public is_bah_included: BASE_APPLICANTDeclined_answer_race_gender;
        public is_bah_includedSpecified: boolean;
        public monthly_income_base_salary: number;
        public monthly_income_base_salarySpecified: boolean;
        public is_tax_exempt_monthly_income_base_salary: BASE_APPLICANTDeclined_answer_race_gender;
        public is_hmda_exempt_monthly_income_base_salary: BASE_APPLICANTDeclined_answer_race_gender;
        public monthly_income_over_time: number;
        public monthly_income_over_timeSpecified: boolean;
        public monthly_income_bonus: number;
        public monthly_income_bonusSpecified: boolean;
        public monthly_income_commission: number;
        public monthly_income_commissionSpecified: boolean;
        public monthly_income_dividends: number;
        public monthly_income_dividendsSpecified: boolean;
        public monthly_income_net_rental: number;
        public monthly_income_net_rentalSpecified: boolean;
        public income_verify_method: string;
        constructor() {
            this.is_tax_exempt_monthly_income_base_salary = BASE_APPLICANTDeclined_answer_race_gender.N;
            this.is_hmda_exempt_monthly_income_base_salary = BASE_APPLICANTDeclined_answer_race_gender.N;
        }
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_MONTHLY_INCOMEOTHER_INCOME {
        public monthly_income: number;
        public monthly_incomeSpecified: boolean;
        public monthly_income_description: string;
        public is_tax_exempt: BASE_APPLICANTDeclined_answer_race_gender;
        public is_hmda_exempt: BASE_APPLICANTDeclined_answer_race_gender;
        constructor() {
            this.is_tax_exempt = BASE_APPLICANTDeclined_answer_race_gender.N;
            this.is_hmda_exempt = BASE_APPLICANTDeclined_answer_race_gender.N;
        }
    }

    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_ADDRESS_THREE_LINE {
        public street_address_1: string;
        public street_address_2: string;
        public street_address_3: string;
        public country: string;
        public zip: string;
        public city: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_ADDRESS_STRICT {
        public street_address: string;
        public street_no: string;
        public street_direction: BASE_ADDRESS_STRICTStreet_direction;
        public street_directionSpecified: boolean;
        public street_name: string;
        public street_type: string;
        public unit_no: string;
        public verification_date: Date;
        public verification_dateSpecified: boolean;
        public city: string;
        public state: string;
        public zip: string;
        public county: string;
    }
    export enum BASE_ADDRESS_STRICTStreet_direction {
        EAST,

        NORTH,

        NORTHEAST,

        SOUTH,

        SOUTHEAST,

        WEST,

        NORTHWEST,

        SOUTHWEST,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[Xml.Serialization.XmlIncludeAttribute(typeof(VEHICLE_BASE_APPLICANT))]
        [Xml.Serialization.XmlIncludeAttribute(typeof(VEHICLE_APPLICANT))]
        [CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_APPLICANT {
        public ID_CARD: BASE_ID_CARD;
        public ID_CARD2: BASE_ID_CARD;
        public APPROVAL_DENIAL_REASON: string;
        public INCOME_COMMENTS: string;
        public APPLICANT_QUESTIONS: BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTION[];
        public borrower_id: string;
        public is_declined: string;
        public first_name: string;
        public last_name: string;
        public m_initial: string;
        public middle_name: string;
        public suffix: BASE_APPLICANTSuffix;
        public suffixSpecified: boolean;
        public ssn: string;
        public dob: Date;
        public dobSpecified: boolean;
        public dependents: string;
        public ages_of_dependents: string;
        public mother_maiden_name: string;
        public citizenship: BASE_APPLICANTCitizenship;
        public citizenshipSpecified: boolean;
        public member_number: string;
        public bankruptcy_score: string;
        public membership_months: string;
        public marital_status: BASE_APPLICANTMarital_status;
        public marital_statusSpecified: boolean;
        public gender: BASE_APPLICANTGender;
        public genderSpecified: boolean;
        public ethnicity: BASE_APPLICANTEthnicity;
        public ethnicitySpecified: boolean;
        public number_in_household: string;
        public member_type: BASE_APPLICANTMember_type;
        public member_typeSpecified: boolean;
        public race: base_race_type_single[];
        public credit_score: string;
        public custom_score: string;
        public custom_score_2: number;
        public custom_score_2Specified: boolean;
        public declined_answer_race_gender: BASE_APPLICANTDeclined_answer_race_gender;
        public declined_answer_race_genderSpecified: boolean;
        public not_applicable_race_gender: BASE_APPLICANTDeclined_answer_race_gender;
        public not_applicable_race_genderSpecified: boolean;
        public applicant_type: base_applicant_type;
        public is_non_signing_spouse: BASE_APPLICANTDeclined_answer_race_gender;
        public is_non_signing_spouseSpecified: boolean;
        public allow_direct_mail: BASE_APPLICANTDeclined_answer_race_gender;
        public allow_direct_mailSpecified: boolean;
        public allow_email_solicit: BASE_APPLICANTDeclined_answer_race_gender;
        public allow_email_solicitSpecified: boolean;
        public allow_telemarket: BASE_APPLICANTDeclined_answer_race_gender;
        public allow_telemarketSpecified: boolean;
        public allow_share_info: BASE_APPLICANTDeclined_answer_race_gender;
        public allow_share_infoSpecified: boolean;
        public security_code: string;
        public is_ofac_id_ver: BASE_APPLICANTDeclined_answer_race_gender;
        public is_ofac_id_verSpecified: boolean;
        public id_authentication_result: BASE_APPLICANTId_authentication_result;
        public id_authentication_resultSpecified: boolean;
        public is_fraud_prescore: BASE_APPLICANTDeclined_answer_race_gender;
        public is_fraud_prescoreSpecified: boolean;
        public legal_state_of_residency: string;
        public relation_to_primary: string;
        public is_fraud_fas: BASE_APPLICANTDeclined_answer_race_gender;
        public is_fraud_fasSpecified: boolean;
        public is_high_risk_consumer: BASE_APPLICANTDeclined_answer_race_gender;
        public is_high_risk_consumerSpecified: boolean;
        public is_thin: BASE_APPLICANTDeclined_answer_race_gender;
        public is_thinSpecified: boolean;
        public contact_index: string;
        public mla_covered_status: string;
        public mla_covered_date: Date;
        public mla_covered_dateSpecified: boolean;
        public AnyAttr: any;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTION {
        public APPLICANT_QUESTION_ANSWER: BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTIONAPPLICANT_QUESTION_ANSWER[];
        public question_name: string;
        public question_type: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_APPLICANT_QUESTIONSAPPLICANT_QUESTIONAPPLICANT_QUESTION_ANSWER {
        public answer_text: string;
        public answer_value: string;
    }
    export enum BASE_APPLICANTSuffix {
        JR,

        SR,

        II,

        III,

        IV,

        V,

        VI,

        VII,

        VIII,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_APPLICANTCitizenship {
        USCITIZEN,

        PERMRESIDENT,

        NONPERMRESIDENT,

        NONRESIDENTALIEN,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_APPLICANTMarital_status {
        SEPARATED,

        UNMARRIED,

        MARRIED,

        DOMESTIC_PARTNER,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_APPLICANTGender {
        MALE,

        FEMALE,

        OTHER,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_APPLICANTEthnicity {
        HISPANIC,

        NOT_HISPANIC,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum BASE_APPLICANTMember_type {
        CURRENT,

        NEW,

        //[Xml.Serialization.XmlEnumAttribute("PRE-APPROVED")]
        PREAPPROVED,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    export enum base_race_type_single {
        AMERICAN_INDIAN,

        ASIAN,

        BLACK,

        PACIFIC_ISLANDER,

        WHITE
    }
   
    export enum BASE_APPLICANTId_authentication_result {
        FAIL,

        INCOMPLETE,

        PASS,

        REVIEW,

        NOT_REQUIRE
    }
    /*[Xml.Serialization.XmlIncludeAttribute(typeof(VEHICLE_LOAN))]
        [CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
    export class APP {
        public version: number;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_BASE_APPLICANTPREVIOUS_ADDRESS extends BASE_ADDRESS_LOOSE {
        public occupancy_status: VEHICLE_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status;
        public occupancy_statusSpecified: boolean;
        public occupancy_duration: string;
        public other_occupancy_description: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_BASE_APPLICANTMAILING_ADDRESS extends BASE_ADDRESS_LOOSE {
        public is_current: BASE_APPLICANTDeclined_answer_race_gender;
        constructor() {
            super();
            this.is_current = BASE_APPLICANTDeclined_answer_race_gender.Y;
        }
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_BASE_APPLICANTFINANCIAL_INFO {
        public CURRENT_EMPLOYMENT: VEHICLE_BASE_APPLICANTFINANCIAL_INFOCURRENT_EMPLOYMENT[];
        public PREVIOUS_EMPLOYMENT: VEHICLE_BASE_APPLICANTFINANCIAL_INFOPREVIOUS_EMPLOYMENT[];
        public MONTHLY_INCOME: BASE_MONTHLY_INCOME;
        public MONTHLY_DEBT: BASE_MONTHLY_DEBT;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_BASE_APPLICANTFINANCIAL_INFOCURRENT_EMPLOYMENT extends BASE_EMPLOYMENT {
        public employee_of_lender_type: base_employee_of_lender_types;
        public employee_of_lender_typeSpecified: boolean;
        public is_employee_of_lender: BASE_APPLICANTDeclined_answer_race_gender;
        public is_employee_of_lenderSpecified: boolean;
        public profession_months: string;
    }
    export enum base_employee_of_lender_types {
        NONE,

        EMPLOYEE,

        MANAGER,

        EXECUTIVE,

        BOARDMEMBER,

        EMPLOYEEFAMILY,

        BOARDFAMILY,

        OTHER
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_BASE_APPLICANTFINANCIAL_INFOPREVIOUS_EMPLOYMENT extends BASE_EMPLOYMENT {
        public monthly_income: number;
        public monthly_incomeSpecified: boolean;
        public employment_end_date: Date;
        public employment_end_dateSpecified: boolean;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_ASSET_LISTASSET extends BASE_ASSET {

    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_APPLICANTCREDIT_REPORT {
        public report_id: number;
        public report_format: VEHICLE_APPLICANTCREDIT_REPORTReport_format;
        public encoding: VEHICLE_APPLICANTCREDIT_REPORTEncoding;
        public encodingSpecified: boolean;
        public Value: string;
    }
    export enum VEHICLE_APPLICANTCREDIT_REPORTReport_format {
        RAW_XML,

        MCL_COMMON,

        MISMO
    }
    export enum VEHICLE_APPLICANTCREDIT_REPORTEncoding {
        CDATA,

        INNER_TEXT,

        BASE64
    }


    export enum business_applicant_type {
        //[Xml.Serialization.XmlEnumAttribute("")]
        Item,

        C,

        G
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_LOANLOAN_INFO extends VEHICLE_LOAN_INFO {

    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_PRICE_ADJUSTMENT_LISTPRICE_ADJUSTMENT {
        public adjust_type: BASE_PRICE_ADJUSTMENT_LISTPRICE_ADJUSTMENTAdjust_type;
        public value: number;
        public valueSpecified: boolean;
        public description: string;
    }
    export enum BASE_PRICE_ADJUSTMENT_LISTPRICE_ADJUSTMENTAdjust_type {
        RATE,

        APR,

        PMT_P
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_TASK_LISTTASK {
        public ASSIGNTO: BASE_PARTY;
        public COMPLETE: BASE_PARTY;
        public CREATE: BASE_PARTY;
        public DUELOAN: BASE_PARTY;
        public APPLIEDSET: BASE_TASK_LISTTASKAPPLIEDSET;
        public REQUIRESET: BASE_TASK_LISTTASKREQUIRESET;
        public date_due: string;
        public date_completed: string;
        public date_created: string;
        public description: string;
        public required: string;
        public completed: string;
        public category: string;
        public priority: string;
        public position: string;
        public assignto_officer: string;
        public complete_officer: string;
        public create_officer: string;
        public dueloan_officer: string;
        public is_applied: string;
        public is_required: string;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_TASK_LISTTASKAPPLIEDSET {
        public conditionset_hash: string;
        public encoding: string;
        public Text: string[];
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class BASE_TASK_LISTTASKREQUIRESET {
        public conditionset_hash: string;
        public encoding: string;
        public Text: string[];
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_LOANFUNDING extends VEHICLE_FUNDING_INFO {
        public INSURANCE_OPTIONS: VEHICLE_LOANFUNDINGINSURANCE_OPTIONS;
        public CLOSE_END_INFO: BASE_CLOSE_END_INFO;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_LOANFUNDINGINSURANCE_OPTIONS {
        public INSURANCE_OPTION: VEHICLE_INSURANCE[];
        public loan_class: VEHICLE_LOANFUNDINGINSURANCE_OPTIONSLoan_class;
        public loan_classSpecified: boolean;
        public description: string;
        public is_mapr_gap: BASE_APPLICANTDeclined_answer_race_gender;
        public is_mapr_gapSpecified: boolean;
        public is_mapr_sc: BASE_APPLICANTDeclined_answer_race_gender;
        public is_mapr_scSpecified: boolean;
        public lenders_protection_certificate_number: string;
        public borrower_1_index: string;
        public borrower_1_is_spouse: string;
        public borrower_2_index: string;
        public borrower_2_is_spouse: string;
    }
    export enum VEHICLE_LOANFUNDINGINSURANCE_OPTIONSLoan_class {
        O,

        C,

        //[Xml.Serialization.XmlEnumAttribute("")]
        Item
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_LOANCOMMENTS extends BASE_COMMENTS {
        public UNABLE_TO_FUND: Object;
        public MEMBER_APPROVAL_DENIAL_REASONS: Object;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_LOANVEHICLES {
        public VEHICLE: VEHICLE_LOANVEHICLESVEHICLE[];
        public TRADE_IN: VEHICLE_TRADE_IN;
    }
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
        [SerializableAttribute()]
        [Diagnostics.DebuggerStepThroughAttribute()]
        [ComponentModel.DesignerCategoryAttribute("code")]
        [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
    export class VEHICLE_LOANVEHICLESVEHICLE extends VEHICLE_FINANCING {

    }

/*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
    [SerializableAttribute()]
    [Diagnostics.DebuggerStepThroughAttribute()]
    [ComponentModel.DesignerCategoryAttribute("code")]
    [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class VEHICLE_BASE_APPLICANTCURRENT_ADDRESS extends BASE_CURRENT_ADDRESS_CHOICE {
    public occupancy_status: VEHICLE_BASE_APPLICANTCURRENT_ADDRESSOccupancy_status;
    public occupancy_statusSpecified: boolean;
    public occupancy_duration: string;
    public other_occupancy_description: string;
}

/*[Xml.Serialization.XmlIncludeAttribute(typeof(VEHICLE_APPLICANT))]
    [CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
    [SerializableAttribute()]
    [Diagnostics.DebuggerStepThroughAttribute()]
    [ComponentModel.DesignerCategoryAttribute("code")]
    [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class VEHICLE_BASE_APPLICANT extends BASE_APPLICANT {
    public CURRENT_ADDRESS: VEHICLE_BASE_APPLICANTCURRENT_ADDRESS;
    public PREVIOUS_ADDRESS: VEHICLE_BASE_APPLICANTPREVIOUS_ADDRESS;
    public MAILING_ADDRESS: VEHICLE_BASE_APPLICANTMAILING_ADDRESS;
    public FINANCIAL_INFO: VEHICLE_BASE_APPLICANTFINANCIAL_INFO;
    public REFERENCE: BASE_REFERENCE[];
    public CONTACT_INFO: BASE_CONTACT_INFO;
    public ASSETS: BASE_ASSET[];
    public DECLARATIONS: DECLARATIONS;
    public paper_grade: string;
}


/*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
    [SerializableAttribute()]
    [Diagnostics.DebuggerStepThroughAttribute()]
    [ComponentModel.DesignerCategoryAttribute("code")]
    [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class VEHICLE_APPLICANT extends VEHICLE_BASE_APPLICANT {
    public SPOUSE: VEHICLE_BASE_APPLICANT;
    public CREDIT_REPORT: VEHICLE_APPLICANTCREDIT_REPORT;
    public FOM_ANSWERED_QUESTIONS: FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTION[];
    public business_applicant_type: business_applicant_type;
    public business_applicant_typeSpecified: boolean;
    public co_business_applicant_type: business_applicant_type;
    public co_business_applicant_typeSpecified: boolean;
    public is_principal_business_owner: BASE_APPLICANTDeclined_answer_race_gender;
    public is_principal_business_ownerSpecified: boolean;
    public co_is_principal_business_owner: BASE_APPLICANTDeclined_answer_race_gender;
    public co_is_principal_business_ownerSpecified: boolean;
    public is_fraud_credit: BASE_APPLICANTDeclined_answer_race_gender;
    public is_fraud_creditSpecified: boolean;
    public is_ofac_credit: BASE_APPLICANTDeclined_answer_race_gender;
    public is_ofac_creditSpecified: boolean;
    public combined_unsecured_aggregate_amount_with_lender: number;
    public combined_unsecured_aggregate_amount_with_lenderSpecified: boolean;
    public combined_secured_aggregate_amount_with_lender_no_mortgage: number;
    public combined_secured_aggregate_amount_with_lender_no_mortgageSpecified: boolean;
}

/*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
    [SerializableAttribute()]
    [Diagnostics.DebuggerStepThroughAttribute()]
    [ComponentModel.DesignerCategoryAttribute("code")]
    [Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class VEHICLE_LOAN extends APP {
    public APPLICANTS: VEHICLE_LOANAPPLICANTS;
    public LOAN_INFO: VEHICLE_LOANLOAN_INFO;
    public PRICE_ADJUSTMENTS: BASE_PRICE_ADJUSTMENT_LISTPRICE_ADJUSTMENT[];
    public LOAN_STATUS: VEHICLE_STATUS;
    public DISBURSEMENTS: BASE_DISBURSEMENT[];
    public TASK_LIST: BASE_TASK_LISTTASK[];
    public FUNDING: VEHICLE_LOANFUNDING;
    public COMMENTS: VEHICLE_LOANCOMMENTS;
    public STRUCTURED_COMMENTS: BASE_STRUCTURED_COMMENTS;
    public VEHICLES: VEHICLE_LOANVEHICLES;
    public CUSTOM_QUESTIONS: BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION[];
    public CONTACTS: BASE_LOAN_CONTACTS;
    public SYSTEM: VEHICLE_SYSTEM[];
    public HMDA_INFO: BASE_HMDA_INFO;
    public HMDA_XML: BASE_HMDA_XML;
    public INTEGRATION_DATA_LIST: INTEGRATION_DATA[];
    public MODIFICATION_HISTORY: BASE_MODIFICATION_HISTORY;
    public EVENT_HISTORY: BASE_EVENT_HISTORY;
    public WEBMS_HISTORY: BASE_WEBMS_HISTORY;
    public VEHICLE_BUSINESS_INFORMATIONS: VEHICLE_BUSINESS_INFORMATIONS;
}
    /*[CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
    [SerializableAttribute()]
    [Diagnostics.DebuggerStepThroughAttribute()]
    [ComponentModel.DesignerCategoryAttribute("code")]
    [Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/

export class VEHICLE_LOAN11 extends VEHICLE_LOAN {

}