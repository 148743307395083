export class FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTIONFOM_ANSWER {
    public text: string;
    public value: string;
    public field_type: FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTIONFOM_ANSWERField_type;
    public field_typeSpecified: boolean;
}
export enum FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTIONFOM_ANSWERField_type {
    TEXTBOX,

    DROPDOWN,

    GEOCODE_LOOKUP
}

export class FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTION {
    public text_template: string;
    public FOM_ANSWERS: FOM_ANSWERED_QUESTIONSFOM_ANSWERED_QUESTIONFOM_ANSWER[];
    public name: string;
}

export enum BASE_EMPLOYMENTEmployment_status {
    EM,

    MI,

    HO,

    //OW,

    RE,

    SE,

    ST,

    UN,

    OT,

    RM,

    GOV
}

export enum BASE_EMPLOYMENTProfession_title {
    'Profession/Job Title',

    'Profession/MOS',

    'Former Profession/Job Title',

    'Former Profession/MOS',
}

/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_CUSTOM_QUESTIONSCUSTOM_QUESTION {
    public CUSTOM_QUESTION_ANSWER: BASE_CUSTOM_QUESTIONSCUSTOM_QUESTIONCUSTOM_QUESTION_ANSWER[];
    public question_name: string;
    public question_type: string;
}
/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(AnonymousType = true, Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_CUSTOM_QUESTIONSCUSTOM_QUESTIONCUSTOM_QUESTION_ANSWER {
    public answer_text: string;
    public answer_value: string;
}


/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_MONTHLY_DEBT {
    public monthly_liability: number;
    public monthly_liabilitySpecified: boolean;
    public monthly_housing_cost: number;
    public monthly_housing_costSpecified: boolean;
    public monthly_rent: number;
    public monthly_rentSpecified: boolean;
    public monthly_mortgage_payment: number;
    public monthly_mortgage_paymentSpecified: boolean;
    public job_expense: number;
    public job_expenseSpecified: boolean;
    public alimony: number;
    public alimonySpecified: boolean;
    public child_support: number;
    public child_supportSpecified: boolean;
    public separate_maintenance_expense: number;
    public separate_maintenance_expenseSpecified: boolean;
    public tax_expense: number;
    public tax_expenseSpecified: boolean;
    public other_expense_1: number;
    public other_expense_1Specified: boolean;
    public other_expense_2: number;
    public other_expense_2Specified: boolean;
    public other_expense_description_1: string;
    public other_expense_description_2: string;
    public aggregate_amount_with_lender: number;
    public aggregate_amount_with_lenderSpecified: boolean;
    public aggregate_amount_with_lender_secured_no_mortgage: number;
    public aggregate_amount_with_lender_secured_no_mortgageSpecified: boolean;
}

export enum base_YN {
    Y,

    N,


    Item
}


export enum BASE_LOAN_INFOPreapproval_type {
    CP,

    CS,


    Item
}

export enum BASE_LOAN_INFOApp_subtype {
    IVL,

    DVL,

    LVL,

    SCC,

    UCC,

    LOCPL,

    SPL,

    UPL,

    IHE,

    LOCHE,

    CCBL,

    IBL,

    LOCBL,

    VLBL,


    Item
}

/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_LOAN_INFO {
    public rate_code: string;
    public max_underwrite_amount: number;
    public max_underwrite_amountSpecified: boolean;
    public payment_due_day: string;
    public payment_due_day2: string;
    public epl_fees_total: number;
    public epl_fees_totalSpecified: boolean;
    public is_claimed: base_YN;
    public is_claimedSpecified: boolean;
    public is_complete_consumer: base_YN;
    public is_complete_consumerSpecified: boolean;
    public require_product_refresh: base_YN;
    public require_product_refreshSpecified: boolean;
    public is_addon: base_YN;
    public is_addonSpecified: boolean;
    public is_amendment: base_YN;
    public is_amendmentSpecified: boolean;
    public rate_adjustment: number;
    public rate_adjustmentSpecified: boolean;
    public rate_adjustment_reason: string;
    public solve_for: string;
    public override_code: string;
    public override_tier: string;
    public is_possible_dupe: base_YN;
    public is_possible_dupeSpecified: boolean;
    public is_fraud_manual: base_YN;
    public is_fraud_manualSpecified: boolean;
    public is_ofac_manual: base_YN;
    public is_ofac_manualSpecified: boolean;
    public is_possible_dupe_manual: base_YN;
    public is_possible_dupe_manualSpecified: boolean;
    public preapproval_type: BASE_LOAN_INFOPreapproval_type;
    public preapproval_typeSpecified: boolean;
    public app_subtype: BASE_LOAN_INFOApp_subtype;
    public app_subtypeSpecified: boolean;
    public campaign_code: string;
    public is_workout_loan: base_YN;
    public is_workout_loanSpecified: boolean;
    public is_solvefor_manual: base_YN;
    public is_solvefor_manualSpecified: boolean;
    public tier: string;
    public tier_previous: string;
    public is_prequalification_applied: base_YN;
    public is_prequalification_appliedSpecified: boolean;
    public is_ofac: base_YN;
    public is_ofacSpecified: boolean;
    public is_high_risk_consumer: base_YN;
    public is_high_risk_consumerSpecified: boolean;
    public is_high_risk_consumer_manual: base_YN;
    public is_high_risk_consumer_manualSpecified: boolean;
    public product_reference_id: string;
}

/*[System.CodeDom.Compiler.GeneratedCodeAttribute("xsd", "4.8.3928.0")]
[System.SerializableAttribute()]
[System.Diagnostics.DebuggerStepThroughAttribute()]
[System.ComponentModel.DesignerCategoryAttribute("code")]
[System.Xml.Serialization.XmlTypeAttribute(Namespace = "http://www.meridianlink.com/CLF")]*/
export class BASE_EMPLOYMENT {
    public employment_start_date: Date;
    public employment_start_dateSpecified: boolean;
    public employed_months: string;
    public employment_address: string;
    public employment_city: string;
    public employment_state: string;
    public employment_zip: string;
    public occupation: string;
    public employer: string;
    public employment_phone: string;
    public employment_status: BASE_EMPLOYMENTEmployment_status;
    public employment_statusSpecified: boolean;
    public other_employment_description: string;
    public supervisor_name: string;
    public pay_grade: string;
    public ets: Date;
    public etsSpecified: boolean;
    public employment_weekly_hours: number;
    public employment_weekly_hoursSpecified: boolean;
    public employment_business_type: string;
}

export enum FinalPageEnum{
    None,
    Congratulation, //1.3
    Wait2Days,     //1.2
    ErrorReceived // 1.1
}

export enum BASE_CONTACT_INFOPreferred_contact_method {
    HOME,

    WORK,

    CELL
}

export enum base_applicant_type {
    P,

    C,

    S,

    G
}

export enum APP_STATUSValue {
    /// <remarks/>
    NEW,
    
    /// <remarks/>
    DECLINED,
    
    /// <remarks/>
    APPROVED,
    
    /// <remarks/>
    REFERRED,
    
    /// <remarks/>
    CANCELED,
    
    /// <remarks/>
    INCOMPLETE,
    
    /// <remarks/>
    PENDING,
    
    /// <remarks/>
    INSTANTAPPROVED,
    
    /// <remarks/>
    FRAUD,
    
    /// <remarks/>
    AP,
    
    /// <remarks/>
    NMI,
    
    /// <remarks/>
    MEMDC,
    

    PENDING1,
    

    INSTANTAPPROVED1,
    
    /// <remarks/>
    DUP,
}