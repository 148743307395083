import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { XPRESS_LOAN, XPRESS_ACCOUNT_TYPES_APPROVEDACCOUNT_TYPE, XPRESS_FUNDING_SOURCESFUNDING_SOURCE, XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type, XPRESS_ACCOUNT_TYPESACCOUNT_TYPE, XPRESS_LOANINTERESTED_ACCOUNTS, XPRESS_LOAN_INFOStatus } from 'src/models/xpress_loan.model';
import { MatDialog } from '@angular/material/dialog';
import { CreditCardFormComponent } from './../credit-card-form/credit-card-form.component';
import { FUNDDEPOSIT_INPUTREQUEST, INPUTREQUESTCARD_DETAILS } from 'src/models/fund-deposit.model';
import { ApiService } from 'src/shared/api.service';
import { CardDetails } from './../credit-card-form/card-details';
import * as _ from 'lodash';
import { BankFormComponent } from './../bank-form/bank-form.component';
import { AnswerQuestionsComponent } from '../../review-page/answer-questions/answer-questions.component';
import { ApplicantService } from 'src/shared/applicant.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { LoanTypeEnum } from 'src/models/loan-type.enum';
import { LoanAppService } from 'src/shared/loan-app.service';
import { CREDITCARD_LOAN11 } from 'src/models/creditcard_loan.model';
import { VEHICLE_LOAN } from 'src/models/vehicle_loan.model';
import { PERSONAL_LOAN } from 'src/models/personal_loan.model';
import { APP_STATUSValue, FinalPageEnum } from 'src/models/common.model';
import { AppComponent } from 'src/app/app.component';
import { MemberService } from 'src/shared/member.service';
import { ApplicantLoanComponent } from 'src/app/applicant-loan/applicant-loan.component';
import { ActivatedRoute, Route } from '@angular/router';
import { NmaoWfService } from 'src/shared/nmao-wf.service';
import { FinalPageComponent } from 'src/components/final-page/final-page.component';
import { LoanApiLogService } from 'src/shared/loan-api-log.service';

declare var gtag: any;

@Component({
  selector: 'app-branch-funding-page',
  templateUrl: './branch-funding-page.component.html',
  styleUrls: ['./branch-funding-page.component.css']
})
export class BranchFundingPageComponent implements OnInit {

  selectedFundingType: XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type;
  fundingModel: any={}
  
  @Input() creditCardLoan: CREDITCARD_LOAN11;
  @Output() creditCardLoanChange: EventEmitter<CREDITCARD_LOAN11> = new EventEmitter<CREDITCARD_LOAN11>();

  @Input() vehicleLoan: VEHICLE_LOAN;
  @Output() vehicleLoanChange: EventEmitter<VEHICLE_LOAN> = new EventEmitter<VEHICLE_LOAN>();

  @Input() personalLoan: PERSONAL_LOAN;
  @Output() personalLoanChange: EventEmitter<PERSONAL_LOAN> = new EventEmitter<PERSONAL_LOAN>();

  @Input() xpressLoan:XPRESS_LOAN ={} as any;

  @Input() selectedProducts: any[];

  decisionAppId: string;
  captchaRequired: boolean = false;
  reactiveForm: FormGroup;
  captchaToken: string;
  ApplicantLoanComponent = ApplicantLoanComponent;
  soResponses: any = {
    xpress: {},
    //decision: {}
  };

  selectedFundingSource: any;

  interestedAccounts : XPRESS_ACCOUNT_TYPESACCOUNT_TYPE[] = [];

  cardDetails:CardDetails;
  // fundingSourceCreditCard : XPRESS_FUNDING_SOURCESFUNDING_SOURCE;
  // fundingSourceAnotherFinancials: XPRESS_FUNDING_SOURCESFUNDING_SOURCE [];

  isLoading = false;

  myForm = new FormGroup({}) // Instantiating our form

  isCash: boolean = false;


  bankDetails: any = {};
  selectMethodCard;

  param: any = {};

  public fundingSources:  any = [];
  public isShowBank: boolean = false;
  public isShowCreditCard: boolean = false;
  constructor(private fb: FormBuilder, private dialog: MatDialog, private apiService: ApiService,
    private loanAppService: LoanAppService,
    private memberService: MemberService,
    private applicantService: ApplicantService,
    private route: ActivatedRoute,
    private nmaoWfService: NmaoWfService,
    private loanApiLogService: LoanApiLogService
    ) { 
     
  }

  ngOnInit(): void {
    this.param = this.route.snapshot.queryParams;
    this.getFundingSources(this.param.at, "ref="+ this.param.ref);

    this.reactiveForm = new FormGroup({
      recaptchaReactive: new FormControl(null, Validators.required)
    });

    this.interestedAccounts = this.applicantService.initInterestedAccounts(this.selectedProducts);

    this.myForm = this.fb.group({

    })

    _.each(this.interestedAccounts, acc=>{
      this.myForm.addControl(acc.account_name, new FormControl('',[Validators.required, Validators.min(acc.min_deposit)]))
    })

    this.memberService.checkMemberSubmit().subscribe(r => {
      if (r) {
        this.captchaRequired = true;
      }
    })

    gtag('event', 'go-To-Funding-Page', {
      'value':` User go to funding page`
    })
  }



  async ngAfterViewInit(){
    // var r = await this.nmaoWfService.getTestXaByJson('XA_type1_test1.json').toPromise();
    // this.xpressLoan = r.xPRESS_LOAN
    // console.log(this.xpressLoan)
  }

  openCreditCardForm(existingCard?){   
    if(!existingCard){
      existingCard=new  CardDetails("","",undefined,"","");
      existingCard.billing_ADDRESS_is_current = true
      try{
        existingCard.billing_street_address= this.xpressLoan.APPLICANTS[0].CURRENT_ADDRESS.LOOSE_ADDRESS.street_address_1,
        existingCard.billing_zip= this.xpressLoan.APPLICANTS[0].CURRENT_ADDRESS.LOOSE_ADDRESS.zip,
        existingCard.billing_city= this.xpressLoan.APPLICANTS[0].CURRENT_ADDRESS.LOOSE_ADDRESS.city,
        existingCard.billing_state=  this.xpressLoan.APPLICANTS[0].CURRENT_ADDRESS.LOOSE_ADDRESS.state
      
      }catch{
      }

    }

    this.dialog.open(CreditCardFormComponent, {
      panelClass: AppComponent.isDesktop ? 'col-md-6' : 'full-screen',
      scrollStrategy:  AppComponent.scrollStrategy,
      data:{
        existingCard: existingCard,
      }
    }).afterClosed().subscribe(r=>{
      console.log(r)
      if (r) {
        this.cardDetails = {...r};
        console.log( this.cardDetails)
        if(! this.xpressLoan.FUNDING_SOURCES){
          this.xpressLoan.FUNDING_SOURCES = [];
        }
        var fundingCreditCard =  _.find(this.fundingSources, {funding_type: XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type.CREDITCARD})
        var fundingSourceCreditCard = {
          clf_funding_source_id: fundingCreditCard.clf_funding_source_id,
          funding_type: fundingCreditCard.funding_type,
          cc_card_type: "Visa",
          name_on_card: this.cardDetails.cardHolder,
          cc_card_number: this.cardDetails.cardNumber,
          cc_card_last4: this.cardDetails.cardNumber.slice(-4),
          cc_card_exp_date: new Date(`${this.cardDetails.expirationYear}-${this.cardDetails.expirationMonth}-01`),
          cc_charge_failure_count: 0,
        } as any;
        fundingSourceCreditCard = {...existingCard,...this.cardDetails,...fundingSourceCreditCard}
        this.xpressLoan.FUNDING_SOURCES.push(fundingSourceCreditCard);
      }
    })
  }
  
  openBankForm(existingBank?){
    this.dialog.open(BankFormComponent, {
      panelClass: AppComponent.isDesktop ? 'col-md-4' : 'full-screen',
      scrollStrategy:  AppComponent.scrollStrategy,
      data:{
        existingBank: existingBank,
      },
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe(r=>{
      if (r) {
        this.bankDetails = {...r}
        var fundingBank =  _.find(this.fundingSources, {funding_type: XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type.BANK})
      }
      if(! this.xpressLoan.FUNDING_SOURCES){
        this.xpressLoan.FUNDING_SOURCES = [];
      }
      // r.clf_funding_source_id= "BANK" + this.xpressLoan.FUNDING_SOURCES.length + 1,
      r.clf_funding_source_id = fundingBank.clf_funding_source_id,
      r.funding_type = fundingBank.funding_type
      this.xpressLoan.FUNDING_SOURCES.push(r);
    })
  }

  setCashFundingSource(){
    this.xpressLoan.FUNDING_SOURCES = [
      _.find(this.fundingSources, {funding_type: XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type.CASH})
    ];
  }


  async submitXA(){
 

    //check if user select disabled funding type
    if(this.isDisabled(this.fundingSources[this.selectedFundingSource].maxAmount)){
      alert('Your amount is over the threshold, please check your amount again.');
      return;
    }

    if(this.getFundingInfoError()){
      alert(this.getFundingInfoError());
      return;
    }

    this.isLoading = true;

      //TODO
      //but if contain products of type 2, we must submit decisionloan also\
      var productType2 = _.find(this.selectedProducts, {applicationType: 2})
      if(productType2 != null){
        
        this.soResponses.decision = await this.loanAppService.submitDecisionLoan(productType2.loan_type, this.creditCardLoan, this.vehicleLoan, this.personalLoan).toPromise()
        
        var outputJsonObjDecision = JSON.parse(this.soResponses.decision.outputJson);
        this.decisionAppId = outputJsonObjDecision.OUTPUT.RESPONSE['@loan_id'];
      }

    this.xpressLoan.INTERESTED_ACCOUNTS ={
      ACCOUNT_TYPE : this.interestedAccounts
    }
   
    var submitMLResult = await  this.apiService.submitXpressApp(this.xpressLoan, '',this.captchaToken, 1).toPromise();
   
      this.soResponses.xpress = submitMLResult;
      this.soResponses.applicationType = 1;

      var checkFraudRes = true;
      
      var outputJsonObj = JSON.parse(this.soResponses.xpress.outputJson);
      var XAId =outputJsonObj.OUTPUT.RESPONSE['@loan_id'];
      this.soResponses.appId = XAId;
      this.soResponses.decisionAppId =  this.decisionAppId;
      //call fundingDeposit
      //this.fundingDeposit();

  
    
      // var fundingLog = {
      //   appId:XAId,
      //   cc_card_last4:this.cardDetails?.cardNumber?.slice(-4),
      //   cc_card_cvn: this.cardDetails?.ccv,
      //   createdDate : new Date(),
      //   fundingType: !!this.cardDetails?.cardNumber ? 1 : 2
      // }

      // this.apiService.saveFundingLog(fundingLog).subscribe(r=>{console.log('saved funding log')});

      // var currentStatus = await this.nmaoWfService.getCurrentStatus(XAId);
      // if(currentStatus.status=='FRAUD'){
      //   var addApprovedAccount_setAppStatusResult =   await this.nmaoWfService.addApprovedAccount_setAppStatus(XAId, XPRESS_LOAN_INFOStatus.FRAUD, this.selectedFundingType).toPromise()
      //   this.nmaoWfService.displayFinalPage(FinalPageEnum.Wait2Days);
      //   return;
      // }
      
      //Save Product code
      await this.loanApiLogService.saveProductCode(XAId, this.selectedProducts).toPromise();
      /// CHECK FRAUD AFTER SUBMIT
      checkFraudRes =  await this.nmaoWfService.checkFraudOrDup(XAId, this.selectedFundingType);
      if(!checkFraudRes){
        return checkFraudRes;
      }

      var preciseIdResult = await this.nmaoWfService.openPreciseId(XAId, null, this.xpressLoan, this.selectedProducts, this.soResponses, 1);
      console.log(preciseIdResult)
      if(await this.nmaoWfService.preciseId_check(preciseIdResult, XAId, this.selectedFundingType)){

      //efund
        var eFundsResult = await this.nmaoWfService.eFundsCall(XAId)
        if(await this.nmaoWfService.eFundsCall_check(eFundsResult, XAId, this.selectedFundingType)){
          //credit pullcard
          var creditReportResult = await this.nmaoWfService.creditPullCall(XAId)
          if(await this.nmaoWfService.creditPullCall_check(creditReportResult,  XAId,this.selectedFundingType)){
            //addApprovedAccount_setAppStatus
            var addApprovedAccount_setAppStatusResult =  await this.nmaoWfService.addApprovedAccount_setAppStatus(XAId, XPRESS_LOAN_INFOStatus.PENDING, this.selectedFundingType).toPromise()
            
            //fund deposit
            //? with funding page only
           var fundingCallResult = await this.nmaoWfService.fundingCall(this.buildFundingRequest());
           //If funding was bank account, then check for funding status as "PROCESSING"
           //IF funding was credit card, then check for funding status as "Funded"

           checkFraudRes =  await this.nmaoWfService.checkFraudOrDup(XAId, this.selectedFundingType);
           if(!checkFraudRes){
            return checkFraudRes;
          }

           if(fundingCallResult && this.nmaoWfService.fundingCallResult_check(XAId, this.selectedFundingType)){

            await this.nmaoWfService.setAppStatusFragment(XAId, APP_STATUSValue.INSTANTAPPROVED).toPromise()

             //simytar
             var symitarBookResult = await this.nmaoWfService.symitarBook(XAId, this.soResponses);
             var memberNumber =this.nmaoWfService.symitarBookResult_check(symitarBookResult)
             if(memberNumber){
               await this.nmaoWfService.presentAtomic(XAId);
               await this.nmaoWfService.openDocuSign(XAId, null,this.selectedProducts)
               FinalPageComponent.dataFinal.memberNumber= memberNumber;
               FinalPageComponent.dataFinal.firstName = this.xpressLoan.APPLICANTS[0].first_name
               this.nmaoWfService.displayFinalPage(FinalPageEnum.Congratulation)
               return;
             }
           }

          }
        }

      }

     var checkFraudRes=  await this.nmaoWfService.checkFraudOrDup(XAId, this.selectedFundingType);
      if(!checkFraudRes){
        return checkFraudRes;
      }

      //add approved-accounts and set status to REFERRED
      if(this.nmaoWfService.didAddApprovedAccounts){
          await this.nmaoWfService.setAppStatusFragment(XAId, APP_STATUSValue.REFERRED).toPromise()
      }else{
          await this.nmaoWfService.addApprovedAccount_setAppStatus(XAId, XPRESS_LOAN_INFOStatus.REFERRED, this.selectedFundingType).toPromise()
      }

      this.nmaoWfService.displayFinalPage(FinalPageEnum.Wait2Days)
   
      // var addApprovedAccount_setAppStatusResult = 
      // await (await this.nmaoWfService.addApprovedAccount_setAppStatus(XAId, XPRESS_LOAN_INFOStatus.INSTANTAPPROVED)).toPromise()
    

    //ggtag manager 
    gtag('event','deposit-product-completed',{
      'gtm.classes': 'funding-submit',
      'value': ' Deposit app completed'
    })
  }
  getFundingInfoError() {
    if(this.selectedFundingSource == 0 && !this.bankDetails.bank_name_on_card){
      return 'Please add a bank account';
    }
    if(this.selectedFundingSource == 1 && !this.cardDetails.cardHolder){
      return 'Please add a credit card';
    }
    return null;
  }

  buildFundingRequest(){


  var requestJson = JSON.stringify((!!this.cardDetails?.cardNumber ? [{last4:this.cardDetails?.cardNumber?.slice(-4), cvn:this.cardDetails?.ccv}] : []));

   var requestJsonCCsEncrypted=  this.nmaoWfService.encryptRsa(requestJson);

    var request = {
      loanId:  this.soResponses.appId,
      requestJsonCCsEncrypted:  requestJsonCCsEncrypted
    }as any;
    return request;

  }


  // fundingDeposit() {
   
  //   this.apiService.fundDeposit(request).subscribe(r=>{})
  // }

  // async showPreciseId(){

  //   var outputJsonObj = JSON.parse(this.soResponses.xpress.outputJson);
  //   var XAId =outputJsonObj.OUTPUT.RESPONSE['@loan_id'];
  //   this.soResponses.appId = XAId;
  //   this.soResponses.decisionAppId =  this.decisionAppId;
  //   //this.fundingDeposit();



  //  // await this.nmaoWfService.openPreciseId(XAId,this.decisionAppId,this.xpressLoan,this.selectedProducts, this.soResponses, null)

    
  //     //go to complete step


  // }

  isDisabled(maxAmount){
    try{
      
      return this.getTotalDeposit() >maxAmount;
    }
    catch{
      return false;
    }
  }

 

  getTotalDeposit(){
      var total =0;
     _.each(this.interestedAccounts,i=>{
      total += parseFloat(i.amount_deposit.toString())
    })
    return total;
  }

  removeBankAcc(bankAcc){
    _.remove(this.xpressLoan.FUNDING_SOURCES, { bank_account_number: bankAcc.bank_account_number })
  }

  removeCC(cc){
    _.remove(this.xpressLoan.FUNDING_SOURCES, { cc_card_number: cc.cc_card_number })
  }

  resolvedCaptchaToken(captchaToken: string){
    console.log(captchaToken)
    this.captchaToken = captchaToken;
  }

  //google tag manager
  unfocusTotalDeposit(value){
    if(!value){
      gtag('event', 'unfocus-total-deposit',{
        'gtm.classes': 'total-deposit',
        'value': `User didn't list "How much to deposit"  `
      })
    }
  }

  
  checkFundingSource(){
    gtag('event', 'unConfirm-funding-source',{
      'value':` User haven’t selected the “Funding Source.” `
    })
  }

  chooseFundingMethod(value, i, ev){
    if(this.isDisabled(this.fundingSources[i].maxAmount)){
      ev.stopPropagation();
      return;
    }
    this.selectedFundingSource=i
    this.selectMethodCard = value
    switch (this.selectMethodCard) {
      case 'CREDITCARD':
        this.isShowCreditCard = true;
        this.isCash = false;
        this.isShowBank = false;
        this.selectedFundingType = XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type.CREDITCARD
        break;
      case 'BANK':
        this.isShowBank = true;
        this.isCash = false;
        this.isShowCreditCard = false;
        this.selectedFundingType = XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type.BANK
        break;
      case 'CASH':
        this.isCash = true;
        this.isShowBank = false;
        this.isShowCreditCard = false;
        this.selectedFundingType = XPRESS_FUNDING_SOURCESFUNDING_SOURCEFunding_type.CASH
        this.setCashFundingSource();
        break;
      default:
        break;
    }
    // alert(this.selectedFundingType)
  }

  getFundingSources(baseType, channelUri){
    this.apiService.getFundingSources(baseType, channelUri).subscribe(res => {
      this.fundingSources = res;
    })
  } 

}
