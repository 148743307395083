import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { FooterComponent } from 'src/components/footer/footer.component';
import { HeaderComponent } from 'src/components/header/header.component';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorComponent } from 'src/components/error/error.component';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { SharedModule } from 'src/shared/shared.module';
import { ApplicantLoanModule } from './applicant-loan/applicant-loan.module';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    HttpModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    NoopAnimationsModule,
    MatNativeDateModule,
    BrowserAnimationsModule
  ],
  entryComponents: [  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
