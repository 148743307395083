<div id="applicant_loan_component" [ngClass]="AppComponent.isDesktop ? 'container-xl' : 'container-fluid'" class="{{ApplicantLoanComponent.currentChannel?.channelName}}"
[ngStyle]="{'height': (selectedProducts?.length > 1 && currentStepId !== 2.5) ? 'auto' : ''}" >
  <!-- –– Start Page Container ––-->

  <!--  -->
  <ng-container *ngIf="ApplicantLoanComponent.isCoBrand">
    <app-cobrand-header [steps]="steps" [currentStepId]="currentStepId"></app-cobrand-header>
  </ng-container>

  <ng-container *ngIf="!ApplicantLoanComponent.isCoBrand">
    <app-header  [steps]="steps" [currentStepId]="currentStepId"></app-header>
  </ng-container>

  <hr style="border-top: 2px solid #E9ECEF;" class="mb-4">

  <div [ngStyle]="{'margin':  AppComponent.isDesktop ? '0 40px 60px 40px' : '0'}">
    <app-step-one [validationErrors]="validationErrors"  *ngIf="currentStepId == 1" [(creditCardLoan)]="creditCardLoan" [(vehicleLoan)]="vehicleLoan"
      [(personalLoan)]="personalLoan" [(xpressLoan)]="xpressLoan"
       [(eligibilityModel)]="eligibilityModel"
       [(customQuestions)]="customQuestions"
       (productsHaveChanged)="productsHaveChanged($event)" 
       #stepOne>
    </app-step-one>

    <app-review-page [validationErrors]="validationErrors"  *ngIf="currentStepId == 2" 
      [(creditCardLoan)]="creditCardLoan"
      [(vehicleLoan)]="vehicleLoan"
      [(personalLoan)]="personalLoan" 
      [selectedLoanType]="selectedLoanType"
      [selectedProducts]="selectedProducts"
      [(xpressLoan)]="xpressLoan"    
         [(eligibilityModel)]="eligibilityModel"
         [(customQuestions)]="customQuestions"
      [applicationType]="applicationType"> </app-review-page>

    <app-branch-funding-page  *ngIf="currentStepId == 2.5" [(xpressLoan)]="xpressLoan" [selectedProducts]="selectedProducts"
    [(creditCardLoan)]="creditCardLoan"
    [(vehicleLoan)]="vehicleLoan"
    [(personalLoan)]="personalLoan" 
    >
    </app-branch-funding-page>

    <!-- <app-branch-funding-page  *ngIf="currentStepId == 2.51" [(xpressLoan)]="xpressLoan" [selectedProducts]="selectedProducts"
    [(creditCardLoan)]="creditCardLoan"
    [(vehicleLoan)]="vehicleLoan"
    [(personalLoan)]="personalLoan" 
    >
    </app-branch-funding-page> -->

    <app-final-page  [soResponses]="soResponses"
    [selectedLoanType]="selectedLoanType"
    [isThereError]="isThereError"
      *ngIf="currentStepId == 3">
    </app-final-page>
  </div>

  <hr *ngIf="selectedProducts.length > 1" style="border-top: 2px solid #E9ECEF;" class="mt-4">

  <app-footer ></app-footer>
</div>
