<div mat-dialog-title style="text-align: center;">
  <h2 style="display: contents;">
      Answer Precise Questions <span >For {{currentFullName}} </span>
  </h2>
</div>

<div mat-dialog-content style="min-height: 400px; overflow-x: hidden;">
  <div *ngIf="isLoading" style="text-align: center;margin-top: 150px;">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
        Loading...
  </div>
  <form [formGroup]="applicantForm" class="needs-validation" novalidate="" style="padding:20px">
    <ul [ngStyle]="{'padding':  AppComponent.isDesktop ? '' : 'inherit', 'margin-left':  AppComponent.isDesktop ? '' : '-20px'}"> 
      <li *ngFor="let q of questions;let indexQ = index">
        <p>
          {{q.question_text}}
        </p>

        <!-- <ul>
          <li *ngFor="let a of q.answers">
            <p>
              {{a.answer_text}}
            </p>
          </li>
        </ul> -->
        <mat-radio-group  aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="q.selectedAnswer" formControlName="selectAnswerControl{{indexQ}}" required>
          <mat-radio-button class="example-radio-button" *ngFor="let a of q.answers; let index = index" [value]="index">
            {{a.answer_text}}
          </mat-radio-button>
        </mat-radio-group>
      </li>
    </ul>
  </form>
  <div >
    <div *ngIf="isSubmitting">
      <mat-progress-spinner diameter="15"></mat-progress-spinner> please wait..
    </div>
    <a style="width: 100%;" href="javascript:void()"><button [disabled]=" isSubmitting || isLoading || applicantForm.invalid " (click)="save()" class="btn btn-primary  btn-block">Continue</button></a>
  </div>
</div>

