import { Component, OnInit, Inject, Input, EventEmitter, NgZone } from '@angular/core';
import { LoanTypeEnum } from 'src/models/loan-type.enum';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/shared/api.service';
import { PERSONAL_LOAN, PERSONAL_LOAN_INFOPersonal_loan_type } from 'src/models/personal_loan.model';
import * as _ from 'lodash'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { XPRESS_APPLICANT } from 'src/models/xpress_loan.model';
import { DocuSignComponent } from '../docu-sign/docu-sign.component';
import { FinalPageEnum } from 'src/models/common.model';
import { ApplicantLoanComponent } from 'src/app/applicant-loan/applicant-loan.component';
import { LoanAppService } from 'src/shared/loan-app.service';
import { FinalPageComponent } from 'src/components/final-page/final-page.component';
import { PlaidService } from 'src/shared/plaid.service';
import { AtomicService } from 'src/shared/atomic.service';
import { Router } from '@angular/router';
import { LoanApiLogService } from 'src/shared/loan-api-log.service';

declare var gtag: any;
@Component({
  selector: 'app-answer-questions',
  templateUrl: './answer-questions.component.html',
  styleUrls: ['./answer-questions.component.css']
})
export class AnswerQuestionsComponent implements OnInit {
    
  appId: string ="";
  decisionAppId : string ="";
  selectedProducts : any[];
  questions = [];
  session_id ="";
  htmlResult = "";
  isLoading= true;
  isSubmitting = false;
  hasJointApp = false;
  isJointAppHint = "Joint Applicant";
  currentAppIndex = '0';
  currentFullName = '';
  applicants : XPRESS_APPLICANT[];
  preciseIdResults :any =[];
  soResponses: any;
  

  AppComponent = AppComponent;
  constructor(
    public dialogRef: MatDialogRef<AnswerQuestionsComponent>, 
    private dialog: MatDialog,
    private apiService: ApiService,
    private fb: FormBuilder,
    private loanAppService: LoanAppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private plaidService: PlaidService,
    private atomicService: AtomicService,
    private ngZone : NgZone,
    private loanApiLogService: LoanApiLogService
    ) {
      this.appId = data.appId;
      this.decisionAppId= data.decisionAppId;
      this.hasJointApp = data.hasJointApp;
      this.applicants = data.applicants;
      this.selectedProducts = data.selectedProducts;
      this.soResponses = data.soResponses;
   }
   applicantForm: FormGroup = new FormGroup({});
   ngOnInit(): void {
    this.ngZone.run(async ()=>{
      
  
      if ( this.decisionAppId) {
        await this.apiService.getLoanDecision(this.decisionAppId).toPromise();
      }
    })
   
  
    this.currentFullName = this.applicants[0].first_name + " " + this.applicants[0].last_name;
    this.getQuestions('0', 'false');
    gtag('event', 'gtm.timer', {
      'value': `The application is complete except answering the final “Precise Question.”`
    })
  }

  private getQuestions(index: string, isJoint: string) {
    this.currentAppIndex = index;
    this.apiService.getQuestions(this.appId, index, isJoint).subscribe(r => {
      if(!r){
       
        return;
      }
     
      ApplicantLoanComponent.currentStepIdChanged.emit({lastPage: "Show Questions Popup"})

      this.isLoading = false;
      this.session_id = r.session_id;
      r.questions.forEach((q, index) => {
        this.applicantForm.addControl(`selectAnswerControl${index}`, new FormControl('', Validators.required));
      });
      this.questions = r.questions;
    },err=>{
      this.dialogRef.close();
    });
  }

  save(){
    var answers = _.map(this.questions, q=>{
      return {
        answer_text: (q.selectedAnswer +1 ).toString()
      }
    }) 
    this.isSubmitting= true;
    this.isLoading= true;
    this.apiService.submitAnswers(this.appId, this.currentAppIndex, 'false', this.session_id, answers).subscribe(async r=>{
      this.isSubmitting= false;
      this.preciseIdResults.push(r);
      if(this.hasJointApp){
        //loading questions for joint app
        this.questions = [];
        this.currentFullName = this.applicants[0].SPOUSE.first_name + " " + this.applicants[0].SPOUSE.last_name;
        //reload
        this.isLoading = true;
        this.getQuestions('0', 'true');
        this.hasJointApp = false;
      }else{

        //Open Docu-Sign
        this.dialogRef.close(this.preciseIdResults);
      }

    });
  }




}
